import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import {
  formatoFechaHora,
  formatoMexico,
  getColorStatusApuesta,
} from "../../../../Config/reuserFunctions";
import { ApuestasContext } from "../Context/ApuestasContext";
import { STATUS_APUESTA } from "../../../../Config/constantes";
import { Chip } from "@mui/material";
import { useTheme } from "@emotion/react";

const getStylesRecompensa = (theme, status) => {
  switch (status) {
    case STATUS_APUESTA.cancelada:
      return {
        bgcolor: theme.palette.indicatorRed.main,
        color: theme.palette.indicatorRed.text,
      }
      case STATUS_APUESTA.perdida:
        return {
          bgcolor: theme.palette.indicatorRed.main,
          color: theme.palette.indicatorRed.text,
      }
      case STATUS_APUESTA.ganada:
        return {
          bgcolor: theme.palette.indicatorGreen.main,
          color: theme.palette.indicatorGreen.text,
      }
    default:
      return {
        bgcolor: theme.palette.background.paper,
        //color: theme.palette.indicatorBlue.text,
      }
  }

}

export default function ApuestasTableBody({ data, handleSelect, juegosQuery }) {
  const { itemsSelected } = React.useContext(ApuestasContext);
  const { totalRecompensa, totalJugado } = juegosQuery;
  const theme = useTheme();

  return (
    <TableBody>
      {data.map((res) => {
        const checked = itemsSelected.find((item) => item._id === res._id);
        const username = res.usuarioData
          ? `${res.usuarioData?.nombre} ${res.usuarioData?.apellidoPaterno}`
          : res.nombre_usuario;
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{
              height: "30px",
              "& .cell": {
                fontSize: 12,
                py: 0,
              },
            }}
          >
            <TableCell
              padding="checkbox"
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 2,
                bgcolor: (theme) => theme.palette.background.paper,
                //borderLeft: `7px solid ${getColorStatusApuesta(res.status)}`,
              }}
            >
              <Checkbox
                onChange={(e) => handleSelect(e.target.checked, res)}
                onClick={(e) => e.stopPropagation()}
                checked={checked ? true : false}
                disabled={res.status === STATUS_APUESTA.cancelada}
              />
            </TableCell>
            <TableCell className="cell">
              {res.torneoData?.nombre || res?.torneo_name || ""}
            </TableCell>
            <TableCell className="cell">
              {res.charreadaData?.nombre || res?.charreada_name || ""}
            </TableCell>
            <TableCell className="cell">
              {res.equipoData?.nombre || res?.equipo_name || ""}
            </TableCell>
            <TableCell className="cell">
              {res.suerteData?.suerte || res?.suerte_name || ""}
            </TableCell>
            <TableCell className="cell">
              {res.charroData?.nombre || res?.charro_name || ""}
            </TableCell>
            <TableCell className="cell">{username}</TableCell>
            <TableCell className="cell">
              {res.usuarioData?.email || ""}
            </TableCell>
            <TableCell className="cell">{formatoMexico(res.momio)}</TableCell>
            <TableCell
              className="cell"
              sx={{
                bgcolor: (theme) => theme.palette.indicatorBlue.main,
                color: (theme) => theme.palette.indicatorBlue.text,
                position: "sticky",
                right: 240,
              }}
            >
              <b>${formatoMexico(res.apuesta)}</b>
            </TableCell>
            <TableCell
              className="cell"
              sx={{
                ...getStylesRecompensa(theme, res.status),
                //bgcolor: (theme) =>  theme.palette.indicatorGreen.main,
                //color: (theme) =>  theme.palette.indicatorGreen.text,
                position: "sticky",
                right: 100,
              }}
            >
              <b>{res.status === STATUS_APUESTA.ganada ? `$${formatoMexico(res.recompensa)}` : "$0"}</b>
            </TableCell>
            <TableCell
              className="cell"
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
                color: theme => theme.palette.getContrastText(getColorStatusApuesta(res.status)),
                position: "sticky",
                right: 0,
              }}
            >
              <Chip
                size="small"
                label={res.status}
                sx={{
                  width: "100%",
                  bgcolor: getColorStatusApuesta(res.status),
                  color: theme => theme.palette.getContrastText(getColorStatusApuesta(res.status)),
                }}
              />
            </TableCell>
            <TableCell className="cell">
              {formatoFechaHora(res.fecha)}
            </TableCell>
            <TableCell className="cell">{res.ticket}</TableCell>
          </TableRow>
        );
      })}
      <TableRow
        sx={{
          height: "30px",
          position: "sticky",
          bottom: 30,
          zIndex: 3,
          backgroundColor: (theme) => theme.palette.customPaper,
          "& .cell": {
            fontSize: 12,
            py: 0,
          },
        }}
      >
        <TableCell className="cell" colSpan={8} />
        <TableCell
          className="cell"
          align="right"
          sx={{ position: "sticky", right: 345 }}
        >
          <b>Totales</b>
        </TableCell>
        <TableCell
          className="cell"
          sx={{
            bgcolor: (theme) => theme.palette.indicatorBlue.main,
            color: (theme) => theme.palette.indicatorBlue.text,
            position: "sticky",
            right: 240,
          }}
        >
          <b>${formatoMexico(totalJugado)}</b>
        </TableCell>
        <TableCell
          className="cell"
          sx={{
            bgcolor: (theme) => theme.palette.indicatorGreen.main,
            color: (theme) => theme.palette.indicatorGreen.text,
            position: "sticky",
            right: 100,
          }}
        >
          <b>${formatoMexico(totalRecompensa)}</b>
        </TableCell>
        <TableCell className="cell" colSpan={3} />
        {/* <TableCell
          className="cell"
          align="right"
          sx={{ position: "sticky", right: 75 }}
        >
          <b>Totales</b>
        </TableCell>
        <TableCell className="cell" sx={{ position: "sticky", right: 0 }}>
          <b>${formatoMexico(totalJugado + totalRecompensa)}</b>
        </TableCell> */}
      </TableRow>
      <TableRow
        sx={{
          height: "30px",
          position: "sticky",
          bottom: 0,
          zIndex: 3,
          backgroundColor: (theme) => theme.palette.customPaper,
          "& .cell": {
            fontSize: 12,
            py: 0,
          },
        }}
      >
        <TableCell className="cell" colSpan={12} />
        <TableCell
          className="cell"
          align="right"
          sx={{ minWidth: 110, position: "sticky", right: 100 }}
        >
          <b>Total SC</b>
        </TableCell>
        <TableCell className="cell" sx={{ position: "sticky", right: 0 }}>
          <b>${formatoMexico(totalJugado - totalRecompensa)}</b>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
