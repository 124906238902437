import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function ErrorAutocomplete({ label }) {
  return (
    <Autocomplete
      disabled
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          size="small"
          label={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: "Error al cargar",
          }}
        />
      )}
    />
  );
}
