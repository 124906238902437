import React, { createContext } from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetUsers } from "../graphql/queries";
import { GetCatsMov } from "../../../SolicitudesRetiro/graphql/queries";

export const UsuariosContext = createContext();

const initialCats = {
  movimiento: "",
  status: "",
  torneo: "",
};

const UserCtxProvider = ({ children }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState("");
  const [filterCats, setFilterCats] = React.useState(initialCats);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 20 });
  const [FILTER] = useDebounce(filter, 500);
  const [FILTERCATS] = useDebounce(filterCats, 500);
  const { page, limit } = pageConfig;
  const [itemsSelected, setItemsSelected] = React.useState([]);
  const [indeterminated, setIndeterminated] = React.useState(false);

  const usersQuery = useQuery(GetUsers, {
    variables: {
      filter: FILTER,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const catsQuery = useQuery(GetCatsMov, {
    fetchPolicy: "network-only",
    variables: {
      filter: FILTERCATS,
    },
  });

  const { data, loading, error, refetch } = usersQuery;

  return (
    <UsuariosContext.Provider
      value={{
        filter,
        setFilter,
        initialCats,
        catsQuery,
        filterCats,
        setFilterCats,
        queryData: data,
        queryLoading: loading,
        queryError: error,
        queryRefetch: refetch,
        pageConfig,
        setPageConfig,
        itemsSelected,
        setItemsSelected,
        indeterminated,
        setIndeterminated,
      }}
    >
      {children}
    </UsuariosContext.Provider>
  );
};

export default UserCtxProvider;
