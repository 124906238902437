import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import UserUnLock from "../Actions/UserUnLock";

export default function BlackListTableBody({ data }) {
  return (
    <TableBody>
      {data.map((res) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{ height: "48px" }}
          >
            <TableCell>{res.nombre}</TableCell>
            <TableCell>{res.email}</TableCell>
            <TableCell>{res.motivo}</TableCell>
            <TableCell>{res.fecha}</TableCell>
            <TableCell>
              <UserUnLock data={res} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
