import React from "react";
import Box from "@mui/material/Box";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import esLocale from "date-fns/locale/es";
import moment from "moment-timezone";
import { MovimientoContext } from "../Context/MovimientoContext";
import { IconButton, Paper } from "@mui/material";
import { FilterAltOff } from "@mui/icons-material";

export default function FilterDates() {
  const { filter, setFilter, initialFilter } = React.useContext(
    MovimientoContext
  );

  const handleChange = (name, value) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const handleClear = () => {
    setFilter(initialFilter);
  };

  return (
    <Box sx={{ display: "flex", gap: 1, flexGrow: 1 }}>
      <LocalizationProvider dateAdapter={AdapterMoment} locale={esLocale}>
        <DemoContainer
          components={["DatePicker", "DatePicker"]}
          sx={{ padding: 0, overflow: "none" }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Paper>
              <DatePicker
                value={filter.fechaInicio ? moment(filter.fechaInicio) : null}
                onChange={(val) =>
                  handleChange(
                    "fechaInicio",
                    moment(val).tz("America/Mexico_City").format()
                  )
                }
                label="De"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
            </Paper>
            <Paper>
              <DatePicker
                value={filter.fechaFin ? moment(filter.fechaFin) : null}
                onChange={(val) =>
                  handleChange(
                    "fechaFin",
                    moment(val).tz("America/Mexico_City").format()
                  )
                }
                label="A"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
            </Paper>
          </Box>
        </DemoContainer>
      </LocalizationProvider>
      <IconButton color="primary" onClick={handleClear}>
        <FilterAltOff />
      </IconButton>
    </Box>
  );
}
