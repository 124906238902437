import { blue, green, grey, orange, red, yellow } from "@mui/material/colors";

export const light = {
  palette: {
    mode: "light",
    primary: {
      main: red["A200"],
    },
    secondary: {
      main: red["A400"],
    },
    success: {
      main: green[600],
    },
    customPaper: "#f6f8fb",
    navbar: "#151929", //red["A200"],
    scharra1: "#D72022",
    scharra2: "#5D9E33",
    indicatorBlue: {
      main: blue[50],
      text: '#000',
    },
    indicatorYellow: {
      main: yellow[50],
      text: '#000',
    },
    indicatorGreen: {
      main: green[50],
      text: green[900],
    },
    indicatorRed: {
      main: red[50],
      text: '#000',
    },
  },
};
export const dark = {
  palette: {
    mode: "dark",
    navbar: "#2a385a",
    customPaper: "#1a203b",
    scharra1: "#D72022",
    scharra2: "#5D9E33",
    indicatorBlue: {
      main: blue[200],
      text: '#000',
    },
    indicatorYellow: {
      main: yellow[100],
      text: '#000',
    },
    indicatorGreen: {
      main: green[100],
      text: green[900],
    },
    indicatorRed: {
      main: red[100],
      text: '#000',
    },
    primary: {
      main: red["A200"],
    },
    secondary: {
      main: red["A400"],
    },
    background: {
      default: "#2a385a",
      paper: "#262b4b",
    },
    error: {
      main: red[700],
    },
    warning: {
      main: orange[800],
    },
    info: {
      main: blue[300],
    },
    success: {
      main: green[700],
    },
    text: {
      primary: "#ffffff", 
      secondary: grey[300], 
      disabled: grey[200], 
    },
    divider: "rgba(255,255,255,0.12)", 
    action: {
      active: "#ffffff", 
      hover: "rgba(255,255,255,0.08)",
      selected: "rgba(255,255,255,0.16)",
      disabled: "rgba(255,255,255,0.3)",
      disabledBackground: "rgba(255,255,255,0.12)",
    },
  },
};
