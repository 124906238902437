import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ArrowBack, Add, Edit } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { CharroContext } from "../Context/CharroContext";
import { CreateCharro, UpdateCharro } from "../graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { CustomButton } from "../../../../../Components/CustomMuiComponents";
import { useDebounce } from "use-debounce";
import { GetTeams } from "../../Equipos/graphql/queries";

const initialState = {
  nombre: "",
  equipo: "",
  equipoData: {
    nombre: "",
  },
  foto: "",
};

export default function CrearCharro({ data = initialState, edit = false }) {
  const { charrosQuery } = React.useContext(CharroContext);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [createCharro] = useMutation(CreateCharro);
  const [updateCharro] = useMutation(UpdateCharro);
  const [open, setOpen] = React.useState(false);
  const [charro, setCharro] = React.useState(initialState);

  const handleOpen = () => {
    setOpen(true);
    if (edit)
      setCharro({
        ...data,
      });
  };

  const handleClose = () => {
    setOpen(false);
    setCharro(initialState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCharro((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let { equipoData, ...input } = charro;
      if(!input.foto) delete input.foto;
      const response = await createCharro({
        variables: {
          input,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createCharro.message,
        variant: "success",
      });
      charrosQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result)
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let { _id, equipoData, ...input } = charro;
      if(!input.foto) delete input.foto;
      const response = await updateCharro({
        variables: {
          idCharro: _id,
          input
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateCharro.message,
        variant: "success",
      });
      charrosQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen} size="small">
          <Edit />
        </IconButton>
      ) : (
        <CustomButton
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpen}
          size="small"
          disableElevation
        >
          Nuevo
        </CustomButton>
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "35vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Nuevo charro
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="form-charro"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "80vh" }}>
            <form id="form-charro" onSubmit={edit ? handleUpdate : handleSave}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Nombre
                </Typography>
                <TextField
                  tabIndex={1}
                  fullWidth
                  required
                  name="nombre"
                  size="small"
                  value={charro.nombre}
                  onChange={handleChange}
                />
              </Box>
              <AutocompleteTeams charro={charro} setCharro={setCharro} />
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}

const AutocompleteTeams = ({ charro, setCharro }) => {
  const [inputValue, setInputValue] = React.useState("");
  const [filter] = useDebounce(inputValue, 500);
  const { tokenSesion } = React.useContext(MainContext);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleChange = (event, value) => {
    setCharro((st) => ({
      ...st,
      equipo: value?._id || "",
      equipoData: { nombre: value?.nombre || "" },
    }));
  };

  const { loading, data } = useQuery(GetTeams, {
    variables: {
      filter,
      page: 0,
      limit: 20,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const dataArr = data?.getTeams?.docs || [];

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Typography color="gray">
          <span style={{ color: "red" }}>*</span>Equipo
        </Typography>
        <Autocomplete
          options={dataArr}
          getOptionLabel={(option) => option?.nombre || ""}
          onInputChange={handleInputChange}
          size="small"
          renderInput={(params) => (
            <TextField required {...params} variant="outlined" />
          )}
          onChange={handleChange}
          required
          value={charro.equipoData}
          inputValue={inputValue}
          loading={loading}
          isOptionEqualToValue={(option, value) => option.nombre === value.nombre}
          renderOption={(props, option) => (
            <li {...props} key={option._id}>
              {option.nombre}
            </li>
          )}
        />
      </Box>
    </>
  );
};
