import { gql } from "@apollo/client";

export const CreateCharro = gql`
  mutation createCharro($input: charroInput) {
    createCharro(input: $input) {
      message
    }
  }
`;

export const UpdateCharro = gql`
  mutation updateCharro($idCharro: ID!, $input: charroInput) {
    updateCharro(idCharro: $idCharro, input: $input) {
      message
    }
  }
`;

export const DeleteCharro = gql`
  mutation deleteCharro($idCharro: ID!) {
    deleteCharro(idCharro: $idCharro) {
      message
    }
  }
`;
