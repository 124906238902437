import React from "react";
import { Box, Stack } from "@mui/material";
import ApuestasCtxProvider, {
  ApuestasContext,
} from "./Context/ApuestasContext";
import SearchComponent from "./Actions/Search";
import Tableapuestas from "./Table";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import FilterChips from "../../../Components/FilterChips";
import CancelarApuestas from "./Actions/CancelarApuestas";
import ColorLeyend from "./Actions/ColorLeyend";
import DeleteTestGames from "./Actions/DeleteTestGames";

export default function ApuestasRealizadas() {
  return (
    <Box>
      <ApuestasCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          sx={{ pb: 1 }}
          bgcolor="background.paper"
        >
          <ColorLeyend sx={{display: {xs: "none", md: "flex"}}} />
          <SearchComponent />
          <DeleteTestGames />
          <CancelarApuestas />
          <Export />
        </Stack>
        <ColorLeyend sx={{display: {xs: "flex", md: "none"}}} />
        <FilterChips context={ApuestasContext} sx={{ display: "flex" }} />
        <RenderTable />
      </ApuestasCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { apuestasQuery } = React.useContext(ApuestasContext);

  if (apuestasQuery.loading) return <CustomLoader />;
  if (apuestasQuery.error)
    return <ErrorMessage message={apuestasQuery.error.message} />;

  return <Tableapuestas />;
};
