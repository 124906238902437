import { gql } from "@apollo/client";

export const CreateSuerte = gql`
  mutation createSuerte($suerte: String!) {
    createSuerte(suerte: $suerte) {
      message
    }
  }
`;

export const UpdateSuerte = gql`
  mutation updateSuerte($idSuerte: ID!, $suerte: String!) {
    updateSuerte(idSuerte: $idSuerte, suerte: $suerte) {
      message
    }
  }
`;

export const DeleteSuerte = gql`
  mutation deleteSuerte($idSuerte: ID!) {
    deleteSuerte(idSuerte: $idSuerte) {
      message
    }
  }
`;
