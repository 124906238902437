import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Close, PriceCheck } from "@mui/icons-material";
import { MainContext } from "../../../../Context/MainCtx";
import TableMovimientosUser from "./Table";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import ExcelExport from "./CortesExport";
import { IconButton } from "@mui/material";
import { GetCortesVendedor } from "../../Cortes/graphql/queries";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CortesDetail({ idUser, usuarioData }) {
  const [open, setOpen] = React.useState(false);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClickOpen}>
        <PriceCheck />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          sx: {
            height: "90vh",
          },
        }}
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{ flexGrow: 1 }}
              variant="h6"
            >{`Todos los movimientos de ${usuarioData.nombre}`}</Typography>
            <ExcelExport idUser={idUser} />
            <Button
              size="small"
              variant="contained"
              disableElevation
              onClick={handleClose}
              sx={{ textTransform: "none" }}
              startIcon={<Close />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <RenderTable
            idUser={idUser}
            setPageConfig={setPageConfig}
            pageConfig={pageConfig}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const RenderTable = ({ idUser, setPageConfig, pageConfig }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const { page, limit } = pageConfig;

  const query = useQuery(GetCortesVendedor, {
    variables: {
      idUser,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const { loading, data, error } = query;

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const datos = data?.getCortesVendedor;

  return (
    <TableMovimientosUser
      query={datos}
      setPageConfig={setPageConfig}
      pageConfig={pageConfig}
    />
  );
};
