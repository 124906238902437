import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { DeleteOutline } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../../Context/MainCtx";
import { DeleteUserOld } from "../../graphql/mutations";

export default function EliminarOld({ user, refetch, handleClose }) {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [deleteUserOld] = useMutation(DeleteUserOld);

  const handleDelete = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const res = await deleteUserOld({
        variables: {
          idUser: user._id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: res.data.deleteUserOld.message,
        variant: "success",
      });
      refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Button
      variant="contained"
      color="warning"
      size="small"
      disableElevation
      sx={{ textTransform: "none", mx: 1 }}
      onClick={handleDelete}
      disabled={loading}
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : <DeleteOutline />
      }
    >
      Eliminar
    </Button>
  );
}
