import React from "react";
import { gql, useMutation } from "@apollo/client";
import { MainContext } from "../Context/MainCtx";
import { useLocation } from "react-router-dom";

const CreateLogError = gql`
  mutation createLogError($input: inputLogError) {
    createLogError(input: $input) {
      message
    }
  }
`;

export default function CreateLogErrorComponent() {
  const { tokenDecoded, errors } = React.useContext(MainContext);
  const [createLogError] = useMutation(CreateLogError);
  const [path, setPath] = React.useState("");
  const location = useLocation();
  const usuario = tokenDecoded?._id || "";

  const productionLogs = process.env.REACT_APP_LOGS;

  React.useEffect(() => {
    setPath(location.pathname);
    if (errors && productionLogs !== "true" && location.pathname !== path) {
      const newLogError = async () => {
        try {
          const { graphQLErrors, networkError, message, funcion } = errors;
          let statusCode = "";
          let detalles = "";

          if (graphQLErrors && graphQLErrors.length) {
            const { extensions, message } = graphQLErrors[0];
            statusCode = extensions.code;
            detalles = message;
          }
          if (networkError) {
            const { result } = networkError;
            if (result && result.errors.length) {
              const { extensions, message } = result.errors[0];
              statusCode = extensions.code;
              detalles = message;
            } else {
              statusCode = networkError.statusCode;
              detalles = message;
            }
          }
          await createLogError({
            variables: {
              input: {
                usuario,
                message,
                statusCode,
                detalles,
                funcion,
              },
            },
          });
        } catch (err) {
          console.log(err);
          console.log(err.networkError?.result);
        }
      };
      newLogError();
    }
  }, [path, location, errors, productionLogs, createLogError, usuario]);

  return null;
}
