import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AvatarMenuComponent from "../../Components/Avatar/AvatarMenuComponent";
import { CustomButton } from "../CustomMuiComponents";
import { Stack } from "@mui/material";
//import { useNavigate } from "react-router-dom";
import CustomIconComponent from "../CustomIconComponent";
import { itemsNav } from "./listItems";
import { MainContext } from "../../Context/MainCtx";
import logoHoriz from "../../assets/images/logoHoriz.png";
import UserBalance from "../Avatar/UserBalance";
import { HashLink } from "react-router-hash-link";
import FooterResponsive from "../Footer/FooterResponsive";
import LazyImage from "../LazyImage";

export default function Navbar() {
  //const navigate = useNavigate();
  const { tokenDecoded } = React.useContext(MainContext);

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{ bgcolor: (theme) => theme.palette.navbar }}
    >
      <Stack
        component={Toolbar}
        variant="dense"
        direction="row"
        alignItems="center"
        spacing={1}
      >
        <Box
          sx={{ display: "flex", pr: 3, cursor: "pointer" }}
          component={HashLink}
          to="/#inicio"
        >
          <LazyImage src={logoHoriz} alt="suerte charra" height={50} />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {itemsNav(tokenDecoded).map((res, index) => (
          <CustomButton
            key={index}
            size="small"
            color="inherit"
            disableElevation
            sx={{ display: { xs: "none", md: "flex" } }}
            component={res.component}
            to={res.url}
            startIcon={
              res.icon ? (
                <CustomIconComponent
                  iconName={res.icon}
                  //color="primary"
                />
              ) : null
            }
          >
            <b>{res.title}</b>
          </CustomButton>
        ))}
        {tokenDecoded ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <UserBalance />
          </Box>
        ) : null}
        {tokenDecoded ? (
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <AvatarMenuComponent />
          </Box>
        ) : null}
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <FooterResponsive />
        </Box>
      </Stack>
    </AppBar>
  );
}
