import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DragIndicator } from "@mui/icons-material";
import CreateNewFaq from "./crearteFaq";
import { useMutation, useQuery } from "@apollo/client";
import { GetFAQs } from "./graphql/queries";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import DeleteFaq from "./deleteFaq";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../../../Components/CustomDroppable";
import { OrderFAQ } from "./graphql/mutations";
import { MainContext } from "../../../../Context/MainCtx";
import PreviewContent from "../../../../Components/CustomEditor/PreviewContent";
import UploadImgFaq from "./UploadImgFaq";

export default function FaqComponent() {
  const dataQuery = useQuery(GetFAQs, {
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">Preguntas Frecuentes</Typography>
        <CreateNewFaq refetch={dataQuery.refetch} />
      </Box>
      <Box mt={2}>
        <RenderFaqList dataQuery={dataQuery} />
      </Box>
    </div>
  );
}

const RenderFaqList = ({ dataQuery }) => {
  const { data, loading, error, refetch } = dataQuery;
  const [items, setItems] = React.useState([]);
  const [ordenUpdate] = useMutation(OrderFAQ);
  const { snackMessage } = React.useContext(MainContext);

  React.useEffect(() => {
    if (data) setItems(data.getFaqs);
  }, [data]);

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  // Función para manejar el evento de arrastrar y soltar
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    setItems(newItems);
    await ordenFaqUpdate(newItems);
  };

  const ordenFaqUpdate = async (newItems) => {
    try {
      const IDs = newItems.map((res) => res._id);
      await ordenUpdate({
        variables: { IDs },
      });
      refetch();
    } catch (err) {
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="faqs">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((res, index) => (
                <Draggable key={res._id} draggableId={res._id} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        pb: 1,
                        gap: 1,
                        bgcolor: "background.paper",
                      }}
                    >
                      <Box sx={{ p: 1 }} {...provided.dragHandleProps}>
                        <DragIndicator color="action" />
                      </Box>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6">
                          <b>{`#${index + 1} ${res.pregunta}`}</b>
                        </Typography>
                        <Box sx={{ pl: 3 }}>
                          <PreviewContent content={res.respuesta} />
                        </Box>
                        <UploadImgFaq faq={res} refetch={refetch} />
                      </Box>
                      <Box
                        sx={{
                          p: 1,
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 1,
                        }}
                      >
                        <CreateNewFaq data={res} edit refetch={refetch} />
                        <DeleteFaq data={res} refetch={refetch} />
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </Box>
  );
};
