import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { ArrowBack, Add, Edit } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { CharreadaContext } from "../Context/CharreadaContext";
import { CreateCharreada, UpdateCharreada } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CustomButton } from "../../../../../Components/CustomMuiComponents";

export default function CrearCharreada({ data = "", edit = false }) {
  const { charreadasQuery } = React.useContext(CharreadaContext);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [createCharreada] = useMutation(CreateCharreada);
  const [updateCharreada] = useMutation(UpdateCharreada);
  const [open, setOpen] = React.useState(false);
  const [charreada, setCharreada] = React.useState({ nombre: "" });

  const handleOpen = () => {
    setOpen(true);
    if (edit) setCharreada(data);
  };

  const handleClose = () => {
    setOpen(false);
    setCharreada({ nombre: "" });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setCharreada((state) => ({
      ...state,
      nombre: value,
    }));
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const response = await createCharreada({
        variables: { nombre: charreada.nombre },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createCharreada.message,
        variant: "success",
      });
      charreadasQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const response = await updateCharreada({
        variables: {
          idCharreada: charreada._id,
          nombre: charreada.nombre,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateCharreada.message,
        variant: "success",
      });
      charreadasQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen} size="small">
          <Edit />
        </IconButton>
      ) : (
        <CustomButton
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpen}
          size="small"
          disableElevation
        >
          Nuevo
        </CustomButton>
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "35vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Nueva charreada
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="form-charrea"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "80vh" }}>
            <form id="form-charrea" onSubmit={edit ? handleUpdate : handleSave}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Nombre
                </Typography>
                <TextField
                  tabIndex={1}
                  fullWidth
                  required
                  name="nombre"
                  size="small"
                  value={charreada.nombre}
                  onChange={handleChange}
                />
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}
