import React, { createContext } from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetLogErrors } from "../graphql/queries";

export const LogsContext = createContext();

const LogsCtxProvider = ({ children }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState("");
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const { page, limit } = pageConfig;

  const logsQuery = useQuery(GetLogErrors, {
    variables: {
      filter: FILTER,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const { data, loading, error, refetch } = logsQuery;

  return (
    <LogsContext.Provider
      value={{
        filter,
        setFilter,
        queryData: data,
        queryLoading: loading,
        queryError: error,
        queryRefetch: refetch,
        pageConfig,
        setPageConfig,
      }}
    >
      {children}
    </LogsContext.Provider>
  );
};

export default LogsCtxProvider;
