import { gql } from "@apollo/client";

export const CreateStep = gql`
  mutation createStep($input: stepInput) {
    createStep(input: $input) {
      message
    }
  }
`;

export const UpdateStep = gql`
  mutation updateStep($idStep: ID!, $input: stepInput) {
    updateStep(idStep: $idStep, input: $input) {
      message
    }
  }
`;
export const DeleteStep = gql`
  mutation deleteStep($idStep: ID!) {
    deleteStep(idStep: $idStep) {
      message
    }
  }
`;
export const OrdenStepUpdate = gql`
  mutation ordenStepUpdate($IDs: [ID]) {
    ordenStepUpdate(IDs: $IDs) {
      message
    }
  }
`;
