import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { ArrowBack, Add, Edit } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { FactorContext } from "../Context/FactorContext";
import { CreateFactor, UpdateFactor } from "../graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { CustomButton } from "../../../../../Components/CustomMuiComponents";
import { useDebounce } from "use-debounce";
import { GetSuertes } from "../../../Catalogos/Suertes/graphql/queries";
import AutocompleteInput from "../../../../../Components/Inputs/AutocompleteInput";

const initialState = {
  suerte: "",
  suerteData: null,
  promMin: 1,
  promMax: 1,
  factor: 0,
};

export default function Crear({ data = initialState, edit = false }) {
  const { factoresQuery, type } = React.useContext(FactorContext);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [createFactor] = useMutation(CreateFactor);
  const [updateFactor] = useMutation(UpdateFactor);
  const [open, setOpen] = React.useState(false);
  const [factor, setFactor] = React.useState(initialState);

  const handleOpen = () => {
    setOpen(true);
    if (edit)
      setFactor({
        ...data,
      });
  };
  const handleClose = () => {
    setOpen(false);
    setFactor(initialState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFactor({
      ...factor,
      [name]: parseFloat(value),
    });
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setFactor({ ...factor, [name]: 0, factor: 0 });
      return;
    }
    if (factor.promMin && factor.promMax) {
      let calculo = parseFloat((factor.promMin / factor.promMax).toFixed(4));
      setFactor({ ...factor, factor: calculo });
    }
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let { suerteData, ...input } = factor;
      const response = await createFactor({
        variables: {
          input: {
            ...input,
            typeFactor: type,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createFactor.message,
        variant: "success",
      });
      factoresQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let { _id, suerteData, ...input } = factor;
      const response = await updateFactor({
        variables: {
          idFactor: _id,
          input: {
            ...input,
            typeFactor: type,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateFactor.message,
        variant: "success",
      });
      factoresQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen} size="small">
          <Edit />
        </IconButton>
      ) : (
        <CustomButton
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpen}
          size="small"
          disableElevation
        >
          Nuevo
        </CustomButton>
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "35vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Nuevo factor
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="form-factor"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "80vh" }}>
            <form id="form-factor" onSubmit={edit ? handleUpdate : handleSave}>
              <AutocompleteTeams factor={factor} setFactor={setFactor} />
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Promedio mínimo
                </Typography>
                <TextField
                  fullWidth
                  required
                  name="promMin"
                  size="small"
                  type="number"
                  value={factor.promMin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    min: 1,
                    step: "0.01",
                  }}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Promedio máximo
                </Typography>
                <TextField
                  fullWidth
                  required
                  name="promMax"
                  size="small"
                  type="number"
                  value={factor.promMax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    min: 1,
                    step: "0.01",
                  }}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Factor
                </Typography>
                <TextField
                  fullWidth
                  required
                  name="factor"
                  size="small"
                  type="number"
                  onChange={handleChange}
                  value={factor.factor}
                  inputProps={{
                    min: 0.0001,
                    step: "0.0001",
                  }}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>TypeFactor
                </Typography>
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={type}
                  disabled
                />
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}

const AutocompleteTeams = ({ factor, setFactor }) => {
  const [inputValue, setInputValue] = React.useState("");
  const [filter] = useDebounce(inputValue, 500);
  const { tokenSesion } = React.useContext(MainContext);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleChange = (event, value) => {
    setFactor((st) => ({
      ...st,
      suerte: value?._id || "",
      suerteData: value
        ? {
            suerte: value.suerte,
            _id: value._id,
          }
        : null,
    }));
  };

  const { loading, data, error } = useQuery(GetSuertes, {
    variables: {
      filter,
      page: 0,
      limit: 20,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const dataArr = data?.getSuertes?.docs || [];

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Typography color="gray">
          <span style={{ color: "red" }}>*</span>Suerte
        </Typography>
        <AutocompleteInput
          renderData={dataArr}
          value={factor.suerteData}
          inputValue={inputValue}
          loading={loading}
          error={error}
          onChange={handleChange}
          onInputChange={handleInputChange}
          isOptionEqualToValue={(option, value) =>
            option.suerte === value.suerte
          }
          required
          getOptionLabel={(option) => option?.suerte || ""}
        />
      </Box>
    </>
  );
};
