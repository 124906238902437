import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";

export default function MovimientosTableHead({ handleSort, order, orderBy }) {
  const headCells = [
    {
      id: "status",
      padding: "checkbox",
      align: "left",
      label: "Status",
      sortable: false,
      sx: null,
    },
    {
      id: "movimiento",
      padding: "checkbox",
      align: "left",
      label: "Movimiento",
      sortable: false,
      sx: null,
    },
    {
      id: "concepto",
      padding: "normal",
      align: "left",
      label: "Concepto",
      sortable: false,
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "monto",
      padding: "checkbox",
      align: "left",
      label: "Monto",
      sortable: false,
      sx: null,
    },
    {
      id: "fecha",
      padding: "normal",
      align: "left",
      label: "Fecha",
      sortable: true,
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "comentarios",
      padding: "normal",
      align: "left",
      label: "Comentarios",
      sortable: false,
      sx: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...headCell.sx,
              "&:hover .button-filter": {
                display: "inline-flex!important",
              },
            }}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell align="right" padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}
