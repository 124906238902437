import React, { createContext } from "react";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetApuestasManager } from "../graphql/queries";
import { io } from "socket.io-client";
import { GetAllCatsCats } from "../../../Dashboard/Auditorias/Movimientos/graphql/queries";

export const ApuestasContext = createContext();

const initialFilter = {
  search: "",
  torneo: "",
  equipo: "",
  charro: "",
  suerte: "",
  status: "",
};
const initialCats = {
  torneo: "",
  equipo: "",
  charro: "",
  charreada: "",
  suerte: "",
  statusApuesta: "",
};

const ApuestasCtxProvider = ({ children }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initialFilter);
  const [filterCats, setFilterCats] = React.useState(initialCats);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  //const [FILTERCATS] = useDebounce(filterCats, 500);
  const [itemsSelected, setItemsSelected] = React.useState([]);
  const [indeterminated, setIndeterminated] = React.useState(false);
  const { page, limit } = pageConfig;

  const apuestasQuery = useQuery(GetApuestasManager, {
    variables: {
      filter: FILTER,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });
  const catsQuery = useQuery(GetAllCatsCats, {
    variables: { filter: initialCats },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  console.log(apuestasQuery)

  const { refetch } = apuestasQuery;
  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("createApuesta", () => refetch());
    return () => socket.disconnect();
  }, [refetch]);

  return (
    <ApuestasContext.Provider
      value={{
        filter,
        setFilter,
        setFilterCats,
        filterCats,
        initialFilter,
        apuestasQuery,
        catsQuery,
        pageConfig,
        setPageConfig,
        itemsSelected,
        setItemsSelected,
        indeterminated,
        setIndeterminated,
      }}
    >
      {children}
    </ApuestasContext.Provider>
  );
};

export default ApuestasCtxProvider;
