import { withSnackbar } from "notistack";
import React, { createContext } from "react";
import jwtDecode from "jwt-decode";
import { useCookies } from "react-cookie";
import { io } from "socket.io-client";

export const MainContext = createContext();

const initialPaymentState = { title: "", value: 0, img: "", monto: 0, completed: false }

const MainCtxProvider = ({ children, enqueueSnackbar }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["_ScSesion"]);
  const tokenSesion = cookies._ScSesion;
  const decoded = tokenSesion ? jwtDecode(tokenSesion) : null;
  const [tokenDecoded, setTokenDecoded] = React.useState(decoded);
  const [theming, setTheming] = React.useState(localStorage.getItem("theming"));
  const [errors, setErrors] = React.useState(null);
  const [dataPayment, setDataPayment] = React.useState(initialPaymentState);

  const snackMessage = React.useCallback(
    ({
      message = "No Message",
      variant = "default",
      preventDuplicate = true,
      anchorOrigin = { horizontal: "center", vertical: "top" },
    }) => {
      enqueueSnackbar(message, {
        variant,
        preventDuplicate,
        anchorOrigin,
        style: {
          top: 35,
        },
      });
    },
    [enqueueSnackbar]
  );

  const handleCloseSesion = React.useCallback(() => {
    removeCookie("_ScSesion", { path: "/" });
    localStorage.removeItem("currentToken");
    setTokenDecoded(null);
  }, [removeCookie]); 

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("newSessionActive", (data) => {
      const { token, userId } = data;

      const currentToken = localStorage.getItem("currentToken")
      const currentDecoded = currentToken ? jwtDecode(currentToken) : null;

      if (!currentDecoded || !currentToken) return; //si no tiene sesion activa no avanza
      if (currentDecoded?._id !== userId) return; //si no es el usuario logeado no avanza
      if (currentToken === token) return; // si el token es el mismo no avanza
      //hay sesion activa, el usuario es el mismo y el token es diferente, entonces le hace un logout
      handleCloseSesion();
    });
    return () => socket.disconnect();
  }, [handleCloseSesion]);

  return (
    <MainContext.Provider
      value={{
        snackMessage,
        tokenDecoded,
        tokenSesion,
        setCookie,
        handleCloseSesion,
        setTokenDecoded,
        theming,
        setTheming,
        errors,
        setErrors,
        dataPayment,
        setDataPayment,
        initialPaymentState
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default withSnackbar(MainCtxProvider);
