import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import { Done } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { SendRecoverPass } from "./gql/mutations";
import { MainContext } from "../../../Context/MainCtx";
import { useMutation } from "@apollo/client";

export default function RecoverPassword() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [sendRecoverPassword] = useMutation(SendRecoverPass);
  const { snackMessage } = React.useContext(MainContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmail("");
  };

  const handleSendEmail = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      const response = await sendRecoverPassword({
        variables: {
          email,
        },
      });
      setLoading(false);
      snackMessage({
        message: response.data.sendRecoverPassword.message,
        variant: "success",
      });
      handleClose();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} sx={{ textTransform: "none" }}>
        Olvidé mi contraseña
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ingresa tu dirección de correo electrónico, te enviaremos un enlace
            para restablecer tu contraseña, recuerda que si no esta en tu
            bandeja de entrada, puede estar en <b>Spam</b>
          </DialogContentText>
          <br />
          <form id="recover-pass" onSubmit={handleSendEmail}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="tu correo electrónico"
              fullWidth
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            sx={{ textTransform: "none" }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            form="recover-pass"
            variant="contained"
            size="small"
            disableElevation
            disabled={loading}
            sx={{ textTransform: "none" }}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Done />
              )
            }
          >
            Enviar email
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
