import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useMatches, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CreateUser } from "./gql/mutations";
import { MainContext } from "../../../Context/MainCtx";
import jwtDecode from "jwt-decode";

function Confirmation() {
  const navigate = useNavigate();
  const matches = useMatches();
  const token = matches && matches.length > 0 ? matches[0].params.token : "";
  const [createUser] = useMutation(CreateUser);
  const { snackMessage, setTokenDecoded, setCookie } = React.useContext(MainContext);

  const hasExecutedEffect = React.useRef(false);

  React.useEffect(() => {
    const createNewUser = async () => {
      try {
        const response = await createUser({
          variables: {
            token,
          },
        });
        setCookie("_ScSesion", response.data.createUser.token, { path: "/" });
        localStorage.setItem("currentToken", token);
        const decoded = jwtDecode(response.data.createUser.token);
        setTokenDecoded(decoded);
        snackMessage({
          message: "Cuenta confirmada con éxito",
          variant: "success",
        });
        if (decoded.rol === process.env.REACT_APP_ROL_ADMON) {
          navigate("/admin");
          return;
        }
        navigate("/");
      } catch (error) {
        snackMessage({
          message: error.message,
          variant: "error",
        });
        navigate(`/confirmation/error/${error.message}`);
        if (error.networkError) console.log(error.networkError.result);
      }
    };
    if (!hasExecutedEffect.current) {
      hasExecutedEffect.current = true;
      createNewUser();
    }
  }, [createUser, navigate, setCookie, setTokenDecoded, snackMessage, token]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
      }}
    >
      <CircularProgress />
      <Typography>Espere un momento...</Typography>
    </Box>
  );
}

export default Confirmation;
