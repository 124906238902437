import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CrearCompetencia from "../Actions/Create";
import DeleteCompetencia from "../Actions/Delete";
import {
  formatoFechaCorta,
  formatoMexico,
  isMobile,
} from "../../../../../Config/reuserFunctions";
import ChipStatus from "../Actions/Status";
import { CompetenciaContext } from "../Context/CompetenciaContext";
import { useQuery } from "@apollo/client";
import { GetCaculatedFields } from "../graphql/queries";
import { io } from "socket.io-client";
import { EventBusy, Star, VisibilityOff } from "@mui/icons-material";
import { STATUS_COMP } from "../../../../../Config/constantes";
import { Box, Checkbox } from "@mui/material";

export default function CompetenciasTableBody({ data, shadows, handleSelect }) {
  const { compQuery, itemsSelected, setCompDetail } = React.useContext(
    CompetenciaContext
  );

  return (
    <TableBody>
      {data.map((res) => {
        const checked = itemsSelected.find((item) => item._id === res._id);
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{
              height: "30px",
              "& .cell": {
                fontSize: 12,
                py: 0,
              },
            }}
            hover
            onClick={() => setCompDetail(res)}
          >
            <TableCell
              padding="checkbox"
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 2,
                bgcolor: (theme) => theme.palette.background.paper,
              }}
            >
              <Checkbox
                onChange={(e) => handleSelect(e.target.checked, res)}
                onClick={(e) => e.stopPropagation()}
                checked={checked ? true : false}
              />
            </TableCell>
            <TableCell align="right" padding="checkbox">
              <Box display="flex">
              {res.hidden && (
                  <VisibilityOff fontSize="small" color="action" />
                )}
                {res.status === STATUS_COMP.ganador ? (
                  <Star fontSize="small" color="info" />
                ) : res.noSePresento ? (
                  <EventBusy fontSize="small" color="action" />
                ) : null}
              </Box>
            </TableCell>
            <TableCell className="cell">
              {res.torneoData?.nombre || res?.torneo_name || ""}
            </TableCell>
            <TableCell className="cell">{res.charreadaData?.nombre}</TableCell>
            <TableCell className="cell">{res.suerteData?.suerte}</TableCell>
            <TableCell
              className="cell"
              align="center"
              sx={{
                bgcolor: (theme) => theme.palette.indicatorYellow.main,
                color: (theme) => theme.palette.indicatorYellow.text,
              }}
            >
              {res.typeFactor || 3}
            </TableCell>
            <TableCell
              className="cell"
              align="center"
              sx={{
                bgcolor: (theme) => theme.palette.indicatorYellow.main,
                color: (theme) => theme.palette.indicatorYellow.text,
              }}
            >
              {res.typeCuota || 3}
            </TableCell>
            <TableCell className="cell">{res.equipoData?.nombre}</TableCell>
            <TableCell className="cell">{res.charroData?.nombre}</TableCell>
            <RenderCalculos res={res} shadows={shadows} />
            <TableCell
              className="cell"
              align="center"
              sx={{
                position: "sticky",
                right: 150,
                bgcolor: (theme) => theme.palette.background.paper,
                px: 1,
              }}
            >
              <ChipStatus competencia={res} refetch={compQuery.refetch} />
            </TableCell>
            <TableCell className="cell">
              {formatoFechaCorta(res.createdAt)}
            </TableCell>
            <TableCell
              className="cell"
              align="right"
              sx={{
                position: "sticky",
                right: 100,
                bgcolor: (theme) => theme.palette.background.paper,
                px: 1,
              }}
            >
              <CrearCompetencia data={res} reply />
            </TableCell>
            <TableCell
              className="cell"
              align="right"
              sx={{
                position: "sticky",
                right: 66,
                bgcolor: (theme) => theme.palette.background.paper,
                px: 0,
              }}
            >
              <CrearCompetencia data={res} edit />
            </TableCell>
            <TableCell
              className="cell"
              align="right"
              sx={{
                position: "sticky",
                right: 0,
                bgcolor: (theme) => theme.palette.background.paper,
              }}
            >
              <DeleteCompetencia data={res} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}

const RenderCalculos = ({ res, shadows }) => {
  const { showCalc } = React.useContext(CompetenciaContext);

  const { data, refetch } = useQuery(GetCaculatedFields, {
    variables: {
      input: {
        idCompetencia: res._id,
        torneo: res.torneo,
        equipo: res.equipo,
        charreada: res.charreada,
        charro: res.charro,
        suerte: res.suerte,
        multiplicador: res.multiplicador,
        momioMin: res.momioMin,
        momioMax: res.momioMax,
      },
    },
    //fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedMomio", () => refetch());
    return () => socket.disconnect();
  }, [refetch]);

  let calc = null;
  if (data) calc = data.getCalcCompetencia;

  return (
    <>
      {showCalc ? (
        <TableCell className="cell">
          {calc ? formatoMexico(calc.promedio) : ""}
        </TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">{calc ? calc.mayor : ""}</TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">{calc ? calc.menor : ""}</TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">{calc ? calc.aritmetica : ""}</TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">{calc ? calc.HECTOR : ""}</TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">{calc ? calc.media : ""}</TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">{calc ? calc.probabilidad : ""}</TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">{calc ? calc.constante : ""}</TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">{calc ? calc.factor : ""}</TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">
          {calc ? formatoMexico(calc.rendimiento) : ""}%
        </TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">
          {calc ? formatoMexico(calc.cuota) : ""}
        </TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">
          ${calc ? formatoMexico(calc.valorPunto) : ""}
        </TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">
          ${calc ? formatoMexico(calc.ponderador) : ""}
        </TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">
          ${calc ? formatoMexico(calc.ponderado) : ""}
        </TableCell>
      ) : null}
      <TableCell className="cell">
        ${calc ? formatoMexico(calc.apuestas) : ""}
      </TableCell>
      {showCalc ? (
        <TableCell className="cell">
          ${calc ? formatoMexico(calc.totalApuestas) : ""}
        </TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">
          ${calc ? formatoMexico(calc.bolsa) : ""}
        </TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">
          ${calc ? formatoMexico(calc.bolsaMenosApuesta) : ""}
        </TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">
          ${calc ? formatoMexico(calc.porcentajeCasa) : ""}
        </TableCell>
      ) : null}
      {showCalc ? (
        <TableCell className="cell">
          $
          {calc ? formatoMexico(calc.bolsaMenosApuestaMenosPorcentajeCasa) : ""}
        </TableCell>
      ) : null}
      <TableCell
        className={`cell ${isMobile ? "" : shadows.end}`}
        sx={{
          position: "sticky",
          right: 265,
          bgcolor: (theme) => theme.palette.indicatorBlue.main,
          color: (theme) => theme.palette.indicatorBlue.text,
        }}
      >
        {calc ? formatoMexico(calc.momio) : ""}
      </TableCell>
      <TableCell className="cell">{formatoMexico(res.multiplicador)}</TableCell>
    </>
  );
};
