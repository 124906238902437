import React from "react";
import { Box, Stack } from "@mui/material";
import ResultadoCtxProvider, {
  ResultadoContext,
} from "./Context/ResultadoContext";
import TableResultados from "./Table";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import CreateResultado from "./Actions/Create";
import FilterChips from "../../../../Components/FilterChips";

export default function Resultados() {
  return (
    <Box>
      <ResultadoCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          sx={{ mb: 1, position: { md: "absolute" }, top: 60, right: 24 }}
          bgcolor="background.paper"
        >
          <CreateResultado />
          <Export />
        </Stack>
        <FilterChips context={ResultadoContext} sx={{ display: "flex" }} />
        <RenderTable />
      </ResultadoCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { resultQuery } = React.useContext(ResultadoContext);

  if (resultQuery.loading) return <CustomLoader />;
  if (resultQuery.error)
    return <ErrorMessage message={resultQuery.error.message} />;

  return <TableResultados />;
};
