import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Close, FilterAltOutlined } from "@mui/icons-material";
import { MainContext } from "../../../../../../Context/MainCtx";
import { GetMovimientosUserReport } from "./queries";
import TableMovimientosUser from "./Table";
import CustomLoader from "../../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import FilterChips from "../FilterChips";
import UserExcelExport from "./UserExport";
import { formatoMexico } from "../../../../../../Config/reuserFunctions";
import { TIPO_MOVIMIENTO } from "../../../../../../Config/constantes";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialFilter = {
  fechaInicio: null,
  fechaFin: null,
  status: "",
  movimiento: "",
};

export default function UserDetail({ idUser, usuarioData, data, nombre }) {
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState(initialFilter);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { tokenSesion } = React.useContext(MainContext);
  const { page, limit } = pageConfig;

  const query = useQuery(GetMovimientosUserReport, {
    variables: {
      filter,
      usuario: idUser,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const totalAdquirido =
    query.data?.getMovimientosUserReport?.totalAdquirido || 0;
    const totalRetiro =
    query.data?.getMovimientosUserReport?.totalRetiro || 0;

    const username = `${usuarioData?.nombre} ${usuarioData?.apellidoPaterno ?? ""} ${usuarioData?.apellidoMaterno ?? ""}`

  return (
    <React.Fragment>
      <Button
        size="small"
        sx={{ fontSize: 12, textTransform: "none", py: 0 }}
        onClick={handleClickOpen}
        color="info"
      >
        {`${ username || nombre}`}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          sx: {
            height: "90vh",
          },
        }}
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6">
                {`Movimientos de `}
                <b>
                  {usuarioData
                    ? `${usuarioData?.nombre} ${usuarioData?.apellidoPaterno} ${usuarioData?.apellidoMaterno}`
                    : nombre}
                </b>
              </Typography>
              <Typography sx={{ lineHeight: 0.5 }} variant="subtitle1">
                {usuarioData?.email || ""}
              </Typography>
            </Box>
            <UserExcelExport filter={filter} usuario={idUser} dataComp={data} />
            <Button
              size="small"
              variant="contained"
              disableElevation
              onClick={handleClose}
              sx={{ textTransform: "none" }}
              startIcon={<Close />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
            <Button
              size="small"
              variant="outlined"
              color={
                filter.movimiento === TIPO_MOVIMIENTO.compra
                  ? "primary"
                  : "inherit"
              }
              disableElevation
              onClick={() =>
                !filter.movimiento
                  ? setFilter((st) => ({
                      ...st,
                      movimiento: TIPO_MOVIMIENTO.compra,
                    }))
                  : setFilter(initialFilter)
              }
              sx={{ textTransform: "none" }}
              endIcon={<FilterAltOutlined />}
            >
              <Typography>
                {`Toros Comprados: `}
                <b>{formatoMexico(totalAdquirido)}</b>
              </Typography>
            </Button>
            <Button
              size="small"
              variant="outlined"
              color={
                filter.movimiento === TIPO_MOVIMIENTO.retiro
                  ? "primary"
                  : "inherit"
              }
              disableElevation
              onClick={() =>
                !filter.movimiento
                  ? setFilter((st) => ({
                      ...st,
                      movimiento: TIPO_MOVIMIENTO.retiro,
                    }))
                  : setFilter(initialFilter)
              }
              sx={{ textTransform: "none" }}
              endIcon={<FilterAltOutlined />}
            >
              <Typography>
                {`Retiros: `}
                <b>{formatoMexico(totalRetiro)}</b>
              </Typography>
            </Button>
            <Typography>
              Balance: <b>{formatoMexico(usuarioData?.saldo)}</b>
            </Typography>
            <Typography>
              SC: <b>{formatoMexico(totalAdquirido - usuarioData?.saldo - totalRetiro)}</b>
            </Typography>
          </Box>
          <FilterChips filter={filter} setFilter={setFilter} />
          <RenderTable
            query={query}
            setPageConfig={setPageConfig}
            pageConfig={pageConfig}
            filter={filter}
            setFilter={setFilter}
            dataComp={data}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const RenderTable = ({
  setPageConfig,
  pageConfig,
  filter,
  setFilter,
  dataComp,
  query,
}) => {
  const { loading, data, error } = query;

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const datos = data?.getMovimientosUserReport;

  return (
    <TableMovimientosUser
      query={datos}
      setPageConfig={setPageConfig}
      pageConfig={pageConfig}
      filter={filter}
      setFilter={setFilter}
      dataComp={dataComp}
    />
  );
};
