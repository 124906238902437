import React from "react";
import LinkMui from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import logoHoriz from "../../assets/images/logoHoriz.png";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { Facebook, Instagram } from "@mui/icons-material";
import moment from "moment-timezone";
import CookieConsent from "./Cookies";
import LazyImage from "../LazyImage";
import packageJson from "../../../package.json";

function Footer() {
  const year = moment().year();
  return (
    <Box sx={{ zIndex: 3 }}>
      <CookieConsent />
      <Box
        sx={{
          p: 2,
          py: 1,
          pb: { xs: 10, md: 5 },
          bgcolor: (theme) => theme.palette.navbar,
          display: {xs: "none",  md: "block"}
        }}
      >
        <Grid container spacing={3}>
          <Grid item>
            <Box>
              <LinkMui
                color="white"
                sx={{ display: "block", my: 0.5 }}
                component={Link}
                to="/about"
                underline="none"
              >
                {"Quiénes somos"}
              </LinkMui>
              <LinkMui
                color="white"
                sx={{ display: "block", my: 0.5 }}
                component={Link}
                to="/terminos"
                underline="none"
              >
                {"Términos y condiciones"}
              </LinkMui>
              <LinkMui
                color="white"
                sx={{ display: "block", my: 0.5 }}
                component={Link}
                to="/politicas"
                underline="none"
              >
                {"Políticas de privacidad"}
              </LinkMui>
            </Box>
          </Grid>
          <Grid item sx={{ flexGrow: 1 }}>
            <Box>
              <LinkMui
                color="white"
                sx={{ display: "block", my: 0.5 }}
                component={Link}
                to="/faq"
                underline="none"
              >
                {"Preguntas frecuentes"}
              </LinkMui>
              <LinkMui
                color="white"
                sx={{ display: "block", my: 0.5 }}
                href="/indicaciones"
                underline="none"
              >
                {"Indicaciones para jugar"}
              </LinkMui>
              <LinkMui
                color="white"
                sx={{ display: "block", my: 0.5 }}
                component={Link}
                to="/contacto"
                underline="none"
              >
                {"Contáctanos"}
              </LinkMui>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LazyImage src={logoHoriz} alt="suerte charra" height={50} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography color="white">
                Síguenos en nuestras redes sociales
              </Typography>
              <Box
                component="a"
                target="_blank"
                href="https://www.facebook.com/SuerteCharraMX"
              >
                <Facebook htmlColor="white" />
              </Box>
              <Box
                component="a"
                target="_blank"
                href="https://www.instagram.com/suertecharramx"
              >
                <Instagram htmlColor="white" />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Typography color="white" align="center">
          Copyright {year}© suertecharra.com v{packageJson.version}
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
