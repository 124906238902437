import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Delete } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { CircularProgress, IconButton } from "@mui/material";
import { ManagerContext } from "../Context/ManagerContext";
import { DeleteUserDash } from "../graphql/mutations";

export default function DeleteUser({ data }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const { queryRefetch } = React.useContext(ManagerContext);
  const [deleteUser] = useMutation(DeleteUserDash);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      handleClose();
      setLoading(true);
      const response = await deleteUser({
        variables: {
          idUser: data._id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.deleteUser.message,
        variant: "success",
      });
      queryRefetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <IconButton
        color="error"
        size="small"
        onClick={() => handleClickOpen()}
      >
        {loading ? <CircularProgress size={20} color="inherit" /> : <Delete fontSize="small" />}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Eliminar ${data.nombre}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se va a eliminar este elemento, ¿Quiere continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus color="inherit">
            No, cancelar
          </Button>
          <Button
            onClick={() => handleDelete()}
            color="error"
            variant="contained"
            disableElevation
          >
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
