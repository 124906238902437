import React from "react";
import { Box, Stack } from "@mui/material";
import CompetenciaCtxProvider, {
  CompetenciaContext,
} from "./Context/CompetenciaContext";
import TableCompetencias from "./Table";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import CreateCompetencia from "./Actions/Create";
import FilterChips from "../../../../Components/FilterChips";
import ActionSelectMenu from "./Actions/ActionButtons";
import ShowCalcs from "./Actions/ShowCalc";
import FactorSettings from "./Actions/FactorSettings";
import CuotaSettings from "./Actions/CuotaSettings";

export default function Competencias() {
  return (
    <Box>
      <CompetenciaCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
          sx={{ position: { md: "absolute" }, top: 55, right: 24 }}
          bgcolor="background.paper"
        >
          <ActionSelectMenu />
          <ShowCalcs />
          <FactorSettings />
          <CuotaSettings />
          <CreateCompetencia />
          <Export />
        </Stack>
        <FilterChips
          context={CompetenciaContext}
          sx={{ display: 'flex' }}
        />
        <RenderTable />
      </CompetenciaCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { compQuery } = React.useContext(CompetenciaContext);

  if (compQuery.loading) return <CustomLoader />;
  if (compQuery.error)
    return <ErrorMessage message={compQuery.error.message} />;

  return <TableCompetencias />;
};
