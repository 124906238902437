import React from 'react'
import ButtonsBanners from './ButtonsBanners'
import CarouselBanners from './CarouselBanners'
import { Box } from '@mui/material'

export default function Banners() {
  return (
    <div>
        <ButtonsBanners />
        <Box sx={{my: 5}} />
        <CarouselBanners />
    </div>
  )
}
