import { gql } from "@apollo/client";

export const GetMovimientosUser = gql`
  query getMovimientosUser(
    $idUsuario: ID!
    $filter: usuerFilterMovs
    $limit: Int
    $page: Int
  ) {
    getMovimientosUser(
      idUsuario: $idUsuario
      filter: $filter
      limit: $limit
      page: $page
    ) {
      pendiente
      docs {
        _id
        usuario
        usuarioData {
          nombre
        }
        monto
        balanceEnMovimiento
        concepto
        movimiento
        status
        comentarios
        fecha
        nombreAfilial
      }
      totalDocs
      page
      totalPages
    }
  }
`;
