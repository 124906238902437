import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Done, Visibility, VisibilityOff } from "@mui/icons-material";
import { MainContext } from "../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { Container, Grid } from "@mui/material";
import { UpdateUser } from "../../Home/Perfil/gql/mutations";

export default function UserForm({ data, refetch }) {
  const { snackMessage, tokenSesion, setErrors } = React.useContext(
    MainContext
  );
  const [loading, setLoading] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [user, setUser] = React.useState({
    ...data?.getUser,
    password: "",
    repeatPassword: "",
  });
  const [imagenes, setImagenes] = React.useState([]);
  const [updateUser] = useMutation(UpdateUser);

  React.useEffect(() => {
    if (data?.getUser?.imagenesID?.length) {
      setImagenes(data.getUser.imagenesID);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (user.password && user.password !== user.repeatPassword) {
        snackMessage({
          message: "Las contraseñas no coiciden",
          variant: "error",
        });
        return;
      }
      setLoading(true);
      let noAwsImages = [...imagenes].filter((res) => !res.key);
      const { _id, clabeBanco, ...input } = user;
      const response = await updateUser({
        variables: {
          idUser: _id,
          input: {
            ...input,
            newImgs: noAwsImages,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateUser.message,
        variant: "success",
      });
      refetch();
      setLoading(false);
      setUser({
        ...user,
        password: "",
        repeatPassword: "",
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
      setErrors({ ...error, funcion: "updateUser" });
    }
  };

  return (
    <Container maxWidth="md" sx={{ p: { md: 4, xs: 1 } }}>
      <form onSubmit={handleSave}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6" noWrap>
            Configuración de mi cuenta
          </Typography>
          <Button
            size="small"
            color="primary"
            type="submit"
            variant="contained"
            disableElevation
            disabled={loading}
            sx={{ textTransform: "none" }}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Done />
              )
            }
          >
            Guardar
          </Button>
        </Box>
        <Box mb={2} mt={2}>
          <Typography variant="subititle2">
            <b>Información básica</b>
          </Typography>
          <Divider />
        </Box>
        <Box mb={2}>
          <Typography variant="body2" color="gray">
            Nombres(s)<span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            name="nombre"
            required
            fullWidth
            value={user?.nombre}
            onChange={handleChange}
          />
        </Box>
        <Box mb={2} mt={2}>
          <Typography variant="subititle2">
            <b>Información de contácto</b>
          </Typography>
          <Divider />
        </Box>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <Typography variant="body2" color="gray">
              Direccion
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              name="direccion"
              value={user?.direccion}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="body2" color="gray">
              Correo electrónico
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={user?.email}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="body2" color="gray">
              Telefono
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              name="telefono"
              value={user?.telefono}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Box mb={1} mt={2}>
          <Typography variant="subititle2">
            <b>Seguridad</b>
          </Typography>
          <Divider />
        </Box>
        <Box>
          <Typography variant="subtitle2">
            <b>Cambiar contraseña</b>
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            label="Nueva contraseña"
            fullWidth
            margin="dense"
            name="password"
            type={view ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setView(!view)}>
                    {view ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={user.password}
            required={user.password ? true : false}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            size="small"
            label="Confirma tu contraseña"
            fullWidth
            margin="dense"
            name="repeatPassword"
            value={user.repeatPassword}
            required={user.password ? true : false}
            type={view ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setView(!view)}>
                    {view ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            error={
              user.password &&
              user.repeatPassword &&
              user.password !== user.repeatPassword
                ? true
                : false
            }
          />
        </Box>
      </form>
    </Container>
  );
}
