import React from "react";
import { Box, Stack } from "@mui/material";
import MovsSellerCtxProvider, {
  MovSellerContext,
} from "./Context/MovSellerContext";
import SearchComponent from "./Actions/Search";
import TableMoviminetosSeller from "./Table";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import FilterChips from "../../../Components/FilterChips";

export default function MovimientosSeller() {
  return (
    <Box>
      <MovsSellerCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          sx={{ pb: 1, mt: {xs: 1, md: 0} }}
          bgcolor="background.paper"
          justifyContent="flex-end"
        >
          <SearchComponent />
          <Export />
        </Stack>
        <FilterChips context={MovSellerContext} sx={{ display: "flex" }} />
        <RenderTable />
      </MovsSellerCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { movsQuery } = React.useContext(MovSellerContext);

  if (movsQuery.loading) return <CustomLoader />;
  if (movsQuery.error)
    return <ErrorMessage message={movsQuery.error.message} />;

  return <TableMoviminetosSeller />;
};
