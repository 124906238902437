import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CrearEstado from "../Actions/Create";
import DeleteState from "../Actions/Delete";

export default function EstadosTableBody({ estados }) {

  return (
    <TableBody>
      {estados.map((state) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={state._id}
            sx={{ height: "48px" }}
          >
            <TableCell>{state.estado}</TableCell>
            <TableCell>{state.pais}</TableCell>
            <TableCell align="right"><CrearEstado data={state} edit /></TableCell>
            <TableCell align="right"><DeleteState data={state} /></TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
