import { gql } from "@apollo/client";

export const CreateFactor = gql`
  mutation createFactor($input: factorInput) {
    createFactor(input: $input) {
      message
    }
  }
`;

export const UpdateFactor = gql`
  mutation updateFactor($idFactor: ID!, $input: factorInput) {
    updateFactor(idFactor: $idFactor, input: $input) {
      message
    }
  }
`;

export const DeleteFactor = gql`
  mutation deleteFactor($idFactor: ID!) {
    deleteFactor(idFactor: $idFactor) {
      message
    }
  }
`;
