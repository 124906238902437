import { gql } from "@apollo/client";

export const CreateMovimientoRetiro = gql`
  mutation createMovimientoRetiro($input: movimientoInput) {
    createMovimientoRetiro(input: $input) {
      message
    }
  }
`;

export const CancelMovimientoRetiro = gql`
  mutation cancelMovimientoRetiro($idMovimiento: ID!, $idUsuario: ID!,  $comentarios: String, $status: String) {
    cancelMovimientoRetiro(idMovimiento: $idMovimiento, idUsuario: $idUsuario, comentarios: $comentarios, status: $status) {
      message
    }
  }
`;