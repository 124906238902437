import React, { createContext } from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetCuotas } from "../graphql/queries";

export const CuotaContext = createContext();

const CuotaCtxProvider = ({ children, typeCuota }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState("");
  const [type, setType] = React.useState(typeCuota || 3);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const { page, limit } = pageConfig;

  const cuotasQuery = useQuery(GetCuotas, {
    variables: {
      filter: FILTER,
      page,
      limit,
      type,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    if(typeCuota) setType(typeCuota)
  }, [typeCuota])

  return (
    <CuotaContext.Provider
      value={{
        filter,
        setFilter,
        cuotasQuery,
        pageConfig,
        setPageConfig,
        type,
        setType,
      }}
    >
      {children}
    </CuotaContext.Provider>
  );
};

export default CuotaCtxProvider;
