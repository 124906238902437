import React, { useState } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { Box, Button, ButtonGroup, CircularProgress } from "@mui/material";
import { Done } from "@mui/icons-material";

function RichTextEditor({ saveFunction = null, content = null }) {
  const [loading, setLoading] = React.useState();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  React.useEffect(() => {
    if (content) {
      const parseContent = JSON.parse(content);
      const contentState = convertFromRaw(parseContent);
      onChange(EditorState.createWithContent(contentState));
    }
  }, [content]);

  const onChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const onToggleInlineStyle = (style) => {
    onChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  const onToggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const handleKeyBinding = (e) => {
    if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.key === "M") {
      return "toggle-monospace";
    }
    return getDefaultKeyBinding(e);
  };

  // Load previously saved editor content if any
  const loadSavedContent = async () => {
    try {
      if (!saveFunction) return;
      setLoading(true);
      const content = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      //funcition to save
      await saveFunction(content);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error loading saved content:", err);
    }
  };

  const handleReturn = (e) => {
    if (e.shiftKey) {
      onChange(RichUtils.insertSoftNewline(editorState));
      return "handled";
    }
    return "not-handled";
  };

  return (
    <Box>
      <Box
        sx={{
          position: "sticky",
          top: 48,
          display: "flex",
          gap: 1,
          bgcolor: "background.paper",
          py: 1,
          zIndex: 9,
        }}
      >
        <ButtonGroup size="small" variant="outlined" color="inherit">
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-one")}
          >
            H1
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-two")}
          >
            H2
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-three")}
          >
            H3
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-four")}
          >
            H4
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-five")}
          >
            H5
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-six")}
          >
            H6
          </Button>
        </ButtonGroup>
        <ButtonGroup size="small" variant="outlined" color="inherit">
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleInlineStyle("BOLD")}
          >
            <b>Bold</b>
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleInlineStyle("ITALIC")}
          >
            <i>Italic</i>
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleInlineStyle("UNDERLINE")}
          >
            <u>Underline</u>
          </Button>
        </ButtonGroup>
        <ButtonGroup size="small" variant="outlined" color="inherit">
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("unordered-list-item")}
          >
            Ul
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("ordered-list-item")}
          >
            Ol
          </Button>
        </ButtonGroup>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          size="small"
          disableElevation
          onClick={loadSavedContent}
          startIcon={
            loading ? <CircularProgress size={20} color="inherit" /> : <Done />
          }
        >
          Guardar
        </Button>
      </Box>
      <Box sx={{ minHeight: "75vh" }}>
        <Editor
          editorState={editorState}
          onChange={onChange}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={handleKeyBinding}
          handleReturn={handleReturn}
          keyCommandMap={{
            "toggle-monospace": "toggle-monospace",
          }}
        />
      </Box>
    </Box>
  );
}

export default RichTextEditor;
