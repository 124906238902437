import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { Box, Container, Toolbar } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import BottomNav from "../../Components/Navbar/ResponsiveNav";
import background from "../../assets/images/background.png";
import CreateLogComponent from "../../Components/LogUser";
import CreateLogErrorComponent from "../../Components/ErrorLog";
import { MainContext } from "../../Context/MainCtx";
import { ROLES } from "../../Config/constantes";

export default function Home() {
  const { tokenDecoded } = React.useContext(MainContext);

  if (tokenDecoded && tokenDecoded.rol === ROLES.MANAGER) return <Navigate to="/seller" />;
  if (tokenDecoded && tokenDecoded.rol === ROLES.SELLER) return <Navigate to="/seller" />;

  return (
    <Box sx={{ bgcolor: "background.default" }}>
      <CreateLogComponent />
      <CreateLogErrorComponent />
      <Navbar />
      <Box
        sx={{
          position: "fixed",
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
        }}
      />
      <Box
        sx={{
          position: "relative",
          //backgroundColor: "rgba(0,0,0,0.5)",
          //backdropFilter: "blur(2px)",
          height: "110%",
          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar variant="dense" />
          <Outlet />
          <Toolbar />
        </Container>
        <Footer />
      </Box>
      <BottomNav />
    </Box>
  );
}
