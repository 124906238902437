import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { listSecondary } from "../listItems";
import CustomIconComponent from "../../../../Components/CustomIconComponent";
import DarkMode from "../../../../Components/DarkMode";
import { MainContext } from "../../../../Context/MainCtx";
import { Logout } from "@mui/icons-material";
import { Badge } from "@mui/material";

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  margin: theme.spacing(2),
  backgroundColor: theme.palette.divider,
  borderRadius: 3,
}));

export default function ListMenuBottom({
  open,
  setOpen,
  pathname,
  handleClickSecodanry,
  getContent,
}) {
  const { handleCloseSesion } = React.useContext(MainContext);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      slotProps={{
        root: {
          style: { zIndex: 1000 },
        },
      }}
      PaperProps={{
        sx: {
          //height: `50%`,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          zIndex: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Puller />
      </Box>
      <Box
        sx={{
          px: 2,
          pb: 2,
          mb: 2,
          height: "100%",
          overflow: "auto",
        }}
      >
        <List>
          {listSecondary.map(({ title, url, icon, badge }, index) => {
            const content = getContent(badge);
            return (
              <ListItem key={`subitems-${index}`} disablePadding>
                <ListItemButton
                  selected={url === pathname}
                  onClick={() => handleClickSecodanry({ title, url })}
                >
                  <ListItemIcon>
                    <Badge badgeContent={content} color="primary">
                      <CustomIconComponent
                        iconName={icon}
                        color={url === pathname ? "primary" : "inherit"}
                      />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </ListItem>
            );
          })}
          <DarkMode listModeRes />
          <ListItem disablePadding>
            <ListItemButton onClick={handleCloseSesion}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Cerrar sesión" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Toolbar />
    </SwipeableDrawer>
  );
}
