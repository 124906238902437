import { gql } from "@apollo/client";

export const GetAdminAuth = gql`
  query getAdminAuth {
    getAdminAuth {
      message
    }
  }
`;
export const GetManagerAuth = gql`
  query getManagerAuth {
    getManagerAuth {
      message
    }
  }
`;
export const GetSellerAuth = gql`
  query getSellerAuth {
    getSellerAuth {
      message
    }
  }
`;
export const GetFilialAuth = gql`
  query getFilialAuth {
    getFilialAuth {
      message
    }
  }
`;