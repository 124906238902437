import * as React from "react";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import {
  formatoFechaHora,
  formatoMexico,
  getColorStatusApuesta,
} from "../../../Config/reuserFunctions";
import { useQuery } from "@apollo/client";
import { GetBetsUser } from "./gql/queries";
import { MainContext } from "../../../Context/MainCtx";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import { Box, Chip } from "@mui/material";
import { AccessTime } from "@mui/icons-material";
import { STATUS_APUESTA } from "../../../Config/constantes";
import { io } from "socket.io-client";

export default function ApuestasEnCurso() {
  const { tokenSesion, tokenDecoded, setErrors } = React.useContext(
    MainContext
  );
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const { page, limit } = pageConfig;

  const { data, loading, error, refetch } = useQuery(GetBetsUser, {
    variables: {
      idUsuario: tokenDecoded._id,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedApuestas", () => refetch());
    return () => socket.disconnect();
  }, [refetch]);

  React.useEffect(() => {
    if (error) setErrors({ ...error, funcion: "getBetsUser" });
  }, [error, setErrors]);

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const { docs, totalDocs } = data?.getBetsUser;

  const handleChangePage = (event, page) => {
    setPageConfig((st) => ({ ...st, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPageConfig({ limit: parseInt(event.target.value, 10), page: 0 });
  };

  const getIconStatus = (status) => {
    const { pendiente } = STATUS_APUESTA;
    switch (status) {
      case pendiente:
        return <AccessTime color="action" />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ py: 0.5 }}>
      <Box
        sx={{
          maxHeight: {
            xs: `calc(90vh - 272px)`,
            sm: `calc(90vh - 180px)`,
            overflow: "auto",
          },
        }}
      >
        {docs.map((res) => {
          const suerte = res.suerteData?.suerte;
          const isTeam = suerte.toLowerCase() === "equipo";
          const status =
            res.status === STATUS_APUESTA.enCurso ? "EN JUEGO" : res.status;
          return (
            <Box
              key={res._id}
              sx={{
                px: 1,
                borderBottom: "1px solid",
                borderColor: "divider",
                py: 1,
              }}
              variant="outlined"
            >
              <Box sx={{ display: { xs: "block", md: "flex" }, gap: 1 }}>
                <Typography variant="body2">
                  <b>{`${res.torneoData?.nombre} ${res.charreadaData?.nombre}`}</b>
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body2">
                  {formatoFechaHora(res.fecha)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {isTeam ? (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Chip
                      size="small"
                      label={
                        <b>{`${formatoMexico(res.momio)} ${
                          res.suerteData?.suerte
                        }`}</b>
                      }
                    />
                    <Typography>{res.equipoData?.nombre}</Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Chip
                      size="small"
                      label={
                        <b>{`${formatoMexico(res.momio)} ${
                          res.suerteData?.suerte
                        }`}</b>
                      }
                    />
                    <Typography>{res.charroData?.nombre}</Typography>
                  </Box>
                )}
                <Chip
                  size="small"
                  icon={getIconStatus(res.status)}
                  label={status}
                  sx={{
                    bgcolor: getColorStatusApuesta(res.status),
                    color: theme => theme.palette.getContrastText(getColorStatusApuesta(res.status)),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "flex-end" },
                }}
              >
                <Box>
                  <Typography align="right">
                    Jugaste: {formatoMexico(res.apuesta)}
                  </Typography>
                  <Typography align="right">
                    Posible recompensa: {formatoMexico(res.totalGanar)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      <TablePagination
        component="div"
        count={totalDocs}
        page={pageConfig.page}
        onPageChange={handleChangePage}
        rowsPerPage={pageConfig.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
