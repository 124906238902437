import React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";

export default function FilterChips({ filter, setFilter, hide }) {
  const data = Object.keys(filter) || [];

  const handleDelete = (key) => {
    setFilter({
      ...filter,
      [key]: "",
    });
  };

  return (
    <Box>
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        {data.map((key, indx) =>
          filter[key] ? (
            key === "fechaInicio" ||
            key === "fechaFin" || hide === key ? null : (
              <Grid item>
                <Chip
                  key={indx}
                  label={filter[key]}
                  size="small"
                  onDelete={() => handleDelete(key)}
                />
              </Grid>
            )
          ) : null
        )}
      </Grid>
    </Box>
  );
}
