import { gql } from "@apollo/client";

export const CreateFilial = gql`
  mutation createFilial($input: updateUsuarioDashInput) {
    createFilial(input: $input) {
      message
    }
  }
`;
export const UpdateUserAdmin = gql`
  mutation updateUserDashboard($idUser: ID!, $input: updateUsuarioDashInput) {
    updateUserDashboard(idUser: $idUser, input: $input) {
      message
    }
  }
`;
