import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../../Components/ExcelExportButton";
import { FactorContext } from "../Context/FactorContext";
import { GetFactores } from "../graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    const { _id, suerteData, ...obj } = res;
    dataFormat.push({
      ...obj,
      suerte: suerteData?.suerte,

    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { factoresQuery, type } = React.useContext(FactorContext);
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const excelData = formatTable(factoresQuery.data?.getFactores?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getFactores = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetFactores,
        variables: {
          filter: "",
          type,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getFactores.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name={`Suerte charra - Factores ${type}`}
        getQueryData={getFactores}
        loading={loading}
        importModel="factores"
        refetchModel={factoresQuery.refetch}
        onlyUpload
        factorCuota={type}
      />
    </div>
  );
}
