import React from "react";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation } from "@apollo/client";
import { SendNewTorneoNotification } from "../graphql/mutations";
import { MainContext } from "../../../../../Context/MainCtx";
import { Email } from "@mui/icons-material";

export default function SendNotification({ torneo }) {
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(null);
  const [sendNewTorneoNotification] = useMutation(SendNewTorneoNotification);

  const updateStastus = async () => {
    try {
      setLoading(true);
      const response = await sendNewTorneoNotification({
        variables: {
          idTorneo: torneo._id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.sendNewTorneoNotification.message,
        variant: "success",
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <IconButton disabled={loading} onClick={() => updateStastus()} size="small">
      {loading ? <CircularProgress size={20} color="inherit" /> : <Email />}
    </IconButton>
  );
}
