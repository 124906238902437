import { gql } from "@apollo/client";

export const GetMotivos = gql`
  query getMotivos {
    getMotivos {
      _id
      motivo
    }
  }
`;
export const CreateMotivo = gql`
  mutation createMotivo($motivo: String!) {
    createMotivo(motivo: $motivo) {
      message
    }
  }
`;
export const DeleteMotivos = gql`
  mutation deleteMotivos($idMotivo: ID!) {
    deleteMotivos(idMotivo: $idMotivo) {
      message
    }
  }
`;