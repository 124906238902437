import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CancelarMovimiento from "../Actions/Cancel";
import { GetIconMovimiento, formatoFechaHora, formatoMexico } from "../../../../Config/reuserFunctions";

export default function MovimientosTableBody({ data, shadows }) {
  return (
    <TableBody>
      {data.map((res) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{
              height: "30px",
              "& .cell": {
                fontSize: 12,
                py: 0,
              },
            }}
          >
            <TableCell className="cell" padding="checkbox">
              <GetIconMovimiento status={res.status} />
            </TableCell>
            <TableCell className="cell">{res.status}</TableCell>
            <TableCell className="cell">{res.movimiento}</TableCell>
            <TableCell className="cell">{res.concepto}</TableCell>
            <TableCell className="cell">{formatoMexico(res.monto || res.balanceEnMovimiento)}</TableCell>
            <TableCell className="cell">
              {formatoFechaHora(res.fecha)}
            </TableCell>
            <TableCell className="cell">{res.comentarios}</TableCell>
            <TableCell
              className="cell"
              align="right"
              sx={{
                position: "sticky",
                right: 0,
                bgcolor: (theme) => theme.palette.background.paper,
              }}
            >
              <CancelarMovimiento data={res} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}