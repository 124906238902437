import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useApolloClient } from "@apollo/client";
import { GetCaculatedFields } from "../graphql/queries";
import { formatoMexico } from "../../../../../Config/reuserFunctions";
import { useDebounce } from "use-debounce";
import { Grid } from "@mui/material";
import { MOMIO_MAX, MOMIO_MIN } from "../../../../../Config/constantes";

export default function CalculatedFields({ competencia, setCompetencia }) {
  const [query, setQuery] = React.useState({
    loading: false,
    error: undefined,
    data: {},
  });
  const client = useApolloClient();
  const [VALUE] = useDebounce(competencia, 500);
  const { data, loading, error } = query;

  React.useEffect(() => {
    if (VALUE.torneo && VALUE.charreada && VALUE.suerte) {
      const getCalculos = async () => {
        try {
          setQuery((st) => ({ ...st, loading: true }));
          const response = await client.query({
            query: GetCaculatedFields,
            variables: {
              input: {
                idCompetencia: VALUE._id || "",
                torneo: VALUE.torneo,
                equipo: VALUE.equipo,
                charreada: VALUE.charreada,
                charro: VALUE.charro,
                suerte: VALUE.suerte,
                multiplicador: VALUE.multiplicador,
                momioMin: VALUE.momioMin,
                momioMax: VALUE.momioMax,
              },
            },
            fetchPolicy: "network-only",
          });
          setQuery((st) => ({
            ...st,
            loading: false,
            data: response.data.getCalcCompetencia,
          }));
        } catch (error) {
          console.log(error)
          setQuery((st) => ({ ...st, loading: false, error }));
        }
      };
      getCalculos();
    }
  }, [VALUE, client]);

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item md={4}>
          <Typography variant="body2" color="gray">
            <span style={{ color: "red" }}>*</span>Multiplicador
          </Typography>
          <TextField
            fullWidth
            name="multiplicador"
            size="small"
            type="number"
            value={competencia.multiplicador}
            onChange={(e) => {
              setCompetencia((state) => ({
                ...state,
                multiplicador: parseFloat(e.target.value),
              }));
            }}
            onBlur={(e) => {
              if (!e.target.value) {
                setCompetencia((state) => ({
                  ...state,
                  multiplicador: 0,
                }));
              }
            }}
            inputProps={{
              min: 0.0,
              step: "0.01",
            }}
          />
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" color="gray">
            Momio mínimo
          </Typography>
          <TextField
            fullWidth
            name="momioMin"
            size="small"
            type="number"
            value={competencia.momioMin}
            onChange={(e) => {
              setCompetencia((state) => ({
                ...state,
                momioMin: parseFloat(e.target.value),
              }));
            }}
            onBlur={(e) => {
              if (!e.target.value) {
                setCompetencia((state) => ({
                  ...state,
                  momioMin: MOMIO_MIN,
                }));
              }
            }}
            inputProps={{
              min: 0.01,
              step: "0.01",
            }}
          />
        </Grid>
        <Grid item md={4}>
          <Typography variant="body2" color="gray">
            Momio máximo
          </Typography>
          <TextField
            fullWidth
            name="momioMax"
            size="small"
            type="number"
            value={competencia.momioMax}
            onChange={(e) => {
              setCompetencia((state) => ({
                ...state,
                momioMax: parseFloat(e.target.value),
              }));
            }}
            onBlur={(e) => {
              if (!e.target.value) {
                setCompetencia((state) => ({
                  ...state,
                  momioMax: MOMIO_MAX,
                }));
              }
            }}
            inputProps={{
              min: 0.01,
              step: "0.01",
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ pb: 3 }}>
        <CalculatedTextField
          name="Promedio"
          data={data.promedio}
          loading={loading}
          error={error}
        />
        <CalculatedTextField
          name="Mayor"
          data={data.mayor}
          loading={loading}
          error={error}
        />
        <CalculatedTextField
          name="Menor"
          data={data.menor}
          loading={loading}
          error={error}
        />
        <CalculatedTextField
          name="Aritmética"
          data={data.aritmetica}
          loading={loading}
          error={error}
        />
        <CalculatedTextField
          name="HECTOR"
          data={data.HECTOR}
          loading={loading}
          error={error}
        />
        <CalculatedTextField
          name="Media"
          data={data.media}
          loading={loading}
          error={error}
        />
        <CalculatedTextField
          name="Probabilidad"
          data={data.probabilidad}
          loading={loading}
          error={error}
        />
        <CalculatedTextField
          name="Constante"
          data={data.constante}
          loading={loading}
          error={error}
        />
        <CalculatedTextField
          name="Factor"
          data={data.factor}
          loading={loading}
          error={error}
        />
        <CalculatedTextField
          name="Rendimiento"
          data={data.rendimiento}
          loading={loading}
          error={error}
          icon="%"
        />
        <CalculatedTextField
          name="Cuota"
          data={data.cuota}
          loading={loading}
          error={error}
        />
        <CalculatedTextField
          name="Valor punto"
          data={formatoMexico(data.valorPunto)}
          loading={loading}
          error={error}
          icon="$"
        />
        <CalculatedTextField
          name="Ponderador"
          data={formatoMexico(data.ponderador)}
          loading={loading}
          error={error}
          icon="$"
        />
        <CalculatedTextField
          name="Ponderado"
          data={formatoMexico(data.ponderado)}
          loading={loading}
          error={error}
          icon="$"
        />
        <CalculatedTextField
          name="Juegos"
          data={formatoMexico(data.apuestas)}
          loading={loading}
          error={error}
          icon="$"
        />
        <CalculatedTextField
          name="Total juegos"
          data={formatoMexico(data.totalApuestas)}
          loading={loading}
          error={error}
          icon="$"
        />
        <CalculatedTextField
          name="Bolsa"
          data={formatoMexico(data.bolsa)}
          loading={loading}
          error={error}
          icon="$"
        />
        <CalculatedTextField
          name="Bolsa - juegos"
          data={formatoMexico(data.bolsaMenosApuesta)}
          loading={loading}
          error={error}
          icon="$"
        />
        <CalculatedTextField
          name="Porcentaje casa"
          data={formatoMexico(data.porcentajeCasa)}
          loading={loading}
          error={error}
          icon="$"
        />
        <CalculatedTextField
          name="Bolsa - juegos - porcentajeCasa"
          data={formatoMexico(data.bolsaMenosApuestaMenosPorcentajeCasa)}
          loading={loading}
          error={error}
          icon="$"
        />
        <CalculatedTextField
          name="Momio"
          data={data.momio}
          loading={loading}
          error={error}
        />
      </Box>
    </Box>
  );
}

const CalculatedTextField = ({ name, data, loading, icon = "" }) => {
  return (
    <Box sx={{ display: "flex", my: 1, gap: 1 }}>
      <Typography color="GrayText">{name}:</Typography>
      {loading ? (
        <Typography>cargando...</Typography>
      ) : (
        <Typography>{`${icon} ${data}`}</Typography>
      )}
    </Box>
  );
};
