import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  ArrowBack,
  Block,
  Close,
  LockClock,
  NotificationImportant,
} from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../Context/MainCtx";
import { MovimientoContext } from "../Context/MovimientoContext";
import {
  AceptarMovimiento,
  AddUserBlacklist,
  CancelMovimientoRetiro,
  SolicitunEnProceso,
} from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { Container, DialogActions, Divider, Toolbar } from "@mui/material";
import { formatoMexico } from "../../../../Config/reuserFunctions";
import {
  STATUS_MOVIMIENTO,
  TIPO_MOVIMIENTO,
  TORO,
} from "../../../../Config/constantes";
import ImgModal from "./ImgModal";
import RespuestasComp from "./Respuestas";

export default function AceptarRechazarMovimiento({ data }) {
  const { movQuery } = React.useContext(MovimientoContext);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loadingReject, setLoadingReject] = React.useState(false);
  const [loadingAcept, setLoadingAcept] = React.useState(false);
  const [loadingProcess, setLoadingProcess] = React.useState(false);
  const [loadingBlock, setLoadingBlock] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [comentarios, setComentarios] = React.useState("");
  const [cancelMovimientoRetiro] = useMutation(CancelMovimientoRetiro);
  const [payMovimiento] = useMutation(AceptarMovimiento);
  const [solicitunEnProceso] = useMutation(SolicitunEnProceso);
  const [addUserBlacklist] = useMutation(AddUserBlacklist);
  const { movimiento, status, monto, usuarioData } = data;

  const toros = monto / TORO;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (movimiento !== TIPO_MOVIMIENTO.retiro) return null;
  if (status === STATUS_MOVIMIENTO.realizado) return null;
  if (status === STATUS_MOVIMIENTO.cancelado) return null;
  if (status === STATUS_MOVIMIENTO.rechazado) return null;
  if (status === STATUS_MOVIMIENTO.fallido) return null;

  const handleReject = async () => {
    try {
      if (!comentarios) {
        snackMessage({
          message: "comentarios obligatorio",
          variant: "info",
        });
        return;
      }
      setLoadingReject(true);
      const response = await cancelMovimientoRetiro({
        variables: {
          idMovimiento: data._id,
          idUsuario: data.usuario,
          comentarios,
          status: STATUS_MOVIMIENTO.rechazado,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.cancelMovimientoRetiro.message,
        variant: "success",
      });
      movQuery.refetch();
      setLoadingReject(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoadingReject(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const handleProcess = async () => {
    try {
      setLoadingProcess(true);
      const response = await solicitunEnProceso({
        variables: {
          idMovimiento: data._id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.solicitunEnProceso.message,
        variant: "success",
      });
      movQuery.refetch();
      setLoadingProcess(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoadingProcess(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const handleAcept = async () => {
    try {
      /* if (!comentarios) {
        snackMessage({
          message: "comentarios obligatorio",
          variant: "info",
        });
        return;
      } */
      setLoadingAcept(true);
      const response = await payMovimiento({
        variables: {
          idMovimiento: data._id,
          comentarios,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.payMovimiento.message,
        variant: "success",
      });
      movQuery.refetch();
      setLoadingAcept(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoadingAcept(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const handleBlockUser = async () => {
    try {
      if (!comentarios) {
        snackMessage({
          message: "comentarios o motivo obligatorio",
          variant: "info",
        });
        return;
      }
      setLoadingBlock(true);
      const response = await addUserBlacklist({
        variables: {
          input: {
            idMovimiento: data._id,
            usuario: data.usuario,
            comentarios,
            motivo: comentarios
          }
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.addUserBlacklist.message,
        variant: "success",
      });
      movQuery.refetch();
      setLoadingBlock(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoadingBlock(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const imgs = usuarioData?.imagenesID || [];

  return (
    <div>
      <IconButton color="primary" size="small" onClick={handleOpen}>
        <NotificationImportant />
      </IconButton>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "40vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="gray">
              Solicitud de retiro
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              color="inherit"
              disableElevation
              disabled={loadingReject || loadingAcept}
              onClick={handleClose}
              sx={{ textTransform: "none" }}
              startIcon={<ArrowBack />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Container>
            <Box>
              <Typography variant="subtitle2">
                <b>Nombres:</b> {usuarioData?.nombre}
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography variant="subtitle2">
                  <b>A. parterno:</b> {usuarioData?.apellidoPaterno}
                </Typography>
                <Typography variant="subtitle2">
                  <b>A. Materno:</b> {usuarioData?.apellidoMaterno}
                </Typography>
              </Box>
              <Typography variant="subtitle2">
                <b>F. Nacimiento: </b> {usuarioData?.fechaNacimiento}
              </Typography>
              <Typography variant="subtitle2">
                <b>Direccion: </b> {usuarioData?.direccion}
              </Typography>
              <Typography variant="subtitle2">
                <b>Telefono: </b> {usuarioData?.telefono}
              </Typography>
              <Typography variant="subtitle2">
                <b>Correo: </b> {usuarioData?.email}
              </Typography>
              <Box mt={1}>
                <Typography variant="subititle2">
                  <b>Información Bancaria</b>
                </Typography>
                <Divider />
              </Box>
              {data?.solicitoRetirarEn === "Paypal" ? (
                <>
                  <Typography variant="subtitle2">
                    <b>Cuenta paypal:</b> {usuarioData?.paypalAccount}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="subtitle2">
                    <b>Institución bancaria:</b>{" "}
                    {usuarioData?.institucionBancaria}
                  </Typography>
                  <Typography variant="subtitle2">
                    <b>{usuarioData?.tipoClabeBanco}:</b>{" "}
                    {usuarioData?.clabeBanco}
                  </Typography>
                  <Typography variant="subtitle2">
                    <b>Solicitó retiro en:</b> {data?.solicitoRetirarEn}
                  </Typography>
                </>
              )}
              <Box sx={{ mt: 1, display: "flex", gap: 0.5 }}>
                {imgs.map((res, index) => (
                  <ImgModal key={index} img={res} />
                ))}
              </Box>
              <Box sx={{ my: 1 }} />
              <Typography align="right" variant="h6">
                Toros a retirar: <b>{formatoMexico(toros)}</b>
              </Typography>
              <Typography align="right" variant="h6">
                Total a recibir: <b>{formatoMexico(monto)} Toros</b>
              </Typography>
              <RespuestasComp
                data={data}
                comentarios={comentarios}
                setComentarios={setComentarios}
              />
            </Box>
          </Container>
        </DialogContent>
        <DialogActions>
          <Box sx={{width: "100%"}}>
            <Button
              variant="contained"
              color="info"
              fullWidth
              disableElevation
              disabled={loadingProcess}
              onClick={handleProcess}
              sx={{ textTransform: "none" }}
              startIcon={
                loadingProcess ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <LockClock />
                )
              }
            >
              En proceso
            </Button>
            <Box sx={{ mb: 2, mt: 1, display: "flex", gap: 1 }}>
            <Button
                variant="contained"
                color="error"
                fullWidth
                disableElevation
                disabled={loadingBlock}
                onClick={handleBlockUser}
                sx={{ textTransform: "none" }}
                startIcon={
                  loadingBlock ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Block />
                  )
                }
              >
                Bloquear
              </Button>
              <Button
                variant="contained"
                color="warning"
                fullWidth
                disableElevation
                disabled={loadingReject}
                onClick={handleReject}
                sx={{ textTransform: "none" }}
                startIcon={
                  loadingReject ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Close />
                  )
                }
              >
                Rechazado
              </Button>
              <Button
                variant="contained"
                color="success"
                fullWidth
                disableElevation
                disabled={loadingAcept}
                onClick={handleAcept}
                sx={{ textTransform: "none" }}
                startIcon={
                  loadingAcept ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Done />
                  )
                }
              >
                Pagado
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Drawer>
    </div>
  );
}
