import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { ArrowBack, Add, Edit, EmojiEvents } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { TorneoContext } from "../Context/TorneoContext";
import { CreateTorneo, UpdateTorneo } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CustomButton } from "../../../../../Components/CustomMuiComponents";
import DateTimeInput from "../../../../../Components/DateTimeInput";
import { blueGrey, yellow } from "@mui/material/colors";

const initialState = {
  nombre: "",
  lugar: "",
  lienzoCharro: "",
  fechaInicio: "",
  fechaFin: "",
  abrev: "",
  tipo: "",
};

export default function CrearTorneo({ data = "", edit = false }) {
  const { torneosQuery } = React.useContext(TorneoContext);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [createTorneo] = useMutation(CreateTorneo);
  const [updateTorneo] = useMutation(UpdateTorneo);
  const [open, setOpen] = React.useState(false);
  const [torneo, setTorneo] = React.useState(initialState);

  const handleOpen = () => {
    setOpen(true);
    if (edit) setTorneo(data);
  };

  const handleClose = () => {
    setOpen(false);
    setTorneo(initialState);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setTorneo((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleChangeTipo = (tipo) => {
    setTorneo((state) => ({ ...state, tipo }));
  };

  const handleChangeDate = (name, value) => {
    setTorneo((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const response = await createTorneo({
        variables: {
          input: torneo,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createTorneo.message,
        variant: "success",
      });
      torneosQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const { _id, createdAt, ...input } = torneo;
      const response = await updateTorneo({
        variables: {
          idTorneo: torneo._id,
          input,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateTorneo.message,
        variant: "success",
      });
      torneosQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen} size="small">
          <Edit />
        </IconButton>
      ) : (
        <CustomButton
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpen}
          size="small"
          disableElevation
        >
          Nuevo
        </CustomButton>
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "35vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Nuevo torneo
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="form-torneo"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "80vh" }}>
            <form id="form-torneo" onSubmit={edit ? handleUpdate : handleSave}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Nombre
                </Typography>
                <TextField
                  fullWidth
                  required
                  name="nombre"
                  size="small"
                  value={torneo.nombre}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Abrev
                </Typography>
                <TextField
                  fullWidth
                  name="abrev"
                  size="small"
                  value={torneo.abrev}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Lugar
                </Typography>
                <TextField
                  fullWidth
                  required
                  name="lugar"
                  size="small"
                  value={torneo.lugar}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Lienzo Charro
                </Typography>
                <TextField
                  fullWidth
                  name="lienzoCharro"
                  size="small"
                  value={torneo.lienzoCharro}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Fecha inicio
                </Typography>
                <DateTimeInput
                  name="fechaInicio"
                  onChange={handleChangeDate}
                  value={torneo.fechaInicio}
                  required
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Fecha fin
                </Typography>
                <DateTimeInput
                  name="fechaFin"
                  onChange={handleChangeDate}
                  value={torneo.fechaFin}
                  required
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Tipo de evento
                </Typography>
                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<EmojiEvents htmlColor={yellow[800]} />}
                    color="inherit"
                    sx={{
                      "&:hover": {bgcolor: yellow[600]},
                      borderColor: yellow[600],
                      bgcolor: torneo.tipo === "ORO" && yellow[600],
                    }}
                    onClick={() => handleChangeTipo("ORO")}
                  >
                    ORO
                  </Button>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<EmojiEvents htmlColor={blueGrey[400]} />}
                    color="inherit"
                    sx={{
                      "&:hover": {bgcolor: blueGrey[300]},
                      borderColor: blueGrey[200],
                      bgcolor: torneo.tipo === "PLATA" && blueGrey[200],
                    }}
                    onClick={() => handleChangeTipo("PLATA")}
                  >
                    PLATA
                  </Button>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<EmojiEvents htmlColor="#be6127" />}
                    color="inherit"
                    sx={{
                      "&:hover": {bgcolor: "#da8a67"},
                      borderColor: "#da8a67",
                      bgcolor: torneo.tipo === "BRONCE" && "#da8a67",
                    }}
                    onClick={() => handleChangeTipo("BRONCE")}
                  >
                    BRONCE
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}
