import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { EmailsContext } from "../Context/EmailsContext";

export default function UnreadCheck() {
  const { filter, setFilter } = React.useContext(EmailsContext);

  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={filter.unread}
          onChange={(e) => setFilter({ ...filter, unread: e.target.checked })}
        />
      }
      label="No leídos"
    />
  );
}
