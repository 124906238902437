import React, { useState, useEffect } from "react";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

function PreviewContent({ content }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (content) {
      const contentStateRaw = JSON.parse(content);
      const contentState = convertFromRaw(contentStateRaw);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [content]);

  return <Editor editorState={editorState} readOnly />;
}

export default PreviewContent;
