import React, { createContext } from "react";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetMessages } from "../graphql/queries";

export const EmailsContext = createContext();

const initialFilter = {
  search: "",
  unread: false,
}

const EmailCtxProvider = ({ children }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initialFilter);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const { page, limit } = pageConfig;

  const emailQuery = useQuery(GetMessages, {
    variables: {
      filter: FILTER,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  return (
    <EmailsContext.Provider
      value={{
        filter,
        setFilter,
        initialFilter,
        emailQuery,
        pageConfig,
        setPageConfig,
      }}
    >
      {children}
    </EmailsContext.Provider>
  );
};

export default EmailCtxProvider;
