import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import MenuSideComponent from "./Navegacion/MenuSideComponent";
import NavbarDashboard from "./Navegacion/NavbarDashboard";
import { MainContext } from "../../Context/MainCtx";
import { Toolbar } from "@mui/material";
import BottomMenuResponsive from "./Navegacion/Responsive/BottomNav";
import { useQuery } from "@apollo/client";
import { GetAdminAuth } from "../Home/SignIn/gql/queries";
import CustomLoader from "../../Components/CustomLoader";
import ErrorMessage from "../../Components/ErrorMessage";
import { GetPendientes } from "./Auditorias/Movimientos/graphql/queries";
import { GetUnreadMessages } from "./Emails/graphql/queries";
import { io } from "socket.io-client";
import CreateLogComponent from "../../Components/LogUser";

export default function DashboardIndex() {
  const [view, setView] = React.useState("");
  const navigate = useNavigate();
  const matches = useMatches();
  const pathname = matches && matches.length > 1 ? matches[1].pathname : "";
  const { tokenSesion } = React.useContext(MainContext);
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const { loading, data, error } = useQuery(GetAdminAuth, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const movQuery = useQuery(GetPendientes, { fetchPolicy: "network-only" });
  const emailQuery = useQuery(GetUnreadMessages, { fetchPolicy: "network-only" });
  const pendientes = movQuery.data?.getMovPendientes || 0;
  const unreads = emailQuery.data?.getMessagesUnread || 0;

  const getContent = (badge = null) => {
    if (!badge) return 0;
    if (badge === "movimientos") return pendientes;
    if (badge === "contacto") return unreads;
  };

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedNotification", () => {
      movQuery.refetch();
      emailQuery.refetch();
    });
    return () => socket.disconnect();
  }, [movQuery, emailQuery]);

  React.useEffect(() => {
    if (data) {
      pathname ? navigate(pathname) : navigate("/admin/juegos");
      if (!view) setView(localStorage.getItem("lastview"));
    }
  }, [navigate, pathname, view, data]);

  if (loading) return <CustomLoader height="90vh" size={40} />;
  if (error) {
    console.log(error)
    if (error.message === "Unauthorized") navigate("/");
    return <ErrorMessage message={error.message} />;
  }

  const width = open ? "225px" : "114px"; //112px

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        bgcolor: (theme) => theme.palette.background.paper,
      }}
    >
      <CssBaseline />
      <CreateLogComponent />
      <NavbarDashboard handleDrawerToggle={handleDrawerToggle} view={view} />
      <MenuSideComponent
        open={open}
        setOpen={setOpen}
        setView={setView}
        handleClose={handleDrawerToggle}
        getContent={getContent}
      />
      <Box sx={{ flex: 1, px: { xs: 1, md: 3 } }}>
        <Toolbar variant="dense" />
        <Box
          sx={{
            mt: { xs: 0, md: 2 },
            width: {
              xs: `calc(100vw - 20px)`,
              sm: `calc(100vw - auto)`,
              md: `calc(100vw - ${width})`,
            },
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <BottomMenuResponsive setView={setView} getContent={getContent} />
    </Box>
  );
}
