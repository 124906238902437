import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import { ArrowBack, Edit } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { UsuariosContext } from "../Context/UsuariosContext";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { UpdateUserAdmin } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import RecoverUserData from "./Recovery/RecoverUserData";
import moment from "moment-timezone";
import DateInput from "../../../../../Components/Inputs/DateInput";
import { ROLES } from "../../../../../Config/constantes";
import ImgModal from "../../../SolicitudesRetiro/Actions/ImgModal";

export default function UpdateUser({ data }) {
  const { queryRefetch } = React.useContext(UsuariosContext);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [updateUserDashboard] = useMutation(UpdateUserAdmin);
  const [open, setOpen] = React.useState(false);
  const [dataUser, setDataUser] = React.useState(null);

  const handleOpen = () => {
    setDataUser(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDataUser(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataUser((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleChangeDate = (name, value) => {
    setDataUser((st) => ({ ...st, [name]: moment(value).format() }));
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const {
        createdAt,
        _id,
        saldo,
        imagenesID,
        recovered,
        historyImgs,
        totalTorosAdquiridos,
        totalRetiros,
        ...input
      } = dataUser;
      //if (typeof makeAdmin === "boolean") input.rol = makeAdmin;

      await updateUserDashboard({
        variables: {
          idUser: _id,
          input,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      queryRefetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const imgs = dataUser?.imagenesID || [];
  const imgsHistory = data?.historyImgs ? data?.historyImgs : [];

  return (
    <div>
      <IconButton onClick={handleOpen} size="small">
        <Edit fontSize="small" />
      </IconButton>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "40vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Editar Usuario
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="form-user"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <form id="form-user" onSubmit={handleSave}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Nombre
                </Typography>
                <TextField
                  tabIndex={1}
                  fullWidth
                  required
                  name="nombre"
                  size="small"
                  value={dataUser?.nombre}
                  onChange={handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gap: 0.5,
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <Box sx={{ mb: 1 }}>
                  <Typography variant="body2" color="gray">
                    Apellido Paterno
                  </Typography>
                  <TextField
                    tabIndex={1}
                    fullWidth
                    name="apellidoPaterno"
                    size="small"
                    value={dataUser?.apellidoPaterno}
                    onChange={handleChange}
                  />
                </Box>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="body2" color="gray">
                    Apellido Materno
                  </Typography>
                  <TextField
                    tabIndex={1}
                    fullWidth
                    name="apellidoMaterno"
                    size="small"
                    value={dataUser?.apellidoMaterno}
                    onChange={handleChange}
                  />
                </Box>
              </Box>

              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Fecha de nacimiento
                </Typography>
                <DateInput
                  name="fechaNacimiento"
                  value={dataUser?.fechaNacimiento}
                  onChange={handleChangeDate}
                  margin="dense"
                  label="Fecha de nacimiento"
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Dirección
                </Typography>
                <TextField
                  tabIndex={1}
                  fullWidth
                  name="direccion"
                  size="small"
                  value={dataUser?.direccion}
                  onChange={handleChange}
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gap: 0.5,
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <Box sx={{ mb: 1 }}>
                  <Typography variant="body2" color="gray">
                    Telefono
                  </Typography>
                  <TextField
                    tabIndex={1}
                    fullWidth
                    name="telefono"
                    size="small"
                    value={dataUser?.telefono}
                    onChange={handleChange}
                  />
                </Box>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="body2" color="gray">
                    <span style={{ color: "red" }}>*</span>Correo
                  </Typography>
                  <TextField
                    tabIndex={1}
                    fullWidth
                    required
                    name="email"
                    size="small"
                    value={dataUser?.email}
                    onChange={handleChange}
                  />
                </Box>
              </Box>

              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Cuenta de Paypal
                </Typography>
                <TextField
                  tabIndex={1}
                  fullWidth
                  name="paypalAccount"
                  size="small"
                  value={dataUser?.paypalAccount}
                  onChange={handleChange}
                />
              </Box>
              <FormControl fullWidth size="small" margin="dense" required>
                <InputLabel id="rol-select">Rol</InputLabel>
                <Select
                  labelId="rol-select"
                  value={dataUser?.rol}
                  label="Rol"
                  onChange={(e) =>
                    setDataUser({ ...dataUser, rol: e.target.value })
                  }
                >
                  {Object.keys(ROLES).map((res, index) => (
                    <MenuItem key={index} value={ROLES[res]}>
                      {ROLES[res]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Stack direction="row" spacing={2}>
                <Box sx={{ mb: 1 }}>
                  <FormControlLabel
                    onChange={(e) =>
                      setDataUser({
                        ...dataUser,
                        activo: e.target.checked,
                      })
                    }
                    control={<Switch checked={dataUser?.activo} />}
                    label={dataUser?.activo ? "Activo" : "Inactivo"}
                  />
                </Box>
              </Stack>
            </form>
          </Box>
          <Box>
            <Typography>Identificación</Typography>
            <Divider />
          </Box>
          <Box sx={{ mt: 1, display: "flex", gap: 0.5 }}>
            {imgs.map((res, index) => (
              <ImgModal key={index} img={res} />
            ))}
          </Box>
          <Box sx={{mt: 1}}>
            <Typography>Historial de identificaciones</Typography>
            <Divider />
          </Box>
          <Box sx={{ my: 1, display: "flex", gap: 0.5 }}>
            {imgsHistory.map((res, index) => (
              <ImgModal key={index} img={res} action idUser={data?._id} queryRefetch={queryRefetch} />
            ))}
          </Box>
          <RecoverUserData user={data} refetch={queryRefetch} />
        </DialogContent>
      </Drawer>
    </div>
  );
}
