import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../../Config/reuserFunctions";
import { ChipRol } from "../../Usuarios/Table/CustomChips";
import UpdateUser from "../Actions/Update";
import DeleteUser from "../Actions/Delete";
import MovsDetail from "../MovsDetail";
import CortesDetail from "../../../../Seller/Vendedores/CortesDetails";

export default function UsuariosTableBody({ usuarios }) {

  return (
    <TableBody>
      {usuarios.map((user) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={user._id}
            sx={{ height: "48px", cursor: "pointer" }}
          >
            <TableCell>{user.nombre}</TableCell>
            <TableCell>{user.encargado}</TableCell>
            <TableCell>{user.telefono}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{formatoMexico(user.saldo)}</TableCell>
            <TableCell>{<ChipRol rol={user.rol} />}</TableCell>
            <TableCell>{formatoFechaCorta(user.createdAt)}</TableCell>
            <TableCell sx={{ px: 0.5 }} align="center">
              {<MovsDetail idUser={user._id} usuarioData={user} />}
            </TableCell>
            <TableCell sx={{ px: 0.5 }} align="center">
              {<CortesDetail idUser={user._id} usuarioData={user} />}
            </TableCell>
            <TableCell sx={{ px: 0.5 }}>
              <UpdateUser data={user} edit />
            </TableCell>
            <TableCell sx={{ px: 0.5 }}>
              <DeleteUser data={user} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
