import * as React from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { ManagerContext } from "../Context/ManagerContext";
import UsuarioTableBody from "./TableBody";
import {
  formatoMexico,
  getComparator,
  stableSort,
} from "../../../../../Config/reuserFunctions";
import UsuarioTableHead from "./TableHead";
import { Box, Typography } from "@mui/material";

export default function UsuarioTable() {
  const { queryData, setPageConfig, pageConfig } = React.useContext(
    ManagerContext
  );
  const { docs, totalDocs, totalBalanceUsers } = queryData.getManagersAndSellers;
  const [shadows, setShadows] = React.useState({
    start: "",
    end: "leftShadowTable",
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("nombre");
  const tableEl = React.useRef();

  const renderSorted = React.useMemo(() => {
    return stableSort(docs, getComparator(order, orderBy));
  }, [order, orderBy, docs]);

  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    if (scrollLeft === 0) {
      setShadows({ start: "removeShadowTable", end: "leftShadowTable" });
      return;
    }
    if (scrollLeft >= realWidth) {
      setShadows({ start: "rightShadowTable", end: "removeShadowTable" });
      return;
    }
    setShadows({ start: "rightShadowTable", end: "leftShadowTable" });
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth;
    changeClassName(scrollLeft, realWidth);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, page) => {
    setPageConfig((st) => ({ ...st, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPageConfig({ limit: parseInt(event.target.value, 10), page: 0 });
  };

  return (
    <>
      <Paper variant="outlined">
        <TableContainer
          onScroll={handleOnScroll}
          ref={tableEl}
          sx={{
            transition: "width .3s",
            height: { xs: `calc(100vh - 272px)`, sm: `calc(100vh - 180px)` }, //xs: 232, sm: 140 sin paginacion
            borderRadius: 2,
          }}
        >
          <Table stickyHeader size="small">
            <UsuarioTableHead
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
            />
            <UsuarioTableBody shadows={shadows} usuarios={renderSorted} />
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ my: 1.5 }}>
          Balance total de usuarios: <b>{formatoMexico(totalBalanceUsers)}</b>
        </Typography>
        <TablePagination
          component="div"
          count={totalDocs}
          page={pageConfig.page}
          onPageChange={handleChangePage}
          rowsPerPage={pageConfig.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
