import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { formatoFechaCorta } from "../../../../../Config/reuserFunctions";
import UpdateUser from "../Actions/Update";
import DeleteUser from "../Actions/Delete";
import ChipStatusUser from "../Actions/Status";
import { UsuariosContext } from "../Context/UsuariosContext";
import MovsDetail from "../MovsDetail";
import { Checkbox } from "@mui/material";
import AjusteBalance from "../Actions/AjusteBalance";

export default function UsuariosTableBody({ usuarios, handleSelect }) {
  const { queryRefetch, itemsSelected } = React.useContext(UsuariosContext);

  return (
    <TableBody>
      {usuarios.map((user) => {
        const checked = itemsSelected.find((item) => item._id === user._id);
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={user._id}
            sx={{
              height: "48px",
              cursor: "pointer",
            }}
          >
            <TableCell
              padding="checkbox"
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 2,
                bgcolor: (theme) => theme.palette.background.paper,
              }}
              className="cell"
            >
              <Checkbox
                onChange={(e) => handleSelect(e.target.checked, user)}
                onClick={(e) => e.stopPropagation()}
                checked={checked ? true : false}
              />
            </TableCell>
            <TableCell>{user.nombre}</TableCell>
            <TableCell>{user.apellidoPaterno}</TableCell>
            <TableCell>{user.apellidoMaterno}</TableCell>
            <TableCell>{user.telefono}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>
              <AjusteBalance user={user} />
            </TableCell>
            <TableCell>{formatoFechaCorta(user.createdAt)}</TableCell>
            <TableCell sx={{ px: 0.5 }} align="center">
              {<MovsDetail idUser={user._id} usuarioData={user} />}
            </TableCell>
            <TableCell sx={{ px: 0.5 }} align="center">
              {<ChipStatusUser usuario={user} refetch={queryRefetch} />}
            </TableCell>
            <TableCell sx={{ px: 0.5 }}>
              <UpdateUser data={user} />
            </TableCell>
            <TableCell sx={{ px: 0.5 }}>
              <DeleteUser data={user} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
