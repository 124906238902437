import React from "react";
import { Box, Stack } from "@mui/material";
import CorteContextCtxProvider, {
  CorteContext,
} from "./Context/CorteContext";
import TableCortesSeller from "./Table";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import Export from "./Actions/Export";

export default function CortesSeller() {
  return (
    <Box>
      <CorteContextCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          sx={{ pb: 1, mt: {xs: 1, md: 0} }}
          bgcolor="background.paper"
          justifyContent="flex-end"
        >
          <Export />
        </Stack>
        <RenderTable />
      </CorteContextCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { corteQuery } = React.useContext(CorteContext);

  if (corteQuery.loading) return <CustomLoader />;
  if (corteQuery.error)
    return <ErrorMessage message={corteQuery.error.message} />;

  return <TableCortesSeller />;
};
