import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import { formatoMexico } from "../../Config/reuserFunctions";
import { GetUserBalance } from "../../Views/Dashboard/Auditorias/Usuarios/graphql/queries";
import { useQuery } from "@apollo/client";
import { MainContext } from "../../Context/MainCtx";
import { Info } from "@mui/icons-material";
import { io } from "socket.io-client";
import { FaCoins } from "react-icons/fa";

export default function UserBalance({color = "primary"}) {
  const { tokenSesion, setErrors } = React.useContext(MainContext);

  const { data, loading, error, refetch } = useQuery(GetUserBalance, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedBalance", () => refetch());
    return () => socket.disconnect();
  }, [refetch]);

  React.useEffect(() => {
    if(error)setErrors({...error, funcion: "getUserBalance"})
  }, [error, setErrors])

  if (loading) return <CircularProgress size={20} color="primary" />;
  if (error) return <Info color="primary" />;

  return (
    <Typography color={color} sx={{ mx: 1, gap: 0.5, display: "flex", alignItems: "center" }}>
      <span>
        <FaCoins style={{ fontSize: 13 }} />
      </span>
      <b>{formatoMexico(data?.getUserBalance?.saldo)}</b>
    </Typography>
  );
}
