import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../../Components/ExcelExportButton";
import { FilialesContext } from "../Context/FilialesContext";
import { formatoFechaCorta } from "../../../../../Config/reuserFunctions";
import { GetFiliales } from "../graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((filial) => {
    const { _id, activo, createdAt, imagenesID, ...obj } = filial;
    dataFormat.push({
      ...obj,
      status: activo ? "ACTIVO" : "INACTIVO",
      fechaRegistro: formatoFechaCorta(createdAt),
    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { queryData } = React.useContext(FilialesContext);
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const excelData = formatTable(queryData?.getFiliales?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getFiliales = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetFiliales,
        variables: {
          filter: "",
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getFiliales.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name="Suerte charra filiales"
        getQueryData={getFiliales}
        loading={loading}
      />
    </div>
  );
}
