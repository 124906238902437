import React from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { useMutation } from "@apollo/client";
import { CreateMovimientoDeposito, CreateMovimientoError } from "./mutations";
import { MainContext } from "../../../Context/MainCtx";
import { Box, CircularProgress, Typography } from "@mui/material";

export default function PaypalPayment({ toros, monto }) {
  const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "MXN",
    intent: "capture",
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      <PaypalButtonsComponent toros={toros} monto={monto} />
    </PayPalScriptProvider>
  );
}

const PaypalButtonsComponent = ({ toros, monto }) => {
  const {
    tokenDecoded,
    tokenSesion,
    snackMessage,
    setErrors,
    setDataPayment,
  } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [{ isPending }] = usePayPalScriptReducer();
  const [createMovimientoDeposito] = useMutation(CreateMovimientoDeposito);
  const [createMovimientoError] = useMutation(CreateMovimientoError);

  const createMovimientoDB = async (data) => {
    try {
      setLoading(true);
      const res = await createMovimientoDeposito({
        variables: {
          input: {
            usuario: tokenDecoded._id,
            concepto: `Compra de ${toros} toros`,
            monto,
            orderID: data.orderID,
            payerID: data.payerID,
            paymentID: data.paymentID,
            paymentSource: data.paymentSource,
            comentarios: "",
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: res.data.createMovimientoDeposito.message,
        variant: "success",
      });
      setLoading(false);
      setDataPayment((st) => ({ ...st, completed: true }));
    } catch (error) {
      console.log(error);
      snackMessage({
        message: error.message,
        variant: "error",
      });
      setErrors({ ...error, funcion: "createMovimientoDeposito" });
    }
  };

  const createOrder = (data, actions) => {
    // Lógica para crear la orden de pago
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: monto,
              currency_code: "MXN",
            },
          },
        ],
      })
      .catch((error) => {
        handleError(error, "Crear Orden");
        throw error;
      });
  };

  const handleApprove = async (data, actions) => {
    try {
      const order = await actions.order.capture();
      await createMovimientoDB(data);
      return order;
    } catch (error) {
      await handleError(error, "Aprobación");
      throw error;
    }
  };

  const handleError = async (error, from = "Unknown") => {
    try {
      console.error("Error en el pago:", error);
      snackMessage({
        message: `Hubo un problema al realizar el pago: ${error.message}`,
        variant: "error",
      });
      setLoading(true);
      await createMovimientoError({
        variables: {
          input: {
            usuario: tokenDecoded._id,
            concepto: `Compra de ${toros} toros`,
            monto,
            comentarios: `${from}: ${error?.message || "Error Desconocido"}`,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isPending && (
        <Box
          py={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <CircularProgress size={20} />
          <Typography>Cargando métodos de pago...</Typography>
        </Box>
      )}
      <PayPalButtons
        createOrder={createOrder}
        onApprove={handleApprove}
        onError={handleError}
        disabled={loading || !tokenDecoded}
      />
    </>
  );
};
