import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import PerfilManager from '../Seller/Perfil';
import { Edit } from '@mui/icons-material';

export default function EditarProfile() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button size="small" startIcon={<Edit />} onClick={handleClickOpen}>
        Editar perfil
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <PerfilManager />
        <DialogActions>
          <Button onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
