import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";

import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import { Close, Email } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { UsuariosContext } from "../Context/UsuariosContext";
import CustomAvatar from "../../../../../Components/Avatar/CustomAvatar";
import { Grid, TextField } from "@mui/material";
import RichTinyTextEditor from "../../../../../Components/CustomEditor/TinyEditor";
import { EditorState } from "draft-js";
import { useDropzone } from "react-dropzone";
import { stateToHTML } from "draft-js-export-html";
import moment from "moment-timezone";
import { CreateMasiveEmail } from "../graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import DeleteTrashEmails from "./DeleteTrashEmails";
import { getEmailsTemp } from "../graphql/queries";

export default function SendEmail() {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const {
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(UsuariosContext);
  const [subject, setSubject] = React.useState("");
  const [image, setImage] = React.useState();
  const [preview, setPreview] = React.useState();
  const [previewEditor, setPreviewEditor] = React.useState();
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  const [createEmail] = useMutation(CreateMasiveEmail);
  const [open, setOpen] = React.useState(false);

  const queryEmail = useQuery(getEmailsTemp);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditorState(EditorState.createEmpty());
    setSubject("");
    setImage("");
    setPreview("");
  };

  const handleRemoveFile = () => {
    setImage();
    setPreview();
  };

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function () {
        let image = reader.result;
        setPreview(image);
      };
      setImage(acceptedFiles[0]);
    },
    [setImage, setPreview]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSendMany = async () => {
    try {
      if (!itemsSelected.length) return;
      const receivers = itemsSelected.map(({ email }) => ({ email }));
      setLoading(true);
      let input = {
        subject,
        receivers,
        body: previewEditor,
      }
      if(image) input.image = image;
      const response = await createEmail({
        variables: {
          input,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      snackMessage({
        message: response.data.createEmail.message,
        variant: "success",
      });
      queryEmail.refetch();
      setItemsSelected([]);
      setIndeterminated(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    setPreviewEditor(html);
  }, [editorState]);

  return (
    <>
      <Button
        color="info"
        size="small"
        variant="contained"
        startIcon={<Email />}
        disableElevation
        disabled={!itemsSelected.length}
        onClick={handleClick}
        sx={{ textTransform: "none" }}
      >
        Email
      </Button>
      <Dialog open={open} fullWidth maxWidth="lg">
        <DialogTitle component="div">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Enviar Correo Electrónico</Typography>
            <DeleteTrashEmails queryEmail={queryEmail} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <Box sx={{ mb: 1 }}>
                <Typography>Destinatarios</Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <AvatarGroup
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                    total={itemsSelected.length}
                  >
                    {itemsSelected.slice(0, 10).map((res, indx) => (
                      <CustomAvatar key={indx} name={res.nombre} />
                    ))}
                  </AvatarGroup>
                </Box>
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography>Asunto</Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Box>
              <Box>
                {!preview ? (
                  <Box
                    {...getRootProps()}
                    sx={{
                      border: "1px dashed #ccc",
                      padding: "10px",
                      textAlign: "center",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  >
                    <input {...getInputProps()} />
                    <p>Agregar una imagen</p>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box>
                      <img src={preview} alt="email" height={54} />
                    </Box>
                    <Button
                      startIcon={<Close />}
                      onClick={handleRemoveFile}
                      sx={{ textTransform: "none" }}
                    >
                      Eliminar
                    </Button>
                  </Box>
                )}
              </Box>
              <RichTinyTextEditor
                editorState={editorState}
                setEditorState={setEditorState}
                height="25vh"
              />
            </Grid>
            <Grid item md={6}>
              <body
                style={{
                  //position: "relative",
                  minHeight: 300,
                  minWidth: 360,
                  maxWidth: 600,
                  backgroundImage:
                      "url(https://scharrapublic.s3.us-west-2.amazonaws.com/background.png)",
                }}
              >
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src="https://scharrapublic.s3.us-west-2.amazonaws.com/suertecharra.png"
                      height="50px"
                      alt="logoscharra"
                    />
                  </div>

                  <div style={{ padding: "16px 30px" }}>
                    <div style={{ padding: "16px", backgroundColor: "#fff" }}>
                      {preview && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "300px",
                              width: "300px",
                            }}
                          >
                            <img
                              src={preview}
                              alt="adjunto"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div
                        dangerouslySetInnerHTML={{ __html: previewEditor }}
                      />
                      <p
                      style={{
                        //color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      {moment().format("ll")} © suertecharra.com
                    </p>
                    </div>
                  </div>
                </div>
              </body>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="inherit"
            size="small"
            onClick={handleClose}
            variant="contained"
            disableElevation
          >
            Cancelar
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="info"
            size="small"
            onClick={handleSendMany}
            variant="contained"
            disableElevation
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
