import React from "react";
import { Box, Container, Grid } from "@mui/material";
import tienda from "../../../assets/images/store.png";
import toro100 from "../../../assets/images/store_100.png";
import toro200 from "../../../assets/images/store_200.png";
import toro500 from "../../../assets/images/store_500.png";
import toro1000 from "../../../assets/images/store_1000.png";
import toro5000 from "../../../assets/images/store_5000.png";
import toro10000 from "../../../assets/images/store_10000.png";
import LazyImage from "../../../Components/LazyImage";
import { MainContext } from "../../../Context/MainCtx";
import { TORO } from "../../../Config/constantes";
import { useNavigate } from "react-router-dom";
import CheckSesionStatus from "../CheckSesionStatus";

const toros = [
  { title: "100 toros", value: 100, img: toro100, monto: 100 * TORO },
  { title: "200 toros", value: 200, img: toro200, monto: 200 * TORO },
  { title: "500 toros", value: 500, img: toro500, monto: 500 * TORO },
  { title: "1000 toros", value: 1000, img: toro1000, monto: 1000 * TORO },
  { title: "5000 toros", value: 5000, img: toro5000, monto: 5000 * TORO },
  { title: "10000 toros", value: 10000, img: toro10000, monto: 10000 * TORO },
];

function Tienda() {
  const { setDataPayment } = React.useContext(MainContext);
  const navigate = useNavigate();

  return (
    <Container sx={{ py: 1, minHeight: "calc(95vh - 48px)" }}>
      <CheckSesionStatus />
      <LazyImage src={tienda} alt="torneo" height="50" />
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          {toros.map((res, index) => (
            <Grid key={index} item xs={6} md={4}>
              <Box
                onClick={() => {
                  setDataPayment(res);
                  navigate("/payment");
                }}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 230,
                  transition: ".3s ease",
                  "&:hover": {
                    transform: "translate(0px, -5px)",
                  },
                }}
              >
                <LazyImage src={res.img} alt={res.title} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default Tienda;
