import React from "react";
import { useApolloClient } from "@apollo/client";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { RiFileExcel2Line } from "react-icons/ri";
import { exportToCSV } from "react-easy-export";
import { green } from "@mui/material/colors";
import { MainContext } from "../../../../../Context/MainCtx";
import { formatoFechaHora } from "../../../../../Config/reuserFunctions";
import { GetMovimientosUserReport } from "./queries";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    const {
      _id,
      usuarioData,
      torneoData,
      competenciaData,
      comentarios,
      fecha,
      ...newData
    } = res;
    const charrComp = res.competenciaData?.charreadaData?.nombre || "N/A";
    const suerteComp = res.competenciaData?.suerteData?.suerte || "";
    const competencia = `${charrComp} ${suerteComp}`;
    const usuario = `${res.usuarioData?.nombre || ""} ${
      res.usuarioData?.apellidoPaterno || ""
    } ${res.usuarioData?.apellidoMaterno || ""}`;

    dataFormat.push({
      ...newData,
      usuario,
      torneo: torneoData?.nombre || res.torneo,
      competencia: competencia,
      fecha: formatoFechaHora(fecha),
    });
  });
  return dataFormat;
};

export default function UserExcelExport({ filter, usuario }) {
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const client = useApolloClient();

  /* Queries */
  const getMovimientos = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetMovimientosUserReport,
        variables: {
          filter,
          usuario,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getMovimientosUserReport.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const exportAllData = async () => {
    const response = await getMovimientos();
    if (response) exportToCSV(response, `movimientos usuario.csv`);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="success"
        sx={{ bgcolor: green[600] }}
        disableElevation
        onClick={exportAllData}
      >
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <RiFileExcel2Line style={{ fontSize: 19, margin: "2px 0" }} />
        )}
      </Button>
    </div>
  );
}
