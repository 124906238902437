import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Close } from "@mui/icons-material";
import { MainContext } from "../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { CircularProgress, IconButton } from "@mui/material";
import { MovimientoContext } from "../Context/MovimientoContext";
import { CancelMovimientoRetiro } from "../graphql/mutations";
import {
  STATUS_MOVIMIENTO,
  TIPO_MOVIMIENTO,
} from "../../../../Config/constantes";

export default function CancelMovimiento({ data }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion, tokenDecoded } = React.useContext(MainContext);
  const { movQuery } = React.useContext(MovimientoContext);
  const [cancelMovimientoRetiro] = useMutation(CancelMovimientoRetiro);
  const { movimiento, status } = data;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (movimiento !== TIPO_MOVIMIENTO.retiro) return null;
  if (status !== STATUS_MOVIMIENTO.pendiente) return null;

  const handleCancel = async () => {
    try {
      setLoading(true);
      const response = await cancelMovimientoRetiro({
        variables: {
          idMovimiento: data._id,
          idUsuario: tokenDecoded._id,
          comentarios: "Cancelada por el usuario",
          status: STATUS_MOVIMIENTO.cancelado
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.cancelMovimientoRetiro.message,
        variant: "success",
      });
      movQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <IconButton color="error" size="small" onClick={() => handleClickOpen()}>
        <Close />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>¿Quieres cancelar esta solicitud?</DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            autoFocus
            color="inherit"
            variant="contained"
            disableElevation
            size="small"
            disabled={loading}
          >
            No
          </Button>
          <Button
            onClick={() => handleCancel()}
            color="error"
            variant="contained"
            disableElevation
            size="small"
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
            disabled={loading}
          >
            Cancelar solicitud
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
