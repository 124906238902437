import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  formatoFechaHora,
  formatoMexico,
  getColorStatusApuesta,
} from "../../../../Config/reuserFunctions";
import { Chip } from "@mui/material";
//import CancelarApuestas from "../Actions/CancelarApuestas";
import ReprintTicket from "../Actions/Reprint";
import PagarApuesta from "../Actions/Pagar";

export default function ApuestasTableBody({ data }) {
  return (
    <TableBody>
      {data.map((res) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{
              height: "30px",
              "& .cell": {
                fontSize: 12,
                py: 0,
              },
            }}
          >
            <TableCell
              padding="checkbox"
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 2,
                bgcolor: (theme) => theme.palette.background.paper,
              }}
            >
              {res.ticket}
            </TableCell>
            <TableCell className="cell" padding="checkbox">
              <Chip
                size="small"
                label={res.status}
                sx={{
                  textTransform: "lowercase",
                  bgcolor: getColorStatusApuesta(res.status),
                  color: (theme) =>
                    theme.palette.getContrastText(
                      getColorStatusApuesta(res.status)
                    ),
                  fontWeight: "bold",
                  height: 20,
                }}
              />
            </TableCell>
            <TableCell className="cell">{res.torneoData?.nombre}</TableCell>
            <TableCell className="cell">{res.charreadaData?.nombre}</TableCell>
            <TableCell className="cell">{res.suerteData?.suerte}</TableCell>
            <TableCell className="cell">{res.equipoData?.nombre}</TableCell>
            <TableCell className="cell">{res.charroData?.nombre}</TableCell>
            <TableCell className="cell">
              ${formatoMexico(res.apuesta)}
            </TableCell>
            <TableCell className="cell">{formatoMexico(res.momio)}</TableCell>
            <TableCell className="cell">
              {formatoFechaHora(res.fecha)}
            </TableCell>
            <TableCell className="cell" align="center" padding="checkbox">
              <PagarApuesta data={res} />
            </TableCell>
            <TableCell className="cell" align="center" padding="checkbox">
              <ReprintTicket data={res} />
            </TableCell>
            {/* <TableCell className="cell" align="center" padding="checkbox">
              <CancelarApuestas data={res} />
            </TableCell> */}
          </TableRow>
        );
      })}
    </TableBody>
  );
}
