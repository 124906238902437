import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";
import FilterSelectTable from "../../../../../Components/Inputs/FilterSelect";
import { ResultadoContext } from "../Context/ResultadoContext";

export default function ResultadoTableHead({ handleSort, order, orderBy }) {
  const {
    catsQuery,
    filter,
    setFilter,
    //setFilterCats,
    //filterCats,
  } = React.useContext(ResultadoContext);

  const headCells = [
    {
      id: "torneo",
      padding: "normal",
      align: "left",
      label: "Torneo",
      sortable: false,
      filter: {
        filteringData: "torneos",
        filterName: "torneo",
        filterBy: "nombre",
      },
      sx: {
        minWidth: "400px",
      },
    },
    {
      id: "equipo",
      padding: "normal",
      align: "left",
      label: "Equipo",
      sortable: false,
      filter: {
        filteringData: "equipos",
        filterName: "equipo",
        filterBy: "nombre",
      },
      sx: {
        minWidth: "200px",
      },
    },
    {
      id: "suerte",
      padding: "normal",
      align: "left",
      label: "Suerte",
      sortable: false,
      filter: {
        filteringData: "suertes",
        filterName: "suerte",
        filterBy: "suerte",
      },
      sx: {
        minWidth: "200px",
      },
    },
    {
      id: "charro",
      padding: "normal",
      align: "left",
      label: "Charro",
      sortable: false,
      filter: {
        filteringData: "charros",
        filterName: "charro",
        filterBy: "nombre",
      },
      sx: {
        minWidth: "300px",
      },
    },
    {
      id: "calificacion",
      padding: "normal",
      align: "left",
      label: "Calificación",
      sortable: true,
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "real",
      padding: "normal",
      align: "left",
      label: "Real",
      sortable: true,
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "fecha",
      padding: "normal",
      align: "left",
      label: "Fecha",
      sortable: true,
      sx: {
        minWidth: "140px",
      },
    },
    {
      id: "usuario",
      padding: "normal",
      align: "left",
      label: "Usuario",
      sortable: false,
      sx: {
        minWidth: "200px",
      },
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...headCell.sx,
              "&:hover .button-filter": {
                display: "inline-flex!important",
              },
            }}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
            {headCell.filter ? (
              <FilterSelectTable
                query={
                  catsQuery?.data?.getCatalogos[headCell.filter.filteringData]
                }
                loading={catsQuery.loading}
                error={catsQuery.error}
                value={filter}
                setFilter={setFilter}
                //setFilterCats={setFilterCats}
                //filterCats={filterCats}
                filterName={headCell.filter.filterName}
                filterBy={headCell.filter.filterBy}
              />
            ) : null}
          </TableCell>
        ))}
        <TableCell align="right" padding="checkbox" />
        <TableCell align="right" padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}
