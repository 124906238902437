import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CrearCharro from "../Actions/Create";
import DeleteCharro from "../Actions/Delete";

export default function CharrosTableBody({ charros }) {

  return (
    <TableBody>
      {charros.map((charro) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={charro._id}
            sx={{ height: "48px" }}
          >
            <TableCell>{charro.nombre}</TableCell>
            <TableCell>{charro.equipoData?.nombre}</TableCell>
            <TableCell>{charro.equipoData?.estado?.estado}</TableCell>
            <TableCell>{charro.equipoData?.estado?.pais}</TableCell>
            <TableCell align="right"><CrearCharro data={charro} edit /></TableCell>
            <TableCell align="right"><DeleteCharro data={charro} /></TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
