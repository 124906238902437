import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Delete } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { gql, useMutation } from "@apollo/client";
import { CircularProgress, IconButton } from "@mui/material";
import { TorneoContext } from "../Context/TorneoContext";
import { DeleteTorneo } from "../graphql/mutations";
import { useLocation } from "react-router-dom";
import { createLogDeleteItem } from "./getInfoSesion";
import { STATUS_TORNEO } from "../../../../../Config/constantes";

const CreateLogUser = gql`
  mutation createLogUser($input: inputLog) {
    createLogUser(input: $input) {
      message
    }
  }
`;

export default function Deletetorneos({ data }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const { torneosQuery } = React.useContext(TorneoContext);
  const [deleteTorneo] = useMutation(DeleteTorneo);

  const [createLogUser] = useMutation(CreateLogUser);
  const location = useLocation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await deleteTorneo({
        variables: {
          idTorneo: data._id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.deleteTorneo.message,
        variant: "success",
      });
      setLoading(false);
      await createLogDeleteItem({
        createLogUser,
        path: location.pathname,
        tokenSesion,
        detail: `Elimino Torneo: ${data.nombre}`,
      });
      torneosQuery.refetch();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <IconButton color="error" size="small" onClick={() => handleClickOpen()} disabled={data.status !== STATUS_TORNEO.cerrado}>
        <Delete />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Eliminar ${data.nombre}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se va a eliminar este elemento, ¿Quiere continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus color="inherit">
            No, cancelar
          </Button>
          <Button
            onClick={() => handleDelete()}
            color="error"
            variant="contained"
            disableElevation
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Delete />
              )
            }
          >
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
