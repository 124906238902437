import { gql } from "@apollo/client";

export const CreateState = gql`
  mutation createState($input: stateInput) {
    createState(input: $input) {
      message
    }
  }
`;

export const UpdateState = gql`
  mutation updateState($idState: ID!, $input: stateInput) {
    updateState(idState: $idState, input: $input) {
      message
    }
  }
`;

export const DeleteState = gql`
  mutation deleteState($idState: ID!) {
    deleteState(idState: $idState) {
      message
    }
  }
`;
