import * as React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Close } from "@mui/icons-material";
import LazyImage from "../../../../Components/LazyImage";
import { Button, CircularProgress } from "@mui/material";
import { DeleteImgUser } from "../../../Home/Perfil/gql/mutations";
import { MainContext } from "../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";

export default function ImgModal({ img, action, idUser, queryRefetch }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deleteImgUser] = useMutation(DeleteImgUser);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await deleteImgUser({
        variables: {
          idUser,
          key: img.key,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.deleteImgUser.message,
        variant: "success",
      });
      queryRefetch();
      handleClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          height: 100,
          width: 150,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
      >
        <LazyImage src={img.url} alt={img.key} />
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <Box sx={{ position: "relative" }}>
          {action ? (
            <Button
              variant="contained"
              disableElevation
              size="small"
              onClick={() => handleDelete()}
              disabled={loading}
              startIcon={loading ? <CircularProgress color="inherit" size={20} /> : null}
              sx={{
                textTransform: "none",
                position: "absolute",
                top: 8,
                left: 8,
              }}
            >
              Eliminar
            </Button>
          ) : null}
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
          <Box
            sx={{
              height: 400,
              //minWidth: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LazyImage src={img.url} alt={img.key} />
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
