import React from "react";
import CrearApuesta from "./CrearApuesta";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { EmojiEvents } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { STATUS_COMP } from "../../../../Config/constantes";
import { filtrarPalabra } from "../../../../Config/reuserFunctions";

export default function CompetenciasTable({ data, torneo, charreada }) {
  return (
    <Box>
      {data.map(({ suerte, competencias, terminado }, index) => (
        <AccordionComponent
          key={index}
          suerte={suerte}
          competencias={competencias}
          terminado={terminado}
          torneo={torneo}
          charreada={charreada}
        />
      ))}
    </Box>
  );
}

const AccordionComponent = ({
  suerte,
  competencias,
  terminado,
  torneo,
  charreada,
}) => {
  const theme = useTheme();
  const { activo, ganador } = STATUS_COMP;
  const [expanded, setExpanded] = React.useState(false);

  const ended = competencias.some(({ status }) => status !== activo);
  const open = competencias.some(({ status }) => status === activo);
  const winner = competencias.some(({ status }) => status === ganador);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      //defaultExpanded={index === 0}
      expanded={expanded}
      onChange={handleChange}
      key={suerte._id}
      variant="outlined"
      sx={{
        border: "none",
        "& .MuiAccordionSummary-root": { minHeight: "40px" },
        "& .MuiAccordionSummary-content": { margin: 0, padding: 0 },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${suerte._id}-content`}
        id={`panel${suerte._id}-header`}
      >
        <Box
          sx={{
            display: "flex",
            borderRadius: "3px",
            justifyContent: "space-between",
            alignItems: "center",
            background: {
              xs:
                "linear-gradient(90deg, rgba(244,67,54,1) 55%,rgba(244,67,54,0.3) 70%, rgba(244,67,54,0) 100%)",
              md:
                "linear-gradient(90deg, rgba(244,67,54,1) 5%,rgba(244,67,54,0.3) 40%, rgba(244,67,54,0) 100%)",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              px: 1,
              color: "white",
            }}
          >
            <EmojiEvents />
            <Typography
              variant="h6"
              className="fontAnton"
              sx={{ fontSize: { xs: 12, md: 19 } }}
            >
              <i>{suerte.suerte}</i>
            </Typography>
          </Box>
          {(!ended || open) && expanded ? (
            <Typography
              color="primary"
              variant="h6"
              className="fontAnton"
              sx={{ mr: 2, fontSize: { xs: 12, md: 19 } }}
            >
              <i>Momio</i>
            </Typography>
          ) : winner ? (
            <Typography
              color="primary"
              variant="h6"
              className="fontAnton"
              sx={{ mr: 2, fontSize: { xs: 12, md: 19 } }}
            >
              <i>Con Ganador</i>
            </Typography>
          ) : null}
          {!expanded && open ? (
            <Typography
              color="primary"
              variant="h6"
              className="fontAnton"
              sx={{ mr: 2, fontSize: { xs: 12, md: 19 } }}
            >
              <i>Juego abierto</i>
            </Typography>
          ) : null}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {competencias.map((res) => {
          const suerte = res.suerteData?.suerte;
          const isTeam = suerte.toLowerCase() === "equipo";
          const cerrado = res.status !== activo && true;
          return (
            <Box
              key={res._id}
              sx={{
                px: 1,
                pb: 1,
                borderBottom: "1px solid",
                borderColor: "divider",
              }}
            >
              <Grid
                container
                sx={{
                  alignItems: "center",
                  opacity: cerrado ? "0.6" : 1,
                }}
              >
                <Grid item xs={7} md={7}>
                  {isTeam ? (
                    <Box sx={{ gap: 1 }}>
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          lineHeight: 1.1,
                          fontSize: { xs: 12, md: 16 },
                        }}
                      >
                        <b>{suerte}</b>
                        {/* Equipo */}
                      </Typography>
                      <Typography sx={{ fontSize: { xs: 12, md: 16 } }}>
                        {filtrarPalabra(res.equipoData?.nombre, "EMPATE")}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ gap: 1 }}>
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          lineHeight: 1.1,
                          fontSize: { xs: 12, md: 16 },
                        }}
                      >
                        <b>{suerte}</b>
                        {/* Charro */}
                      </Typography>
                      <Typography sx={{ fontSize: { xs: 12, md: 16 } }}>
                        {filtrarPalabra(res.charroData?.nombre, "EMPATE")}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid
                  item
                  xs={5}
                  md={5}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <CrearApuesta
                    data={res}
                    torneo={torneo}
                    charreada={charreada}
                    terminado={terminado}
                  />
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
