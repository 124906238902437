import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CustomIconComponent from "../../../Components/CustomIconComponent";
import { itemsResSubMenu } from "../../../Components/Navbar/listItems";
import DarkMode from "../../../Components/DarkMode";
import { Logout, Settings } from "@mui/icons-material";
import { MainContext } from "../../../Context/MainCtx";
import { Link } from "react-router-dom";

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  margin: theme.spacing(2),
  backgroundColor: theme.palette.divider,
  borderRadius: 3,
}));

export default function ListMenuResponsive({
  open,
  setOpen,
  pathname,
  handleClickSecodanry,
}) {
  const { handleCloseSesion, tokenDecoded } = React.useContext(MainContext);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      slotProps={{
        root: {
          style: { zIndex: 1000 },
        },
      }}
      PaperProps={{
        sx: {
          height: tokenDecoded ? `58vh` : '25vh',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          zIndex: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Puller />
      </Box>
      <Box
        sx={{
          px: 2,
          pb: 2,
          mb: 2,
          height: "100%",
          overflow: "auto",
        }}
      >
        <List>
          {tokenDecoded?.rol === process.env.REACT_APP_ROL_ADMON ? (
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/admin">
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Panel Admin" />
              </ListItemButton>
            </ListItem>
          ) : null}
          {itemsResSubMenu(tokenDecoded).map(
            ({ title, url, icon, component }, index) => (
              <ListItem key={`subitems-${index}`} disablePadding>
                <ListItemButton
                  selected={url === pathname}
                  onClick={() => handleClickSecodanry({ title, url })}
                  component={component}
                  to={url}
                >
                  <ListItemIcon>
                    <CustomIconComponent
                      iconName={icon}
                      color={url === pathname ? "primary" : "inherit"}
                    />
                  </ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </ListItem>
            )
          )}
          <DarkMode listModeRes />
          {tokenDecoded ? (
            <ListItem disablePadding>
              <ListItemButton onClick={handleCloseSesion}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Cerrar sesión" />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </Box>
      <Toolbar />
    </SwipeableDrawer>
  );
}
