import React, { createContext } from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetFactores } from "../graphql/queries";

export const FactorContext = createContext();

const FactorCtxProvider = ({ children, typeFactor }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState("");
  const [type, setType] = React.useState(typeFactor || 3);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const { page, limit } = pageConfig;

  const factoresQuery = useQuery(GetFactores, {
    variables: {
      filter: FILTER,
      page,
      limit,
      type,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    if(typeFactor) setType(typeFactor)
  }, [typeFactor])
  
  return (
    <FactorContext.Provider
      value={{
        filter,
        setFilter,
        factoresQuery,
        pageConfig,
        setPageConfig,
        type,
        setType,
      }}
    >
      {children}
    </FactorContext.Provider>
  );
};

export default FactorCtxProvider;
