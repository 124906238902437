import * as React from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
//import { CustomButton } from "../../../../../../Components/CustomMuiComponents";
import {
  DoDisturbOn,
  PauseCircle,
  Restore,
  DisplaySettings,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import { CompetenciaContext } from "../../Context/CompetenciaContext";
import SelectWinners from "./SelectWinners";
import EliminarCompetencias from "./DeleteMany";
import { MainContext } from "../../../../../../Context/MainCtx";
import { UpdateHiddenStatus, UpdateStatusMany } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { STATUS_COMP } from "../../../../../../Config/constantes";
import SetNoSePresento from "./setNP";
import { DialogContent, Divider, Tooltip } from "@mui/material";
import { CustomButton } from "../../../../../../Components/CustomMuiComponents";

export default function ActionSelectMenu() {
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const {
    filter,
    compQuery,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(CompetenciaContext);
  const [updateStatusMany] = useMutation(UpdateStatusMany);
  const [updateHiddenStatus] = useMutation(UpdateHiddenStatus);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const items = itemsSelected.length;
  const { torneo, charreada } = filter;
  const showChar = torneo && charreada;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelected(null);
  };

  const handleAction = async () => {
    try {
      if (!selected) return;
      const idsComp = itemsSelected.map((res) => res._id);
      setLoading(true);
      if (selected === "hidden" || selected === "show") {
        const res = await updateHiddenStatus({
          variables: {
            idsComp,
            torneo,
            charreada,
            hidden: selected === "hidden" ? true : false,
          },
          context: {
            headers: {
              Authorization: `Bearer ${tokenSesion}`,
            },
          },
        });
        snackMessage({
          message: res.data.updateHiddenStatus.message,
          variant: "success",
        });
      } else {
        const res = await updateStatusMany({
          variables: {
            idsComp,
            status: STATUS_COMP[selected],
          },
          context: {
            headers: {
              Authorization: `Bearer ${tokenSesion}`,
            },
          },
        });
        snackMessage({
          message: res.data.updateStatusMany.message,
          variant: "success",
        });
      }
      setLoading(false);
      compQuery.refetch();
      setItemsSelected([]);
      setIndeterminated(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const getTextButton = () => {
    switch (selected) {
      case "activo":
        return "Reanudar";
      case "pendiente":
        return "Pausar";
      case "cerrado":
        return "Terminar";
      case "hidden":
        return "Ocultar";
      case "show":
        return "Mostrar";
      default:
        return "Confirmar";
    }
  };

  return (
    <div>
      <Tooltip arrow title="Acciones en Competencias" placement="top">
        {/* <IconButton onClick={handleClickOpen} disabled={!items}>
          <Campaign />
        </IconButton> */}
        <CustomButton
          variant="text"
          disableElevation
          size="small"
          onClick={handleClickOpen}
          startIcon={<DisplaySettings />}
          color="inherit"
        >
          Control
        </CustomButton>
      </Tooltip>

      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { minWidth: 300 },
        }}
      >
        <DialogContent>
          <List dense>
            <ListItem
              disableGutters
              disablePadding
              onClick={() => setSelected("activo")}
            >
              <ListItemButton
                selected={selected === "activo"}
                disabled={!items}
              >
                <ListItemIcon>
                  <Restore />
                </ListItemIcon>
                <ListItemText primary="Reanudar" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disableGutters
              disablePadding
              onClick={() => setSelected("pendiente")}
            >
              <ListItemButton
                selected={selected === "pendiente"}
                disabled={!items}
              >
                <ListItemIcon>
                  <PauseCircle />
                </ListItemIcon>
                <ListItemText primary="Pausar" />
              </ListItemButton>
            </ListItem>
            <ListItem
              disableGutters
              disablePadding
              onClick={() => setSelected("cerrado")}
            >
              <ListItemButton
                selected={selected === "cerrado"}
                disabled={!items}
              >
                <ListItemIcon>
                  <DoDisturbOn />
                </ListItemIcon>
                <ListItemText primary="Cerrar" />
              </ListItemButton>
            </ListItem>
            <SelectWinners handleCloseMenu={handleClose} />
            <SetNoSePresento handleCloseMenu={handleClose} />
            <EliminarCompetencias handleCloseMenu={handleClose} />
            <Divider sx={{my: 1}} />
            <ListItem
              disableGutters
              disablePadding
              onClick={() => setSelected("hidden")}
            >
              <ListItemButton
                selected={selected === "hidden"}
                disabled={!showChar}
              >
                <ListItemIcon>
                  <VisibilityOff />
                </ListItemIcon>
                <ListItemText primary="Ocultar" secondary={showChar && `${charreada} de ${torneo}`}  />
              </ListItemButton>
            </ListItem>
            <ListItem
              disableGutters
              disablePadding
              onClick={() => setSelected("show")}
            >
              <ListItemButton
                selected={selected === "show"}
                disabled={!showChar}
              >
                <ListItemIcon>
                  <Visibility />
                </ListItemIcon>
                <ListItemText primary="Mostrar" secondary={showChar && `${charreada} de ${torneo}`} />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="inherit"
            size="small"
            onClick={handleClose}
            variant="contained"
            fullWidth
            disableElevation
          >
            Cancelar
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="info"
            size="small"
            onClick={handleAction}
            variant="contained"
            fullWidth
            disableElevation
            disabled={loading || !selected}
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            {getTextButton()}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
