import * as React from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { CompetenciaContext } from "../Context/CompetenciaContext";
import TableCompetenciasBody from "./TableBody";
import {
  getComparator,
  stableSort,
} from "../../../../../Config/reuserFunctions";
import TableCompetenciasHead from "./TableHead";
import ApuestaDetail from "./Apuestas";

export default function TableCompetencia() {
  const {
    compQuery,
    setPageConfig,
    pageConfig,
    setItemsSelected,
    setIndeterminated,
    itemsSelected,
  } = React.useContext(CompetenciaContext);
  const { docs, totalDocs } = compQuery?.data?.getCompetencias;
  const [shadows, setShadows] = React.useState({
    start: "",
    end: "leftShadowTable",
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const tableEl = React.useRef();

  const renderSorted = React.useMemo(() => {
    return stableSort(docs, getComparator(order, orderBy));
  }, [order, orderBy, docs]);

  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    if (scrollLeft === 0) {
      setShadows({ start: "removeShadowTable", end: "leftShadowTable" });
      return;
    }
    if (scrollLeft >= realWidth) {
      setShadows({ start: "rightShadowTable", end: "removeShadowTable" });
      return;
    }
    setShadows({ start: "rightShadowTable", end: "leftShadowTable" });
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth;
    changeClassName(scrollLeft, realWidth);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelect = (checked, item) => {
    const items = [...itemsSelected];
    if (checked) items.push(item);
    if (!checked) {
      const indx = items.findIndex((elm) => elm._id === item._id);
      items.splice(indx, 1);
    }
    setItemsSelected(items);
    //revisar el checkAll
    if (items.length > 0 && items.length !== docs.length) {
      setIndeterminated(true);
      return;
    }
    setIndeterminated(false);
  };
  const handleCheckAll = (checked) => {
    setIndeterminated(false);
    if (checked) {
      setItemsSelected(docs);
      return;
    }
    setItemsSelected([]);
  };

  const handleChangePage = (event, page) => {
    setPageConfig((st) => ({ ...st, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPageConfig({ limit: parseInt(event.target.value, 10), page: 0 });
  };

  return (
    <>
      <Paper variant="outlined">
        <ApuestaDetail />
        <TableContainer
          onScroll={handleOnScroll}
          ref={tableEl}
          sx={{
            transition: "width .3s",
            height: { xs: `calc(100vh - 272px)`, sm: `calc(100vh - 200px)` }, //xs: 232, sm: 140 sin paginacion
            borderRadius: 2,
          }}
        >
          <Table stickyHeader size="small">
            <TableCompetenciasHead
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
              handleCheckAll={handleCheckAll}
            />
            <TableCompetenciasBody
              shadows={shadows}
              data={renderSorted}
              handleSelect={handleSelect}
            />
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        component="div"
        count={totalDocs}
        page={pageConfig.page}
        onPageChange={handleChangePage}
        rowsPerPage={pageConfig.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
