import { gql } from "@apollo/client";

export const GetMovimientos = gql`
  query getMovimientos($filter: usuerFilterMovs, $limit: Int, $page: Int) {
    getMovimientos(filter: $filter, limit: $limit, page: $page) {
      totalPendientes
      docs {
        _id
        usuario
        usuarioData {
          nombre
          apellidoPaterno
          apellidoMaterno
          fechaNacimiento
          telefono
          direccion
          imagenesID {
            key
            url
          }
          email
          activo
          paypalAccount
          institucionBancaria
          clabeBanco
          tipoClabeBanco
        }
        nombre_usuario
        monto
        concepto
        movimiento
        status
        comentarios
        fecha
        orderID
        payerID
        paymentID
        paymentSource
        solicitoRetirarEn
        afilial
        nombreAfilial
      }
      totalDocs
      page
      totalPages
    }
  }
`;
export const GetCatsMov = gql`
  query getCatalogosMov($filter: usuerFilterMovs) {
    getCatalogosMov(filter: $filter) {
      movimientos {
        movimiento
      }
      status {
        status
      }
    }
  }
`;
export const GetPendientes = gql`
  query Query {
    getMovPendientes
  }
`;
export const GetAnswers = gql`
  query getAnswers {
    getAnswers {
      _id
      respuesta
    }
  }
`;