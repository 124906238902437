import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import {
  ArrowBack,
  CurrencyExchange,
} from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../Context/MainCtx";
import { MovimientoContext } from "../Context/MovimientoContext";
import { CreateMovimientoRetiro } from "../graphql/mutations";
import { useApolloClient, useMutation } from "@apollo/client";
import { CustomButton } from "../../../../Components/CustomMuiComponents";
import {
  Alert,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import UserBalance from "../../../../Components/Avatar/UserBalance";
import { GetUserBalance } from "../../../Dashboard/Auditorias/Usuarios/graphql/queries";
import { useDebounce } from "use-debounce";
import { formatoMexico } from "../../../../Config/reuserFunctions";
import { TORO } from "../../../../Config/constantes";
import IconToro from "../../../../assets/images/toro.png"

export default function CrearMovimientoRetiro() {
  const { movQuery } = React.useContext(MovimientoContext);
  const {
    snackMessage,
    tokenSesion,
    tokenDecoded,
    setErrors,
  } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [createMovimientoRetiro] = useMutation(CreateMovimientoRetiro);
  const [open, setOpen] = React.useState(false);
  const [balance, setBalance] = React.useState(0);
  const [toros, setToros] = React.useState(10);
  const [monto, setMonto] = React.useState(10);
  const [solicitoEn, setSolicitoEn] = React.useState("Clabe/Numero bancario");
  const [MONTO] = useDebounce(monto, 500);

  const pendiente = movQuery?.data?.getMovimientosUser?.pendiente || false;

  const handleOpen = () => {
    if (pendiente) {
      snackMessage({
        message: "Ya tienes una solicutud pendiente",
        variant: "info",
      });
      return;
    }
    setOpen(true);
    getBalance();
  };

  const handleClose = () => {
    setOpen(false);
    setToros(10);
    setMonto(10);
  };

  const client = useApolloClient();
  const getBalance = async () => {
    try {
      const res = await client.query({
        query: GetUserBalance,
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      if (res.data) setBalance(res.data.getUserBalance?.saldo || 0);
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const handleGetQuantity = (e) => {
    const value = parseFloat(e.target.value);
    if (value > balance) return;
    setToros(value);
    setMonto(value * TORO);
  };

  const handleSave = async () => {
    try {
      if (loading || !monto || !toros || pendiente) return;
      setLoading(true);
      const response = await createMovimientoRetiro({
        variables: {
          input: {
            usuario: tokenDecoded._id,
            concepto: `Solicitud Retiro ${toros} toros`,
            monto,
            solicitoRetirarEn: solicitoEn,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createMovimientoRetiro.message,
        variant: "success",
      });
      movQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      setErrors({ ...err, funcion: "createMovimientoRetiro" });
    }
  };

  return (
    <div>
      <CustomButton
        color="primary"
        variant="contained"
        startIcon={<CurrencyExchange />}
        onClick={handleOpen}
        disableElevation
      >
        Solicitar retiro
      </CustomButton>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "45vw" }, overflowY: "auto" },
        }}
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Solicitar retiro
              </Typography>
            </Stack>
            <UserBalance />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Container>
            <Alert severity="info">
                Antes de solicitar retiro, revisa que todos tus datos estén
                completos
                <ul>
                  <li>Nombre completo, domicilio y fecha de nacimiento</li>
                  <li>Fotos de tu identificación</li>
                  <li>Cuenta de paypal</li>
                  <li>Institucion Bancaria</li>
                  <li>Clabe interbancaria o Número de tarjeta</li>
                </ul>
                <b>Importante:</b> Verifica que tus datos de identificación, los
                datos de tu cuenta de SuerteCharra y tus datos bancarios
                conicidan. En caso de cualquir incumplimiento ya mencionado,
                la solicitud de retiro será rechazada.
                 <p><b>*El monto minimo de retiro son 10 toros.</b></p>
                 <p><b>*El pago de recompensas se hace en días y horas hábiles.</b></p>
            </Alert>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Box sx={{ width: 300 }}>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  onChange={handleGetQuantity}
                  value={toros}
                  onBlur={(e) => {
                    if (!e.target.value || parseFloat(e.target.value) < 10) {
                      setMonto(10);
                      setToros(10);
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={IconToro} alt="toro" height={20} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">{balance}</InputAdornment>
                    ),
                    inputProps: {
                      min: 10,
                      max: balance,
                    },
                  }}
                />
                <Box sx={{ mt: 1 }} />
                <Typography variant="body2" color="gray">
                  Donde deseas recibir tu retiro
                </Typography>
                <FormControl fullWidth size="small" name="solicitoRetirarEn">
                  <Select
                    name="solicitoRetirarEn"
                    value={solicitoEn}
                    onChange={(e) => setSolicitoEn(e.target.value)}
                  >
                    <MenuItem value="Clabe/Numero bancario">
                      Clabe/Numero bancario
                    </MenuItem>
                    <MenuItem value="Paypal">Paypal</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ my: 1 }} />
                <Typography align="center" variant="h6">
                  Total a recibir: <b>{formatoMexico(MONTO)} Toros</b>
                </Typography>
                <Box sx={{ my: 2, display: "flex", gap: 1 }}>
                  <Button
                    variant="contained"
                    color="inherit"
                    fullWidth
                    disableElevation
                    disabled={loading}
                    onClick={handleClose}
                    sx={{ textTransform: "none" }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    disableElevation
                    disabled={loading || !monto || !toros}
                    onClick={handleSave}
                    sx={{ textTransform: "none" }}
                    startIcon={
                      loading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <Done />
                      )
                    }
                  >
                    Solicitar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </DialogContent>
      </Drawer>
    </div>
  );
}
