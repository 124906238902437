import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Add, DeleteOutline } from "@mui/icons-material";
import { MainContext } from "../../../../Context/MainCtx";
import { CreateAnswer, DeleteAnswere } from "../graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { GetAnswers } from "../graphql/queries";
import { DialogContentText, ListItemText } from "@mui/material";

export default function RespuestasComp({ comentarios, setComentarios }) {
  const { tokenSesion } = React.useContext(MainContext);

  const { loading, data, error, refetch } = useQuery(GetAnswers, {
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  if (loading)
    return <TextField disabled size="small" value="Loading..." fullWidth />;
  if (error) return <TextField disabled size="small" value="error" fullWidth />;

  return (
    <FormControl fullWidth size="small" margin="dense">
      <InputLabel id="resp-id">Comentarios</InputLabel>
      <Select
        labelId="resp-id"
        id="demo-simple-select"
        value={comentarios}
        label="Comentarios"
        onChange={(e) => setComentarios(e.target.value)}
        renderValue={(val) => val}
        startAdornment={
          <InputAdornment position="start">
            <AddNew setComentarios={setComentarios} refetch={refetch} />
          </InputAdornment>
        }
      >
        {data.getAnswers.map((res) => (
          <MenuItem key={res._id} value={res.respuesta}>
            <ListItemText primary={res.respuesta} />
            <DeleteItem
              data={res}
              setComentarios={setComentarios}
              refetch={refetch}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const AddNew = ({ setComentarios, refetch }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("");
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [createAnswer] = useMutation(CreateAnswer);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (e) => {
    try {
      if (!value) return;
      setLoading(true);
      const response = await createAnswer({
        variables: {
          respuesta: value,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createAnswer.message,
        variant: "success",
      });
      setValue("");
      setComentarios(value);
      setLoading(false);
      refetch();
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClickOpen}>
        <Add />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Agregar nueva respuesta</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleClose}
            color="inherit"
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleSave}
            color="primary"
            disabled={loading}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
const DeleteItem = ({ data, setComentarios, refetch }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [deleteAnswere] = useMutation(DeleteAnswere);

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleDelete = async (e) => {
    try {
      e.stopPropagation();
      setLoading(true);
      const response = await deleteAnswere({
        variables: {
          idAnswere: data._id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.deleteAnswere.message,
        variant: "success",
      });
      setComentarios("");
      setLoading(false);
      refetch();
      handleClose(e);
    } catch (err) {
      console.log(err);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClickOpen}>
        <DeleteOutline />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Elimnar respuesta</DialogTitle>
        <DialogContent>
          <DialogContentText>{data.respuesta}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleClose}
            color="inherit"
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleDelete}
            color="error"
            disabled={loading}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
