import { gql } from "@apollo/client";

export const AceptarMovimiento = gql`
  mutation payMovimiento($idMovimiento: ID!, $comentarios: String!) {
    payMovimiento(idMovimiento: $idMovimiento, comentarios: $comentarios) {
      message
    }
  }
`;

export const UpdateMovimientoRetiro = gql`
  mutation updateMovimiento($idMovimiento: ID!, $input: movimientoUpdateInput) {
    updateMovimiento(idMovimiento: $idMovimiento, input: $input) {
      message
    }
  }
`;
export const CancelMovimientoRetiro = gql`
  mutation cancelMovimientoRetiro($idMovimiento: ID!, $idUsuario: ID!,  $comentarios: String, $status: String) {
    cancelMovimientoRetiro(idMovimiento: $idMovimiento, idUsuario: $idUsuario, comentarios: $comentarios, status: $status) {
      message
    }
  }
`;
export const CancelMovimientoRetiroRealizado = gql`
  mutation cancelMovimientoRetiroRealizado($idMovimiento: ID!, $idUsuario: ID!) {
    cancelMovimientoRetiroRealizado(idMovimiento: $idMovimiento, idUsuario: $idUsuario) {
      message
    }
  }
`;
export const SolicitunEnProceso = gql`
  mutation solicitunEnProceso($idMovimiento: ID!) {
    solicitunEnProceso(idMovimiento: $idMovimiento) {
      message
    }
  }
`;
export const AddUserBlacklist = gql`
  mutation addUserBlacklist($input: inputblacklist) {
    addUserBlacklist(input: $input) {
      message
    }
  }
`;
export const CreateAnswer = gql`
  mutation createAnswer($respuesta: String!) {
    createAnswer(respuesta: $respuesta) {
      message
    }
  }
`;
export const DeleteAnswere = gql`
  mutation deleteAnswere($idAnswere: ID!) {
    deleteAnswere(idAnswere: $idAnswere) {
      message
    }
  }
`;
export const DeleteSolicitudes = gql`
  mutation deleteSolicitudes($solicitudes: [ID]) {
    deleteSolicitudes(solicitudes: $solicitudes) {
      message
    }
  }
`;
