import React from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { AddPhotoAlternate, Close } from "@mui/icons-material";
import { MainContext } from "../../../../Context/MainCtx";
import { DeleteImgFAQ, UploadImgFAQ } from "./graphql/mutations";
import { useMutation } from "@apollo/client";
import LazyImage from "../../../../Components/LazyImage";

export default function UploadImgFaq({ faq, refetch }) {
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [uploadImgFaq] = useMutation(UploadImgFAQ);
  const [deleteImgFaq] = useMutation(DeleteImgFAQ);

  const cargarImagen = (e) => {
    const { files } = e.target;
    handleSave(files[0]);
  };

  const handleSave = async (image) => {
    try {
      setLoading(true);
      const response = await uploadImgFaq({
        variables: {
          idFaq: faq._id,
          image,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.uploadImgFaq.message,
        variant: "success",
      });
      setLoading(false);
      refetch();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const eliminarImagen = async () => {
    try {
      setLoading(true);
      const response = await deleteImgFaq({
        variables: {
          idFaq: faq._id,
          key: faq.keyImage,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.deleteImgFaq.message,
        variant: "success",
      });
      setLoading(false);
      refetch();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box>
          <input
            accept="image/jpeg, image/jpg, image/png"
            style={{ display: "none" }}
            id={`faq-image-${faq._id}`}
            type="file"
            onChange={cargarImagen}
            disabled={loading || faq?.keyImage}
          />
          <label htmlFor={`faq-image-${faq._id}`}>
            <Button
              variant="text"
              color="primary"
              component="span"
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <AddPhotoAlternate />
                )
              }
              sx={{ textTransform: "none" }}
              disabled={loading || faq?.keyImage}
            >
              Cargar imagen
            </Button>
          </label>
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        {faq.keyImage ? (
          <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}>
            <Box sx={{ height: 100, width: 140 }}>
              <LazyImage
                alt={`faq-${faq._id}`}
                src={faq.image || ""}
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography noWrap>{faq?.keyImage || ""}</Typography>
            </Box>
            <Button
              sx={{ textTransform: "none" }}
              size="small"
              onClick={() => eliminarImagen()}
              startIcon={<Close />}
            >
              Eliminar
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
