import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";
import FilterDate from "./FilterDate";

const headCells = [
  {
    id: "nombre",
    padding: "normal",
    align: "left",
    label: "Nombre",
    sortable: true,
    sx: {
      minWidth: "300px",
    },
  },
  {
    id: "lugar",
    padding: "normal",
    align: "left",
    label: "Lugar",
    sortable: true,
    sx: {
      minWidth: "180px",
    },
  },
  {
    id: "lienzoCharro",
    padding: "normal",
    align: "left",
    label: "Lienzo charro",
    sortable: true,
    sx: {
      minWidth: "180px",
    },
  },
  {
    id: "fechaInicio",
    padding: "normal",
    align: "left",
    label: "Inicia el",
    sortable: true,
    //filter: true,
    sx: {
      minWidth: "130px",
    },
  },
  {
    id: "fechaFin",
    padding: "normal",
    align: "left",
    label: "Termina el",
    sortable: true,
    //filter: true,
    sx: {
      minWidth: "130px",
    },
  },
  {
    id: "tipo",
    padding: "normal",
    align: "left",
    label: "Tipo",
    sortable: false,
    sx: {
      minWidth: "100px",
    },
  },
  {
    id: "status",
    padding: "normal",
    align: "left",
    label: "Status",
    sortable: false,
    sx: {
      minWidth: "100px",
    },
  },
  {
    id: "createdAt",
    padding: "normal",
    align: "left",
    label: "Creado el",
    sortable: true,
    sx: {
      minWidth: "140px",
    },
  },
];

export default function TorneoTableHead({ handleSort, order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...headCell.sx,
              "&:hover .button-filter": {
                display: "inline-flex!important",
              },
            }}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
            {headCell.filter ? <FilterDate /> : null}
          </TableCell>
        ))}
        <TableCell align="right" padding="checkbox" />
        <TableCell align="right" padding="checkbox" />
        <TableCell align="right" padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}
