import React, { createContext } from "react";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetMovimientosUser } from "../graphql/queries";
import { io } from "socket.io-client";

export const MovimientoContext = createContext();

const initialFilter = {
  fechaInicio: null,
  fechaFin: null,
};

const MovimeintoCtxProvider = ({ children }) => {
  const { tokenSesion, tokenDecoded } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initialFilter);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const { page, limit } = pageConfig;

  const movQuery = useQuery(GetMovimientosUser, {
    variables: {
      idUsuario: tokenDecoded._id,
      filter: FILTER,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedNotification", () => movQuery.refetch());
    return () => socket.disconnect();
  }, [movQuery]);

  return (
    <MovimientoContext.Provider
      value={{
        filter,
        setFilter,
        initialFilter,
        movQuery,
        pageConfig,
        setPageConfig,
      }}
    >
      {children}
    </MovimientoContext.Provider>
  );
};

export default MovimeintoCtxProvider;
