import React from "react";
import { Box, Stack } from "@mui/material";
import TeamsCtxProvider, { EstadosContext } from "./Context/EstadosContext";
import SearchComponent from "./Actions/Search";
import EquiposTable from "./Table";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import CreateEstado from "./Actions/Create";

export default function EstadosPaises() {
  return (
    <Box>
      <TeamsCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          sx={{ mb: 1, position: { md: "absolute" }, top: 60, right: 24 }}
          bgcolor="background.paper"
        >
          <SearchComponent />
          <CreateEstado />
          {/* <CreateEquipo /> */}
          <Export />
        </Stack>
        <RenderTable />
      </TeamsCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { statesQuery } = React.useContext(EstadosContext);

  if (statesQuery.loading) return <CustomLoader />;
  if (statesQuery.error) return <ErrorMessage message={statesQuery.error.message} />;

  return <EquiposTable />;
};
