import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import MenuSideComponent from "./Navegacion/MenuSideComponent";
import NavbarDashboard from "./Navegacion/NavbarDashboard";
import { MainContext } from "../../Context/MainCtx";
import { Toolbar } from "@mui/material";
import BottomMenuResponsive from "./Navegacion/Responsive/BottomNav";
import { useQuery } from "@apollo/client";
import { GetSellerAuth } from "../Home/SignIn/gql/queries";
import CustomLoader from "../../Components/CustomLoader";
import ErrorMessage from "../../Components/ErrorMessage";
import CreateLogComponent from "../../Components/LogUser";

export default function SellerIndex() {
  const [view, setView] = React.useState("");
  const navigate = useNavigate();
  const matches = useMatches();
  const pathname = matches && matches.length > 1 ? matches[1].pathname : "";
  const { tokenSesion } = React.useContext(MainContext);
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const { loading, data, error } = useQuery(GetSellerAuth, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    if (data) {
      pathname ? navigate(pathname) : navigate("/seller/juegos");
      if (!view) setView(localStorage.getItem("lastview"));
    }
  }, [navigate, pathname, view, data]);

  if (loading) return <CustomLoader height="90vh" size={40} />;
  if (error) {
    if (error.message === "Unauthorized") navigate("/");
    return <ErrorMessage message={error.message} />;
  }

  const width = open ? "225px" : "114px"; //112px

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        bgcolor: (theme) => theme.palette.background.paper,
      }}
    >
      <CssBaseline />
      <CreateLogComponent />
      <NavbarDashboard handleDrawerToggle={handleDrawerToggle} view={view} />
      <MenuSideComponent
        open={open}
        setOpen={setOpen}
        setView={setView}
        handleClose={handleDrawerToggle}
      />
      <Box sx={{ flex: 1, px: { xs: 1, md: 3 } }}>
        <Toolbar variant="dense" />
        <Box
          sx={{
            mt: { xs: 0, md: 2 },
            width: {
              xs: `calc(100vw - 20px)`,
              sm: `calc(100vw - auto)`,
              md: `calc(100vw - ${width})`,
            },
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <BottomMenuResponsive setView={setView} />
    </Box>
  );
}
