import { gql } from "@apollo/client";

export const SignInUser = gql`
  mutation loginUser($input: inputLogin) {
    loginUser(input: $input) {
      token
    }
  }
`;
export const SendRecoverPass = gql`
  mutation sendRecoverPassword($email: String!) {
    sendRecoverPassword(email: $email) {
      message
    }
  }
`;
export const ResetPassword = gql`
  mutation updatePassword($password: String!) {
    updatePassword(password: $password) {
      message
    }
  }
`;
export const VerifyReCaptcha = gql`
  mutation verifyReCaptcha($token: String!) {
    verifyReCaptcha(token: $token) {
      message
    }
  }
`;