import * as React from "react";
import Box from "@mui/material/Box";
import { useDropzone } from "react-dropzone";
import LazyImage from "../../../../Components/LazyImage";

export default function UploadImage({ setStepData, preview, setPreview }) {

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function () {
        let image = reader.result;
        setPreview(image);
      };
      setStepData((st) => ({ ...st, newImagen: acceptedFiles[0] }));
    },
    [setStepData, setPreview]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Box>
      {!preview ? (
        <Box
          {...getRootProps()}
          sx={{
            border: "2px dashed #ccc",
            borderRadius: "5px",
            padding: "20px",
            textAlign: "center",
            cursor: "pointer",
            marginBottom: "20px",
          }}
        >
          <input {...getInputProps()} />
          <p>Arrastra y suelta tu imagen, o haz clic para seleccionar</p>
        </Box>
      ) : (
        <Box
          sx={{
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LazyImage
            src={preview}
            alt="prev"
          />
        </Box>
      )}
    </Box>
  );
}
