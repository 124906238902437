import { gql } from "@apollo/client";

export const GetSigninLogs = gql`
  query getSigninLogs($filter: String, $limit: Int, $page: Int) {
    getSigninLogs(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        nombre
        email
        rol
        urlRef
        device
        browser
        ipAddress
        place
        fecha
        detail
        createdAt
      }
      totalDocs
      page
      totalPages
    }
  }
`;
