import { gql } from "@apollo/client";

export const GetCompetencias = gql`
  query getCompetencias($filter: inputCompFilter, $limit: Int, $page: Int) {
    getCompetencias(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        torneo
        torneoData {
          nombre
        }
        charreada
        charreadaData {
          nombre
        }
        equipo
        equipoData {
          nombre
        }
        suerte
        suerteData {
          suerte
        }
        charro
        charroData {
          nombre
        }
        multiplicador
        momioMin
        momioMax
        status
        ganador
        createdAt
        fechaCharreada
        noSePresento
        torneo_name
        typeFactor
        typeCuota
        hidden
      }
      totalDocs
      page
      totalPages
      catalogos {
        torneos
        charreadas
        suertes
        equipos
        charros
        status
      }
    }
  }
`;

export const GetCaculatedFields = gql`
  query getCalcCompetencia($input: InputCalc) {
    getCalcCompetencia(input: $input) {
      promedio
      mayor
      menor
      aritmetica
      HECTOR
      media
      probabilidad
      constante
      factor
      rendimiento
      cuota
      valorPunto
      ponderador
      ponderado
      apuestas
      totalApuestas
      bolsa
      bolsaMenosApuesta
      porcentajeCasa
      bolsaMenosApuestaMenosPorcentajeCasa
      momio
    }
  }
`;
export const GetBetsByCompetencia = gql`
  query getBetsByCompetencia(
    $filter: String
    $competencia: ID
    $limit: Int
    $page: Int
  ) {
    getBetsByCompetencia(
      filter: $filter
      competencia: $competencia
      limit: $limit
      page: $page
    ) {
      docs {
        _id
        usuario
        usuarioData {
          _id
          nombre
          email
        }
        nombre_usuario
        apuesta
        momio
        totalGanar
        fecha
        status
        ganada
      }
      page
      totalDocs
      totalPages
    }
  }
`;
