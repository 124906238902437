import { gql } from "@apollo/client";

export const ReplyUser = gql`
  mutation replyUser($idMessage: ID!, $input: messageInput) {
    replyUser(idMessage: $idMessage, input: $input) {
      message
    }
  }
`;
export const MarkAsReadUnread = gql`
  mutation markAsReadUnread($idMessage: ID!) {
    markAsReadUnread(idMessage: $idMessage) {
      message
    }
  }
`;