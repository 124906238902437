import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CrearCuota from "../Actions/Create";
import DeleteCuota from "../Actions/Delete";

export default function CuotasTableBody({ data }) {

  return (
    <TableBody>
      {data.map((res) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{ height: "48px" }}
          >
            <TableCell>{res.suerteData?.suerte}</TableCell>
            <TableCell>{res.cuota}</TableCell>
            <TableCell>{res.typeCuota}</TableCell>
            <TableCell align="right"><CrearCuota data={res} edit /></TableCell>
            <TableCell align="right"><DeleteCuota data={res} /></TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
