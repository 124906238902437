import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListContent from "./ListContent";
import { listItems } from "./listItems";
import { Toolbar, Typography } from "@mui/material";
import packageJson from "../../../../package.json";

const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  overflowY: "hidden",
  "& .MuiPaper-root:hover": {
    overflowY: "auto",
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  overflowY: "hidden",
  "& .MuiPaper-root:hover": {
    overflowY: "auto",
  },
  width: `calc(${theme.spacing(7.6)} + 1px)`,
  [theme.breakpoints.down("md")]: {
    width: 0,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  [theme.breakpoints.down("md")]: {
    position: "absolute",
  },
}));

export default function MenuSideComponent({
  open,
  setView,
  handleClose,
  getContent,
}) {
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar variant="dense" />
      <List dense>
        {listItems.map((items, index) => (
          <ListContent
            key={index}
            items={items}
            setView={setView}
            handleClose={handleClose}
            getContent={getContent}
          />
        ))}
      </List>
      <Typography variant="caption" color="GrayText" sx={{
        position: "absolute",
        bottom: 5,
        left: 10
      }}>
        v{packageJson.version}
      </Typography>
    </Drawer>
  );
}
