import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../Components/ExcelExportButton";
import { CorteContext } from "../Context/CorteContext";
import { GetCortesVendedor } from "../graphql/queries";
import { MainContext } from "../../../../Context/MainCtx";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    const { _id, vendedor, encargado, ...input} = res
    dataFormat.push({
      ...input
    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { corteQuery } = React.useContext(CorteContext);
  const { tokenSesion, snackMessage, tokenDecoded } = React.useContext(MainContext);
  const excelData = formatTable(corteQuery.data?.getCortesVendedor?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getCortes = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetCortesVendedor,
        variables: {
          idUser: tokenDecoded._id,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getCortesVendedor.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name="Suerte charra - Cortes"
        getQueryData={getCortes}
        loading={loading}
      />
    </div>
  );
}
