import { Button, TextField, Select, styled } from "@mui/material";

export const CustomSearchTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    cursor: "pointer",
    "& .MuiInputBase-input": {
      padding: "4.2px 10px 4.2px 0px",
      width: "56px",
      transition: "width .3s",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiInputBase-input:focus": {
      width: "250px",
      cursor: "text",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.light,
    },
  },
}));

export const CustomEditableTextField = styled(TextField)(({ theme }) => ({
  "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active": {
    WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.paper} inset !important`,
  },
  "& .MuiOutlinedInput-root": {
    cursor: "pointer",
    padding: 0,
    "& fieldset": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      cursor: "pointer",
      padding: "4px",
      fontSize: 18,
    },
    "& .MuiInputBase-input:focus": {
      cursor: "text",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #B2BAC2",
    },
  },
}));

export const CustomTableSelect = styled(Select)(({ theme }) => ({
  borderColor: "#F3F3F3",
  "& .MuiSelect-select": {
    padding: "5px 10px 5px 0px",
  },
  "& fieldset": {
    borderColor: "#F3F3F3",
  },
  "&:hover fieldset": {
    borderColor: "#F3F3F3!important",
  },
  "&.Mui-focused fieldset": {
    border: "1px solid #F3F3F3!important",
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  //fontSize: 14,
}));
