import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  formatoFechaHora,
  formatoMexico,
} from "../../../../../Config/reuserFunctions";

export default function MovimientosTableBody({ data }) {
  return (
    <TableBody>
      {data.map((res) => {
        const charrComp = res.competenciaData?.charreadaData?.nombre || "N/A";
        const suerteComp = res.competenciaData?.suerteData?.suerte || "";
        const competencia = `${charrComp} ${suerteComp}`
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{
              height: "30px",
              "& .cell": {
                fontSize: 12,
                py: 0,
              },
            }}
          >
            <TableCell className="cell">{res.movimiento}</TableCell>
            <TableCell className="cell">{res.torneoData?.nombre || "N/A"}</TableCell>
            <TableCell className="cell">{competencia}</TableCell>
            <TableCell className="cell">${formatoMexico(res.monto)}</TableCell>
            <TableCell className="cell">{res.metodoPago}</TableCell>
            <TableCell className="cell">{res.status}</TableCell>
            <TableCell className="cell">
              {formatoFechaHora(res.fecha)}
            </TableCell>
            <TableCell className="cell">{res.balanceEnMovimiento}</TableCell>
            <TableCell className="cell">{res.balanceAnterior}</TableCell>
            <TableCell className="cell">{res.nuevoBalance}</TableCell>
            <TableCell className="cell">{res.comentarios}</TableCell>
            <TableCell className="cell">{res.orderID || "N/A"}</TableCell>
            <TableCell className="cell">{res.payerID || "N/A"}</TableCell>
            <TableCell className="cell">{res.paymentID || "N/A"}</TableCell>
            <TableCell className="cell">{res.paymentSource || "N/A"}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
