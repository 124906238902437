import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import NavbarDashboard from "../Dashboard/Navegacion/NavbarDashboard";
import { MainContext } from "../../Context/MainCtx";
import { Container, Toolbar } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GetFilialAuth } from "../Home/SignIn/gql/queries";
import CustomLoader from "../../Components/CustomLoader";
import ErrorMessage from "../../Components/ErrorMessage";
import CreateLogComponent from "../../Components/LogUser";
import SolicitudesRetiro from "../Dashboard/SolicitudesRetiro";

export default function DashboardFilialIndex() {
  const navigate = useNavigate();
  const { tokenSesion } = React.useContext(MainContext);
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const { loading, error } = useQuery(GetFilialAuth, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  if (loading) return <CustomLoader height="90vh" size={40} />;
  if (error) {
    if (error.message === "Unauthorized") navigate("/");
    return <ErrorMessage message={error.message} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        bgcolor: (theme) => theme.palette.background.paper,
      }}
    >
      <CssBaseline />
      <CreateLogComponent />
      <NavbarDashboard handleDrawerToggle={handleDrawerToggle} view="Solicitudes de retiro" />
      <Container maxWidth="xl" sx={{py: 1}}>
        <Toolbar variant="dense" />
        <SolicitudesRetiro />
      </Container>
    </Box>
  );
}
