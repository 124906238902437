import * as React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Add, DeleteOutline } from "@mui/icons-material";
import { MainContext } from "../../../../../../Context/MainCtx";
import { useMutation, useQuery } from "@apollo/client";
import { GetMotivos, CreateMotivo, DeleteMotivos } from "./queries";
import { DialogContentText, ListItemText } from "@mui/material";

export default function RespuestaMotivo({ datos, setDatos }) {
  const { tokenSesion } = React.useContext(MainContext);

  const { loading, data, error, refetch } = useQuery(GetMotivos, {
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  if (loading)
    return <TextField disabled size="small" value="Loading..." fullWidth />;
  if (error) return <TextField disabled size="small" value="error" fullWidth />;

  return (
    <>
      <FormControl fullWidth size="small" margin="dense" required>
        <InputLabel id="resp-id">Motivo</InputLabel>
        <Select
          labelId="resp-id"
          id="demo-simple-select"
          value={datos.comentarios}
          label="Motivo"
          onChange={(e) =>
            setDatos((st) => ({ ...st, comentarios: e.target.value }))
          }
          renderValue={(val) => datos.comentarios}
          startAdornment={
            <InputAdornment position="start">
              <AddNew setDatos={setDatos} refetch={refetch} />
            </InputAdornment>
          }
        >
          {data.getMotivos.map((res) => (
            <MenuItem key={res._id} value={res.motivo}>
              <ListItemText primary={res.motivo} />
              <DeleteItem data={res} setDatos={setDatos} refetch={refetch} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {console.log("VALUE", datos)}
      <FormControl
        fullWidth
        size="small"
        margin="dense"
        required
        disabled={datos.concepto === "RESTA"}
      >
        <InputLabel id="resp-id-2">Crear movimiento (Usuario)</InputLabel>
        <Select
          labelId="resp-id-2"
          value={datos.nuevoMov}
          //defaultValue="NO"
          label="rear movimiento (Usuario)"
          onChange={(e) =>
            setDatos((st) => ({ ...st, nuevoMov: e.target.value }))
          }
          //renderValue={(val) => val}
        >
          <MenuItem value="NO">
            <ListItemText primary="No crear movimiento" />
          </MenuItem>
          <MenuItem value="COMPRA">
            <ListItemText primary="COMPRA" />
          </MenuItem>
          {/* {Object.keys(TIPO_MOVIMIENTO).map((res) => (
            <MenuItem key={res} value={res}>
              <ListItemText primary={res} />
            </MenuItem>
          ))} */}
        </Select>
      </FormControl>
    </>
  );
}

const AddNew = ({ setDatos, refetch }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("");
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [createMotivo] = useMutation(CreateMotivo);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (e) => {
    try {
      if (!value) return;
      setLoading(true);
      const response = await createMotivo({
        variables: {
          motivo: value,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createMotivo.message,
        variant: "success",
      });
      setValue("");
      setDatos((st) => ({ ...st, comentarios: value }));
      setLoading(false);
      refetch();
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClickOpen}>
        <Add />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Agregar nuevo motivo</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleClose}
            color="inherit"
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleSave}
            color="primary"
            disabled={loading}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
const DeleteItem = ({ data, setDatos, refetch }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [deleteMotivos] = useMutation(DeleteMotivos);

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleDelete = async (e) => {
    try {
      e.stopPropagation();
      setLoading(true);
      const response = await deleteMotivos({
        variables: {
          idMotivo: data._id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.deleteMotivos.message,
        variant: "success",
      });
      setDatos((st) => ({ ...st, comentarios: "" }));
      setLoading(false);
      refetch();
      handleClose(e);
    } catch (err) {
      console.log(err);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClickOpen}>
        <DeleteOutline />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Elimnar motivo</DialogTitle>
        <DialogContent>
          <DialogContentText>{data.motivo}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleClose}
            color="inherit"
          >
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            disableElevation
            onClick={handleDelete}
            color="error"
            disabled={loading}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
