import React from "react";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { CustomSearchTextField } from "../../../../../Components/CustomMuiComponents";
import { UsuariosContext } from "../Context/UsuariosContext";

export default function SearchComponent({sx = null}) {
  const { filter, setFilter } = React.useContext(UsuariosContext);
  
  return (
    <CustomSearchTextField
      placeholder="Buscar"
      size="small"
      variant="outlined"
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
      sx={sx}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
