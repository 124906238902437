import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";
import FilterSelectTable from "../../../../Components/Inputs/FilterSelect";
import { MovSellerContext } from "../Context/MovSellerContext";

const headCells = [
  {
    id: "status",
    padding: "checkbox",
    align: "left",
    label: "Status",
    sortable: false,
    sx: null,
    filter: {
      filteringData: "status",
      filterName: "status",
      filterBy: "status",
    },
  },
  {
    id: "torneo",
    padding: "normal",
    align: "left",
    label: "Torneo",
    sortable: false,
    sx: {
      minWidth: "220px",
    },
    filter: {
      filteringData: "torneos",
      filterName: "torneo",
      filterBy: "nombre",
    },
  },
  {
    id: "competencia",
    padding: "normal",
    align: "left",
    label: "Competencia",
    sortable: false,
    sx: {
      minWidth: "350px",
    },
  },
  {
    id: "fecha",
    padding: "normal",
    align: "left",
    label: "Fecha",
    sortable: true,
    sx: {
      minWidth: "200px",
    },
  },
  {
    id: "movimiento",
    padding: "normal",
    align: "left",
    label: "Tipo",
    sortable: false,
    sx: {
      minWidth: "140",
    },
    filter: {
      filteringData: "movimientos",
      filterName: "movimiento",
      filterBy: "movimiento",
    },
  },
  {
    id: "balanceEnMovimiento",
    padding: "checkbox",
    align: "left",
    label: "Cantidad",
    sortable: false,
    sx: null,
  },
  {
    id: "metodoPago",
    padding: "checkbox",
    align: "left",
    label: "Metodo de Pago",
    sortable: false,
    sx: {
      minWidth: "140px",
    },
  },
];

export default function MovimientosSellerTableHead({
  handleSort,
  order,
  orderBy,
}) {
  const {
    catsQuery,
    filter,
    setFilter,
    //setFilterCats,
    //filterCats,
  } = React.useContext(MovSellerContext);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...headCell.sx,
              "&:hover .button-filter": {
                display: "inline-flex!important",
              },
            }}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
            {headCell.filter ? (
              <FilterSelectTable
                query={
                  catsQuery?.data?.getCatalogosMov[
                    headCell.filter.filteringData
                  ]
                }
                loading={catsQuery.loading}
                error={catsQuery.error}
                value={filter}
                setFilter={setFilter}
                //filterCats={filterCats}
                //setFilterCats={setFilterCats}
                filterName={headCell.filter.filterName}
                filterBy={headCell.filter.filterBy}
              />
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
