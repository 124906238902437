import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GetBetsByCompetencia } from "../../graphql/queries";
import { MainContext } from "../../../../../../Context/MainCtx";
import CustomLoader from "../../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../../Components/ErrorMessage";
import { Paper, TablePagination } from "@mui/material";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { formatoFechaCorta } from "../../../../../../Config/reuserFunctions";
import { CompetenciaContext } from "../../Context/CompetenciaContext";

export default function ApuestasTable({ competencia, filter }) {
  const { tokenSesion } = React.useContext(MainContext);
  const { refetchApuestas } = React.useContext(CompetenciaContext);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);

  const handleChangePage = (event, page) => {
    setPageConfig((st) => ({ ...st, page }));
  };

  const { data, loading, error, refetch } = useQuery(GetBetsByCompetencia, {
    variables: {
      filter: FILTER,
      competencia: competencia._id,
      page: pageConfig.page,
      limit: pageConfig.limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    refetch();
  }, [refetchApuestas, refetch]);

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const { docs, totalDocs } = data.getBetsByCompetencia;

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Usuario</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">Juego</TableCell>
              <TableCell align="right">Momio</TableCell>
              <TableCell align="right">Cobrará</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Fecha</TableCell>
              <TableCell align="right">Ganó</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((res) => {
              const { usuarioData } = res;
              const usuario = usuarioData
                ? `${usuarioData.nombre} ${usuarioData.apellidoPaterno || ""}`
                : res.nombre_usuario;
              return (
                <TableRow key={res._id}>
                  <TableCell>{usuario}</TableCell>
                  <TableCell>{usuarioData.email || ""}</TableCell>
                  <TableCell align="right">{res.apuesta}</TableCell>
                  <TableCell align="right">{res.momio}</TableCell>
                  <TableCell align="right">{res.totalGanar}</TableCell>
                  <TableCell align="right">{res.status}</TableCell>
                  <TableCell align="right">
                    {formatoFechaCorta(res.fecha)}
                  </TableCell>
                  <TableCell align="right">
                    {res.ganada ? "SI" : " NO"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalDocs}
        page={pageConfig.page}
        onPageChange={handleChangePage}
        rowsPerPage={pageConfig.limit}
      />
    </Paper>
  );
}
