import React from "react";
import { useRouteError } from "react-router-dom";
import { Box, Typography, useTheme, Paper } from "@mui/material";

export default function NoMatch() {
  const error = useRouteError();
  const theme = useTheme();

  return (
    <Paper
      id="error-page"
      sx={{
        bgcolor: theme.palette.background.default,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography variant="h1" align="center">
          Oops!
        </Typography>
        <Typography align="center">
          Lo sentimos, ocurrió un error inesperado.
        </Typography>
        <Typography align="center">
          <i>{error.statusText || error.message}</i>
        </Typography>
      </Box>
    </Paper>
  );
}
