import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import { GetBannersButtons } from "./gql";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import UploadButton from "./Upload";

export default function ButtonsBanners() {
  const { loading, data, error, refetch } = useQuery(GetBannersButtons, {
    fetchPolicy: "network-only",
  });

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  return (
    <Grid container spacing={2}>
      {data.getBannersButtons.map((res) => (
        <RenderCard key={res._id} data={res} refetch={refetch} />
      ))}
    </Grid>
  );
}

const RenderCard = ({ data, refetch }) => {
  const [preview, setPreview] = React.useState(data.image || "");
  const [file, setFile] = React.useState("");

  return (
    <Grid item xs={12} md={4}>
      <Card
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <CardHeader
          sx={{ py: 0 }}
          action={
            <UploadButton
              data={data}
              file={file}
              setFile={setFile}
              setPreview={setPreview}
              refetch={refetch}
            />
          }
        />
        <CardMedia component="img" height="250" image={preview} alt="torneos" />
        <CardContent sx={{ padding: "0px!important" }}>
          <Box
            sx={{
              backgroundImage: `url(${data.imageTitle})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: 64,
            }}
          />
          <Box
            sx={{
              height: "100%",
              fontWeight: "bolder",
              bgcolor: (theme) => theme.palette.primary.main,
              color: "white",
              textAlign: "center",
              padding: 1.3,
            }}
          >
            {data.text}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
