import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const itemsNav = (sesion) => {
  return sesion
    ? [
        { title: "Inicio", url: "/#inicio", icon: "Home", component: HashLink },
        { title: "Torneos", url: "/torneos", icon: "Stadium", component: Link },
        {
          title: "Tienda",
          url: "/tienda",
          icon: "MonetizationOnOutlined",
          component: Link,
        },
      ]
    : [
        { title: "Inicio", url: "/", icon: "Home", component: Link },
        {
          title: "Iniciar Sesión",
          url: "/signin",
          icon: "Login",
          component: Link,
        },
        {
          title: "Crear cuenta",
          url: "/signup",
          icon: "PersonAdd",
          component: Link,
        },
      ];
};

export const itemsResNav = (link = "/#", sesion) => {
  let url = link;
  if (
    url === "/#inicio" ||
    url === "/torneos" ||
    url === "/tienda" ||
    url === "/signin" ||
    url === "/signup"
  ) {
    url = "";
  }

  return sesion
    ? [
        {
          title: "Inicio",
          url: "/#inicio",
          icon: "Home",
          component: HashLink,
        },
        {
          title: "Torneos",
          url: "/torneos",
          icon: "Stadium",
          component: HashLink,
        },
        {
          title: "Tienda",
          url: "/tienda",
          icon: "MonetizationOnOutlined",
          component: Link,
        },
        {
          title: "Más",
          url,
          icon: "Menu",
          noTab: true,
        },
      ]
    : [
        {
          title: "Inicio",
          url: "/#inicio",
          icon: "Home",
          component: Link,
        },
        {
          title: "Entrar",
          url: "/signin",
          icon: "Login",
          component: Link,
        },
        {
          title: "Regístrate",
          url: "/signup",
          icon: "PersonAdd",
          component: Link,
        },
        {
          title: "Más",
          url,
          icon: "Menu",
          noTab: true,
        },
      ];
};
export const itemsResSubMenu = (sesion) => {
  return sesion
    ? [
        {
          title: "Mi cuenta",
          url: "/perfil",
          icon: "ManageAccounts",
          component: Link,
        },
        {
          title: "Historial",
          url: "/historial",
          icon: "History",
          component: Link,
        },
        {
          title: "Movimientos",
          url: "/movimientos",
          icon: "CurrencyExchange",
          component: Link,
        },
      ]
    : [];
};
