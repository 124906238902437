import { gql } from "@apollo/client";

export const CreateResultado = gql`
  mutation createResultado($input: resultadoInput) {
    createResultado(input: $input) {
      message
    }
  }
`;
export const UpdateResultado = gql`
  mutation updateResultado($idResultado: ID!, $input: resultadoInput) {
    updateResultado(idResultado: $idResultado, input: $input) {
      message
    }
  }
`;

export const DeleteResultado = gql`
  mutation deleteResultado($idResultado: ID!) {
    deleteResultado(idResultado: $idResultado) {
      message
    }
  }
`;
