import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useMatches, useNavigate } from "react-router-dom";
import CustomIconComponent from "../../../Components/CustomIconComponent";
import { isMobile } from "../../../Config/reuserFunctions";
import { Badge } from "@mui/material";

export default function ListContent({ items, setView, handleClose, getContent }) {
  const { title, url, icon } = items;
  const navigate = useNavigate();
  const matches = useMatches();
  const content = getContent(items.badge)

  const pathname = matches && matches.length > 1 ? matches[1].pathname : "";

  const handleClick = () => {
    navigate(url);
    setView(title);
    localStorage.setItem("lastview", title);
    if (isMobile) handleClose();
  };

  return (
    <ListItem disablePadding sx={{ display: "block" }}>
      <ListItemButton
        selected={url === pathname}
        onClick={() => handleClick()}
        sx={{
          minHeight: 38,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 2.6,
            justifyContent: "center",
          }}
        >
          <Badge badgeContent={content} color="primary">
            <CustomIconComponent
              iconName={icon}
              fontSize={24}
              color={url === pathname ? "primary" : "inherit"}
            />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
}
