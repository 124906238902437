import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorAutocomplete from "./ErrorAutocomplete";

export default function AutocompleteInput({
  renderData = [],
  value = "",
  inputValue = "",
  label = "",
  icon = null,
  loading = false,
  error = null,
  required = false,
  disabled = false,
  addButton = null,
  onChange = null,
  onInputChange = null,
  isOptionEqualToValue = null,
  getOptionLabel = null,
}) {
  const [open, setOpen] = React.useState(false);
  if (error) return <ErrorAutocomplete label={label} />;

  return (
    <Autocomplete
      autoHighlight
      openOnFocus
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      options={renderData}
      disabled={disabled}
      value={value || null}
      inputValue={inputValue}
      loading={loading}
      required={required}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            startAdornment: icon ? (
              <InputAdornment position="start">{icon}</InputAdornment>
            ) : null,
            endAdornment: (
              <React.Fragment>
                {addButton ? addButton : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props) => (
        <li {...props} style={{ fontSize: 13 }} key={props.id}>
          {props.key}
        </li>
      )}
      onChange={onChange}
      onInputChange={onInputChange}
    />
  );
}
