import React from "react";
import { Avatar, useTheme } from "@mui/material";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 14)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

export default function CustomAvatar({ size = 40, fontSize = 20, name = "", img = "", double = false }) {
  const theme = useTheme();

  let doubleLetter = "";
  if (!name)
    return (
      <Avatar
        alt="sin foto de perfil"
        loading="lazy"
        src={img}
        style={{
          height: size,
          width: size,
        }}
      />
    );
    
  if (name.split(" ").length > 1) {
    doubleLetter = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  } else {
    doubleLetter = `${name.split(" ")[0][0]}`;
  }

  let onlyOneLetter = name.split(" ")[0][0]

  return (
    <Avatar
      style={{
        height: size,
        width: size,
        backgroundColor: stringToColor(name),
        color: theme.palette.getContrastText(stringToColor(name)),
        fontSize,
      }}
      src={img}
      loading="lazy"
    >
      {double ? doubleLetter.toUpperCase() : onlyOneLetter.toUpperCase()}
    </Avatar>
  );
}
