import { gql } from "@apollo/client";

export const GetCharros = gql`
  query getCharros($filter: String, $limit: Int, $page: Int) {
    getCharros(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        nombre
        equipo
        foto
        equipoData {
          nombre
          estado {
            estado
            pais
          }
        }
      }
      totalDocs
      page
      totalPages
    }
  }
`;
