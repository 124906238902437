import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { Alert, Box, Grid } from "@mui/material";
import { formatoMexico } from "../../../Config/reuserFunctions";
import PaypalPayment from "./PaypalPayment";
import { MainContext } from "../../../Context/MainCtx";
import { Navigate, useNavigate } from "react-router-dom";
import { TORO } from "../../../Config/constantes";
import LazyImage from "../../../Components/LazyImage";
import { ArrowBack, CheckCircle } from "@mui/icons-material";
import CheckSesionStatus from "../CheckSesionStatus";

export default function PaymentDetail() {
  const {
    dataPayment,
    setDataPayment,
    tokenDecoded,
    initialPaymentState,
  } = React.useContext(MainContext);
  const navigate = useNavigate();

  if (!dataPayment.value) return <Navigate to="/" />;

  const { monto, value, title, img } = dataPayment;

  return (
    <Box sx={{ minHeight: "70vh", display: "flex", alignItems: "center" }}>
      <Container maxWidth="md" component={Paper}>
        <CheckSesionStatus />
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: { xs: 120, md: 230 },
              }}
            >
              <LazyImage src={img} alt={title} />
            </Box>
            <Alert severity="info">
              <b>1 toro equivale a ${TORO} MXN</b>
            </Alert>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper
              sx={{
                p: 2,
                boxShadow: {
                  xs: "",
                  md: "-20px 0px 20px 0px rgba(0, 0, 0, 0.12)",
                },
              }}
            >
              {dataPayment.completed ? (
                <Box
                  sx={{
                    display: "flex",
                    height: "300px",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <CheckCircle color="success" sx={{ fontSize: 100 }} />
                  <Typography align="center">
                    Pago Realizado, se te abonará automaticamente tu saldo
                  </Typography>
                  <Button
                    color="info"
                    startIcon={<ArrowBack />}
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                      setDataPayment(initialPaymentState);
                      navigate("/");
                    }}
                  >
                    Regresar a inicio
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Typography align="center" sx={{ fontSize: 16 }}>
                    <b>Detalle de pago</b>
                  </Typography>
                  <br />
                  <Box>
                    <Typography align="center" sx={{ fontSize: 14 }}>
                      Pago por
                    </Typography>
                    <Typography align="center" sx={{ fontSize: 18 }}>
                      <b>{value} Toros</b>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography align="center" sx={{ fontSize: 14 }}>
                      Total a pagar
                    </Typography>
                    <Typography align="center" sx={{ fontSize: 18 }}>
                      <b>${formatoMexico(monto)} MXN</b>
                    </Typography>
                  </Box>
                  <br />
                  <Typography align="center" sx={{ fontSize: 16 }}>
                    <b>Formas de pago</b>
                  </Typography>
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <PaypalPayment toros={value} monto={monto} />
                    {!tokenDecoded ? (
                      <Button
                        onClick={() => navigate("/signin")}
                        fullWidth
                        variant="contained"
                        disableElevation
                        color="info"
                        sx={{ textTransform: "none" }}
                      >
                        Iniciar Sesión
                      </Button>
                    ) : null}
                  </Box>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
