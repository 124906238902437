import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { Navigate } from "react-router-dom";
import { MainContext } from "../../../Context/MainCtx";
import ApuestasTerminadas from "./ApuestasTerminadas";
import ApuestasEnCurso from "./ApuestasEnCurso";
import historial from "../../../assets/images/historial.png";
import LazyImage from "../../../Components/LazyImage";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`recompensa-tabpanel-${index}`}
      aria-labelledby={`recompesas-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `recompesas-tab-${index}`,
    "aria-controls": `recompensa-tabpanel-${index}`,
  };
}

export default function ApuestasUser() {
  const { tokenDecoded } = React.useContext(MainContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!tokenDecoded) return <Navigate to="/signin" />;

  return (
    <Box sx={{ height: "calc(100vh - 48px)" }}>
      <Container maxWidth="md" sx={{ mt: 1 }}>
        <Box
          sx={{
            height: 50,
            width: { xs: "100%", md: 400 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LazyImage
            src={historial}
            alt="historial"
          />
        </Box>
        <Paper variant="outlined">
          <Box sx={{ width: "100%" }}>
            <Tabs value={value} centered onChange={handleChange}>
              <Tab label="En curso" {...a11yProps(0)} />
              <Tab label="Terminadas" sx={{ width: 140 }} {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <ApuestasEnCurso />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ApuestasTerminadas />
            </CustomTabPanel>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
