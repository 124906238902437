import React from "react";
import { Brightness4 } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { MainContext } from "../Context/MainCtx";

export default function DarkMode({ listMode = false, listModeRes = false }) {
  const { theming, setTheming } = React.useContext(MainContext);

  const handleClickTheme = () => {
    setTheming(theming === "dark" ? "light" : "dark");
    localStorage.setItem("theming", theming === "dark" ? "light" : "dark");
  };

  if (listMode) {
    return (
      <MenuItem onClick={handleClickTheme}>
        <ListItemIcon>
          <Brightness4 fontSize="small" />
        </ListItemIcon>
        Tema {theming === "dark" ? "claro" : "oscuro"}
      </MenuItem>
    );
  } else if (listModeRes) {
    return (
      <ListItem disablePadding>
        <ListItemButton onClick={handleClickTheme}>
          <ListItemIcon>
            <Brightness4 />
          </ListItemIcon>
          <ListItemText
            primary={`Tema ${theming === "dark" ? "claro" : "oscuro"}`}
          />
        </ListItemButton>
      </ListItem>
    );
  } else {
    return (
      <IconButton color="inherit" onClick={handleClickTheme}>
        <Brightness4 />
      </IconButton>
    );
  }
}
