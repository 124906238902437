import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import { formatoMexico } from "../../../../../../Config/reuserFunctions";
import { PaidOutlined } from "@mui/icons-material";
import { CircularProgress, Typography } from "@mui/material";
import { CustomButton } from "../../../../../../Components/CustomMuiComponents";
import { UpdateBalanceUser } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../../Context/MainCtx";
import { UsuariosContext } from "../../Context/UsuariosContext";
import RespuestaMotivo from "./RespuestaMotivo";

const initialData = {
  monto: 0,
  concepto: "SUMA",
  comentarios: "",
  nuevoMov: "NO"
}

export default function AjusteBalance({ user }) {
  const [open, setOpen] = React.useState(false);
  const [updateBalanceUser, { loading }] = useMutation(UpdateBalanceUser);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const { queryRefetch } = React.useContext(UsuariosContext);
  const [datos, setDatos] = React.useState(initialData);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDatos(initialData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateBalanceUser({
        variables: {
          input: { ...datos, usuario: user._id },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateBalanceUser.message,
        variant: "success",
      });
      queryRefetch();
      handleClose();
    } catch (err) {
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <Button
        fullWidth
        startIcon={<PaidOutlined />}
        variant="text"
        size="small"
        onClick={handleClickOpen}
        sx={{ justifyContent: "flex-start" }}
      >
        {formatoMexico(user.saldo, 1)}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-title-ajuste"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-title-ajuste">
          {"Realizar ajuste de saldos"}
        </DialogTitle>
        <DialogContent>
          <Typography align="center">{`Saldo actual: ${formatoMexico(
            user.saldo
          )}`}</Typography>
          <form id="form-ajuste" onSubmit={handleSubmit}>
            <TextField
              size="small"
              fullWidth
              type="number"
              label="Monto"
              value={datos.value}
              required
              onChange={(e) =>
                setDatos((st) => ({ ...st, monto: parseFloat(e.target.value) }))
              }
              margin="dense"
            />
            <FormControl fullWidth margin="dense" size="small" required>
              <InputLabel id="concepto-ajuste">Operación</InputLabel>
              <Select
                labelId="concepto-ajuste"
                value={datos.concepto}
                label="Operación"
                onChange={(e) => {
                  setDatos((st) => ({ ...st, concepto: e.target.value, nuevoMov: "NO" }))
                }
                  
                }
              >
                <MenuItem value="">
                  <em>Selecciona una opción</em>
                </MenuItem>
                <MenuItem value="SUMA">Sumar</MenuItem>
                <MenuItem value="RESTA">Restar</MenuItem>
              </Select>
            </FormControl>
            <RespuestaMotivo datos={datos} setDatos={setDatos} />
          </form>
        </DialogContent>
        <DialogActions>
          <CustomButton color="inherit" onClick={handleClose}>
            Cancelar
          </CustomButton>
          <CustomButton
            variant="contained"
            disableElevation
            type="submit"
            form="form-ajuste"
            disabled={loading}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Realizar ajuste
          </CustomButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
