import { gql } from "@apollo/client";

export const CreateUser = gql`
  mutation createUser($token: String!) {
    createUser(token: $token) {
      token
    }
  }
`;
export const SendEmailConfirm = gql`
  mutation createConfirmation($input: crearUsuarioInput) {
    createConfirmation(input: $input) {
      message
    }
  }
`;