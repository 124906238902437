import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, TableSortLabel } from "@mui/material";
import FilterSelectTable from "../../../../../Components/Inputs/FilterSelect";
import { CompetenciaContext } from "../Context/CompetenciaContext";

export default function CompetenciaTableHead({
  handleSort,
  order,
  orderBy,
  handleCheckAll,
}) {
  const {
    filter,
    setFilter,
    //setFilterCats,
    //filterCats,
    itemsSelected,
    indeterminated,
    showCalc,
    compQuery,
  } = React.useContext(CompetenciaContext);

  const { catalogos } = compQuery?.data?.getCompetencias;

  const headCells = [
    {
      id: "torneo",
      padding: "normal",
      align: "left",
      label: "Torneo",
      sortable: false,
      filter: {
        filteringData: "torneos",
        filterName: "torneo",
        filterBy: "nombre",
      },
      sx: {
        minWidth: "400px",
      },
    },
    {
      id: "charreada",
      padding: "normal",
      align: "left",
      label: "Charreada",
      sortable: false,
      filter: {
        filteringData: "charreadas",
        filterName: "charreada",
        //filterBy: "nombre",
      },
      sx: {
        minWidth: "200px",
      },
    },
    {
      id: "suerte",
      padding: "normal",
      align: "left",
      label: "Suerte",
      sortable: false,
      filter: {
        filteringData: "suertes",
        filterName: "suerte",
        filterBy: "suerte",
      },
      sx: {
        minWidth: "180px",
      },
    },
    {
      id: "typeFactor",
      padding: "checkbox",
      align: "left",
      label: "#Factor",
      sortable: false,
    },
    {
      id: "typeCuota",
      padding: "checkbox",
      align: "left",
      label: "#Cuota",
      sortable: false,
    },
    {
      id: "equipo",
      padding: "normal",
      align: "left",
      label: "Equipo",
      sortable: false,
      filter: {
        filteringData: "equipos",
        filterName: "equipo",
        filterBy: "nombre",
      },
      sx: {
        minWidth: "300px",
      },
    },
    {
      id: "charro",
      padding: "normal",
      align: "left",
      label: "Charro",
      sortable: false,
      filter: {
        filteringData: "charros",
        filterName: "charro",
        filterBy: "nombre",
      },
      sx: {
        minWidth: "300px",
      },
    },
    {
      id: "promedio",
      padding: "normal",
      align: "left",
      label: "Promedio",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "mayor",
      padding: "normal",
      align: "left",
      label: "Mayor",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "menor",
      padding: "normal",
      align: "left",
      label: "Menor",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "aritmetica",
      padding: "normal",
      align: "left",
      label: "Aritmética",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "HECTOR",
      padding: "normal",
      align: "left",
      label: "HECTOR",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "media",
      padding: "normal",
      align: "left",
      label: "Media",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "probabilidad",
      padding: "normal",
      align: "left",
      label: "Probabilidad",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "constante",
      padding: "normal",
      align: "left",
      label: "Constante",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "Factor",
      padding: "normal",
      align: "left",
      label: "Factor",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "Rendimiento",
      padding: "normal",
      align: "left",
      label: "Rendimiento",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "Cuota",
      padding: "normal",
      align: "left",
      label: "Cuota",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "ValorPunto",
      padding: "normal",
      align: "left",
      label: "ValorPunto",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "110px",
      },
    },
    {
      id: "Ponderador",
      padding: "normal",
      align: "left",
      label: "Ponderador",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "Ponderado",
      padding: "normal",
      align: "left",
      label: "Ponderado",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "Apuestas",
      padding: "normal",
      align: "left",
      label: "Juegos",
      sortable: false,
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "TotalApuestas",
      padding: "normal",
      align: "left",
      label: "TotalApuestas",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "120px",
      },
    },
    {
      id: "Bolsa",
      padding: "normal",
      align: "left",
      label: "Bolsa",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "BolsaMenosApuesta",
      padding: "normal",
      align: "left",
      label: "BolsaMenosApuesta",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "PorcentajeCasa",
      padding: "normal",
      align: "left",
      label: "PorcentajeCasa",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "bolsaMenosApuestaMenosPorcentajeCasa",
      padding: "normal",
      align: "left",
      label: "bolsaMenosApuestaMenosPorcentajeCasa",
      sortable: false,
      hidden: !showCalc ? true : false, 
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "Momio",
      padding: "checkbox",
      align: "left",
      label: "Momio",
      sortable: false,
      sx: {
        position: "sticky",
        right: 265,
      },
    },
    {
      id: "multiplicador",
      padding: "normal",
      align: "left",
      label: "Multiplicador",
      sortable: true,
      sx: {
        minWidth: "100px",
      },
    },
    {
      id: "status",
      padding: "checkbox",
      align: "center",
      label: "status",
      sortable: true,
      filter: {
        filteringData: "statusComp",
        filterName: "status",
        filterBy: "status",
      },
      sx: {
        minWidth: "120px",
        position: "sticky",
        right: 150,
      },
    },
    {
      id: "createdAt",
      padding: "normal",
      align: "left",
      label: "Fecha",
      sortable: true,
      sx: {
        minWidth: "140px",
      },
    },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
            position: { xs: "relative", sm: "sticky" },
            left: 0,
            zIndex: 3,
          }}
        >
          <Checkbox
            indeterminate={indeterminated}
            checked={itemsSelected.length > 0 && !indeterminated}
            onChange={(e) => handleCheckAll(e.target.checked)}
          />
        </TableCell>
        <TableCell align="right" padding="checkbox" />
        {headCells.map((headCell) => !headCell.hidden ? (
          <TableCell
            key={headCell.id}
            sx={{
              ...headCell.sx,
              "&:hover .button-filter": {
                display: "inline-flex!important",
              },
            }}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
            {headCell.filter ? (
              <FilterSelectTable
                query={catalogos[headCell.filter.filteringData]}
                loading={compQuery.loading}
                error={compQuery.error}
                value={filter}
                setFilter={setFilter}
                //setFilterCats={setFilterCats}
                //filterCats={filterCats}
                filterName={headCell.filter.filterName}
                filterBy={headCell.filter?.filterBy}
              />
            ) : null}
          </TableCell>
        ) : null)}
        <TableCell
          align="right"
          padding="checkbox"
          sx={{
            bgcolor: (theme) => theme.palette.background.paper,
            position: "sticky",
            right: 100,
          }}
        />
        <TableCell
          align="right"
          padding="checkbox"
          sx={{
            bgcolor: (theme) => theme.palette.background.paper,
            position: "sticky",
            right: 66,
          }}
        />
        <TableCell
          align="right"
          padding="checkbox"
          sx={{
            bgcolor: (theme) => theme.palette.background.paper,
            position: "sticky",
            right: 0,
          }}
        />
      </TableRow>
    </TableHead>
  );
}
