import { gql } from "@apollo/client";

export const GetFactores = gql`
  query getFactores($filter: String, $limit: Int, $page: Int, $type: Int) {
    getFactores(filter: $filter, limit: $limit, page: $page, type: $type) {
      docs {
        _id
        suerte
        suerteData {
          _id
          suerte
        }
        promMin
        promMax
        factor
        typeFactor
      }
      totalDocs
      page
      totalPages
    }
  }
`;
