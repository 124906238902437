import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import TablePagination from "@mui/material/TablePagination";
import {
  formatoFechaHora,
  formatoMexico,
  getColorStatusApuesta,
} from "../../../Config/reuserFunctions";
import { useQuery } from "@apollo/client";
import { GetBetsFinishUser } from "./gql/queries";
import { MainContext } from "../../../Context/MainCtx";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import { STATUS_APUESTA } from "../../../Config/constantes";
import { AccessTime, ArrowBack, CheckCircle, Close } from "@mui/icons-material";
import { io } from "socket.io-client";

export default function ApuestasTerminadas() {
  const { tokenSesion, tokenDecoded, setErrors } = React.useContext(
    MainContext
  );
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const { page, limit } = pageConfig;

  const { data, loading, error, refetch } = useQuery(GetBetsFinishUser, {
    variables: {
      filter: {
        idUsuario: tokenDecoded._id,
        fechaInicio: "",
        fechaFin: "",
      },
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedApuestas", () => refetch());
    return () => socket.disconnect();
  }, [refetch]);

  React.useEffect(() => {
    if (error) setErrors({ ...error, funcion: "getBetsFinishUser" });
  }, [error, setErrors]);

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const { docs, totalDocs } = data?.getBetsFinishUser;

  const handleChangePage = (event, page) => {
    setPageConfig((st) => ({ ...st, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPageConfig({ limit: parseInt(event.target.value, 10), page: 0 });
  };

  const getIconStatus = (status) => {
    const { perdida, ganada, pendiente, cancelada } = STATUS_APUESTA;
    switch (status) {
      case perdida:
        return <Close color="error" />;
      case cancelada:
        return <ArrowBack color="warning" />;
      case ganada:
        return <CheckCircle color="success" />;
      case pendiente:
        return <AccessTime color="action" />;
      default:
        return null;
    }
  };

  const getTextRecompensa = (status) => {
    const { perdida, ganada, pendiente, cancelada } = STATUS_APUESTA;
    switch (status) {
      case perdida:
        return "Sin recompensa";
      case cancelada:
        return "Devolución";
      case ganada:
        return "Recompensa";
      case pendiente:
        return "Pendiente";
      default:
        return null;
    }
  };

  return (
    <Box sx={{ py: 0.5 }}>
      <Box
        sx={{
          maxHeight: {
            xs: `calc(90vh - 272px)`,
            sm: `calc(90vh - 180px)`,
            overflow: "auto",
          },
        }}
      >
        {docs.map((res) => {
          const suerte = res.suerteData?.suerte;
          const isTeam = suerte.toLowerCase() === "equipo";
          const status =
            res.status === STATUS_APUESTA.ganada ? "GANADOR" : res.status;
          return (
            <Box
              key={res._id}
              sx={{
                px: 1,
                borderBottom: "1px solid",
                borderColor: "divider",
                py: 1,
              }}
              variant="outlined"
            >
              <Box sx={{ display: { xs: "block", md: "flex" }, gap: 1 }}>
                <Typography variant="body2">
                  <b>{`${res.torneoData?.nombre} ${res.charreadaData?.nombre}`}</b>
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="body2">
                  {formatoFechaHora(res.fecha)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {isTeam ? (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Chip
                      size="small"
                      label={
                        <b>{`${formatoMexico(res.momio)} ${
                          res.suerteData?.suerte
                        }`}</b>
                      }
                    />
                    <Typography>{res.equipoData?.nombre}</Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Chip
                      size="small"
                      label={
                        <b>{`${formatoMexico(res.momio)} ${
                          res.suerteData?.suerte
                        }`}</b>
                      }
                    />
                    <Typography>{res.charroData?.nombre}</Typography>
                  </Box>
                )}
                <Box sx={{ flexGrow: 1 }} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "flex-start", md: "flex-end" },
                  }}
                >
                  <Chip
                    size="small"
                    icon={getIconStatus(res.status)}
                    label={status}
                    sx={{ bgcolor: getColorStatusApuesta(res.status), color: theme => theme.palette.getContrastText(getColorStatusApuesta(res.status)), }}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box>
                  <Typography align="right">
                    Jugado: {formatoMexico(res.apuesta)}
                  </Typography>
                  <Typography align="right">
                    {`${getTextRecompensa(res.status)}: ${formatoMexico(
                      res.status === STATUS_APUESTA.cancelada ? res.apuesta : res.totalGanar
                    )}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      <TablePagination
        component="div"
        count={totalDocs}
        page={pageConfig.page}
        onPageChange={handleChangePage}
        rowsPerPage={pageConfig.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
