import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import CompetenciasTable from "./CompetenciasTable";
import { useQuery } from "@apollo/client";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import { io } from "socket.io-client";
import { MainContext } from "../../../Context/MainCtx";
import { Box } from "@mui/material";
import moment from "moment-timezone";
import { GetCompByCharreada } from "../../Home/Torneos/graphql/queries";

export default function ListaCharreada({ index, data, idTorneo, torneo }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const fecha =
    data.fechaCharreada === "No disponible"
      ? ""
      : moment(data.fechaCharreada).format("lll");

  return (
    <Accordion
      //defaultExpanded={index === 0}
      expanded={expanded}
      onChange={handleExpansion}
      slots={{ transition: Fade }}
      slotProps={{ transition: { timeout: 400 } }}
      sx={{
        "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
        "& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
        "& .MuiAccordionSummary-root": {
          height: expanded ? "40px" : { xs: "60px", md: "50px" },
        },
        "& .MuiAccordionSummary-content": {
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${data._id}-content`}
        id={`panel-${data._id}1-header`}
      >
        <Box
          sx={{
            width: "100%",
            display: { xs: "block", md: "flex" },
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mr: 2,
              lineHeight: { xs: "1", md: "normal" },
              fontSize: { xs: 14, md: 18 },
            }}
          >
            <b>{data.nombre}</b>
          </Typography>
          <Typography
            variant="subtitle2"
            color="gray"
            sx={{ fontSize: { xs: 12, md: 14 } }}
          >
            {fecha}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
        }}
      >
        <GetDataCompetenciasRender
          idTorneo={idTorneo}
          idCharreada={data._id}
          expanded={expanded}
          torneo={torneo}
          charreada={data}
        />
      </AccordionDetails>
    </Accordion>
  );
}

const GetDataCompetenciasRender = ({
  idTorneo,
  idCharreada,
  expanded,
  torneo,
  charreada,
}) => {
  const { setErrors } = React.useContext(MainContext);
  const { loading, data, error, refetch } = useQuery(GetCompByCharreada, {
    variables: {
      idTorneo,
      idCharreada,
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedCompetencia", () => refetch());
    return () => socket.disconnect();
  }, [refetch]);

  React.useEffect(() => {
    if (error) setErrors({ ...error, funcion: "getCompByCharreada" });
  }, [error, setErrors]);

  if (!expanded) return null;
  if (loading) return <CustomLoader height="15vh" />;
  if (error)
    return (
      <ErrorMessage height="20vh" fontSize={30} message={error?.message} />
    );

  const competencias = data?.getCompByCharreada || [];

  return (
    <CompetenciasTable
      data={competencias}
      torneo={torneo}
      charreada={charreada}
    />
  );
};
