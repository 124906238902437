import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { MainContext } from "../Context/MainCtx";
import { useNavigate } from "react-router";

export default function ErrorMessage({
  height = "50vh",
  message = "Hubo un problema desconocido",
  fontSize = 80,
}) {
  const { handleCloseSesion } = React.useContext(MainContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (message === "Sesion Expired") {
      setTimeout(() => {
        handleCloseSesion();
        navigate("/");
      }, 1500);
    }
  }, [handleCloseSesion, message, navigate]);

  return (
    <div>
      <Box
        sx={{
          height,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
        variant="outlined"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 3
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Info color="disabled" sx={{ fontSize }} />
          </Box>
          <Box sx={{ my: 1 }} />
          <Typography color="gray" align="center" variant="h6">
            {message}
          </Typography>
        </Paper>
      </Box>
    </div>
  );
}
