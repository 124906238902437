import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  formatoFechaHora,
  formatoMexico,
} from "../../../../../../Config/reuserFunctions";
import UserDetail from "../../Details/UserDetail";
import { TableFooter, Typography } from "@mui/material";
import { MovimientoContext } from "../../Context/MovimientoContext";

export default function MovimientosTableBody({ data }) {
  const { query } = React.useContext(MovimientoContext);
  const { efectivoTotal, balanceMovimientoTotal } = query.data;

  return (
    <>
      <TableBody>
        {data.map((res) => {
          return (
            <TableRow
              role="checkbox"
              tabIndex={-1}
              key={res._id}
              sx={{
                height: "30px",
                "& .cell": {
                  fontSize: 12,
                  py: 0,
                },
              }}
            >
              <TableCell className="cell">
                <UserDetail
                  idUser={res.usuario}
                  usuarioData={res.usuarioData}
                  nombre={res.nombre_usuario}
                />
              </TableCell>
              <TableCell className="cell">{res.usuarioData?.email ?? ""}</TableCell>
              <TableCell className="cell">{res.concepto}</TableCell>
              <TableCell className="cell">
                ${formatoMexico(res.monto)}
              </TableCell>
              <TableCell className="cell">{res.metodoPago}</TableCell>
              <TableCell className="cell">{res.status}</TableCell>
              <TableCell className="cell">
                {formatoFechaHora(res.fecha)}
              </TableCell>
              <TableCell className="cell">{res.balanceEnMovimiento}</TableCell>
              <TableCell className="cell">{res.balanceAnterior}</TableCell>
              <TableCell className="cell">{res.nuevoBalance}</TableCell>
              <TableCell className="cell">{res.nombreAfilial}</TableCell>
              <TableCell className="cell">{res.comentarios}</TableCell>
              <TableCell className="cell">{res.orderID}</TableCell>
              <TableCell className="cell">{res.payerID}</TableCell>
              <TableCell className="cell">{res.paymentID}</TableCell>
              <TableCell className="cell">{res.paymentSource}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow
          sx={{ position: "sticky", bottom: 0, backgroundColor: theme => theme.palette.background.paper }}
        >
          <TableCell colSpan={2} />
          <TableCell>
            <Typography>
              <b>${efectivoTotal}</b>
            </Typography>
          </TableCell>
          <TableCell colSpan={3} />
          <TableCell>
            <Typography>
              <b>{balanceMovimientoTotal}</b>
            </Typography>
          </TableCell>
          <TableCell colSpan={3} />
        </TableRow>
      </TableFooter>
    </>
  );
}
