import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import { ArrowBack, Add, Edit } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { ResultadoContext } from "../Context/ResultadoContext";
import { CreateResultado, UpdateResultado } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CustomButton } from "../../../../../Components/CustomMuiComponents";
import AutocompleteInput from "../../../../../Components/Inputs/AutocompleteInput";
import moment from "moment-timezone";
import DateInput from "../../../../../Components/Inputs/DateInput";

const initialState = {
  torneo: null,
  torneoData: null,
  equipo: null,
  equipoData: null,
  suerte: null,
  suerteData: null,
  charro: null,
  charroData: null,
  calificacion: 0,
  real: 0,
  fecha: moment().tz("America/Mexico_City").format(),
};

export default function CrearResultado({ data = "", edit = false }) {
  const {
    resultQuery,
    catsQuery,
    setFilterCats,
    filterCats,
  } = React.useContext(ResultadoContext);
  const { snackMessage, tokenSesion, tokenDecoded } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [createResultado] = useMutation(CreateResultado);
  const [updateResultado] = useMutation(UpdateResultado);
  const [open, setOpen] = React.useState(false);
  const [resultado, setResultado] = React.useState(initialState);

  const handleOpen = () => {
    setOpen(true);
    if (edit) setResultado(data);
  };

  const handleClose = () => {
    setOpen(false);
    setResultado(initialState);
  };

  const onchangeInput = (value, name) => {
    setFilterCats({ ...filterCats, [name]: value });
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const {
        torneoData,
        equipoData,
        charroData,
        suerteData,
        ...input
      } = resultado;
      const response = await createResultado({
        variables: {
          input: {
            ...input,
            usuario: tokenDecoded._id,
            nombreUsuario: tokenDecoded.nombre
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createResultado.message,
        variant: "success",
      });
      resultQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const {
        _id,
        torneoData,
        equipoData,
        charroData,
        suerteData,
        usuarioData,
        ...input
      } = resultado;
      const response = await updateResultado({
        variables: {
          idResultado: resultado._id,
          input,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateResultado.message,
        variant: "success",
      });
      resultQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen} size="small">
          <Edit />
        </IconButton>
      ) : (
        <CustomButton
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpen}
          size="small"
          disableElevation
        >
          Nuevo
        </CustomButton>
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "35vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Nuevo resultado
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="form-resultado"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "80vh" }}>
            <form
              id="form-resultado"
              onSubmit={edit ? handleUpdate : handleSave}
            >
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Torneo
                </Typography>
                <AutocompleteInput
                  renderData={catsQuery.data?.getCatalogos.torneos}
                  value={resultado.torneoData}
                  inputValue={filterCats.torneo}
                  loading={catsQuery.loading}
                  error={catsQuery.error}
                  onChange={(_, option) => {
                    setResultado((state) => ({
                      ...state,
                      torneo: option?._id || null,
                      nombreTorneo: option?.nombre || "",
                      torneoData: option
                        ? {
                            nombre: option.nombre,
                            _id: option._id,
                          }
                        : null,
                    }));
                  }}
                  onInputChange={(_, value) => onchangeInput(value, "torneo")}
                  isOptionEqualToValue={(option, value) =>
                    option.nombre === value.nombre
                  }
                  getOptionLabel={(option) => option?.nombre || ""}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Equipo
                </Typography>
                <AutocompleteInput
                  renderData={catsQuery.data?.getCatalogos.equipos}
                  value={resultado.equipoData}
                  inputValue={filterCats.equipo}
                  loading={catsQuery.loading}
                  error={catsQuery.error}
                  onChange={(_, option) => {
                    setResultado((state) => ({
                      ...state,
                      equipo: option?._id || null,
                      nombreEquipo: option?.nombre || "",
                      equipoData: option
                        ? {
                            nombre: option.nombre,
                            _id: option._id,
                          }
                        : null,
                    }));
                  }}
                  onInputChange={(_, value) => onchangeInput(value, "equipo")}
                  isOptionEqualToValue={(option, value) =>
                    option.nombre === value.nombre
                  }
                  getOptionLabel={(option) => option?.nombre || ""}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Charro
                </Typography>
                <AutocompleteInput
                  renderData={catsQuery.data?.getCatalogos.charros}
                  value={resultado.charroData}
                  inputValue={filterCats.charro}
                  loading={catsQuery.loading}
                  error={catsQuery.error}
                  onChange={(_, option) => {
                    setResultado((state) => ({
                      ...state,
                      charro: option?._id || null,
                      nombreCharro: option?.nombre || "",
                      charroData: option
                        ? {
                            nombre: option.nombre,
                            _id: option._id,
                          }
                        : null,
                    }));
                  }}
                  onInputChange={(_, value) => onchangeInput(value, "charro")}
                  isOptionEqualToValue={(option, value) =>
                    option.nombre === value.nombre
                  }
                  getOptionLabel={(option) => option?.nombre || ""}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Suerte
                </Typography>
                <AutocompleteInput
                  renderData={catsQuery.data?.getCatalogos.suertes}
                  value={resultado.suerteData}
                  inputValue={filterCats.suerte}
                  loading={catsQuery.loading}
                  error={catsQuery.error}
                  onChange={(_, option) => {
                    setResultado((state) => ({
                      ...state,
                      suerte: option?._id || null,
                      nombreSuerte: option?.suerte || "",
                      suerteData: option
                        ? {
                            suerte: option.suerte,
                            _id: option._id,
                          }
                        : null,
                    }));
                  }}
                  onInputChange={(_, value) => onchangeInput(value, "suerte")}
                  isOptionEqualToValue={(option, value) =>
                    option.suerte === value.suerte
                  }
                  getOptionLabel={(option) => option?.suerte || ""}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Calificación
                </Typography>
                <TextField
                  fullWidth
                  name="calificacion"
                  size="small"
                  type="number"
                  value={resultado.calificacion}
                  onChange={(e) => {
                    setResultado((state) => ({
                      ...state,
                      calificacion: parseFloat(e.target.value),
                    }));
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setResultado({ ...resultado, real: 0 });
                    }
                  }}
                  inputProps={{
                    min: 0,
                  }}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Real
                </Typography>
                <TextField
                  fullWidth
                  name="real"
                  size="small"
                  type="number"
                  value={resultado.real}
                  onChange={(e) => {
                    setResultado((state) => ({
                      ...state,
                      real: parseFloat(e.target.value),
                    }));
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setResultado({ ...resultado, real: 0 });
                    }
                  }}
                  inputProps={{
                    min: 0,
                  }}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Fecha
                </Typography>
                <DateInput
                  name="fecha"
                  onChange={(name, value) => {
                    setResultado((state) => ({
                      ...state,
                      [name]: moment(value).format(),
                    }));
                  }}
                  value={resultado.fecha}
                  required
                />
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}
