import * as React from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { LogsContext } from "../Context/LogsContext";
import LogsTableBody from "./TableBody";
import {
  getComparator,
  stableSort,
} from "../../../../../Config/reuserFunctions";
import LogsTableHead from "./TableHead";

export default function LogsTable() {
  const { queryData, setPageConfig, pageConfig } = React.useContext(
    LogsContext
  );
  const { docs, totalDocs } = queryData.getLogErrors;
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("fecha");

  const renderSorted = React.useMemo(() => {
    return stableSort(docs, getComparator(order, orderBy));
  }, [order, orderBy, docs]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, page) => {
    setPageConfig((st) => ({ ...st, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPageConfig({ limit: parseInt(event.target.value, 10), page: 0 });
  };

  return (
    <>
      <Paper variant="outlined">
        <TableContainer
          sx={{
            transition: "width .3s",
            height: { xs: `calc(100vh - 272px)`, sm: `calc(100vh - 180px)` }, //xs: 232, sm: 140 sin paginacion
            borderRadius: 2,
          }}
        >
          <Table stickyHeader size="small">
            <LogsTableHead
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
            />
            <LogsTableBody data={renderSorted} />
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        component="div"
        count={totalDocs}
        page={pageConfig.page}
        onPageChange={handleChangePage}
        rowsPerPage={pageConfig.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
