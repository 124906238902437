import { ArrowBack, Info } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useMatches, useNavigate } from "react-router-dom";

export default function ErrorConfirm() {
  const navigate = useNavigate();
  const matches = useMatches();
  const message =
    matches && matches.length > 0 ? matches[0].params.message : "";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "80vh",
      }}
    >
      <Info fontSize="large" color="action" />
      <Typography>
        <b>Error de confirmación</b>
      </Typography>
      <Typography>{message}</Typography>
      <Button
        startIcon={<ArrowBack />}
        size="small"
        variant="contained"
        disableElevation
        sx={{my: 2, textTransform: "none"}}
        onClick={() => navigate("/")}
      >
        Regresar
      </Button>
    </Box>
  );
}
