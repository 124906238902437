import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { formatoFechaCorta } from "../../../../../Config/reuserFunctions";
import DeleteEquipo from "../Actions/Delete";
import CreateEquipo from "../Actions/Create";

export default function EquiposTableBody({ equipos }) {

  return (
    <TableBody>
      {equipos.map((team) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={team._id}
            sx={{ height: "48px" }}
          >
            <TableCell>{team.nombre}</TableCell>
            <TableCell>{team.estado?.estado}</TableCell>
            <TableCell>{team.estado?.pais}</TableCell>
            <TableCell>{formatoFechaCorta(team.createdAt)}</TableCell>
            <TableCell><CreateEquipo data={team} edit /></TableCell>
            <TableCell><DeleteEquipo data={team} /></TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
