import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";

const headCells = [
  {
    id: "status",
    padding: "padding",
    align: "left",
    label: "Status",
    sortable: false,
    sx: null
  },
  {
    id: "fechaInicio",
    padding: "normal",
    align: "left",
    label: "Inició",
    sortable: true,
    sx: {
      minWidth: "180px",
    },
  },
  {
    id: "fechaFin",
    padding: "normal",
    align: "left",
    label: "Terminó",
    sortable: true,
    sx: {
      minWidth: "180px",
    },
  },
  {
    id: "totalMontoInicial",
    padding: "normal",
    align: "left",
    label: "Saldo inicial",
    sortable: false,
    sx: {
      minWidth: "120px"
    },
  },
  {
    id: "totalMontoFinal",
    padding: "normal",
    align: "left",
    label: "Saldo final",
    sortable: false,
    sx: {
      minWidth: "120px"
    }
  },
  {
    id: "totalMontoApuestas",
    padding: "normal",
    align: "left",
    label: "Total Jugado",
    sortable: false,
    sx: {
      minWidth: "130px"
    }
  },
  {
    id: "saldoDespuesDeVentas",
    padding: "normal",
    align: "left",
    label: "Inicial - total",
    sortable: false,
    sx: {
      minWidth: "140px"
    }
  },
  {
    id: "totalMontoApuestasPerdidas",
    padding: "normal",
    align: "left",
    label: "Ganado",
    sortable: false,
    sx: {
      minWidth: "150px"
    }
  },
  {
    id: "totalMontoApuestasPagadas",
    padding: "normal",
    align: "left",
    label: "Perdido",
    sortable: false,
    sx: {
      minWidth: "150px"
    }
  },
  {
    id: "utilidad",
    padding: "normal",
    align: "left",
    label: "Utilidad",
    sortable: false,
    sx: {
      minWidth: "110px"
    },
  },
  {
    id: "cantidadApuestasTotal",
    padding: "checkbox",
    align: "left",
    label: "Juegos",
    sortable: false,
    sx: null
  },
  {
    id: "cantiadadApuestasRealizadas",
    padding: "checkbox",
    align: "left",
    label: "Realizadas",
    sortable: false,
    sx: null
  },
  {
    id: "cantiadadapuestasCanceladas",
    padding: "checkbox",
    align: "left",
    label: "Canceladas",
    sortable: false,
    sx: null
  },
  {
    id: "cantidadApuestasGanadas",
    padding: "checkbox",
    align: "left",
    label: "Perdidas",
    sortable: false,
    sx: null
  },
  {
    id: "cantidadApuestasPerdidas",
    padding: "checkbox",
    align: "left",
    label: "Ganadas",
    sortable: false,
    sx: null
  },
];

export default function CorteTableHead({
  handleSort,
  order,
  orderBy,
}) {

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...headCell.sx,
              "&:hover .button-filter": {
                display: "inline-flex!important",
              },
            }}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
