import React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import moment from "moment-timezone";
import 'moment/locale/es'; 

moment.locale('es');

export default function FilterChips({ filter, setFilter }) {
  const data = Object.keys(filter) || [];

  const handleDelete = (key) => {
    setFilter({
      ...filter,
      [key]: "",
    });
  };

  return (
    <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
      {data.map((key, indx) =>
        filter[key] ? (
          <Grid item>
            <Chip
              key={indx}
              label={
                key === "fechaInicio" || key === "fechaFin"
                  ? moment(filter[key]).tz("America/Mexico_City").format("ll")
                  : filter[key]
              }
              size="small"
              onDelete={() => handleDelete(key)}
            />
          </Grid>
        ) : null
      )}
    </Grid>
  );
}
