import React from "react";
import { useApolloClient } from "@apollo/client";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { RiFileExcel2Line } from "react-icons/ri";
import { exportToCSV } from "react-easy-export";
import { green } from "@mui/material/colors";
import { MainContext } from "../../../../Context/MainCtx";
import { GetCortesVendedor } from "../../Cortes/graphql/queries";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    const { _id, vendedor, encargado, ...input} = res
    dataFormat.push({
      ...input
    });
  });
  return dataFormat;
};
export default function UserExcelExport({ idUser }) {
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const client = useApolloClient();

  /* Queries */
  const getMovimientos = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetCortesVendedor,
        variables: {
          idUser,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getCortesVendedor.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const exportAllData = async () => {
    const response = await getMovimientos();
    if (response) exportToCSV(response, `cortes vendedor.csv`);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="success"
        sx={{ bgcolor: green[600] }}
        disableElevation
        onClick={exportAllData}
      >
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <RiFileExcel2Line style={{ fontSize: 19, margin: "2px 0" }} />
        )}
      </Button>
    </div>
  );
}
