import { createBrowserRouter } from "react-router-dom";
import NoMatch from "../Components/NoMatch/NoMatch";
import SignIn from "../Views/Home/SignIn";
import DashboardIndex from "../Views/Dashboard";
import CatalogosIndex from "../Views/Dashboard/Catalogos";
import SignUpUser from "../Views/Home/SignUp";
import HomeMain from "../Views/Home/Home";
import Home from "../Views/Home/index";
import Tienda from "../Views/Home/Tienda";
import Movimientos from "../Views/Home/Movimientos";
import Perfil from "../Views/Home/Perfil";
import QuienesSomos from "../Views/Home/FooterPages/QuienesSomos";
import Politicas from "../Views/Home/FooterPages/Politicas";
import Terminos from "../Views/Home/FooterPages/Terminos";
import PreguntasFrec from "../Views/Home/FooterPages/PreguntasFrec";
import ApuestasUser from "../Views/Home/ApuestasUser";
import PasswordReset from "../Views/Home/SignIn/PasswordReset";
import Competencias from "../Views/Dashboard/Competencias";
import ApuestasTorneo from "../Views/Home/Torneos/ApuestasTorneo";
import ApuestasRealizadas from "../Views/Dashboard/Apuestas";
import Torneos from "../Views/Home/Torneos";
import Emails from "../Views/Dashboard/Emails";
import Contacto from "../Views/Home/Contacto";
import SettingsFoot from "../Views/Dashboard/Settings";
import Indicaciones from "../Views/Home/FooterPages/Indicaciones";
import Auditorias from "../Views/Dashboard/Auditorias";
import SolicitudesRetiro from "../Views/Dashboard/SolicitudesRetiro";
import Confirmation from "../Views/Home/SignUp/Confirmation";
import ErrorConfirm from "../Views/Home/SignUp/ErrorConfirm";
import VendedoresAsignados from "../Views/Seller/Vendedores";
import MovimientosSeller from "../Views/Seller/Movimientos";
import SellerIndex from "../Views/Seller";
import PerfilManager from "../Views/Seller/Perfil";
import TorneoDetalle from "../Views/Seller/Torneos/Charreadas";
import TorneosManager from "../Views/Seller/Torneos";
import ApuestasRealizadasManager from "../Views/Seller/Apuestas";
import CortesSeller from "../Views/Seller/Cortes";
import DashboardFilialIndex from "../Views/Filial";
import PaymentDetail from "../Views/Home/Tienda/PaymentDetail";
import ReCaptchaWebView from "../Components/Webviews/ReCaptcha";
import PaymentWebView from "../Components/Webviews/Payment";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <NoMatch />,
    children: [
      {
        path: "",
        element: <HomeMain />,
      },
      {
        path: "signin",
        element: <SignIn />,
      },
      {
        path: "signup",
        element: <SignUpUser />,
      },
      {
        path: "torneos",
        element: <Torneos />,
      },
      {
        path: "torneo/:idTorneo",
        element: <ApuestasTorneo />,
      },
      {
        path: "tienda",
        element: <Tienda />,
      },
      {
        path: "payment",
        element: <PaymentDetail />,
      },
      {
        path: "movimientos",
        element: <Movimientos />,
      },
      {
        path: "historial",
        element: <ApuestasUser />,
      },
      {
        path: "perfil",
        element: <Perfil />,
      },
      {
        path: "about",
        element: <QuienesSomos />,
      },
      {
        path: "politicas",
        element: <Politicas />,
      },
      {
        path: "terminos",
        element: <Terminos />,
      },
      {
        path: "faq",
        element: <PreguntasFrec />,
      },
      {
        path: "contacto",
        element: <Contacto />
      },
      {
        path: "indicaciones",
        element: <Indicaciones />
      }
    ],
  },
  {
    path: "/admin",
    element: <DashboardIndex />,
    errorElement: <NoMatch />,
    children: [
      {
        path: "juegos",
        element: <ApuestasRealizadas />
      },
      {
        path: "catalogos",
        element: <CatalogosIndex />,
      },
      {
        path: "competencias",
        element: <Competencias />,
      },
      {
        path: "auditorias",
        element: <Auditorias />
      },
      {
        path: "emails",
        element: <Emails />
      },
      {
        path: "solicitudes",
        element: <SolicitudesRetiro />
      },
      {
        path: "settings",
        element: <SettingsFoot />,
      },
    ],
  },
  {
    path: "/seller",
    element: <SellerIndex />,
    errorElement: <NoMatch />,
    children: [
      {
        path: "torneos",
        element: <TorneosManager />,
      },
      {
        path: "torneo/:idTorneo",
        element: <TorneoDetalle />,
      },
      {
        path: "juegos",
        element: <ApuestasRealizadasManager />
      },
      {
        path: "movimientos",
        element: <MovimientosSeller />,
      },
      {
        path: "actividades",
        element: <CortesSeller />,
      },
      {
        path: "perfil",
        element: <PerfilManager />,
      },
      {
        path: "vendedores",
        element: <VendedoresAsignados />,
      },
    ],
  },
  {
    path: "/resetpassword/:token",
    element: <PasswordReset />,
    errorElement: <NoMatch />,
  },
  {
    path: "/confirmation/:token",
    element: <Confirmation />,
    errorElement: <NoMatch />,
  },
  {
    path: "/confirmation/error/:message",
    element: <ErrorConfirm />,
    errorElement: <NoMatch />,
  },
  {
    path: "/filial",
    element: <DashboardFilialIndex />,
    errorElement: <NoMatch />,
  },
  {
    path: "/webview/recaptcha",
    element: <ReCaptchaWebView />,
    errorElement: <NoMatch />,
  },
  {
    path: "/webview/payment",
    element: <PaymentWebView />,
    errorElement: <NoMatch />,
  },
]);
