import * as React from "react";
import { MovimientoContext } from "../Context/MovimientoContext";
import { CustomButton } from "../../../../Components/CustomMuiComponents";
import { FilterAltOff } from "@mui/icons-material";
import { isMobile } from "../../../../Config/reuserFunctions";

export default function CleanFilters() {
  const {
    setFilter,
    setFilterCats,
    initialFilter,
    initialCats,
  } = React.useContext(MovimientoContext);

  const handleClearFilter = () => {
    setFilter(initialFilter);
    setFilterCats(initialCats);
  };

  return (
    <CustomButton
      size="small"
      color="inherit"
      variant="contained"
      startIcon={isMobile ? null : <FilterAltOff />}
      onClick={handleClearFilter}
      disableElevation
    >
      {isMobile ? <FilterAltOff /> : "Limpiar"}
    </CustomButton>
  );
}
