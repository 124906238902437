import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {
  AlternateEmail,
  LockOpen,
  Login,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../Context/MainCtx";
import jwtDecode from "jwt-decode";
import { SignInUser, VerifyReCaptcha } from "./gql/mutations";
import RecoverPassword from "./RecoverPassword";
import { ROLES } from "../../../Config/constantes";
import ReCaptchaWebComponent from "../../../Components/ReCaptchaComponent";

export default function LoginForm() {
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [verified, setVerified] = React.useState(false);
  const [data, setData] = React.useState({
    email: "",
    password: "",
  });
  const { email, password } = data;
  const navigate = useNavigate();
  const [loginUser] = useMutation(SignInUser);
  const [verifyReCaptcha] = useMutation(VerifyReCaptcha);
  const { snackMessage, setTokenDecoded, setCookie, setErrors } = React.useContext(
    MainContext
  );
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await loginUser({
        variables: {
          input: {
            email,
            password,
          }
        },
      });
      //cookies.set("_ScSesion", response.data.loginUser.token);
      const { token } = response.data.loginUser;
      setCookie("_ScSesion", token, { path: "/" });
      localStorage.setItem("currentToken", token);
      const decoded = jwtDecode(token);
      setTokenDecoded(decoded);
      setLoading(false);
      snackMessage({
        message: "Listo!",
        variant: "success",
      });
      if (decoded.rol === ROLES.ADMIN) {
        navigate("/admin");
        return;
      }
      if (decoded.rol === ROLES.MANAGER) {
        navigate("/seller");
        return;
      }
      if (decoded.rol === ROLES.SELLER) {
        navigate("/seller");
        return;
      }
      if (decoded.rol === ROLES.FILIAL) {
        navigate("/filial");
        return;
      }
      navigate("/");
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
      setErrors({...err, funcion: "loginUser"})
    }
  };

  const handleRecaptchaChange = async (token) => {
    // enviar token a backend para verificarlo
    try {
      await verifyReCaptcha({
        variables: { token },
      });
      setVerified(true);
    } catch (error) {
      console.log(error);
      snackMessage({
        message: error.message,
        variant: "error",
      });
    }
  };

  return (
    <Box sx={{ width: 370 }}>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" align="center">
            <b>Iniciar sesión</b>
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            placeholder="Correo electrónico"
            variant="outlined"
            margin="dense"
            required
            type="email"
            value={email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmail />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            placeholder="Password"
            variant="outlined"
            margin="dense"
            required
            type={show ? "text" : "password"}
            value={password}
            onChange={(e) => setData({ ...data, password: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpen />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!show)}>
                    {show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
            <RecoverPassword />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
            <ReCaptchaWebComponent
              onChange={handleRecaptchaChange}
              onExpired={() => setVerified(false)}
            />
          </Box>
          <Button
            sx={{ mt: 1 }}
            fullWidth
            variant="contained"
            disableElevation
            type="submit"
            disabled={loading || !verified}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Login />
              )
            }
          >
            Entrar
          </Button>
        </form>
      </Paper>
    </Box>
  );
}
