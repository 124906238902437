import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  formatoFechaHora,
  formatoMexico,
} from "../../../../../../../Config/reuserFunctions";
import { STATUS_MOVIMIENTO, TIPO_MOVIMIENTO } from "../../../../../../../Config/constantes";

export default function MovimientosTableBody({
  data,
  dataComp,
  dataQuery,
  filter,
}) {
  const { totalMonto, totalCantidad } = dataQuery;

  const getTextMonto = () => {
    switch (filter.movimiento) {
      case TIPO_MOVIMIENTO.ajusteResta:
        return `Total ${TIPO_MOVIMIENTO.ajusteResta}`;
      case TIPO_MOVIMIENTO.ajusteSuma:
        return `Total ${TIPO_MOVIMIENTO.ajusteSuma}`;
      case TIPO_MOVIMIENTO.compra:
        return `Total ${TIPO_MOVIMIENTO.compra}`;
      case TIPO_MOVIMIENTO.errorCompra:
        return `Total ${TIPO_MOVIMIENTO.errorCompra}`;
      case TIPO_MOVIMIENTO.pagoApuesta:
        return `Total ${TIPO_MOVIMIENTO.pagoApuesta}`;
      case TIPO_MOVIMIENTO.retiro:
        return `Total ${TIPO_MOVIMIENTO.retiro}`;
      default:
        return "Total general";
    }
  };
  const getTextCantidad = () => {
    switch (filter.movimiento) {
      case TIPO_MOVIMIENTO.apuesta:
        return `Total ${TIPO_MOVIMIENTO.apuesta}`;
      case TIPO_MOVIMIENTO.apuestaGanada:
        return `Total ${TIPO_MOVIMIENTO.apuestaGanada}`;
      case TIPO_MOVIMIENTO.apuestaCancelada:
        return `Total ${TIPO_MOVIMIENTO.apuestaCancelada}`;
      default:
        return "Total general";
    }
  };

  return (
    <TableBody>
      {data.map((res) => {
        const charrComp = res.competenciaData?.charreadaData?.nombre || "N/A";
        const suerteComp = res.competenciaData?.suerteData?.suerte || "";
        const competencia = `${charrComp} ${suerteComp}`;
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{
              height: "30px",
              "& .cell": {
                fontSize: 12,
                py: 0,
              },
            }}
          >
            <TableCell className="cell">{res.movimiento}</TableCell>
            <TableCell className="cell">
              {res.torneoData?.nombre || dataComp?.torneo_name || "N/A"}
            </TableCell>
            <TableCell className="cell">{competencia}</TableCell>
            <TableCell
              className="cell"
              sx={{
                color: res.status === STATUS_MOVIMIENTO.realizado && "green",
              }}
            >
              ${formatoMexico(res.monto)}
            </TableCell>
            <TableCell className="cell">{res.metodoPago}</TableCell>
            <TableCell className="cell">{res.status}</TableCell>
            <TableCell className="cell">
              {formatoFechaHora(res.fecha)}
            </TableCell>
            <TableCell className="cell">{res.balanceEnMovimiento}</TableCell>
            <TableCell className="cell">{res.balanceAnterior}</TableCell>
            <TableCell className="cell">{res.nuevoBalance}</TableCell>
            <TableCell className="cell">{res.comentarios}</TableCell>
            <TableCell className="cell">{res.orderID || "N/A"}</TableCell>
            <TableCell className="cell">{res.payerID || "N/A"}</TableCell>
            <TableCell className="cell">{res.paymentID || "N/A"}</TableCell>
            <TableCell className="cell">{res.paymentSource || "N/A"}</TableCell>
          </TableRow>
        );
      })}
      <TableRow
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: (theme) => theme.palette.background.paper,
          "& .cell": {
            border: 0,
          },
        }}
      >
        <TableCell className="cell" colSpan={2} />
        <TableCell className="cell" align="right">
          <b>{getTextMonto()}</b>
        </TableCell>
        <TableCell className="cell">
          <b>${formatoMexico(totalMonto)}</b>
        </TableCell>
        <TableCell className="cell" colSpan={2} />
        <TableCell className="cell" align="right">
          <b>{getTextCantidad()}</b>
        </TableCell>
        <TableCell className="cell">
          <b>{formatoMexico(totalCantidad)}</b>
        </TableCell>
        <TableCell className="cell" colSpan={12} />
      </TableRow>
    </TableBody>
  );
}
