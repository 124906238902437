import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, IconButton } from "@mui/material";
import { PriceCheck } from "@mui/icons-material";
import { GetUserBalance } from "../../../Dashboard/Auditorias/Usuarios/graphql/queries";
import { MainContext } from "../../../../Context/MainCtx";
import { gql, useMutation, useQuery } from "@apollo/client";

const FinishCorte = gql`
  mutation finishCorte($input: corteEndInput) {
    finishCorte(input: $input) {
      message
    }
  }
`;

export default function CerrarCorte({ data, refetch }) {
  const {
    snackMessage,
    tokenSesion,
    tokenDecoded,
    setErrors,
  } = React.useContext(MainContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [finishCorte] = useMutation(FinishCorte);

  const balanceQuery = useQuery(GetUserBalance, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const balance = balanceQuery.data?.getUserBalance?.saldo || 0;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const crearApuestaBD = async () => {
    try {
      setLoading(true);
      const res = await finishCorte({
        variables: {
          input: {
            idCorte: data._id,
            vendedor: tokenDecoded._id,
            totalMontoFinal: balance,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      snackMessage({
        message: res.data.finishCorte.message,
        variant: "success",
      });
      refetch();
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
      setErrors({ ...error, funcion: "createApuestaSeller" });
    }
  };

  if(data.status === "TERMINADO") return null

  return (
    <React.Fragment>
      <IconButton size="small" color="success" onClick={handleClickOpen}>
        <PriceCheck />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Terminar Actividad</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estas seguro de terminar con la actividad?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{textTransform: "none"}} onClick={handleClose}>No, Cancelar</Button>
          <Button sx={{textTransform: "none"}}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
            variant="contained"
            disableElevation
            onClick={crearApuestaBD}
            autoFocus
          >
            Si, Terminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
