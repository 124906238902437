import * as React from "react";
import Tab from "@mui/material/Tab";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { ArrowDropDown } from "@mui/icons-material";

export function FactoresDropdown({ a11yProps, setValue, setType, setTypeC, type }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSetFactor = (type) => {
    setValue(1);
    setType(type)
    setTypeC(0);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tab
        icon={<ArrowDropDown sx={{ fontSize: 16 }} />}
        iconPosition="end"
        label={`Factores ${type ? type : ""}`}
        onClick={handleClickListItem}
        {...a11yProps(1)}
      />
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <MenuItem onClick={() => handleSetFactor(2)}>
          Factores 2
        </MenuItem>
        <MenuItem onClick={() => handleSetFactor(3)}>
          Factores 3
        </MenuItem>
        <MenuItem onClick={() => handleSetFactor(4)}>
          Factores 4
        </MenuItem>
      </Menu>
    </>
  );
}

export function CuotasDropdown({ a11yProps, setValue, setType, setTypeF, type }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClickListItem = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleSetFactor = (type) => {
      setValue(2);
      setType(type)
      setTypeF(0);
      setAnchorEl(null);
    };
  
    return (
      <>
        <Tab
          icon={<ArrowDropDown sx={{ fontSize: 16 }} />}
          iconPosition="end"
          label={`Cuotas ${type ? type : ""}`}
          onClick={handleClickListItem}
          {...a11yProps(2)}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleSetFactor(2)}>
            Cuotas 2
          </MenuItem>
          <MenuItem onClick={() => handleSetFactor(3)}>
            Cuotas 3
          </MenuItem>
          <MenuItem onClick={() => handleSetFactor(4)}>
            Cuotas 4
          </MenuItem>
        </Menu>
      </>
    );
  }
  