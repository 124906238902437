import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { AddPhotoAlternate, Close } from "@mui/icons-material";
import LazyImage from "../../../Components/LazyImage";

export default function UploadIds({ user, setUser, imagenes, setImagenes }) {
  const cargarImagen = (e) => {
    const { files } = e.target;
    if (e.target.files.length < 1) return;
    setImagenes([...imagenes, files[0]]);
  };

  const eliminarImagen = (imagen, index) => {
    if (imagen.key) {
      //poner en array de deletedImgs
      const deletedImgs = user.deletedImgs ? [...user.deletedImgs] : [];
      deletedImgs.push(imagen);
      const imagenesID = user.imagenesID.filter(
        (res) => res.key !== imagen.key
      );

      setUser((st) => ({
        ...st,
        deletedImgs,
        imagenesID,
      }));
    }
    const copyImgs = [...imagenes];
    copyImgs.splice(index, 1);
    setImagenes(copyImgs);
  };

  return (
    <Box>
      <Box sx={{ display: {xs: "block", md: "flex"}, alignItems: "center", gap: 1 }}>
        <Box>
          <input
            accept="image/jpeg, image/jpg, image/png"
            style={{ display: "none" }}
            id="identification-person"
            type="file"
            onChange={cargarImagen}
            disabled={imagenes.length > 1 ? true : false}
          />
          <label htmlFor="identification-person">
            <Button
              variant="text"
              color="primary"
              component="span"
              startIcon={<AddPhotoAlternate />}
              sx={{ textTransform: "none" }}
              disabled={imagenes.length > 1 ? true : false}
            >
              Cargar imagen
            </Button>
          </label>
        </Box>
        <Typography variant="subtitle2">
          Formatos admitidos: <b>JPG, JPEG, PNG</b>
        </Typography>
      </Box>
      {imagenes.map((imagen, index) => {
        const preview = imagen.key ? imagen.url : URL.createObjectURL(imagen);
        return (
          <Box key={index} sx={{ my: 2 }}>
            <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}>
              <Box sx={{ height: 100, width: 140 }}>
                <LazyImage
                  alt={`identificacion-${index + 1}`}
                  src={preview}
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography noWrap>
                  {imagen.key ? imagen.key : imagen.name}
                </Typography>
                {imagen.key ? null : (
                  <Typography variant="caption">{imagen.size} bits</Typography>
                )}
              </Box>
              <Button
                sx={{ textTransform: "none" }}
                size="small"
                onClick={() => eliminarImagen(imagen, index)}
                startIcon={<Close />}
              >
                Eliminar
              </Button>
            </Box>
            <Divider />
          </Box>
        );
      })}
    </Box>
  );
}
