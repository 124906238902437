import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import esLocale from "date-fns/locale/es";
import moment from "moment-timezone";
import { Today } from "@mui/icons-material";

export default function FilterDateRange({ filter, setFilter, filterName }) {
  const [open, setOpen] = React.useState(null);
  const [classN, setClassN] = React.useState("button-filter");

  const handleClick = (event) => {
    event.stopPropagation();
    setOpen(!open);
    setClassN(!open ? "" : "button-filter");
  };

  const handleChange = (name, value) => {
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  return (
    <>
      <IconButton
        id="filter-date-range"
        aria-controls={open ? "filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        className={classN}
        sx={{
          display:
            open || filter[filterName] ? "inline-flex!important" : "none",
          color: (theme) =>
            filter[filterName]
              ? theme.palette.primary.main
              : theme.palette.action.disabled,
          padding: 0,
          //mx: 1,
        }}
      >
        <Today sx={{ fontSize: 20 }} />
      </IconButton>
      {(open || filter[filterName]) && (
        <Box sx={{ display: "flex", gap: 1, width: 360 }}>
          <LocalizationProvider dateAdapter={AdapterMoment} locale={esLocale}>
            <DemoContainer
              components={["DatePicker", "DatePicker"]}
              sx={{ padding: 0, overflow: "none" }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <DatePicker
                  value={filter.fechaInicio ? moment(filter.fechaInicio) : null}
                  onChange={(val) =>
                    handleChange(
                      "fechaInicio",
                      moment(val).tz("America/Mexico_City").format()
                    )
                  }
                  label="De"
                  slotProps={{
                    textField: {
                      size: "small",
                      sx: {
                        "& .MuiOutlinedInput-root": {
                          "& .MuiInputBase-input": {
                            padding: "5px 0px 6px 4px",
                          },
                        },
                      },
                    },
                    openPickerButton: {
                      size: "small",
                    },
                    openPickerIcon: {
                      sx: { fontSize: 20 },
                    },
                  }}
                />

                <DatePicker
                  value={filter.fechaFin ? moment(filter.fechaFin) : null}
                  onChange={(val) =>
                    handleChange(
                      "fechaFin",
                      moment(val).tz("America/Mexico_City").format()
                    )
                  }
                  label="A"
                  slotProps={{
                    textField: {
                      size: "small",
                      sx: {
                        "& .MuiOutlinedInput-root": {
                          "& .MuiInputBase-input": {
                            padding: "5px 0px 6px 4px",
                          },
                        },
                      },
                    },
                    openPickerButton: {
                      size: "small",
                    },
                    openPickerIcon: {
                      sx: { fontSize: 20 },
                    },
                  }}
                />
              </Box>
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      )}
    </>
  );
}
