import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CrearResultado from "../Actions/Create";
import DeleteResultado from "../Actions/Delete";
import {
  formatoFechaCorta,
  isMobile,
} from "../../../../../Config/reuserFunctions";

export default function ResultadosTableBody({ data, shadows }) {
  return (
    <TableBody>
      {data.map((res) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{
              height: "30px",
              "& .cell": {
                fontSize: 12,
                py: 0,
              },
            }}
          >
            <TableCell className="cell">{res.torneoData?.nombre || res.nombreTorneo}</TableCell>
            <TableCell className="cell">{res.equipoData?.nombre || res.nombreEquipo}</TableCell>
            <TableCell className="cell">{res.suerteData?.suerte || res.nombreSuerte}</TableCell>
            <TableCell className="cell">{res.charroData?.nombre || res.nombreCharro}</TableCell>
            <TableCell className="cell">{res.calificacion}</TableCell>
            <TableCell className="cell">{res.real}</TableCell>
            <TableCell className="cell">{formatoFechaCorta(res.fecha)}</TableCell>
            <TableCell className="cell">{res.usuarioData?.nombre || res.nombreUsuario}</TableCell>
            <TableCell
              className={`cell ${isMobile ? "" : shadows.end}`}
              align="right"
              sx={{
                position: "sticky",
                right: 50,
                bgcolor: (theme) => theme.palette.background.paper,
                px: 1
              }}
            >
              <CrearResultado data={res} edit />
            </TableCell>
            <TableCell
              align="right"
              className="cell"
              sx={{
                position: "sticky",
                right: 0,
                bgcolor: (theme) => theme.palette.background.paper,
                px: 1
              }}
            >
              <DeleteResultado data={res} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
