import { gql } from "@apollo/client";

export const GetBetsUser = gql`
  query GetBetsUser($idUsuario: ID!, $limit: Int, $page: Int) {
    getBetsUser(idUsuario: $idUsuario, limit: $limit, page: $page) {
      docs {
        _id
        torneo
        torneoData {
          _id
          nombre
        }
        equipo
        equipoData {
          _id
          nombre
        }
        suerte
        suerteData {
          _id
          suerte
        }
        charro
        charroData {
          _id
          nombre
        }
        charreada
        charreadaData {
          _id
          nombre
        }
        usuario
        usuarioData {
          _id
          nombre
        }
        apuesta
        totalGanar
        momio
        fecha
        status
        ganada
        ticket
      }
      page
      totalDocs
      totalPages
    }
  }
`;
export const GetBetsFinishUser = gql`
  query GetBetsFinishUser($filter: inputFilterUser, $limit: Int, $page: Int) {
    getBetsFinishUser(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        torneo
        torneoData {
          _id
          nombre
        }
        equipo
        equipoData {
          _id
          nombre
        }
        suerte
        suerteData {
          _id
          suerte
        }
        charro
        charroData {
          _id
          nombre
        }
        charreada
        charreadaData {
          _id
          nombre
        }
        usuario
        usuarioData {
          _id
          nombre
        }
        apuesta
        totalGanar
        momio
        fecha
        status
        ganada
        ticket
      }
      page
      totalDocs
      totalPages
    }
  }
`;
