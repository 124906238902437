import { gql } from "@apollo/client";

export const UpdateUserAdmin = gql`
  mutation updateUserDashboard($idUser: ID!, $input: updateUsuarioDashInput) {
    updateUserDashboard(idUser: $idUser, input: $input) {
      message
    }
  }
`;
export const CreateSeller = gql`
  mutation createSeller($input: updateUsuarioDashInput) {
    createSeller(input: $input) {
      message
    }
  }
`;
export const DeleteUserDash = gql`
  mutation deleteUser($idUser: ID!) {
    deleteUser(idUser: $idUser) {
      message
    }
  }
`;
export const UpdateStatusUser = gql`
  mutation updateStatusUser($idUser: ID!, $activo: Boolean) {
    updateStatusUser(idUser: $idUser, activo: $activo) {
      message
    }
  }
`;
