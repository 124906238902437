import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  GetIconMovimiento,
  formatoFechaHora,
  formatoMexico,
} from "../../../../Config/reuserFunctions";
import AceptarRechazarMovimiento from "../Actions/AceptRechMov";
//import CancelarMov from "../Actions/CancelarMov";
import { STATUS_MOVIMIENTO, TIPO_MOVIMIENTO } from "../../../../Config/constantes";
import { blue } from "@mui/material/colors";
import { MovimientoContext } from "../Context/MovimientoContext";
import { Checkbox } from "@mui/material";

export default function MovimientosTableBody({ data, handleSelect }) {
  const { itemsSelected } = React.useContext(MovimientoContext);

  return (
    <TableBody>
      {data.map((res) => {
        let porAtender = true;
        if (res.movimiento !== TIPO_MOVIMIENTO.retiro) porAtender = false;
        if (res.status === STATUS_MOVIMIENTO.realizado) porAtender = false;
        if (res.status === STATUS_MOVIMIENTO.cancelado) porAtender = false;
        if (res.status === STATUS_MOVIMIENTO.rechazado) porAtender = false;
        if (res.status === STATUS_MOVIMIENTO.fallido) porAtender = false;

        const borderLeft = porAtender ? `5px solid ${blue[500]}` : "none";
        const checked = itemsSelected.find((item) => item._id === res._id);

        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{
              height: "30px",
              "& .cell": {
                fontSize: 12,
                py: 0,
              },
            }}
          >
            <TableCell
              padding="checkbox"
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 2,
                bgcolor: (theme) => theme.palette.background.paper,
                borderLeft
              }}
              className="cell"
            >
              <Checkbox
                onChange={(e) => handleSelect(e.target.checked, res)}
                onClick={(e) => e.stopPropagation()}
                checked={checked ? true : false}
                disabled={res.status === STATUS_MOVIMIENTO.en_proceso || res.status === STATUS_MOVIMIENTO.pendiente}
              />
            </TableCell>
            <TableCell padding="checkbox">
              <GetIconMovimiento status={res.status} />
            </TableCell>
            <TableCell
              className="cell"
              sx={
                res.status === STATUS_MOVIMIENTO.en_proceso
                  ? { color: blue[500] }
                  : null
              }
            >
              {res.status}
            </TableCell>
            <TableCell className="cell">{res.movimiento}</TableCell>
            <TableCell className="cell">{res.concepto}</TableCell>
            <TableCell className="cell">${formatoMexico(res.monto)}</TableCell>
            <TableCell className="cell">
              {formatoFechaHora(res.fecha)}
            </TableCell>
            <TableCell className="cell">{res.comentarios}</TableCell>
            <TableCell className="cell">{res.usuarioData?.nombre || res.nombre_usuario}</TableCell>
            <TableCell className="cell">{res.nombreAfilial}</TableCell>
            {/* <TableCell
              className="cell"
              align="right"
              
            >
              <CancelarMov data={res} />
            </TableCell> */}
            <TableCell
              className="cell"
              align="right"
              /*  sx={{
                position: "sticky",
                right: 0,
                bgcolor: (theme) => theme.palette.background.paper,
              }} */
            >
              <AceptarRechazarMovimiento data={res} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
