import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Close } from "@mui/icons-material";
import { MainContext } from "../../../../../../Context/MainCtx";
import { GetMovimientosTorneoReport } from "./queries";
import TableMovimientosApuesta from "./Table";
import CustomLoader from "../../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import FilterChips from "../FilterChips";
import ApuestaExcelExport from "./ApuestaExcelExport";
import { formatoFechaCorta } from "../../../../../../Config/reuserFunctions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialFilter = {
  fechaInicio: null,
  fechaFin: null,
  status: "",
  movimiento: "",
};

export default function TorneoDetail({ idTorneo, torneoData, data }) {
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState(initialFilter);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        size="small"
        sx={{ fontSize: 12, textTransform: "none", py: 0 }}
        onClick={handleClickOpen}
        color="info"
      >
        {torneoData?.nombre || data?.torneo_name || ""}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          sx: {
            height: "90vh",
          },
        }}
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{ flexGrow: 1 }}
              variant="h6"
            >{`Todos los movimientos de ${torneoData?.nombre || data?.torneo_name || ""}`}</Typography>
            <ApuestaExcelExport
              filter={filter}
              torneo={idTorneo}
              torneoData={torneoData}
              torneo_name={data?.torneo_name || ""}
            />
            <Button
              size="small"
              variant="contained"
              disableElevation
              onClick={handleClose}
              sx={{ textTransform: "none" }}
              startIcon={<Close />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
            <Box>
              <Typography>
                Nombre Completo: <b>{`${torneoData?.nombre || data?.torneo_name || ""}`}</b>
              </Typography>
              <Typography>
                Lugar:{" "}
                <b>{`${torneoData?.lugar || ""}, ${torneoData?.lienzoCharro || ""}`}</b>
              </Typography>
            </Box>
            <Box>
              <Typography>
                Inicial el:{" "}
                <b>
                  {` ${formatoFechaCorta(torneoData?.fechaInicio) || ""} `} Termina el{" "}
                  {` ${formatoFechaCorta(torneoData?.fechaFin) || ""}`}
                </b>
              </Typography>
              <Typography>
                Torneo <b>{torneoData?.tipo}</b>
              </Typography>
            </Box>
          </Box>
          <FilterChips filter={filter} setFilter={setFilter} />
          <RenderTable
            torneo={idTorneo}
            setPageConfig={setPageConfig}
            pageConfig={pageConfig}
            filter={filter}
            setFilter={setFilter}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const RenderTable = ({
  torneo,
  setPageConfig,
  pageConfig,
  filter,
  setFilter,
}) => {
  const { tokenSesion } = React.useContext(MainContext);
  const { page, limit } = pageConfig;

  const query = useQuery(GetMovimientosTorneoReport, {
    variables: {
      filter,
      torneo,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const { loading, data, error } = query;

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const datos = data?.getMovimientosTorneoReport;

  return (
    <TableMovimientosApuesta
      query={datos}
      setPageConfig={setPageConfig}
      pageConfig={pageConfig}
      filter={filter}
      setFilter={setFilter}
    />
  );
};
