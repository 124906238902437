import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Checkbox, TableSortLabel } from "@mui/material";
import FilterSelectTable from "../../../../Components/Inputs/FilterSelect";
import { MovimientoContext } from "../Context/MovimientoContext";
import FilterDateRange from "../../../../Components/Inputs/FilterDateRange";

const headCells = [
  {
    id: "status",
    padding: "checkbox",
    align: "left",
    label: "Status",
    sortable: false,
    sx: {
      minWidth: "120px",
    },
    filter: {
      filteringData: "status",
      filterName: "status",
      filterBy: "status",
    },
  },
  {
    id: "movimiento",
    padding: "checkbox",
    align: "left",
    label: "Movimiento",
    sortable: false,
    sx: {
      minWidth: "120px",
    },
    filter: {
      filteringData: "movimientos",
      filterName: "movimiento",
      filterBy: "movimiento",
    },
  },
  {
    id: "concepto",
    padding: "normal",
    align: "left",
    label: "Concepto",
    sortable: false,
    sx: {
      minWidth: "200px",
    },
  },
  {
    id: "monto",
    padding: "checkbox",
    align: "left",
    label: "Monto",
    sortable: false,
    sx: null,
  },
  {
    id: "fecha",
    padding: "normal",
    align: "left",
    label: "Fecha",
    sortable: true,
    filterDate: true,
    filterName: "fechaInicio",
    sx: {
      minWidth: "180px",
    },
  },
  {
    id: "comentarios",
    padding: "normal",
    align: "left",
    label: "Comentarios",
    sortable: false,
    sx: {
      minWidth: "200px",
    },
  },
  {
    id: "usuario",
    padding: "normal",
    align: "left",
    label: "Usuario",
    sortable: false,
    sx: {
      minWidth: "200px",
    },
  },
  {
    id: "nombreAfilial",
    padding: "normal",
    align: "left",
    label: "Filial",
    sortable: true,
    sx: {
      minWidth: "200px",
    },
  },
  /* {
    id: "cancelar",
    padding: "checkbox",
    align: "right",
    label: "Cancelar",
    sortable: false,
    sx: null
  }, */
];

export default function MovimientosTableHead({
  handleSort,
  order,
  orderBy,
  handleCheckAll,
}) {
  const {
    catsQuery,
    filter,
    setFilter,
    //setFilterCats,
    //filterCats,
    itemsSelected,
    indeterminated,
  } = React.useContext(MovimientoContext);
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
            position: { xs: "relative", sm: "sticky" },
            left: 0,
            zIndex: 3,
          }}
        >
          <Checkbox
            indeterminate={indeterminated}
            checked={itemsSelected.length > 0 && !indeterminated}
            onChange={(e) => handleCheckAll(e.target.checked)}
            disabled
          />
        </TableCell>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...headCell.sx,
              "&:hover .button-filter": {
                display: "inline-flex!important",
              },
            }}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
            {headCell.filter ? (
              <FilterSelectTable
                query={
                  catsQuery?.data?.getCatalogosMov[
                    headCell.filter.filteringData
                  ]
                }
                loading={catsQuery.loading}
                error={catsQuery.error}
                value={filter}
                setFilter={setFilter}
                //filterCats={filterCats}
                //setFilterCats={setFilterCats}
                filterName={headCell.filter.filterName}
                filterBy={headCell.filter.filterBy}
              />
            ) : null}
            {headCell.filterDate ? (
              <FilterDateRange
                filter={filter}
                setFilter={setFilter}
                filterName={headCell.filterName}
              />
            ) : null}
          </TableCell>
        ))}
        <TableCell align="right" padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}
