import React from "react";
import { Box, Stack } from "@mui/material";
import CharreadaCtxProvider, { CharreadaContext } from "./Context/CharreadaContext";
import SearchComponent from "./Actions/Search";
import TableCharreadas from "./Table";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import CreateCharreada from "./Actions/Create";

export default function Charreadas() {
  return (
    <Box>
      <CharreadaCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          sx={{ mb: 1, position: { md: "absolute" }, top: 60, right: 24 }}
          bgcolor="background.paper"
        >
          <SearchComponent />
          <CreateCharreada />
          <Export />
        </Stack>
        <RenderTable />
      </CharreadaCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { charreadasQuery } = React.useContext(CharreadaContext);

  if (charreadasQuery.loading) return <CustomLoader />;
  if (charreadasQuery.error) return <ErrorMessage message={charreadasQuery.error.message} />;

  return <TableCharreadas />;
};
