import * as React from "react";
import Chip from "@mui/material/Chip";
import { ROLES } from "../../../../../Config/constantes";
import { CurrencyExchange, Star } from "@mui/icons-material";

export const ChipActive = ({ activo }) => {
  return (
    <Chip
      label={activo ? "activo" : "inactivo"}
      color={activo ? "success" : "default"}
      sx={{
        fontWeight: "bold",
        height: 20,
      }}
    />
  );
};

export const ChipRol = ({ rol }) => {

  switch (rol) {
    case ROLES.ADMIN:
      return (
        <Chip
          label={rol}
          icon={<Star fontSize="small" color="primary" />}
          color="default"
          sx={{
            height: 20,
          }}
        />
      );
      case ROLES.MANAGER:
        return (
          <Chip
            label={rol}
            icon={<Star fontSize="small" color="info" />}
            color="default"
            sx={{
              height: 20,
            }}
          />
        );
      case ROLES.FILIAL:
        return (
          <Chip
            label={rol}
            icon={<CurrencyExchange fontSize="small" />}
            color="default"
            sx={{
              height: 20,
            }}
          />
        );
    default:
      return rol;
  }
  
};
