import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Box, Button, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

const CookieConsent = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const isAccepted = Cookies.get("cookie_consent");
    if (isAccepted === "true") {
      setAccepted(true);
    }
  }, []);

  const handleAccept = () => {
    setAccepted(true);
    Cookies.set("cookie_consent", "true", { expires: 365 });
  };

  if (accepted) {
    return null; // No mostrar el mensaje si el usuario ya ha aceptado las cookies
  }

  return (
    <Box sx={{ position: "fixed", bottom: {xs: 70, md: 0}, zIndex: 99, width: "100%" }}>
      <Box
        sx={{
          //width: "100vw",
          bgcolor: red[600],
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 0.5,
          gap: 2,
        }}
      >
        <Typography>
          Este sitio web utiliza cookies para mejorar su experiencia de usuario.
        </Typography>
        <Button
          variant="contained"
          size="small"
          color="info"
          disableElevation
          onClick={handleAccept}
          sx={{ textTransform: "none" }}
        >
          Aceptar
        </Button>
      </Box>
    </Box>
  );
};

export default CookieConsent;
