import React from "react";
import { Box, Stack } from "@mui/material";
import TeamsCtxProvider, { EquiposContext } from "./Context/EquiposContext";
import SearchComponent from "./Actions/Search";
import EquiposTable from "./Table";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import CreateEquipo from "./Actions/Create";

export default function Equipos() {
  return (
    <Box>
      <TeamsCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 1, position: { md: "absolute" }, top: 60, right: 24 }}
          //sx={{ mb: 1 }}
          bgcolor="background.paper"
        >
          <SearchComponent />
          <CreateEquipo />
          <Export />
        </Stack>
        <RenderTable />
      </TeamsCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { teamsQuery } = React.useContext(EquiposContext);

  if (teamsQuery.loading) return <CustomLoader />;
  if (teamsQuery.error) return <ErrorMessage message={teamsQuery.error?.message} />;

  return <EquiposTable />;
};
