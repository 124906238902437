import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import { Warning } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../../Context/MainCtx";
import { CancelarApuestasCompetencia } from "../../graphql/mutations";
import { CompetenciaContext } from "../../Context/CompetenciaContext";

export default function CancelarApuestas({idCompetencia, competencia}) {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const { setRefetchApuestas } = React.useContext(CompetenciaContext);
  const [cancelarApuestasCompetencia] = useMutation(CancelarApuestasCompetencia);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelApuestas = async () => {
    try {
      setLoading(true);
      const response = await cancelarApuestasCompetencia({
        variables: {
          idCompetencia,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.cancelarApuestasCompetencia.message,
        variant: "success",
      });
      setLoading(false);
      setRefetchApuestas(prev => !prev)
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Button
        color="warning"
        size="small"
        variant="contained"
        startIcon={<Warning />}
        disableElevation
        onClick={handleClick}
        sx={{ textTransform: "none" }}
      >
        Cancelar juegos
      </Button>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle>
          Cancelar juegos
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se cancelaran todos los juegos de <b>{competencia}</b> y se le regresara el
            saldo jugado a los usuarios, ¿Continuar con la acción?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="inherit"
            size="small"
            onClick={handleClose}
            variant="contained"
            fullWidth
            disableElevation
          >
            No, cancelar
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="error"
            size="small"
            onClick={handleCancelApuestas}
            variant="contained"
            fullWidth
            disableElevation
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Si, Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
