import React, { createContext } from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetSuertes } from "../graphql/queries";

export const SuertesContext = createContext();

const LuckyCtxProvider = ({ children }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState("");
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const { page, limit } = pageConfig;

  const suertesQuery = useQuery(GetSuertes, {
    variables: {
      filter: FILTER,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  return (
    <SuertesContext.Provider
      value={{
        filter,
        setFilter,
        suertesQuery,
        pageConfig,
        setPageConfig,
      }}
    >
      {children}
    </SuertesContext.Provider>
  );
};

export default LuckyCtxProvider;
