import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment-timezone";

import 'moment/locale/es-mx'; 
moment.locale('es-mx');

export default function DateInput({
  name = "",
  value = "",
  onChange = null,
  required = false,
  margin = "none",
  fullwidth = false,
  label="",
  maxDate = null
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es-mx">
      <DemoContainer components={["DatePicker"]}>
        <DemoItem>
          <DatePicker
            label={label}
            value={moment(value)}
            onChange={(val) =>
              onChange(name, moment(val))
            }
            sx={{
              minWidth: fullwidth && 337
            }}
            slotProps={{
              textField: {
                size: "small",
                required,
                margin,
              },
            }}
            maxDate={maxDate}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
