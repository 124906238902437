import React, { Suspense, useState } from "react";

const LazyImage = (props) => {
  const { src, alt } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <img
        src={imageLoaded ? src : ""}
        alt={alt}
        onLoad={() => setImageLoaded(true)}
        style={{
          ...(props.sx || null),
          display: imageLoaded ? "block" : "none",
          maxWidth: "100%",
          maxHeight: "100%",
        }}
        {...props}
      />
    </Suspense>
  );
};

export default LazyImage;
