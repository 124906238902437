import * as React from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { SellerContext } from "../Context/SellerContext";
import VendedoresTableBody from "./TableBody";
import {
  formatoMexico,
  getComparator,
  stableSort,
} from "../../../../Config/reuserFunctions";
import VendedoresTableHead from "./TableHead";
import { Box, Typography } from "@mui/material";

export default function UsuarioTable() {
  const { queryData, setPageConfig, pageConfig } = React.useContext(
    SellerContext
  );
  const { docs, totalDocs, totalBalanceUsers } = queryData.getSellersManagers;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("nombre");

  const renderSorted = React.useMemo(() => {
    return stableSort(docs, getComparator(order, orderBy));
  }, [order, orderBy, docs]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, page) => {
    setPageConfig((st) => ({ ...st, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPageConfig({ limit: parseInt(event.target.value, 10), page: 0 });
  };

  return (
    <>
      <Paper variant="outlined">
        <TableContainer
          sx={{
            transition: "width .3s",
            height: { xs: `calc(100vh - 272px)`, sm: `calc(100vh - 180px)` }, //xs: 232, sm: 140 sin paginacion
            borderRadius: 2,
          }}
        >
          <Table stickyHeader size="small">
            <VendedoresTableHead
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
            />
            <VendedoresTableBody data={renderSorted} />
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ my: 1.5 }}>
          Balance total vendedores: <b>{formatoMexico(totalBalanceUsers)}</b>
        </Typography>
        <TablePagination
          component="div"
          count={totalDocs}
          page={pageConfig.page}
          onPageChange={handleChangePage}
          rowsPerPage={pageConfig.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
