import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DragIndicator } from "@mui/icons-material";
import CreateNewStep from "./createStep";
import { useMutation, useQuery } from "@apollo/client";
import { GetSteps } from "./graphql/queries";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import DeleteStep from "./deleteStep";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../../../../Components/CustomDroppable";
import { OrdenStepUpdate } from "./graphql/mutations";
import { MainContext } from "../../../../Context/MainCtx";
import PreviewContent from "../../../../Components/CustomEditor/PreviewContent";
import LazyImage from "../../../../Components/LazyImage";

export default function TutorialComponent() {
  const dataQuery = useQuery(GetSteps, {
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">Como jugar</Typography>
        <CreateNewStep refetch={dataQuery.refetch} />
      </Box>
      <Box mt={2}>
        <RenderStepsList dataQuery={dataQuery} />
      </Box>
    </div>
  );
}

const RenderStepsList = ({ dataQuery }) => {
  const { data, loading, error, refetch } = dataQuery;
  const [items, setItems] = React.useState([]);
  const [ordenStepUpdate] = useMutation(OrdenStepUpdate);
  const { snackMessage } = React.useContext(MainContext);

  React.useEffect(() => {
    if (data) setItems(data.getSteps);
  }, [data]);

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  // Función para manejar el evento de arrastrar y soltar
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    setItems(newItems);
    await ordenUpdate(newItems);
  };

  const ordenUpdate = async (newItems) => {
    try {
      const IDs = newItems.map((res) => res._id);
      await ordenStepUpdate({
        variables: { IDs },
      });
      refetch();
    } catch (err) {
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="steps">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((res, index) => (
                <Draggable key={res._id} draggableId={res._id} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      sx={{
                        display: "flex",
                        borderBottom: "1px solid",
                        borderColor: "divider",
                        pb: 1,
                        gap: 1,
                        bgcolor: "background.paper",
                      }}
                    >
                      <Box sx={{ p: 1 }} {...provided.dragHandleProps}>
                        <DragIndicator color="action" />
                      </Box>
                      <Box sx={{ flexGrow: 1 }}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Typography variant="h6">
                            {`#${index + 1}`}
                          </Typography>
                          <PreviewContent content={res.descripcion} />
                        </Box>
                        {!res.imagen ? null : (
                          <Box
                            sx={{
                              height: 200,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LazyImage
                              src={res.imagen}
                              alt="prev"
                            />
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          p: 1,
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 1,
                        }}
                      >
                        <CreateNewStep data={res} edit refetch={refetch} />
                        <DeleteStep data={res} refetch={refetch} />
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </Box>
  );
};
