import React from "react";
import Banner from "./Banner";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
/* images */
/* import torneosTitle from "../../assets/images/torneosTitle.png";
import tiendaTitle from "../../assets/images/tiendaTitle.png";
import historialTitle from "../../assets/images/historialTitle.png";
import torneosImg from "../../assets/images/dashTorneos.png";
import tiendaImg from "../../assets/images/dashTienda.png";
import historialImg from "../../assets/images/dashHistorial.png"; */
import CustomLoader from "../../Components/CustomLoader";
import ErrorMessage from "../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import { GetBannersButtons } from "../Dashboard/Settings/Banners/ButtonsBanners/gql";

export default function HomeSesion() {
  const { loading, data, error } = useQuery(GetBannersButtons);

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  return (
    <div>
      <Container
        maxWidth="xl"
        sx={{
          my: 2,
          mb: { xs: 10, md: 0 },
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "column" },
          gap: 4,
        }}
        id="inicio" 
      >
        <Grid container spacing={4}>
          {data.getBannersButtons.map((res) => (
            <Grid key={res._id} item xs={12} md={4}>
              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  transition: ".3s ease",
                  "&:hover": {
                    transform: "translate(0px, -5px)",
                    boxShadow: "-3px 10px 10px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <CardActionArea component={Link} to={res.href}>
                  <CardMedia
                    component="img"
                    height="300"
                    image={res.image}
                    alt="torneos"
                  />
                  <CardContent sx={{ padding: 0 }}>
                    <Box
                      sx={{
                        backgroundImage: `url(${res.imageTitle})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        height: 64,
                      }}
                    />
                    <Box
                      sx={{
                        height: "100%",
                        fontWeight: "bolder",
                        bgcolor: (theme) => theme.palette.primary.main,
                        color: "white",
                        textAlign: "center",
                        padding: 1.3,
                      }}
                    >
                      {res.text}
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Banner />
      </Container>
    </div>
  );
}
