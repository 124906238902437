import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";

const headCells = [
  {
    id: "nombre",
    padding: "normal",
    align: "left",
    label: "Nombre",
    sortable: true,
    sx: null,
  },
  {
    id: "encargado",
    padding: "normal",
    align: "left",
    label: "Asignado a",
    sortable: true,
    sx: null,
  },
  {
    id: "telefono",
    padding: "normal",
    align: "left",
    label: "Tel",
    sortable: true,
    sx: null,
  },
  {
    id: "email",
    padding: "normal",
    align: "left",
    label: "Correo",
    sortable: true,
    sx: null,
  },
  {
    id: "saldo",
    padding: "normal",
    align: "left",
    label: "Balance",
    sortable: true,
    sx: null,
  },
  {
    id: "rol",
    padding: "checkbox",
    align: "left",
    label: "Rol",
    sortable: false,
    sx: null,
  },
  {
    id: "createdAt",
    padding: "normal",
    align: "left",
    label: "Ingresó",
    sortable: true,
    sx: null,
  },
  {
    id: "historial",
    padding: "checkbox",
    align: "left",
    label: "History",
    sortable: false,
    sx: null,
  },
  {
    id: "cortes",
    padding: "checkbox",
    align: "left",
    label: "Cortes",
    sortable: false,
    sx: null,
  },
];

export default function UsuariosTableHead({ handleSort, order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={headCell.sx}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell padding="checkbox" />
        <TableCell padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}
