import { gql } from "@apollo/client";

export const GetMovimientosUserReport = gql`
  query getMovimientosUserReport(
    $filter: usuerFilterMovs
    $usuario: ID
    $limit: Int
    $page: Int
  ) {
    getMovimientosUserReport(
      filter: $filter
      usuario: $usuario
      limit: $limit
      page: $page
    ) {
      docs {
        _id
        usuario
        usuarioData{
          nombre
          apellidoMaterno
          apellidoPaterno
        }
        torneo
        torneoData {
          _id
          nombre
        }
        competencia
        competenciaData {
          torneo
          charreada
          charreadaData {
            nombre
          }
          equipo
          equipoData {
            nombre
          }
          suerte
          suerteData {
            suerte
          }
          charro
          charroData {
            nombre
          }
          status
          ganador
        }
        monto
        concepto
        balanceAnterior
        nuevoBalance
        balanceEnMovimiento
        metodoPago
        movimiento
        status
        comentarios
        fecha
        orderID
        payerID
        paymentID
        paymentSource
      }
      totalDocs
      page
      totalPages
      totalMonto
      totalCantidad
      totalRetiro
      totalAdquirido
    }
  }
`;
