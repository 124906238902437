import { gql } from "@apollo/client";

export const GetApuestasManager = gql`
  query getApuestasManager($filter: inputFilter, $limit: Int, $page: Int) {
    getApuestasManager(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        torneo
        torneoData {
          _id
          nombre
        }
        equipo
        equipoData {
          _id
          nombre
        }
        suerte
        suerteData {
          _id
          suerte
        }
        charro
        charroData {
          _id
          nombre
        }
        charreada
        charreadaData {
          _id
          nombre
        }
        usuario
        usuarioData {
          _id
          nombre
        }
        formaPago
        apuesta
        totalGanar
        pagada
        idCompetencia
        momio
        fecha
        status
        ticket
      }
      page
      totalDocs
      totalPages
    }
  }
`;