import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useQuery } from "@apollo/client";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import { useMatches } from "react-router-dom";
import { formatoFechaHora } from "../../../Config/reuserFunctions";
import ListaCharreada from "./ListaCharreadas";
import { Paper } from "@mui/material";
import { SearchOff } from "@mui/icons-material";
import { io } from "socket.io-client";
import { STATUS_TORNEO } from "../../../Config/constantes";
import { MainContext } from "../../../Context/MainCtx";
import { GetCharreadasByTorneo } from "../../Home/Torneos/graphql/queries";
import { blueGrey, orange, yellow } from "@mui/material/colors";

export default function TorneoDetalle() {
  const matches = useMatches();
  const idTorneo = matches && matches.length ? matches[0].params?.idTorneo : "";
  const { setErrors } = React.useContext(MainContext);

  const { loading, data, error, refetch } = useQuery(GetCharreadasByTorneo, {
    variables: {
      idTorneo,
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedCompetencia", () => refetch());
    return () => socket.disconnect();
  }, [refetch]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (error) setErrors({ ...error, funcion: "getCharreadasByTorneo" });
  }, [error, setErrors]);

  if (loading) return <CustomLoader height="100vh" />;
  if (error) return <ErrorMessage message={error?.message} />;

  const { torneo, charreadas } = data?.getCharreadasByTorneo || [];

  const GetTextTypeTournament = () => {
    if (torneo.tipo === "ORO") {
      return (
        <span
          style={{
            backgroundColor: yellow[100],
            padding: "0px 8px",
            borderRadius: 1,
          }}
        >
          <b>Torneo {torneo.tipo}</b>
        </span>
      );
    } else if (torneo.tipo === "PLATA") {
      return (
        <span
          style={{
            backgroundColor: blueGrey[50],
            padding: "0px 8px",
            borderRadius: 1,
          }}
        >
          <b>Torneo {torneo.tipo}</b>
        </span>
      );
    } else {
      return (
        <span
          style={{
            backgroundColor: orange[100],
            padding: "0px 8px",
            borderRadius: 1,
          }}
        >
          <b>Torneo {torneo.tipo}</b>
        </span>
      );
    }
  };

  return (
    <Box id="torneos" sx={{ py: 2, minHeight: "calc(100vh - 48px)" }}>
      <Container maxWidth="md">
        <Box sx={{ display: { xs: "block", md: "flex" } }}>
          <Box>
            <Typography
              sx={{ fontSize: { xs: "16px", md: "24px" } }}
              className="fontAnton"
            >
              <GetTextTypeTournament />
              <b>{torneo.nombre}</b>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
              <Typography
                sx={{
                  fontWeight: "bolder",

                  fontSize: { xs: "14px", md: "18px" },
                }}
              >
                {`${torneo.lienzoCharro}, ${torneo.lugar} ${formatoFechaHora(
                  torneo.fechaInicio
                )} a ${formatoFechaHora(torneo.fechaFin)}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        {torneo.status === STATUS_TORNEO.pausado ? (
          <Typography
            align="center"
            sx={{
              fontWeight: "bolder",
              color: (theme) => theme.palette.warning.light,
              fontSize: { xs: "20px", md: "30px" },
            }}
            className="fontAnton"
          >
            {STATUS_TORNEO.pausado}
          </Typography>
        ) : null}
        <Box sx={{ my: 2 }}>
          {charreadas.length ? (
            charreadas.map((res, index) => (
              <ListaCharreada
                key={res._id}
                index={index}
                data={res}
                idTorneo={idTorneo}
                torneo={torneo}
              />
            ))
          ) : (
            <Container maxWidth="xs" sx={{ mt: 5 }}>
              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <SearchOff color="disabled" fontSize="large" />
                <Typography color="GrayText">
                  Aun no hay competencias
                </Typography>
              </Paper>
            </Container>
          )}
        </Box>
        <Grid container spacing={2} sx={{ my: 3 }}></Grid>
      </Container>
    </Box>
  );
}
