import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { FilterAlt } from "@mui/icons-material";
import { Box } from "@mui/material";
import AutocompleteInput from "./AutocompleteInput";

export default function FilterSelectTable({
  query = [],
  value = "",
  //filterCats = "",
  setFilter = null,
  //setFilterCats = null,
  filterName = "",
  filterBy = "",
  loading = false,
  error = null,
}) {
  const [open, setOpen] = React.useState(null);
  const [classN, setClassN] = React.useState("button-filter");
  const [localFilter, setLocalFilter] = React.useState(value);

  const handleClick = (event) => {
    event.stopPropagation();
    setOpen(!open);
    setClassN(!open ? "" : "button-filter");
  };
/*   const handleClose = () => {
    setOpen(null);
    setClassN("button-filter");
  }; */

  const onchangeInput = (_, value) => {
    setLocalFilter(value)
    //if (!setFilterCats) return;
    //setFilterCats({ ...filterCats, [filterName]: value });
  };

  return (
    <>
      <IconButton
        id="filter-date"
        aria-controls={open ? "filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        className={classN}
        sx={{
          display: open || value[filterName] ? "inline-flex!important" : "none",
          color: (theme) =>
            value[filterName]
              ? theme.palette.primary.main
              : theme.palette.action.disabled,
          padding: 0,
          //mx: 1,
        }}
      >
        <FilterAlt sx={{ fontSize: 18 }} />
      </IconButton>
      {(open || value[filterName]) && (
        <Box sx={{ width: 250 }}>
          <AutocompleteInput
            renderData={query}
            value={value[filterName]}
            inputValue={localFilter}//filterCats[filterName]}
            loading={loading}
            error={error}
            onChange={(_, option) => {
              if (!option) {
                setFilter((state) => ({
                  ...state,
                  [filterName]: "",
                }));
                return;
              }
              setFilter((state) => ({
                ...state,
                [filterName]: option[filterBy] || option || "",
              }));
            }}
            onInputChange={onchangeInput}
            isOptionEqualToValue={(option, value) => (option[filterBy] || option) === value}
            getOptionLabel={(option) => option[filterBy] || option || ""}
          />
        </Box>
      )}
    </>
  );
}
