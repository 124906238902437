import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../../Components/ExcelExportButton";
import { ManagerContext } from "../Context/ManagerContext";
import { formatoFechaCorta } from "../../../../../Config/reuserFunctions";
import { GetManagersAndSellers } from "../graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((user) => {
    const { _id, activo, createdAt, imagenesID, ...obj } = user;
    dataFormat.push({
      ...obj,
      status: activo ? "ACTIVO" : "INACTIVO",
      fechaRegistro: formatoFechaCorta(createdAt),
    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { queryData } = React.useContext(ManagerContext);
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const excelData = formatTable(queryData?.getManagersAndSellers?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getManagersAndSellers = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetManagersAndSellers,
        variables: {
          filter: "",
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getManagersAndSellers.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name="Suerte charra users"
        getQueryData={getManagersAndSellers}
        loading={loading}
      />
    </div>
  );
}
