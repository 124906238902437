import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import esLocale from "date-fns/locale/es";
import moment from "moment-timezone";

export default function DateTimeInput({
  name = "",
  value = "",
  onChange = null,
  required = false,
}) {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment} locale={esLocale}>
        <DemoContainer
          components={["DateTimePicker"]}
          sx={{ padding: 0, overflow: "none" }}
        >
          <DemoItem>
            <DateTimePicker
              value={moment(value)}
              onChange={(val) =>
                onChange(name, moment(val).tz("America/Mexico_City").format())
              }
              required={required}
              slotProps={{
                textField: {
                  size: "small",
                  required,
                },
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
}
