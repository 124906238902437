import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { BrowserUpdated, Close, Done } from "@mui/icons-material";
import { useApolloClient } from "@apollo/client";
import { MainContext } from "../../../../../../Context/MainCtx";
import { GetUserOld } from "../../graphql/queries";
import { formatoMexico } from "../../../../../../Config/reuserFunctions";
import { blue, green } from "@mui/material/colors";
import EliminarOld from "./Eliminar";
import RecuperarOld from "./Recuperar";

export default function RecoverUserData({ user, refetch }) {
  const [loading, setLoading] = React.useState(false);
  const [userOld, setUserOld] = React.useState(null);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const client = useApolloClient();

  /* Queries */
  const getUser = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetUserOld,
        variables: { email: user.email },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      if (!response.data?.getUserOld) {
        snackMessage({
          message: "No se encontro usuario con este email",
          variant: "info",
        });
        return;
      }
      console.log(response);
      setUserOld(response.data.getUserOld);
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const handleClose = () => {
    setUserOld(null);
  };

  return (
    <Box>
      <Alert severity="info">
        <AlertTitle>Recuperar Datos</AlertTitle>
        Busca y recupera los datos de esta cuenta anterior <b>por email</b>
        <Button
          fullWidth
          variant="contained"
          color="info"
          size="small"
          disableElevation
          sx={{ my: 1, textTransform: "none" }}
          onClick={getUser}
          disabled={loading || user.recovered}
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : user.recovered ? (
              <Done />
            ) : (
              <BrowserUpdated />
            )
          }
        >
          {user.recovered ? "Recuperado" : "Buscar y Recuperar"}
        </Button>
      </Alert>
      <Dialog
        open={userOld && true}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Usuario Encontrado</Typography>
            <Button
              size="small"
              color="inherit"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              onClick={handleClose}
              startIcon={<Close />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          
          <Typography sx={{color: blue[500]}}>
            <b>ACTUAL</b>
          </Typography>
          <Divider />
          <Box sx={{ display: "flex", gap: 3 }}>
            <Box>
              <Typography variant="subtitle2">
                <b>Nombre</b>
              </Typography>
              <Typography>{user?.nombre}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                <b>Email</b>
              </Typography>
              <Typography>{user?.email}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                <b>Rol</b>
              </Typography>
              <Typography>{user?.rol}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                <b>Activo</b>
              </Typography>
              <Typography>{user?.activo ? "SI" : "NO"}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                <b>Balance</b>
              </Typography>
              <Typography sx={{ color: green[500] }}>
                <b>{formatoMexico(user?.saldo)}</b>
              </Typography>
            </Box>
          </Box>
          <br />
          <Typography sx={{color: blue[900]}}>
            <b>ENCONTRADO</b>
          </Typography>
          <Divider />
          <Box sx={{ display: "flex", gap: 3 }}>
            <Box>
              <Typography variant="subtitle2">
                <b>Nombre</b>
              </Typography>
              <Typography>{userOld?.nombre}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                <b>Email</b>
              </Typography>
              <Typography>{userOld?.email}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                <b>Rol</b>
              </Typography>
              <Typography>{userOld?.rol}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                <b>Activo</b>
              </Typography>
              <Typography>{userOld?.activo ? "SI" : "NO"}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                <b>Balance</b>
              </Typography>
              <Typography sx={{ color: green[500] }}>
                <b>{formatoMexico(userOld?.saldo)}</b>
              </Typography>
            </Box>
          </Box>
          <Alert
            severity="info"
            sx={{ mt: 5 }}
            action={[
              <EliminarOld
                user={user}
                refetch={refetch}
                handleClose={handleClose}
              />,
              <RecuperarOld
                user={user}
                refetch={refetch}
                handleClose={handleClose}
              />,
            ]}
          >
            <AlertTitle>Recuperar Saldo</AlertTitle>
            Si el usuario actual tiene balance disponible, se le sumara a su
            balance actual y se eliminara el usuario encontrado
          </Alert>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
