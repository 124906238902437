import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function LogsTableBody({ data }) {
  return (
    <TableBody>
      {data.map((log) => {
        const { usuarioData } = log;
        const name = usuarioData ? `${usuarioData?.nombre} ${usuarioData?.apellidoPaterno} ${usuarioData?.apellidoMaterno}` : log?.nombre_usuario;
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={log._id}
            sx={{ height: "48px" }}
          >
            <TableCell>{name}</TableCell>
            <TableCell>{log.message}</TableCell>
            <TableCell>{log.statusCode}</TableCell>
            <TableCell>{log.funcion}</TableCell>
            <TableCell>{log.fecha}</TableCell>
            <TableCell>{log.detalles}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
