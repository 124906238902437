import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../Config/reuserFunctions";
import UpdateVendedor from "../Actions/Update";
import DeleteVendedor from "../Actions/Delete";
import MovsDetail from "../MovsDetail";
import CortesDetail from "../CortesDetails";

export default function VendedoresTableBody({ data }) {

  return (
    <TableBody>
      {data.map((row) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={row._id}
            sx={{ height: "48px", cursor: "pointer" }}
          >
            <TableCell>{row.nombre}</TableCell>
            <TableCell>{row.telefono}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{formatoMexico(row.saldo)}</TableCell>
            <TableCell>{formatoFechaCorta(row.createdAt)}</TableCell>
            <TableCell align="center">
              {<MovsDetail idUser={row._id} usuarioData={row} />}
            </TableCell>
            <TableCell align="center">
              {<CortesDetail idUser={row._id} usuarioData={row} />}
            </TableCell>
            <TableCell>
              <UpdateVendedor data={row} edit />
            </TableCell>
            <TableCell>
              <DeleteVendedor data={row} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
