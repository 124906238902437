import { gql } from "@apollo/client";

export const CreateCuota = gql`
  mutation createCuota($input: cuotaInput) {
    createCuota(input: $input) {
      message
    }
  }
`;

export const UpdateCuota = gql`
  mutation updateCuota($idCuota: ID!, $input: cuotaInput) {
    updateCuota(idCuota: $idCuota, input: $input) {
      message
    }
  }
`;

export const DeleteCuota = gql`
  mutation deleteCuota($idCuota: ID!) {
    deleteCuota(idCuota: $idCuota) {
      message
    }
  }
`;
