import React from "react";
import { Box, Stack } from "@mui/material";
import BlacklistCtxProvider, { BlacklistContext } from "./Context/BlacklistContext";
import SearchComponent from "./Actions/Search";
import BlackListTable from "./Table";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Export from "./Actions/Export";

export default function Blacklist() {
  return (
    <Box>
      <BlacklistCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 1, position: { md: "absolute" }, top: 60, right: 24 }}
          bgcolor="background.paper"
        >
          <SearchComponent />
          <Export />
        </Stack>
        <RenderTable />
      </BlacklistCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { queryLoading, queryError } = React.useContext(BlacklistContext);

  if (queryLoading) return <CustomLoader />;
  if (queryError) return <ErrorMessage message={queryError.message} />;

  return <BlackListTable />;
};
