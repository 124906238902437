import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useReactToPrint } from "react-to-print";
import { Box, IconButton, Typography } from "@mui/material";
import { formatoFechaHora } from "../../../Config/reuserFunctions";
import SCharraIcon from "../../../Icons/ScharraIcon";
import { MainContext } from "../../../Context/MainCtx";
import { Print } from "@mui/icons-material";

export default function TicketTest() {
  const [open, setOpen] = React.useState(false);
  const componentRef = React.useRef();
  const { tokenDecoded } = React.useContext(MainContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => handleClose(),
  });

  return (
    <React.Fragment>
      <IconButton size="small" color="inherit" onClick={handleClickOpen}>
        <Print />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle component="div">
          <Typography variant="h6">Prueba ticket</Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            ref={componentRef}
            style={{ width: "58mm", margin: "auto", pb: 2 }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <SCharraIcon color="black" />
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                Atendido por
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {tokenDecoded.nombre}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                Fecha
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {formatoFechaHora(new Date())}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button onClick={handlePrint} autoFocus variant="contained">
            Imprimir Ticket
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
