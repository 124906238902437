import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppBar } from "@mui/material";
import { useMatches, useNavigate } from "react-router-dom";
import CustomIconComponent from "../../../../Components/CustomIconComponent";
import { listItems } from "../listItems";
import { MainContext } from "../../../../Context/MainCtx";

export default function BottomNav({ setView }) {
  const navigate = useNavigate();
  const matches = useMatches();
  const pathname = matches && matches.length > 1 ? matches[1].pathname : "";
  const [value, setValue] = React.useState(pathname);
  const [more, setMore] = React.useState(false);
  const { tokenDecoded } = React.useContext(MainContext);

  const handleChange = (path) => {
    if (!path) return;
    setValue(path);
    navigate(path);
  };

  const handleClick = ({ title, noTab = false }) => {
    if (noTab) {
      setMore(!more);
      return;
    }
    setView(title);
    setMore(false);
    localStorage.setItem("lastview", title);
  };

  React.useEffect(() => {
    setValue(pathname);
  }, [pathname, setView]);

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        sx={{
          top: "auto",
          bottom: 0,
          display: { xs: "flex", sm: "none" },
          zIndex: 1100,
        }}
      >
        <Tabs
          value={value}
          onChange={(_, path) => handleChange(path)}
          variant="fullWidth"
          sx={{
            width: "100%",
            "& .MuiButtonBase-root": {
              textTransform: "none",
            },
          }}
        >
          {listItems(tokenDecoded).map((res, index) => (
            <Tab
              key={index}
              value={res.url}
              onClick={() => handleClick(res)}
              icon={
                <CustomIconComponent
                  iconName={res.icon}
                  color={res.url === value ? "primary" : "inherit"}
                />
              }
              label={res.title}
            />
          ))}
        </Tabs>
      </AppBar>
    </>
  );
}
