import { gql } from "@apollo/client";

export const GetResultados = gql`
  query GetResultados($filter: inputFilter, $limit: Int, $page: Int) {
    getResultados(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        torneo
        nombreTorneo
        torneoData {
          _id
          nombre
        }
        equipo
        nombreEquipo
        equipoData {
          _id
          nombre
        }
        suerte
        nombreSuerte
        suerteData {
          _id
          suerte
        }
        charro
        nombreCharro
        charroData {
          _id
          nombre
        }
        usuario
        nombreUsuario
        usuarioData {
          _id
          nombre
        }
        calificacion
        real
        fecha
      }
      page
      totalDocs
      totalPages
    }
  }
`;
