import { gql } from "@apollo/client";

export const GetSteps = gql`
  query getSteps {
    getSteps {
      _id
      orden
      descripcion
      imagen
      keyImagen
    }
  }
`;
