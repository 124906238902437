import { gql } from "@apollo/client";

export const GetStates = gql`
  query getStates($filter: String, $limit: Int, $page: Int) {
    getStates(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        estado
        pais
      }
      totalDocs
      page
      totalPages
    }
  }
`;
