import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Reply } from "@mui/icons-material";
import { MainContext } from "../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import { EmailsContext } from "../Context/EmailsContext";
import { ReplyUser } from "../graphql/mutations";
import { CustomButton } from "../../../../Components/CustomMuiComponents";

export default function ReplyUserMessage({ data }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [mensaje, setMensaje] = React.useState("");
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const { emailQuery } = React.useContext(EmailsContext);
  const [replyUser] = useMutation(ReplyUser);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReply = async () => {
    try {
      setLoading(true);
      const response = await replyUser({
        variables: {
          idMessage: data._id,
          input: {
            email: data.email,
            asunto: `RE: ${data.asunto}`,
            mensaje,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.replyUser.message,
        variant: "success",
      });
      emailQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <IconButton size="small" onClick={() => handleClickOpen()}>
        <Reply />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Responder este mensaje`}</DialogTitle>
        <DialogContent>
          <TextField
            label="Correo"
            size="small"
            margin="dense"
            fullWidth
            disabled
            value={data.email}
          />
          <TextField
            label="Asunto"
            size="small"
            margin="dense"
            fullWidth
            disabled
            value={`RE: ${data.asunto}`}
          />
          <TextField
            label="Mensaje"
            size="small"
            margin="dense"
            fullWidth
            value={mensaje}
            onChange={(e) => setMensaje(e.target.value)}
            multiline
            rows={5}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleClose} autoFocus color="inherit">
            Cancelar
          </CustomButton>
          <CustomButton
            onClick={() => handleReply()}
            color="primary"
            variant="contained"
            disableElevation
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Reply />
              )
            }
          >
            Responder
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
