import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../../Components/ExcelExportButton";
import { EquiposContext } from "../Context/EquiposContext";
import { formatoFechaCorta } from "../../../../../Config/reuserFunctions";
import { GetTeams } from "../graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((team) => {
    const { _id, createdAt, ...obj } = team;
    dataFormat.push({
      ...obj,
      estado: obj.estado?.estado,
      pais: obj.estado?.pais,
      fechaRegistro: formatoFechaCorta(createdAt),
    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { teamsQuery } = React.useContext(EquiposContext);
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const excelData = formatTable(teamsQuery?.data?.getTeams?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getTeams = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetTeams,
        variables: {
          filter: "",
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getTeams.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name="Suerte charra equipos"
        getQueryData={getTeams}
        loading={loading}
        importModel="equipos"
        refetchModel={teamsQuery.refetch}
        onlyUpload
      />
    </div>
  );
}
