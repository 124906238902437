import { gql } from "@apollo/client";

export const GetMovimientosUserReport = gql`
  query getMovimientosUserReport(
    $filter: usuerFilterMovs
    $usuario: ID
    $limit: Int
    $page: Int
  ) {
    getMovimientosUserReport(
      filter: $filter
      usuario: $usuario
      limit: $limit
      page: $page
    ) {
      docs {
        _id
        usuario
        usuarioData {
          nombre
          apellidoMaterno
          apellidoPaterno
        }
        torneo
        torneoData {
          _id
          nombre
        }
        competencia
        competenciaData {
          torneo
          charreada
          charreadaData {
            nombre
          }
          equipo
          equipoData {
            nombre
          }
          suerte
          suerteData {
            suerte
          }
          charro
          charroData {
            nombre
          }
          status
          ganador
        }
        monto
        concepto
        balanceAnterior
        nuevoBalance
        balanceEnMovimiento
        metodoPago
        movimiento
        status
        comentarios
        fecha
        orderID
        payerID
        paymentID
        paymentSource
      }
      totalDocs
      page
      totalPages
    }
  }
`;
export const GetMovimientosTorneoReport = gql`
  query getMovimientosTorneoReport(
    $filter: usuerFilterMovs
    $torneo: ID
    $limit: Int
    $page: Int
  ) {
    getMovimientosTorneoReport(
      filter: $filter
      torneo: $torneo
      limit: $limit
      page: $page
    ) {
      docs {
        _id
        usuario
        usuarioData {
          nombre
          apellidoMaterno
          apellidoPaterno
        }
        torneo
        torneoData {
          _id
          nombre
          lugar
          lienzoCharro
          fechaInicio
          fechaFin
          status
          tipo
        }
        competencia
        competenciaData {
          torneo
          charreada
          charreadaData {
            nombre
          }
          equipo
          equipoData {
            nombre
          }
          suerte
          suerteData {
            suerte
          }
          charro
          charroData {
            nombre
          }
          status
          ganador
        }
        monto
        concepto
        balanceAnterior
        nuevoBalance
        balanceEnMovimiento
        metodoPago
        movimiento
        status
        comentarios
        fecha
        orderID
        payerID
        paymentID
        paymentSource
      }
      totalDocs
      page
      totalPages
    }
  }
`;
