import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";

const headCells = [
  {
    id: "nombre",
    padding: "normal",
    align: "left",
    label: "Nombre",
    sortable: false,
    sx: {
      minWidth: 200
    },
  },
  {
    id: "email",
    padding: "normal",
    align: "left",
    label: "Correo",
    sortable: false,
    sx: {
      minWidth: 150
    },
  },
  {
    id: "rol",
    padding: "normal",
    align: "left",
    label: "Rol",
    sortable: false,
    sx: null,
  },
  {
    id: "urlRef",
    padding: "normal",
    align: "left",
    label: "Referencia",
    sortable: false,
    sx: {
      minWidth: 200
    },
  },
  {
    id: "ipAddress",
    padding: "normal",
    align: "left",
    label: "IpAddress",
    sortable: true,
    sx: null,
  },
  {
    id: "place",
    padding: "normal",
    align: "left",
    label: "Lugar",
    sortable: true,
    sx: {
      minWidth: 300
    },
  },
  {
    id: "device",
    padding: "normal",
    align: "left",
    label: "Dispositivo",
    sortable: true,
    sx: {
      minWidth: 200
    },
  },
  {
    id: "browser",
    padding: "normal",
    align: "left",
    label: "Navegador",
    sortable: false,
    sx: {
      minWidth: 200
    },
  },
  {
    id: "detail",
    padding: "normal",
    align: "left",
    label: "Detalle",
    sortable: false,
    sx: {
      minWidth: 200
    },
  },
  {
    id: "fecha",
    padding: "checkbox",
    align: "left",
    label: "Fecha de registro",
    sortable: true,
    sx: {
      minWidth: 230
    },
  },
];

export default function LogsTableHead({ handleSort, order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={headCell.sx}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
