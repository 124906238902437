import { gql } from "@apollo/client";

export const CreateMovimientoDeposito = gql`
  mutation createMovimientoDeposito($input: movimientoInput) {
    createMovimientoDeposito(input: $input) {
      message
    }
  }
`;
export const CreateMovimientoError = gql`
  mutation createMovimientoError($input: movimientoInput) {
    createMovimientoError(input: $input) {
      message
    }
  }
`;