import { gql } from "@apollo/client";

export const GetUsers = gql`
  query getUsers($filter: String, $limit: Int, $page: Int) {
    getUsers(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        nombre
        apellidoPaterno
        apellidoMaterno
        fechaNacimiento
        telefono
        direccion
        imagenesID {
          key
          url
        }
        historyImgs {
          key
          url
        }
        email
        rol
        activo
        paypalAccount
        createdAt
        recovered
        saldo
        totalTorosAdquiridos
        totalRetiros
      }
      totalDocs
      page
      totalPages
      totalBalanceUsers
    }
  }
`;

export const GetUserBalance = gql`
  query getUserBalance {
    getUserBalance {
      saldo
    }
  }
`;
export const GetUserOld = gql`
  query getUserOld($email: String) {
    getUserOld(email: $email) {
      _id
      nombre
      email
      rol
      saldo
      activo
    }
  }
`;
export const getEmailsTemp = gql`
  query getEmails {
    getEmails {
      subject
    }
  }
`;
export const GetUsersTest = gql`
  query getUsersTest {
    getUsersTest {
      _id
      nombre
      email
    }
  }
`;