import { gql } from "@apollo/client";

export const CancelarUnaApuesta = gql`
  mutation cancelarUnaApuesta($idApuesta: ID) {
    cancelarUnaApuesta(idApuesta: $idApuesta) {
      message
    }
  }
`;
export const PagarApuesta = gql`
  mutation pagarApuesta($idApuesta: ID, $metodoPago: String) {
    pagarApuesta(idApuesta: $idApuesta, metodoPago: $metodoPago) {
      message
    }
  }
`;