import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import terminos from "../../../assets/images/terminos.png";
import { Paper } from "@mui/material";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import { GetInfoPage } from "../../Dashboard/Settings/graphql/queries";
import PreviewContent from "../../../Components/CustomEditor/PreviewContent";
import LazyImage from "../../../Components/LazyImage";

function Terminos() {
  return (
    <Container maxWidth="md" sx={{ pt: 1 }}>
      <Box
        sx={{
          height: 50,
          width: { xs: "100%", md: 400 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LazyImage
          src={terminos}
          alt="Terminos y condiciones"
        />
      </Box>
      <Box sx={{ py: 2 }}>
        <Container maxWidth="md" component={Paper} sx={{ p: 2 }}>
          <PreviewTerminos />
        </Container>
      </Box>
    </Container>
  );
}

const PreviewTerminos = () => {
  const { loading, data, error } = useQuery(GetInfoPage, {
    fetchPolicy: "network-only",
  });

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const content = data?.getInfoPage?.terminos || null;

  return <PreviewContent content={content} />;
};

export default Terminos;
