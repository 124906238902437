import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { formatoFechaHora } from "../../../../../../Config/reuserFunctions";
import UserDetail from "../../Details/UserDetail";
import TorneoDetail from "../../Details/TorneoDetail";
import CompetenciaDetail from "../../Details/competenciaDetail";
import { MovimientoContext } from "../../Context/MovimientoContext";
import { TableFooter, Typography } from "@mui/material";

export default function MovimientosTableBody({ data }) {
  const { query } = React.useContext(MovimientoContext);
  const { balanceMovimientoTotal } = query.data;

  return (
    <>
      <TableBody>
        {data.map((res) => {
          return (
            <TableRow
              role="checkbox"
              tabIndex={-1}
              key={res._id}
              sx={{
                height: "30px",
                "& .cell": {
                  fontSize: 12,
                  py: 0,
                },
              }}
            >
              <TableCell className="cell">
                <TorneoDetail
                  idTorneo={res.torneo}
                  torneoData={res.torneoData}
                  data={res}
                />
              </TableCell>
              <TableCell className="cell">
                <CompetenciaDetail
                  idCompetencia={res.competencia}
                  competenciaData={res.competenciaData}
                  data={res}
                />
              </TableCell>
              <TableCell className="cell">
                <UserDetail
                  idUser={res.usuario}
                  usuarioData={res.usuarioData}
                  data={res}
                  nombre={res.nombre_usuario}
                />
              </TableCell>
              <TableCell className="cell">{res.usuarioData?.email || ""}</TableCell>
              <TableCell className="cell">{res.status}</TableCell>
              <TableCell className="cell">
                {formatoFechaHora(res.fecha)}
              </TableCell>
              <TableCell className="cell">
                {formatoFechaHora(res.updatedAt)}
              </TableCell>
              <TableCell className="cell">{res.balanceEnMovimiento}</TableCell>
              <TableCell className="cell">{res.balanceAnterior}</TableCell>
              <TableCell className="cell">{res.nuevoBalance}</TableCell>
              <TableCell className="cell">{res.comentarios}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow
          sx={{ position: "sticky", bottom: 0, backgroundColor: theme => theme.palette.background.paper  }}
        >
          <TableCell colSpan={5} />
          <TableCell>
            <Typography>
              <b>{balanceMovimientoTotal}</b>
            </Typography>
          </TableCell>
          <TableCell colSpan={3} />
        </TableRow>
      </TableFooter>
    </>
  );
}
