import React from "react";
import { Box, Stack } from "@mui/material";
import EmailCtxProvider, { EmailsContext } from "./Context/EmailsContext";
import SearchComponent from "./Actions/Search";
import TableEmails from "./Table";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import UnreadCheck from "./Actions/unreadCheck";

export default function Emails() {
  return (
    <Box>
      <EmailCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 1 }}
          bgcolor="background.paper"
        >
          <UnreadCheck />
          <SearchComponent />
          <Export />
        </Stack>
        <RenderTable />
      </EmailCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { emailQuery } = React.useContext(EmailsContext);
  const { loading, error } = emailQuery;

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  return <TableEmails />;
};
