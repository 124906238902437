import { gql } from "@apollo/client";

export const GetCortesVendedor = gql`
  query getCortesVendedor($idUser: ID, $limit: Int, $page: Int) {
    getCortesVendedor(idUser: $idUser, limit: $limit, page: $page) {
      docs {
        _id
        vendedor
        nombreVendedor
        encargado
        nombreEncargado
        totalMontoInicial
        totalMontoFinal
        totalMontoApuestas
        saldoDespuesDeVentas
        totalMontoApuestasPerdidas
        totalMontoApuestasPagadas
        utilidad
        cantidadApuestasTotal
        cantiadadApuestasRealizadas
        cantiadadapuestasCanceladas
        cantidadApuestasGanadas
        cantidadApuestasPerdidas
        fechaInicio
        fechaFin
        rol
        status
      }
      totalDocs
      page
      totalPages
    }
  }
`;