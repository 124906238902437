import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CrearCharreada from "../Actions/Create";
import DeleteCharreada from "../Actions/Delete";

export default function CharreadasTableBody({ data }) {

  return (
    <TableBody>
      {data.map((res) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{ height: "48px" }}
          >
            <TableCell>{res.nombre}</TableCell>
            <TableCell align="right"><CrearCharreada data={res} edit /></TableCell>
            <TableCell align="right"><DeleteCharreada data={res} /></TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
