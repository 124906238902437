import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Add, DeleteOutline, Edit } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { CreateStep, UpdateStep } from "./graphql/mutations";
import { MainContext } from "../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import RichTinyTextEditor from "../../../../Components/CustomEditor/TinyEditor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import UploadImage from "./UploadImage";

const initialState = { descripcion: "", imagen: "", newImagen: "" };

export default function CreateNewStep({
  data = initialState,
  edit = false,
  refetch = null,
}) {
  const [open, setOpen] = React.useState(false);
  const [stepData, setStepData] = React.useState(initialState);
  const [preview, setPreview] = React.useState();
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [createStep] = useMutation(CreateStep);
  const [updateStep] = useMutation(UpdateStep);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  const handleClickOpen = () => {
    setOpen(true);
    if (edit) {
      setStepData(data);
      setPreview(data.imagen);
      const parseContent = JSON.parse(data.descripcion);
      const contentState = convertFromRaw(parseContent);
      setEditorState(EditorState.createWithContent(contentState));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setStepData(initialState);
    setEditorState(EditorState.createEmpty());
    setPreview();
  };

  const handleRemoveFile = () => {
    setStepData((st) => ({ ...st, imagen: null }));
    setPreview();
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const content = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      let input = {
        descripcion: content,
      };
      if (stepData.newImagen) input.newImagen = stepData.newImagen;
      const response = await createStep({
        variables: {
          input,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createStep.message,
        variant: "success",
      });
      setLoading(false);
      refetch();
      handleClose();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const { _id, orden, ...data } = stepData;
      const content = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      let input = {
        ...data,
        descripcion: content,
      };
      if (typeof imagen === "object") input.newImagen = data.newImagen;
      const response = await updateStep({
        variables: {
          idStep: _id,
          input,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateStep.message,
        variant: "success",
      });
      setLoading(false);
      refetch();
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <React.Fragment>
      {edit ? (
        <IconButton size="small" onClick={handleClickOpen}>
          <Edit />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          variant="contained"
          size="small"
          disableElevation
          sx={{ textTransform: "none" }}
          onClick={handleClickOpen}
        >
          Nueva
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Escribe un nuevo paso</DialogTitle>
        <DialogContent>
          <form>
            <RichTinyTextEditor
              editorState={editorState}
              setEditorState={setEditorState}
              height="25vh"
            />
            <UploadImage
              setStepData={setStepData}
              preview={preview}
              setPreview={setPreview}
            />
          </form>
        </DialogContent>
        <DialogActions>
          {preview ? (
            <Button
              variant="outlined"
              size="small"
              color="info"
              disableElevation
              onClick={handleRemoveFile}
              startIcon={<DeleteOutline />}
              sx={{ textTransform: "none" }}
            >
              Remover imagen
            </Button>
          ) : null}
          <Button
            size="small"
            disableElevation
            sx={{ textTransform: "none" }}
            variant="contained"
            color="inherit"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            disableElevation
            sx={{ textTransform: "none" }}
            variant="contained"
            color="primary"
            onClick={edit ? handleUpdate : handleSave}
            disabled={loading}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
