import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CrearSuerte from "../Actions/Create";
import DeleteSuerte from "../Actions/Delete";

export default function SuertesTableBody({ suertes }) {

  return (
    <TableBody>
      {suertes.map((lucky) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={lucky._id}
            sx={{ height: "48px" }}
          >
            <TableCell>{lucky.suerte}</TableCell>
            <TableCell align="right"><CrearSuerte data={lucky} edit /></TableCell>
            <TableCell align="right"><DeleteSuerte data={lucky} /></TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
