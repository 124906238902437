import React from "react";
//import FormControlLabel from "@mui/material/FormControlLabel";
//import Checkbox from "@mui/material/Checkbox";
import { CompetenciaContext } from "../Context/CompetenciaContext";
import { Tooltip } from "@mui/material";
import { Expand } from "@mui/icons-material";
import { CustomButton } from "../../../../../Components/CustomMuiComponents";

export default function ShowCalcs({ sx = null }) {
  const { showCalc, setShowCalc } = React.useContext(CompetenciaContext);

  const handleChange = (e) => {
    //const { checked } = e.target;
    const checked = !showCalc;
    setShowCalc(checked);
    localStorage.setItem("ShowCalc", JSON.stringify(checked));
  };

  return (
    <Tooltip arrow title="Mostrar cálculos en tabla" placement="top">
      <CustomButton
          variant="text"
          disableElevation
          size="small"
          onClick={handleChange}
          startIcon={<Expand sx={{ rotate: "90deg" }} />}
          color={showCalc ? "primary" : "inherit"}
        >
          {!showCalc ? "Expandir" : "Contraer"}
        </CustomButton>
    </Tooltip>
  );

  /* return (
    <FormControlLabel
      control={
        <Checkbox onChange={handleChange} checked={showCalc ? true : false} />
      }
      label="Mostrar cáculos"
    />
  ); */
}
