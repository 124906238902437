import React from "react";
import { Box, Stack } from "@mui/material";
import TorneoContextCtxProvider, { TorneoContext } from "./Context/TorneoContext";
import SearchComponent from "./Actions/Search";
import TableTorneos from "./Table";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import CreateTorneo from "./Actions/Create";

export default function Torneos() {
  return (
    <Box>
      <TorneoContextCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          sx={{ mb: 1, position: { md: "absolute" }, top: 60, right: 24 }}
          bgcolor="background.paper"
        >
          <SearchComponent />
          <CreateTorneo />
          <Export />
        </Stack>
        <RenderTable />
      </TorneoContextCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { torneosQuery } = React.useContext(TorneoContext);

  if (torneosQuery.loading) return <CustomLoader />;
  if (torneosQuery.error) return <ErrorMessage message={torneosQuery.error.message} />;

  return <TableTorneos />;
};
