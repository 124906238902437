import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";

const headCells = [
  {
    id: "nombre",
    padding: "normal",
    align: "left",
    label: "Nombre",
    sortable: false,
    sx: null
  },
  {
    id: "email",
    padding: "normal",
    align: "left",
    label: "Email",
    sortable: false,
    sx: null
  },
  {
    id: "motivo",
    padding: "normal",
    align: "left",
    label: "Motivo",
    sortable: false,
    sx: null,
  },
  {
    id: "fecha",
    padding: "checkbox",
    align: "left",
    label: "Fecha y Hora",
    sortable: false,
    sx: {
      minWidth: "200px"
    }
  },
];

export default function BlackListHead({ handleSort, order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={headCell.sx}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}
