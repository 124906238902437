import { gql } from "@apollo/client";

export const GetBannersCarousel = gql`
  query getBannersCarousel {
    getBannersCarousel {
      _id
      orden
      image
      keyImage
    }
  }
`;
export const CreateBannerCarousel = gql`
  mutation createBannerCarousel($image: Upload) {
    createBannerCarousel(image: $image) {
      message
    }
  }
`;
export const UpdateBannerCarousel = gql`
  mutation updateBannerCarousel($idBanner: ID!, $image: Upload) {
    updateBannerCarousel(idBanner: $idBanner, image: $image) {
      message
    }
  }
`;
export const DeleteBanner = gql`
  mutation deleteBanners($idBanner: ID!) {
    deleteBanners(idBanner: $idBanner) {
      message
    }
  }
`;