import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import indicaciones from "../../../assets/images/howToPlay.png";
import { Paper, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import { GetSteps } from "../../Dashboard/Settings/Tutorial/graphql/queries";
import PreviewContent from "../../../Components/CustomEditor/PreviewContent";
import LazyImage from "../../../Components/LazyImage";

function Indicaciones() {
  return (
    <Container maxWidth="md" sx={{ pt: 1 }}>
      <Box
        sx={{
          height: 50,
          width: { xs: "100%", md: 400 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LazyImage
          src={indicaciones}
          alt="Indicaciones para jugar"
        />
      </Box>
      <Box sx={{ py: 2 }}>
        <Container maxWidth="md" component={Paper} sx={{ p: 2 }}>
          <PreviewIndicaciones />
        </Container>
      </Box>
    </Container>
  );
}

const PreviewIndicaciones = () => {
  const { loading, data, error } = useQuery(GetSteps, {
    fetchPolicy: "network-only",
  });

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const indicaciones = data.getSteps || [];

  return (
    <Box>
      {indicaciones.map((res, index) => {
        return (
          <Box key={index} sx={{ py: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="h6">{`${index + 1}.- `}</Typography>
              <PreviewContent content={res.descripcion} />
            </Box>
            <Box
              sx={{
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LazyImage
                src={res.imagen}
                alt={`indicacion ${index + 1}`}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Indicaciones;
