import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import { gql, useMutation, useQuery } from "@apollo/client";
import { MainContext } from "../../../Context/MainCtx";
import { CircularProgress, Typography } from "@mui/material";
import { GetUserBalance } from "../../Dashboard/Auditorias/Usuarios/graphql/queries";
import { Warning } from "@mui/icons-material";

const StartCorte = gql`
  mutation startCorte($input: corteStartInput) {
    startCorte(input: $input) {
      message
    }
  }
`;

export default function ObtenerCorteCurso({ refetch }) {
  const {
    snackMessage,
    tokenSesion,
    tokenDecoded,
    setErrors,
  } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [startCorte] = useMutation(StartCorte);

  const balanceQuery = useQuery(GetUserBalance, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const balance = balanceQuery.data?.getUserBalance?.saldo || 0;

  const crearApuestaBD = async () => {
    try {
      setLoading(true);
      const res = await startCorte({
        variables: {
          input: {
            vendedor: tokenDecoded._id,
            nombreVendedor: tokenDecoded.nombre,
            totalMontoInicial: balance,
            rol: tokenDecoded.rol,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      snackMessage({
        message: res.data.startCorte.message,
        variant: "success",
      });
      refetch();
    } catch (error) {
      console.log(error);
      setLoading(false);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
      setErrors({ ...error, funcion: "createApuestaSeller" });
    }
  };

  return (
    <React.Fragment>
      <Box>
        <Alert severity="info">
          Para empezar a realizar juegos es necesario que empiezes una nueva
          actividad
        </Alert>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography textAlign="center">
            Vendedor: {tokenDecoded.nombre}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Typography>Iniciar con:</Typography>
            {balanceQuery.loading ? (
              <CircularProgress size={18} color="inherit" />
            ) : balanceQuery.error ? (
              <Warning fontSize="small" color="action" />
            ) : (
              <Typography>{balance}</Typography>
            )}
          </Box>
          <Button
            variant="contained"
            disableElevation
            sx={{ textTransform: "none" }}
            onClick={() => crearApuestaBD()}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Iniciar actividad
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
