import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { GetCharreadasByTorneo } from "../graphql/queries";
import { useQuery } from "@apollo/client";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import { useMatches, useNavigate } from "react-router-dom";
import { formatoFechaHora } from "../../../../Config/reuserFunctions";
import ListaCharreada from "./ListaCharreadas";
import { Paper } from "@mui/material";
import { SearchOff } from "@mui/icons-material";
import { io } from "socket.io-client";
import { STATUS_TORNEO } from "../../../../Config/constantes";
import { MainContext } from "../../../../Context/MainCtx";
import imgOro from "../../../../assets/images/torneos-oro.png";
import imgPlata from "../../../../assets/images/torneos-plata.png";
import imgBronce from "../../../../assets/images/torneos-bronce.png";
import LazyImage from "../../../../Components/LazyImage";
import CheckSesionStatusC from "../../CheckSesionStatus";

export default function ApuestasTorneo() {
  const matches = useMatches();
  const idTorneo = matches && matches.length ? matches[0].params?.idTorneo : "";
  const { setErrors } = React.useContext(MainContext);
  const navigate = useNavigate();

  const { loading, data, error, refetch } = useQuery(GetCharreadasByTorneo, {
    variables: {
      idTorneo,
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedCompetencia", () => refetch());
    return () => socket.disconnect();
  }, [refetch]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (error) setErrors({ ...error, funcion: "getCharreadasByTorneo" });
  }, [error, setErrors]);

  if (loading) return <CustomLoader height="100vh" />;
  if (error) return <ErrorMessage message={error?.message} />;

  const { torneo, charreadas } = data?.getCharreadasByTorneo || [];

  const GetImage = () => {
    if (torneo.tipo === "ORO") {
      return (
        <LazyImage
          src={imgOro}
          alt="torneo"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/torneos#torneo-oro")}
        />
      );
    } else if (torneo.tipo === "PLATA") {
      return (
        <LazyImage
          src={imgPlata}
          alt="torneo"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/torneos#torneo-plata")}
        />
      );
    } else {
      return (
        <LazyImage
          src={imgBronce}
          alt="torneo"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/torneos#torneo-bronce")}
        />
      );
    }
  };

  return (
    <Box id="torneos" sx={{ py: 2, minHeight: "calc(100vh - 48px)" }}>
      <CheckSesionStatusC />
      <Container maxWidth="md">
        <Box sx={{ display: { xs: "block", md: "flex" } }}>
          <Box
            sx={{
              width: { xs: 100, md: 200 },
              height: { xs: 50, md: "100%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GetImage />
          </Box>
          <Box>
            <Typography
              sx={{ color: "white", fontSize: { xs: "16px", md: "24px" } }}
              className="fontAnton"
            >
              <b>{torneo.nombre}</b>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
              <Typography
                sx={{
                  fontWeight: "bolder",
                  color: "white",
                  fontSize: { xs: "14px", md: "18px" },
                }}
              >
                {`${torneo.lienzoCharro}, ${torneo.lugar} ${formatoFechaHora(
                  torneo.fechaInicio
                )} a ${formatoFechaHora(torneo.fechaFin)}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        {torneo.status === STATUS_TORNEO.pausado ? (
          <Typography
            align="center"
            sx={{
              fontWeight: "bolder",
              color: (theme) => theme.palette.warning.light,
              fontSize: { xs: "20px", md: "30px" },
            }}
            className="fontAnton"
          >
            {STATUS_TORNEO.pausado}
          </Typography>
        ) : null}
        <Box sx={{ my: 2 }}>
          {charreadas.length ? (
            charreadas.map((res, index) => (
              <ListaCharreada
                key={res._id}
                index={index}
                data={res}
                idTorneo={idTorneo}
                torneo={torneo}
              />
            ))
          ) : (
            <Container maxWidth="xs" sx={{ mt: 5 }}>
              <Paper
                sx={{
                  p: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <SearchOff color="disabled" fontSize="large" />
                <Typography color="GrayText">
                  Aun no hay competencias
                </Typography>
              </Paper>
            </Container>
          )}
        </Box>
        <Grid container spacing={2} sx={{ my: 3 }}></Grid>
      </Container>
      
    </Box>
  );
}
