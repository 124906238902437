import * as React from "react";
import Button from "@mui/material/Button";
import { MovimientoContext } from "../Context/MovimientoContext";

export default function NewReportButton() {
  const { setQuery, setFilter, initialFilter, setLoaded } = React.useContext(
    MovimientoContext
  );

  const handleNewReport = () => {
    setQuery((prev) => ({ ...prev, data: [] }));
    setFilter(initialFilter);
    setLoaded(false);
  };

  return (
    <Button
      variant="contained"
      size="small"
      disableElevation
      onClick={handleNewReport}
      sx={{ textTransform: "none" }}
    >
      Nuevo Reporte
    </Button>
  );
}
