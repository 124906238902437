import { gql } from "@apollo/client";

export const CreateFAQ = gql`
  mutation createFaq($input: faqInput) {
    createFaq(input: $input) {
      message
    }
  }
`;

export const UpdateFAQ = gql`
  mutation updateFaq($idFaq: ID!, $input: faqInput) {
    updateFaq(idFaq: $idFaq, input: $input) {
      message
    }
  }
`;
export const DeleteFAQ = gql`
  mutation deleteFaq($idFaq: ID!) {
    deleteFaq(idFaq: $idFaq) {
      message
    }
  }
`;
export const OrderFAQ = gql`
  mutation ordenUpdate($IDs: [ID]) {
    ordenUpdate(IDs: $IDs) {
      message
    }
  }
`;
export const UploadImgFAQ = gql`
  mutation uploadImgFaq($idFaq: ID!, $image: Upload) {
    uploadImgFaq(idFaq: $idFaq, image: $image) {
      message
    }
  }
`;
export const DeleteImgFAQ = gql`
  mutation deleteImgFaq($idFaq: ID!, $key: String) {
    deleteImgFaq(idFaq: $idFaq, key: $key) {
      message
    }
  }
`;
