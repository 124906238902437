import React, { createContext } from "react";
import { MainContext } from "../../../../Context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetMovimientosSeller } from "../graphql/queries";
import { GetCatsMov } from "../../../Dashboard/Auditorias/Movimientos/graphql/queries";

export const MovSellerContext = createContext();

const initialFilter = {
  fechaInicio: "",
  fechaFin: "",
  usuario: "",
  status: "",
  movimiento: "",
  torneo: "",
};
const initialCats = {
  torneo: "",
  equipo: "",
  charro: "",
  charreada: "",
  suerte: "",
  statusApuesta: "",
};

const MovsSellerCtxProvider = ({ children }) => {
  const { tokenSesion } = React.useContext(MainContext);
  const [filter, setFilter] = React.useState(initialFilter);
  const [filterCats, setFilterCats] = React.useState(initialCats);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const [FILTERCATS] = useDebounce(filterCats, 500);
  const [itemsSelected, setItemsSelected] = React.useState([]);
  const [indeterminated, setIndeterminated] = React.useState(false);
  const { page, limit } = pageConfig;

  const movsQuery = useQuery(GetMovimientosSeller, {
    variables: {
      filter: FILTER,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const catsQuery = useQuery(GetCatsMov, {
    variables: { filter: FILTERCATS },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const getCompName = (comp = null) => {
    if (!comp) return null;
    const {
      charreadaData,
      suerteData,
      equipoData,
      charroData,
    } = comp;

    const isEquipo = suerteData.suerte?.toLowerCase() === "equipo" && true;
    let name = "";
    if (isEquipo) {
      name = `${charreadaData?.nombre}, ${equipoData?.nombre} (${suerteData?.suerte})`;
    } else {
      name = `${charreadaData?.nombre}, ${charroData?.nombre} (${suerteData?.suerte})`;
    }
    return name
  };

  return (
    <MovSellerContext.Provider
      value={{
        filter,
        setFilter,
        setFilterCats,
        filterCats,
        initialFilter,
        movsQuery,
        catsQuery,
        pageConfig,
        setPageConfig,
        itemsSelected,
        setItemsSelected,
        indeterminated,
        setIndeterminated,
        getCompName
      }}
    >
      {children}
    </MovSellerContext.Provider>
  );
};

export default MovsSellerCtxProvider;
