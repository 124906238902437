import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import { Filter2, Filter3, Filter4, Tune } from "@mui/icons-material";
import { CustomButton } from "../../../../../Components/CustomMuiComponents";
import { MainContext } from "../../../../../Context/MainCtx";
import { CompetenciaContext } from "../Context/CompetenciaContext";
import { DialogContentText, ListItemIcon, Tooltip } from "@mui/material";
import { UpdateConfigFactor } from "../graphql/mutations";
import { useMutation } from "@apollo/client";

export default function FactorSettings() {
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const {
    filterCats,
    compQuery,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(CompetenciaContext);
  const [updateConfigFactor] = useMutation(UpdateConfigFactor);
  const [loading, setLoading] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const items = itemsSelected.length;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSetFactor = (type) => {
    setOpenAlert(true);
    setSelected(type);
  };

  const handleClose = () => setAnchorEl(null);
  const handleCloseAlert = () => setOpenAlert(null);

  const getComptData = () => {
    let text = "";
    if (filterCats.torneo) text = text.concat(`Torneo ${filterCats.torneo} `);
    if (filterCats.charreada) text = text.concat(`- ${filterCats.charreada} `);
    if (filterCats.suerte) text = text.concat(`- Suerte ${filterCats.suerte} `);
    return text;
  };

  const handleSubmit = async () => {
    try {
      if (!items) return;
      if (!selected) return;

      const idsComp = itemsSelected.map((res) => res._id);
      setLoading(true);
      const res = await updateConfigFactor({
        variables: {
          idsComp,
          typeFactor: selected,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: res.data.updateConfigFactor.message,
        variant: "success",
      });
      setLoading(false);
      compQuery.refetch();
      setItemsSelected([]);
      setIndeterminated(false);
      handleClose();
      handleCloseAlert();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Tooltip arrow title="Configurar Factor" placement="top">
        <CustomButton
          color="inherit"
          variant="text"
          startIcon={<Tune />}
          onClick={handleClick}
          size="small"
          disableElevation
          disabled={!items}
        >
          Factor
        </CustomButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleSetFactor(2)}>
          <ListItemIcon>
            <Filter2 />
          </ListItemIcon>
          Configurar Factores 2
        </MenuItem>
        <MenuItem onClick={() => handleSetFactor(3)}>
          <ListItemIcon>
            <Filter3 />
          </ListItemIcon>
          Configurar Factores 3
        </MenuItem>
        <MenuItem onClick={() => handleSetFactor(4)}>
          <ListItemIcon>
            <Filter4 />
          </ListItemIcon>
          Configurar Factores 4
        </MenuItem>
      </Menu>
      <Dialog
        onClose={handleCloseAlert}
        open={openAlert}
        PaperProps={{
          sx: { minWidth: 250 },
        }}
      >
        <DialogTitle>
          Configurar Factores {selected} a estas competencias
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{getComptData()}</DialogContentText>
          Se cambiara la configuración de factores para las competencias
          seleccionadas
        </DialogContent>
        <DialogActions>
          <CustomButton
            color="inherit"
            size="small"
            onClick={handleCloseAlert}
            variant="contained"
            fullWidth
            disableElevation
          >
            Cancelar
          </CustomButton>
          <CustomButton
            color="info"
            size="small"
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            disableElevation
            disabled={loading}
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Continuar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
