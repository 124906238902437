import * as React from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { MainContext } from "../../../Context/MainCtx";
import SCharraIcon from "../../../Icons/ScharraIcon";
import UserBalance from "../../../Components/Avatar/UserBalance";
import MenuUserSeller from "../../../Components/Avatar/MenuUserSeller";
import TicketTest from "../Torneos/TicketTest";

export default function NavbarDashboard({ handleDrawerToggle, view }) {
  const { tokenDecoded } = React.useContext(MainContext);

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      elevation={0}
    >
      <Toolbar variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          sx={{
            marginRight: 2,
            display: { xs: "none", sm: "flex" },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          <Box display="flex" alignItems="center">
            <Box sx={{ display: { xs: "none", sm: "flex" }, mr: 2 }}>
              <SCharraIcon size={4} />
            </Box>
            <Typography variant="h6" noWrap component="div">
              {view}
            </Typography>
          </Box>
        </Box>
        <Box />
        {tokenDecoded ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <UserBalance color="inherit" />
          </Box>
        ) : null}
        <TicketTest />
        <MenuUserSeller />
      </Toolbar>
    </AppBar>
  );
}
