import { gql } from "@apollo/client";

export const GetBannersButtons = gql`
  query GetBannersButtons {
    getBannersButtons {
      _id
      orden
      text
      href
      image
      keyImage
      imageTitle
    }
  }
`;
export const UpdateBannerButton = gql`
  mutation updateBannerButton($idBanner: ID!, $image: Upload) {
    updateBannerButton(idBanner: $idBanner, image: $image) {
      message
    }
  }
`;