import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Delete } from "@mui/icons-material";
import { useApolloClient, useMutation } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";
import { ApuestasContext } from "../Context/ApuestasContext";
import { DeleteGames } from "../graphql/mutations";
import { GetUsersTest } from "../../Auditorias/Usuarios/graphql/queries";

export default function DeleteTestGames() {
  const [loadingQuery, setLoadingQuery] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const { apuestasQuery } = React.useContext(ApuestasContext);
  const [deleteTestGames, { loading }] = useMutation(DeleteGames);
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  //const [email, setEmail] = React.useState("");
  const client = useApolloClient();

  const getUsers = async () => {
    setLoadingQuery(true);
    try {
      const res = await client.query({
        query: GetUsersTest,
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoadingQuery(false);
      setUsers(res.data?.getUsersTest?.map(({ email }) => email) || []);
    } catch (error) {
      setLoadingQuery(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const handleClick = () => {
    setOpen(true);
    getUsers();
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* const handleAddNewEmail = (e) => {
    e.preventDefault();
    setUsers([...users, email]);
    setEmail("");
  }; */

  /* const handleRemoveEmail = (index) => {
    const newUsers = [...users];
    newUsers.splice(index, 1);
    setUsers(newUsers);
  }; */

  const handleDeleteTest = async () => {
    try {
      if (!users.length) return;
      const res = await deleteTestGames({
        variables: { users },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: res.data.deleteTestGames.message,
        variant: "success",
      });
      apuestasQuery.refetch();
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Button
        color="warning"
        size="small"
        variant="contained"
        startIcon={<Delete />}
        disableElevation
        onClick={handleClick}
        sx={{ textTransform: "none" }}
      >
        Eliminar
      </Button>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle>Eliminar Juegos de pruebas</DialogTitle>
        <DialogContent>
          <Alert severity="info">
            Esta opción es para eliminar principalmente juegos de prueba/test, se eliminaran juegos y movimientos de los siguientes usuarios
          </Alert>
          <Box display="flex" justifyContent="center" mt={2}>
            {loadingQuery && <CircularProgress />}
          </Box>
          <Box>
            {users.map((user, index) => {
              return (
                <TextField
                  key={index}
                  size="small"
                  fullWidth
                  disabled
                  value={user}
                  margin="dense"
                  /* InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveEmail(index)}
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }} */
                />
              );
            })}
            {/* <form onSubmit={handleAddNewEmail}>
              <TextField
                size="small"
                type="email"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        startIcon={<Add />}
                        size="small"
                        sx={{ textTransform: "none" }}
                        type="submit"
                        disabled={!email}
                      >
                        Agregar
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </form> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="inherit"
            size="small"
            onClick={handleClose}
            variant="contained"
            fullWidth
            disableElevation
          >
            Cancelar
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="error"
            size="small"
            onClick={handleDeleteTest}
            variant="contained"
            fullWidth
            disabled={!users.length}
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
