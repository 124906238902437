import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CrearTorneo from "../Actions/Create";
import DeleteTorneo from "../Actions/Delete";
import {
  formatoFechaCorta,
  isMobile,
} from "../../../../../Config/reuserFunctions";
import ChipStatus from "../Actions/Status";
import { TorneoContext } from "../Context/TorneoContext";
import ChipTipoEvento from "../Actions/TipoEvento";
import SendNotification from "../Actions/SendNotification";

export default function TorneosTableBody({ data, shadows }) {
  const { torneosQuery } = React.useContext(TorneoContext);

  return (
    <TableBody>
      {data.map((res) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{ height: "48px" }}
          >
            <TableCell>{res.nombre}</TableCell>
            <TableCell>{res.lugar}</TableCell>
            <TableCell>{res.lienzoCharro}</TableCell>
            <TableCell>{formatoFechaCorta(res.fechaInicio)}</TableCell>
            <TableCell>{formatoFechaCorta(res.fechaFin)}</TableCell>
            <TableCell>
              <ChipTipoEvento torneo={res} refetch={torneosQuery.refetch} />
            </TableCell>
            <TableCell>
              <ChipStatus torneo={res} refetch={torneosQuery.refetch} />
            </TableCell>
            <TableCell>{formatoFechaCorta(res.createdAt)}</TableCell>
            <TableCell
              className={isMobile ? "" : shadows.end}
              align="right"
              sx={{
                position: "sticky",
                right: 96,
                bgcolor: (theme) => theme.palette.background.paper,
              }}
            >
              <SendNotification torneo={res} />
            </TableCell>
            <TableCell
              align="right"
              sx={{
                position: "sticky",
                right: 46,
                bgcolor: (theme) => theme.palette.background.paper,
              }}
            >
              <CrearTorneo data={res} edit />
            </TableCell>
            <TableCell
              align="right"
              sx={{
                position: "sticky",
                right: 0,
                bgcolor: (theme) => theme.palette.background.paper,
              }}
            >
              <DeleteTorneo data={res} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
