import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CompetenciaContext } from "../../Context/CompetenciaContext";
import { Close, Search } from "@mui/icons-material";
import ApuestasTable from "./TableApuestas";
import { CustomSearchTextField } from "../../../../../../Components/CustomMuiComponents";
import { InputAdornment } from "@mui/material";
import CancelarApuestas from "./CancelarApuestas";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ApuestaDetail() {
  const { compDetail, setCompDetail } = React.useContext(CompetenciaContext);
  const [filter, setFilter] = React.useState("");

  if (!compDetail) return null;

  const { charreadaData, suerteData, equipoData, charroData } = compDetail;
  const isEquipo = suerteData.suerte?.toLowerCase() === "equipo" && true;
  let name = "";
  if (isEquipo) {
    name = `${charreadaData?.nombre}, ${equipoData?.nombre} (${suerteData?.suerte})`;
  } else {
    name = `${charreadaData?.nombre}, ${charroData?.nombre} (${suerteData?.suerte})`;
  }

  const handleClose = () => {
    setCompDetail(null);
  };

  return (
    <React.Fragment>
      <Dialog
        open={compDetail ? true : false}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            height: "90vh",
          },
        }}
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{ flexGrow: 1 }}
              variant="h6"
            >{`Apuestas a ${name}`}</Typography>

            <CustomSearchTextField
              placeholder="Buscar usuario"
              size="small"
              variant="outlined"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <CancelarApuestas idCompetencia={compDetail._id} competencia={name} />
            <Button
              size="small"
              variant="contained"
              disableElevation
              onClick={handleClose}
              sx={{ textTransform: "none" }}
              startIcon={<Close />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ApuestasTable competencia={compDetail} filter={filter} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
