import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { formatoFechaHora, formatoMexico } from "../../../../Config/reuserFunctions";
import { MovSellerContext } from "../Context/MovSellerContext";

export default function MovimientosSellerTableBody({ data }) {
  const { getCompName } = React.useContext(MovSellerContext);

  return (
    <>
      <TableBody>
        {data.map((res) => {
          return (
            <TableRow
              role="checkbox"
              tabIndex={-1}
              key={res._id}
              sx={{
                height: "30px",
                "& .cell": {
                  fontSize: 12,
                  py: 0,
                },
              }}
            >
              <TableCell className="cell">{res.status}</TableCell>
              <TableCell className="cell">
                {res.torneoData?.nombre}
              </TableCell>
              <TableCell className="cell">
                {getCompName(res.competenciaData)}
              </TableCell>
              <TableCell className="cell">
                {formatoFechaHora(res.fecha)}
              </TableCell>
              <TableCell className="cell">{res.movimiento}</TableCell>             
              <TableCell className="cell">{formatoMexico(res.balanceEnMovimiento)}</TableCell>
              <TableCell className="cell">{res.metodoPago}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
}
