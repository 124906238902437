import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import { Add, ArrowBack, Edit } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../Context/MainCtx";
import { SellerContext } from "../Context/SellerContext";
import { InputAdornment, TextField } from "@mui/material";
import { CreateSeller, UpdateSeller } from "../graphql/mutations";
import { useApolloClient, useMutation } from "@apollo/client";
import { ROLES } from "../../../../Config/constantes";
import { GetUserBalance } from "../graphql/queries";

export default function UpdateVendedor({ data, edit = false }) {
  const { queryRefetch } = React.useContext(SellerContext);
  const { snackMessage, tokenSesion, tokenDecoded } = React.useContext(
    MainContext
  );
  const [loading, setLoading] = React.useState(false);
  const [updateUserSeller,] = useMutation(UpdateSeller);
  const [createSeller] = useMutation(CreateSeller);
  const [open, setOpen] = React.useState(false);
  const [dataUser, setDataUser] = React.useState(null);
  const [balance, setBalance] = React.useState(0);

  const handleOpen = () => {
    if (edit && data) setDataUser(data);
    setOpen(true);
    getBalance();
  };

  const handleClose = () => {
    setOpen(false);
    setDataUser(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataUser((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const client = useApolloClient();
  const getBalance = async () => {
    try {
      const res = await client.query({
        query: GetUserBalance,
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      if (res.data) setBalance(res.data.getUserBalance?.saldo || 0);
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      await createSeller({
        variables: {
          input: {
            ...dataUser,
            encargado: tokenDecoded.nombre,
            idEncargado: tokenDecoded._id,
            saldo: parseFloat(dataUser.saldo),
            rol: ROLES.SELLER,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      queryRefetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const { createdAt, _id, imagenesID, recovered, ...input } = dataUser;

      await updateUserSeller({
        variables: {
          idUser: _id,
          input: {
            ...input,
            saldo: parseFloat(input.saldo),
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      queryRefetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {!edit ? (
        <Button
          startIcon={<Add fontSize="small" />}
          onClick={handleOpen}
          size="small"
          disableElevation
          sx={{ textTransform: "none" }}
          variant="contained"
        >
          Nuevo
        </Button>
      ) : (
        <IconButton onClick={handleOpen} size="small">
          <Edit fontSize="small" />
        </IconButton>
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "35vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                {edit ? "Editar Vendedor" : "Nuevo Vendedor"}
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="form-user"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <form id="form-user" onSubmit={edit ? handleUpdate : handleSave}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Nombre
                </Typography>
                <TextField
                  fullWidth
                  required
                  name="nombre"
                  size="small"
                  value={dataUser?.nombre}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Dirección
                </Typography>
                <TextField
                  fullWidth
                  name="direccion"
                  size="small"
                  value={dataUser?.direccion}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Telefono
                </Typography>
                <TextField
                  tabIndex={1}
                  fullWidth
                  name="telefono"
                  size="small"
                  value={dataUser?.telefono}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Correo
                </Typography>
                <TextField
                  fullWidth
                  required
                  name="email"
                  size="small"
                  value={dataUser?.email}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Balance
                </Typography>
                <TextField
                  fullWidth
                  name="saldo"
                  size="small"
                  type="number"
                  value={dataUser?.saldo}
                  onChange={handleChange}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setDataUser(st => ({...st, saldo: 0}))
                    }else if(parseFloat(e.target.value) > (data?.saldo + balance)){
                      setDataUser(st => ({...st, saldo: data?.saldo}))
                    }
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: data?.saldo + balance,
                      step: "any",
                    },
                    endAdornment: <InputAdornment position="end">
                      {(balance + data?.saldo) - (dataUser?.saldo)}
                    </InputAdornment>
                  }}
                />
              </Box>
              <Stack direction="row" spacing={2}>
                <Box sx={{ mb: 1 }}>
                  <FormControlLabel
                    onChange={(e) =>
                      setDataUser({
                        ...dataUser,
                        activo: e.target.checked,
                      })
                    }
                    control={<Switch checked={dataUser?.activo} />}
                    label={dataUser?.activo ? "Activo" : "Inactivo"}
                  />
                </Box>
              </Stack>
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}
