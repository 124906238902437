import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppBar } from "@mui/material";
import { itemsResNav } from "../listItems";
import CustomIconComponent from "../../../Components/CustomIconComponent";
import { useMatches, /* useNavigate */ } from "react-router-dom";
import ListMenuResponsive from "./ListMenu";
import { MainContext } from "../../../Context/MainCtx";

export default function BottomMenuResponsive() {
  //const navigate = useNavigate();
  const matches = useMatches();
  const pathname = matches && matches.length > 1 ? matches[1].pathname : "";
  const [value, setValue] = React.useState(pathname);
  const [more, setMore] = React.useState(false);
  const { tokenDecoded } = React.useContext(MainContext);

  const handleChange = (path) => {
    if (!path) return;
    setValue(path);
    //navigate(path);
  };

  const handleClick = ({ title, noTab = false }) => {
    if (noTab) {
      setMore(!more);
      return;
    }
    setMore(false);
    localStorage.setItem("lastview", title);
  };

  const handleClickSecodanry = ({ title, url }) => {
    handleChange(url);
    handleClick({ title });
    localStorage.setItem("lastview", title);
  };

  React.useEffect(() => {
    setValue(pathname);
  }, [pathname]);

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        sx={{
          top: "auto",
          bottom: 0,
          display: { xs: "flex", sm: "none" },
          zIndex: 1100,
        }}
      >
        <Tabs
          value={value}
          onChange={(_, path) => handleChange(path)}
          variant="fullWidth"
          sx={{
            width: "100%",
            "& .MuiButtonBase-root": {
              textTransform: "none",
            },
          }}
        >
          {itemsResNav(value, tokenDecoded).map((res, index) => (
            <Tab
              key={index}
              value={res.url}
              onClick={() => handleClick(res)}
              component={res.component}
              to={res.url}
              icon={
                <CustomIconComponent
                  iconName={res.icon}
                  color={res.url === value ? "primary" : "inherit"}
                />
              }
              label={res.title}
            />
          ))}
        </Tabs>
      </AppBar>
      <ListMenuResponsive
        open={more}
        setOpen={setMore}
        pathname={pathname}
        handleClickSecodanry={handleClickSecodanry}
      />
    </>
  );
}
