import { gql } from "@apollo/client";

export const UpdateUser = gql`
  mutation updateUser($idUser: ID!, $input: updateUsuarioInput) {
    updateUser(idUser: $idUser, input: $input) {
      message
    }
  }
`;
export const UpdatePassword = gql`
  mutation updateUser($idUser: ID!, $input: updateUsuarioInput) {
    updateUser(idUser: $idUser) {
      message
    }
  }
`;
export const DeleteUser = gql`
  mutation deleteUser($idUser: ID!) {
    deleteUser(idUser: $idUser) {
      message
    }
  }
`;
export const DeleteImgUser = gql`
  mutation deleteImgUser($idUser: ID!, $key: String) {
    deleteImgUser(idUser: $idUser, key: $key) {
      message
    }
  }
`;