import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function ReCaptchaWebComponent({onChange, onExpired}) {
  const [loading, setLoading] = React.useState(true);

  return (
    <>
      {loading && (
        <Box
          width={300}
          py={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <CircularProgress size={20} />
          <Typography>Cargando captcha...</Typography>
        </Box>
      )}
      <ReCAPTCHA
        asyncScriptOnLoad={() => setLoading(false)}
        sitekey={process.env.REACT_APP_RECAPTCHA_WEBSITE_KEY}
        onChange={onChange}
        onExpired={onExpired}
      />
    </>
  );
}
