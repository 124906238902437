const listItems = [
  {
    title: "Juegos",
    url: "/admin/juegos",
    icon: "Casino",
  },
  { title: "Competencias", url: "/admin/competencias", icon: "Campaign" },
  {
    title: "Catalogos",
    url: "/admin/catalogos",
    icon: "Stadium",
  },
  {
    title: "Auditorias",
    url: "/admin/auditorias",
    icon: "Troubleshoot",
  },
  {
    title: "Solicitudes retiro",
    url: "/admin/solicitudes",
    icon: "CurrencyExchange",
    badge: "movimientos"
  },
  {
    title: "Emails",
    url: "/admin/emails",
    icon: "Email",
    badge: "contacto"
  },
  /* {
    title: "Banners",
    url: "/admin/banners",
    icon: "Panorama",
    expand: false,
  }, */
  {
    title: "Configuración",
    url: "/admin/settings",
    icon: "Settings",
  },
  {
    title: "Página principal",
    url: "/",
    icon: "Home",
    expand: false,
  },
];

const responsiveListTab = (link = "/#") => {
  let url = link;
  if (url === "/admin/catalogos" || url === "/admin/competencias" || url === "/admin/juegos") {
    url = "";
  }

  return [
    {
      title: "Juegos",
      url: "/admin/juegos",
      icon: "Casino",
    },
    { title: "Competencias", url: "/admin/competencias", icon: "Campaign" },
    {
      title: "Catalogos",
      url: "/admin/catalogos",
      icon: "Stadium",
    },
    {
      title: "Más",
      url,
      icon: "Apps",
      noTab: true,
    },
  ];
};
const listSecondary = [
  {
    title: "Auditorias",
    url: "/admin/auditorias",
    icon: "Troubleshoot",
  },
  {
    title: "Solicitudes de Retiro",
    url: "/admin/solicitudes",
    icon: "CurrencyExchange",
    badge: "movimientos"
  },
  {
    title: "Emails",
    url: "/admin/emails",
    icon: "Email",
    badge: "contacto"
  },
  /* {
    title: "Banners",
    url: "/admin/banners",
    icon: "Panorama",
  }, */
  {
    title: "Configuración",
    url: "/admin/settings",
    icon: "Settings",
  },
  {
    title: "Página principal",
    url: "/",
    icon: "Home",
  },
];

export { listItems, responsiveListTab, listSecondary };
