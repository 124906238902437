import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";
import { router } from "./Config/routes";
import MainCtxProvider, { MainContext } from "./Context/MainCtx";
import { dark, light } from "./Config/colors";
import client from "./Config/apollo";
import { ApolloProvider } from "@apollo/client";

function App() {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
          <MainCtxProvider>
            <ContentApp />
          </MainCtxProvider>
        </SnackbarProvider>
      </ApolloProvider>
    </div>
  );
}

const ContentApp = () => {
  const { theming } = React.useContext(MainContext);
  const theme = createTheme(theming === "dark" ? dark : light);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
