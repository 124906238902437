import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Done } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../../../Context/MainCtx";
import { RecoveryUser } from "../../graphql/mutations";

export default function RecuperarOld({ user, refetch, handleClose }) {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [recoveryUser] = useMutation(RecoveryUser);

  const handleSave = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const res = await recoveryUser({
        variables: {
          idUser: user._id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: res.data.recoveryUser.message,
        variant: "success",
      });
      refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Button
      variant="contained"
      color="info"
      size="small"
      disableElevation
      sx={{ textTransform: "none" }}
      onClick={handleSave}
      disabled={loading}
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : <Done />
      }
    >
      Recuperar
    </Button>
  );
}
