import { gql } from "@apollo/client";

export const GetUserData = gql`
  query getUser {
    getUser {
      _id
      nombre
      apellidoPaterno
      apellidoMaterno
      fechaNacimiento
      telefono
      direccion
      imagenesID {
        key
        url
      }
      email
      paypalAccount
      institucionBancaria
      clabeBanco
      tipoClabeBanco
    }
  }
`;
