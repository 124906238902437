import React from "react";
import { Box, Stack } from "@mui/material";
import ApuestasCtxProvider, {
  ApuestasContext,
} from "./Context/ApuestasContext";
import SearchComponent from "./Actions/Search";
import Tableapuestas from "./Table";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import FilterChips from "../../../Components/FilterChips";

export default function ApuestasRealizadasManager() {
  return (
    <Box>
      <ApuestasCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          sx={{ pb: 1, mt: {xs: 1, md: 0} }}
          bgcolor="background.paper"
          justifyContent="flex-end"
        >
          <SearchComponent />
          <Export />
        </Stack>
        <FilterChips context={ApuestasContext} sx={{ display: "flex" }} />
        <RenderTable />
      </ApuestasCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { apuestasQuery } = React.useContext(ApuestasContext);

  if (apuestasQuery.loading) return <CustomLoader />;
  if (apuestasQuery.error)
    return <ErrorMessage message={apuestasQuery.error.message} />;

  return <Tableapuestas />;
};
