import { gql } from "@apollo/client";

export const UpdatePolicies = gql`
  mutation updatePolicies($politicas: String) {
    updatePolicies(politicas: $politicas) {
      message
    }
  }
`;
export const UpdateTerminos = gql`
  mutation updateTerminos($terminos: String) {
    updateTerminos(terminos: $terminos) {
      message
    }
  }
`;
export const UpdateAbout = gql`
  mutation updateAbout($about: String) {
    updateAbout(about: $about) {
      message
    }
  }
`;