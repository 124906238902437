import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../../Components/ExcelExportButton";
import { CharreadaContext } from "../Context/CharreadaContext";
import { GetCharreadas } from "../graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    const { nombre } = res;
    dataFormat.push({nombre});
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { charreadasQuery } = React.useContext(CharreadaContext);
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const excelData = formatTable(charreadasQuery.data?.getCharreadas?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getCharreadas = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetCharreadas,
        variables: {
          filter: "",
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getCharreadas.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name="Suerte charra - charreadas"
        getQueryData={getCharreadas}
        loading={loading}
        importModel="charreadas"
        refetchModel={charreadasQuery.refetch}
        onlyUpload
      />
    </div>
  );
}
