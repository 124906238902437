import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ArrowBack, Add, Edit } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { EstadosContext } from "../Context/EstadosContext";
import { CreateState, UpdateState } from "../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CustomButton } from "../../../../../Components/CustomMuiComponents";
import axios from "axios";
import { useDebounce } from "use-debounce";

const initialState = {
  estado: "",
  pais: "",
};

export default function CrearEstado({data = initialState, edit = false}) {
  const { statesQuery } = React.useContext(EstadosContext);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [createState] = useMutation(CreateState);
  const [updateState] = useMutation(UpdateState);
  const [open, setOpen] = React.useState(false);
  const [estadoData, setEstadoData] = React.useState(initialState);

  const handleOpen = () => {
    setOpen(true);
    if(edit)setEstadoData(data);
  };

  const handleClose = () => {
    setOpen(false);
    setEstadoData(initialState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEstadoData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const response = await createState({
        variables: {
          input: estadoData,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createState.message,
        variant: "success",
      });
      statesQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const { _id, ...input } = estadoData;
      const response = await updateState({
        variables: {
          idState: _id,
          input,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateState.message,
        variant: "success",
      });
      statesQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {edit ? (
        <IconButton onClick={handleOpen} size="small">
          <Edit />
        </IconButton>
      ) : (
        <CustomButton
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpen}
          size="small"
          disableElevation
        >
          Nuevo
        </CustomButton>
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "35vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                Nuevo estado
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="form-states"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "80vh" }}>
            {!edit ? (
              <AutocompleteGeonames setEstadoData={setEstadoData} />
            ) : null}
            <form id="form-states" onSubmit={edit ? handleUpdate : handleSave}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Estado
                </Typography>
                <TextField
                  tabIndex={1}
                  fullWidth
                  required
                  name="estado"
                  size="small"
                  value={estadoData.estado}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>País
                </Typography>
                <TextField
                  tabIndex={1}
                  fullWidth
                  required
                  name="pais"
                  size="small"
                  value={estadoData.pais}
                  onChange={handleChange}
                />
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}

const AutocompleteGeonames = ({ setEstadoData }) => {
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [debouncedInput] = useDebounce(inputValue, 500);
  const [loading, setLoading] = React.useState(false);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const fetchCountries = async (searchText) => {
    try {
      if (!searchText) return;
      setLoading(true);
      const response = await axios.get(
        `http://api.geonames.org/searchJSON?q=${searchText}}&featureClass=A&maxRows=20&username=aldochagollan`
      );
      const countries = response.data.geonames;
      setOptions(countries);
    } catch (error) {
      console.error("Error al buscar países:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchCountries(debouncedInput);
  }, [debouncedInput]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      onInputChange={handleInputChange}
      size="small"
      sx={{ my: 1 }}
      renderInput={(params) => (
        <TextField {...params} label="Buscar país" variant="outlined" />
      )}
      onChange={(event, value) => {
        setEstadoData({
          estado: value?.name || "",
          pais: value?.countryName || "",
        });
      }}
      inputValue={inputValue}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderOption={(props, option) => (
        <li {...props} key={props.id}>
          {`${option.name}, ${option.countryName}`}
        </li>
      )}
    />
  );
};
