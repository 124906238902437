import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { formatoFechaCorta } from "../../../../../Config/reuserFunctions";
import UpdateFilial from "../Actions/Update";
import DeleteFilial from "../Actions/Delete";

export default function FilialTableBody({ data }) {
  return (
    <TableBody>
      {data.map((res) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{ height: "48px", cursor: "pointer" }}
          >
            <TableCell>{res.nombre}</TableCell>
            <TableCell>{res.telefono}</TableCell>
            <TableCell>{res.email}</TableCell>
            <TableCell>{formatoFechaCorta(res.createdAt)}</TableCell>
            <TableCell>
              <UpdateFilial data={res} edit />
            </TableCell>
            <TableCell>
              <DeleteFilial data={res} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
