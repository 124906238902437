import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ReplyUser from "../Actions/Reply";
import { formatoFechaCorta } from "../../../../Config/reuserFunctions";
import { blue } from "@mui/material/colors";
import AsReadMark from "../Actions/AsRead";

export default function EmailsTableBody({ data, shadows }) {
  return (
    <TableBody>
      {data.map((res) => {
        const borderLeft = !res.read ? `5px solid ${blue[500]}` : "none";
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={res._id}
            sx={{ height: "48px" }}
          >
            <TableCell sx={{ borderLeft }}>
              <AsReadMark data={res} />
            </TableCell>
            <TableCell>{res.email}</TableCell>
            <TableCell>{res.asunto}</TableCell>
            <TableCell>{res.mensaje}</TableCell>
            <TableCell>{formatoFechaCorta(res.fecha)}</TableCell>
            <TableCell
              align="right"
              sx={{
                position: "sticky",
                right: 0,
                bgcolor: (theme) => theme.palette.background.paper,
              }}
            >
              <ReplyUser data={res} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
