import React, { useContext } from "react";
import Box from "@mui/material/Box";
import UserForm from "./UserForm";
import { GetUserData } from "./gql/queries";
import { MainContext } from "../../../Context/MainCtx";
import CustomLoader from "../../../Components/CustomLoader";
import { useQuery } from "@apollo/client";
import ErrorMessage from "../../../Components/ErrorMessage";
import { Navigate } from "react-router-dom";
import { Container, Paper } from "@mui/material";

function Perfil() {
  const { tokenDecoded, tokenSesion, setErrors } = useContext(MainContext);

  const { data, loading, error, refetch } = useQuery(GetUserData, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    if(error)setErrors({...error, funcion: "getUser"})
  }, [error, setErrors])

  if (!tokenDecoded) return <Navigate to="/" />;
  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage height="90vh" message={error.message} />;

  return (
    <Container maxWidth="md">
      <Paper>
        <Box
          sx={{
            //height: "calc(100vh - 48px)",
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <UserForm data={data} refetch={refetch} />
        </Box>
      </Paper>
    </Container>
  );
}

export default Perfil;
