import { gql } from "@apollo/client";

export const CreateTeam = gql`
  mutation createTeam($input: teamInput) {
    createTeam( input: $input) {
      message
    }
  }
`;

export const UpdateTeam = gql`
  mutation updateTeam($idTeam: ID!, $input: teamInput) {
    updateTeam(idTeam: $idTeam, input: $input) {
      message
    }
  }
`;

export const DeleteTeam = gql`
  mutation deleteTeam($idTeam: ID!) {
    deleteTeam(idTeam: $idTeam) {
      message
    }
  }
`;
