import React from "react";
import ReCaptchaWebComponent from "../ReCaptchaComponent";

export default function ReCaptchaWebView() {

  const handleRecaptchaChange = async (token) => {
    if (token) {
      window.postMessage(JSON.stringify({ token: token, expired: false }));
    }
  };
  const handleRecaptchaExpired = async () => {
    window.postMessage(JSON.stringify({ token: null, expired: true }));
  };

  return (
    <ReCaptchaWebComponent
      onChange={handleRecaptchaChange}
      onExpired={handleRecaptchaExpired}
    />
  );
}
