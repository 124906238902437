import React from "react";
import Box from "@mui/material/Box";
import SignUpForm from "./SignUpForm";
import { MainContext } from "../../../Context/MainCtx";
import { Navigate } from "react-router-dom";

export default function SignUpUser() {
  const { tokenDecoded } = React.useContext(MainContext);

  if (tokenDecoded) return <Navigate to="/" />;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mt: 1,
        minHeight: "calc(100vh - 210px)",
        width: "100%",
      }}
    >
      <SignUpForm />
    </Box>
  );
}
