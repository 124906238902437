import { gql } from "@apollo/client";

export const GetTorneosHome = gql`
  query getTorneosHome($filter: String) {
    getTorneosHome(filter: $filter) {
      tipo
      torneos {
        _id
        nombre
        lugar
        lienzoCharro
        fechaInicio
        fechaFin
        status
        abrev
        tipo
      }
    }
  }
`;
export const GetCharreadasByTorneo = gql`
  query GetCharreadasByTorneo($idTorneo: ID!) {
    getCharreadasByTorneo(idTorneo: $idTorneo) {
      torneo {
        _id
        nombre
        lugar
        lienzoCharro
        fechaInicio
        fechaFin
        abrev
        tipo
        status
      }
      charreadas {
        _id
        nombre
        fechaCharreada
      }
    }
  }
`;
export const GetCompByCharreada = gql`
  query GetCompByCharreada($idTorneo: ID!, $idCharreada: ID!) {
    getCompByCharreada(idTorneo: $idTorneo, idCharreada: $idCharreada) {
      suerte {
        _id
        suerte
      }
      terminado
      competencias {
        _id
        torneo
        charreada
        charro
        charroData {
          _id
          nombre
          equipo
        }
        equipo
        equipoData {
          _id
          nombre
        }
        suerte
        suerteData {
          _id
          suerte
        }
        multiplicador
        momioMin
        momioMax
        status
        noSePresento
      }
    }
  }
`;
export const GetCompetencias = gql`
  query GetCompetenciasByTorneo($idTorneo: ID!) {
    getCompetenciasByTorneo(idTorneo: $idTorneo) {
      torneo {
        _id
        nombre
        lugar
        lienzoCharro
        fechaInicio
        fechaFin
        abrev
        tipo
      }
      competencias {
        _id
        torneo
        torneoData {
          _id
          nombre
          lugar
          lienzoCharro
          fechaInicio
          fechaFin
          abrev
          tipo
        }
        charreada
        charreadaData {
          _id
          nombre
        }
        equipo
        equipoData {
          _id
          nombre
          estado {
            _id
            estado
            pais
          }
          createdAt
        }
        suerte
        suerteData {
          _id
          suerte
        }
        charro
        charroData {
          _id
          nombre
          equipo
          foto
          keyFoto
        }
        multiplicador
        momioMin
        momioMax
        createdAt
      }
    }
  }
`;
export const GetCaculatedMomio = gql`
  query getCalcCompetencia($input: InputCalc) {
    getCalcCompetencia(input: $input) {
      momio
    }
  }
`;
