import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import { MonetizationOn } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";
import { ApuestasContext } from "../Context/ApuestasContext";
import { PagarApuesta } from "../graphql/mutations";
import { DialogContent, IconButton, MenuItem, Select,  } from "@mui/material";
import { METODOS_PAGO, STATUS_APUESTA } from "../../../../Config/constantes";
import { formatoMexico } from "../../../../Config/reuserFunctions";

export default function PagarBet({ data }) {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const { apuestasQuery } = React.useContext(ApuestasContext);
  const [pagarApuesta] = useMutation(PagarApuesta);
  const [open, setOpen] = React.useState(false);
  const [metodoPago, setMetodoPago] = React.useState(METODOS_PAGO.efectivo);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelApuestas = async () => {
    try {
      setLoading(true);
      await pagarApuesta({
        variables: {
          idApuesta: data._id,
          metodoPago
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      apuestasQuery.refetch();
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  if (data.status === STATUS_APUESTA.perdida) {
    return (
      <Typography variant="body2" color="error">
        Perdida
      </Typography>
    );
  }
  if (data.status === STATUS_APUESTA.cancelada) {
    return (
      <Typography variant="body2" color="orangered">
        Cancelada
      </Typography>
    );
  }
  if (data.status !== STATUS_APUESTA.ganada) {
    return (
      <Typography variant="body2" color="gray">
        Pendiente
      </Typography>
    );
  }
  
  if (data.pagada) {
    return (
      <Typography variant="body2" color="green">
        Pagada
      </Typography>
    );
  }

  return (
    <>
      <IconButton color="success" size="small" onClick={handleClick}>
        <MonetizationOn />
      </IconButton>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle>
          Pagar juego
        </DialogTitle>
        <DialogContent>
            <Typography variant="h5" align="center">${formatoMexico(data.totalGanar)}</Typography>
            <Box sx={{my: 1}}>
            <FormControl fullWidth size="small" margin="dense" required>
              <InputLabel id="metodos-select">Método de pago</InputLabel>
              <Select
                labelId="metodos-select"
                value={metodoPago}
                label="Método de pago"
                onChange={(e) => setMetodoPago(e.target.value)}
              >
                {Object.keys(METODOS_PAGO).map((res, index) => (
                  <MenuItem key={index} value={METODOS_PAGO[res]}>
                    {METODOS_PAGO[res]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="inherit"
            size="small"
            onClick={handleClose}
            variant="contained"
            fullWidth
            disableElevation
          >
            Cancelar
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="success"
            size="small"
            onClick={handleCancelApuestas}
            variant="contained"
            fullWidth
            disableElevation
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Pagar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
