import { gql } from "@apollo/client";

export const GetManagersAndSellers = gql`
  query getManagersAndSellers($filter: String, $limit: Int, $page: Int) {
    getManagersAndSellers(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        nombre
        apellidoPaterno
        apellidoMaterno
        fechaNacimiento
        telefono
        direccion
        imagenesID {
          key
          url
        }
        email
        rol
        activo
        encargado
        idEncargado
        paypalAccount
        createdAt
        recovered
        saldo
      }
      totalDocs
      page
      totalPages
      totalBalanceUsers
    }
  }
`;
export const getManagers = gql`
  query getManagers {
    getManagers {
      _id
      nombre
    }
  }
`;
export const GetUserBalance = gql`
  query getUserBalance {
    getUserBalance {
      saldo
    }
  }
`;
export const GetUserOld = gql`
  query getUserOld($email: String) {
    getUserOld(email: $email) {
      _id
      nombre
      email
      rol
      saldo
      activo
    }
  }
`;
