import { gql } from "@apollo/client";

export const GetFiliales = gql`
  query getFiliales($filter: String, $limit: Int, $page: Int) {
    getFiliales(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        nombre
        apellidoPaterno
        apellidoMaterno
        fechaNacimiento
        telefono
        direccion
        imagenesID {
          key
          url
        }
        email
        rol
        activo
        createdAt
      }
      totalDocs
      page
      totalPages
    }
  }
`;
