import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Add, Edit } from "@mui/icons-material";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import { CreateFAQ, UpdateFAQ } from "./graphql/mutations";
import { MainContext } from "../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import RichTinyTextEditor from "../../../../Components/CustomEditor/TinyEditor";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

const initialState = { pregunta: "", respuesta: "" };

export default function CreateNewFaq({
  data = initialState,
  edit = false,
  refetch = null,
}) {
  const [open, setOpen] = React.useState(false);
  const [faqData, setFaqData] = React.useState(initialState);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [createFaq] = useMutation(CreateFAQ);
  const [updateFaq] = useMutation(UpdateFAQ);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  const handleClickOpen = () => {
    setOpen(true);
    if (edit) {
      setFaqData(data);
      const parseContent = JSON.parse(data.respuesta);
      const contentState = convertFromRaw(parseContent);
      setEditorState(EditorState.createWithContent(contentState));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFaqData(initialState);
  };

  const handleGetData = (e) => {
    const { name, value } = e.target;
    setFaqData((st) => ({ ...st, [name]: value }));
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const content = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      const response = await createFaq({
        variables: {
          input: {
            pregunta: faqData.pregunta,
            respuesta: content
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createFaq.message,
        variant: "success",
      });
      setLoading(false);
      refetch();
      handleClose();
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const { _id, orden, image, keyImage, ...input } = faqData;
      const content = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      const response = await updateFaq({
        variables: {
          idFaq: _id,
          input: {
            ...input,
            respuesta: content
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateFaq.message,
        variant: "success",
      });
      setLoading(false);
      refetch();
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <React.Fragment>
      {edit ? (
        <IconButton size="small" onClick={handleClickOpen}>
          <Edit />
        </IconButton>
      ) : (
        <Button
          startIcon={<Add />}
          variant="contained"
          size="small"
          disableElevation
          sx={{ textTransform: "none" }}
          onClick={handleClickOpen}
        >
          Nueva
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Nueva pregunta</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              fullWidth
              size="small"
              placeholder="Pregunta"
              margin="dense"
              value={faqData.pregunta}
              onChange={handleGetData}
              name="pregunta"
            />
            <RichTinyTextEditor
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            disableElevation
            sx={{ textTransform: "none" }}
            variant="contained"
            color="inherit"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            size="small"
            disableElevation
            sx={{ textTransform: "none" }}
            variant="contained"
            color="primary"
            onClick={edit ? handleUpdate : handleSave}
            disabled={loading}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
