import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { GetBannersCarousel } from "./gql";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import UploadButton from "./Upload";
import { Typography } from "@mui/material";
import CreateImage from "./Create";

export default function CarouselBanners() {
  const { loading, data, error, refetch } = useQuery(GetBannersCarousel, {
    fetchPolicy: "network-only",
  });
  const [newImg, setNewImg] = React.useState(null);

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Typography variant="h6">Carousel de imagenes</Typography>
        <CreateImage newImg={newImg} setNewImg={setNewImg} refetch={refetch} />
      </Box>
      <Grid container spacing={2}>
        {newImg ? <RenderCardNew data={newImg} /> : null}
        {data.getBannersCarousel.map((res) => (
          <RenderCard key={res._id} data={res} refetch={refetch} />
        ))}
      </Grid>
    </Box>
  );
}

const RenderCard = ({ data, refetch }) => {
  const [preview, setPreview] = React.useState(data.image || "");
  const [file, setFile] = React.useState("");

  return (
    <Grid item xs={12}>
      <Box>
        <Box sx={{display: "flex", justifyContent: "flex-end"}}>
          <UploadButton
            data={data}
            file={file}
            setFile={setFile}
            setPreview={setPreview}
            refetch={refetch}
          />
        </Box>
        <Box
          sx={{
            height: 300,
            backgroundImage: `url(${preview})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      </Box>
    </Grid>
  );
};
const RenderCardNew = ({ data }) => {
  return (
    <Grid item xs={12}>
      <Box>
        <Box
          sx={{
            height: 300,
            backgroundImage: `url(${data})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      </Box>
    </Grid>
  );
};
