import React from "react";
import { useApolloClient } from "@apollo/client";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { RiFileExcel2Line } from "react-icons/ri";
import { exportToCSV } from "react-easy-export";
import { green } from "@mui/material/colors";
import { MainContext } from "../../../../../../Context/MainCtx";
import { formatoFechaHora } from "../../../../../../Config/reuserFunctions";
import { GetMovimientosCompetenciaReport } from "./queries";

const formatTable = (name, data = [], torneo_name = "") => {
  const dataFormat = [];
  data.forEach((res) => {
    const { torneoData, usuarioData } = res;
    const usuario = `${usuarioData?.nombre || ""} ${
      res.usuarioData?.apellidoPaterno || ""
    } ${res.usuarioData?.apellidoMaterno || ""}`;

    dataFormat.push({
      movimiento: res.movimiento,
      torneo: torneoData?.nombre || torneo_name,
      competencia: name,
      usuario,
      status: res.status,
      fecha: formatoFechaHora(res.fecha),
      balanceEnMovimiento: res.balanceEnMovimiento,
      balanceAnterior: res.balanceAnterior,
      nuevoBalance: res.nuevoBalance,
      comentarios: res.comentarios,
    });
  });
  return dataFormat;
};

export default function CompetenciaExcelExport({ filter, competencia, name, dataComp }) {
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const client = useApolloClient();

  /* Queries */
  const getMovimientos = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetMovimientosCompetenciaReport,
        variables: {
          filter,
          competencia,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(name, response.data.getMovimientosCompetenciaReport.docs, dataComp?.torneo_name);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const exportAllData = async () => {
    const response = await getMovimientos();
    if (response)
      exportToCSV(response, `movimientos competencia ${name}.csv`);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="success"
        sx={{ bgcolor: green[600] }}
        disableElevation
        onClick={exportAllData}
      >
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <RiFileExcel2Line style={{ fontSize: 19, margin: "2px 0" }} />
        )}
      </Button>
    </div>
  );
}
