import React from "react";
import { MainContext } from "../../Context/MainCtx";
import { gql, useQuery } from "@apollo/client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router-dom";

const CheckSesion = gql`
  query checkSesion {
    checkSesion {
      message
    }
  }
`;

const GetUser = gql`
  query getUser {
    getUser {
      _id
      nombre
      lastToken
    }
  }
`;

export default function CheckSesionStatus() {
  const { tokenSesion, handleCloseSesion } = React.useContext(MainContext);
  const navigate = useNavigate();

  /* Check sesion status */
  const { error } = useQuery(CheckSesion, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });
  
  const { data } = useQuery(GetUser, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    if(data?.getUser){
      const { lastToken } = data.getUser;
      if(lastToken !== tokenSesion) handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tokenSesion])
  
  const handleClose = () => {
    handleCloseSesion();
    navigate("/signin");
  }

  if (error && error.message === "Sesion Expired") {
    return (
      <Dialog open={true}>
        <DialogTitle>Sesion Expired</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Tu sesión ha expirado, debes volver a iniciar sesión
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button size="small" variant="contained" disableElevation onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return <div />;
}
