import { gql } from "@apollo/client";

export const GetLogErrors = gql`
  query getLogErrors($filter: String, $limit: Int, $page: Int) {
    getLogErrors(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        usuario
        usuarioData {
          nombre
          apellidoPaterno
          apellidoMaterno
        }
        nombre_usuario
        message
        statusCode
        fecha
        detalles
        funcion
      }
      totalDocs
      page
      totalPages
    }
  }
`;
