import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@apollo/client";
import { GetInfoPage } from "../graphql/queries";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import { UpdatePolicies } from "../graphql/mutations";
import { MainContext } from "../../../../Context/MainCtx";
import "draft-js/dist/Draft.css";
import RichTextEditor from "../../../../Components/CustomEditor/Editor";

export default function PoliticasComponent() {
  const dataQuery = useQuery(GetInfoPage, {
    fetchPolicy: "network-only",
  });

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
        <Typography variant="h6">Políticas de privacidad</Typography>
      </Box>
      <Box sx={{mt: 2}}>
        <EditorComp dataQuery={dataQuery} />
      </Box>
    </Box>
  );
}

const EditorComp = ({ dataQuery }) => {
  const { data, loading, error } = dataQuery;
  const [updatePolicies] = useMutation(UpdatePolicies);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  
  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const handleSave = async (politicas) => {
    try {
      if (loading) return;
      const response = await updatePolicies({
        variables: {
          politicas,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updatePolicies.message,
        variant: "success",
      });
      return true
    } catch (err) {
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      return false
    }
  };

  const content = data?.getInfoPage?.politicas || null

  return (
    <RichTextEditor content={content} saveFunction={handleSave} />
  );
};
