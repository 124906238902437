import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../../Components/ExcelExportButton";
import { SuertesContext } from "../Context/SuertesContext";
import { GetSuertes } from "../graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    const { suerte } = res;
    dataFormat.push({suerte});
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { suertesQuery } = React.useContext(SuertesContext);
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const excelData = formatTable(suertesQuery.data?.getSuertes?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getSuertes = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetSuertes,
        variables: {
          filter: "",
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getSuertes.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name="Suerte charra - suertes"
        getQueryData={getSuertes}
        loading={loading}
        importModel="suertes"
        refetchModel={suertesQuery.refetch}
        onlyUpload
      />
    </div>
  );
}
