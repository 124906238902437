import { gql } from "@apollo/client";

export const GetSuertes = gql`
  query getSuertes($filter: String, $limit: Int, $page: Int) {
    getSuertes(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        suerte
      }
      totalDocs
      page
      totalPages
    }
  }
`;
