import { gql } from "@apollo/client";

export const GetCuotas = gql`
  query getCuotas($filter: String, $limit: Int, $page: Int, $type: Int) {
    getCuotas(filter: $filter, limit: $limit, page: $page, type: $type) {
      docs {
        _id
        suerte
        suerteData {
          _id
          suerte
        }
        cuota
        typeCuota
      }
      totalDocs
      page
      totalPages
    }
  }
`;
