import React from "react";
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";
import { Autoplay, Pagination } from "swiper/modules";
//images
import { GetBannersCarousel } from "../../Dashboard/Settings/Banners/CarouselBanners/gql";
import { useQuery } from "@apollo/client";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";

export default function Banner() {
  const { loading, data, error } = useQuery(GetBannersCarousel);

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  return (
    <Box sx={{ height: { xs: "10vh", md: "52vh" } }}>
      <Swiper
        className="mySwiper"
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination]}
      >
        {data.getBannersCarousel.map((res, index) => (
          <SwiperSlide
            key={index}
            style={{
              backgroundImage: `url(${res.image})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
        ))}
      </Swiper>
    </Box>
  );
}
