import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../Context/MainCtx";
import { CreateMessage } from "./gql/mutations";
import { Email } from "@mui/icons-material";

const initial = {
  email: "",
  asunto: "",
  mensaje: "",
};

const asuntos = [
  "Problemas con mi cuenta",
  "Saldos / Cuentas",
  "Error(es) en la aplicación",
  "Comentarios / Quejas / Sugerencias",
];

export default function Contacto() {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(initial);
  const { email, asunto, mensaje } = data;
  const navigate = useNavigate();
  const [createMessage] = useMutation(CreateMessage);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await createMessage({
        variables: {
          input: {
            email,
            asunto,
            mensaje,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      snackMessage({
        message: response.data.createMessage.message,
        variant: "success",
      });
      navigate("/");
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        minHeight: "calc(100vh - 210px)",
        mt: 3,
        width: "100%",
      }}
    >
      <Box sx={{ width: 370 }}>
        <Paper sx={{ p: 2 }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" align="center">
              <b>¡Queremos ayudarte!</b>
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Correo electrónico"
              variant="outlined"
              margin="dense"
              required
              size="small"
              type="email"
              value={email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <FormControl fullWidth size="small" margin="dense" required>
              <InputLabel id="asunto-select">Asunto</InputLabel>
              <Select
                labelId="asunto-select"
                value={asunto}
                label="Asunto"
                onChange={(e) => setData({ ...data, asunto: e.target.value })}
              >
                {asuntos.map((res, index) => (
                  <MenuItem key={index} value={res}>
                    {res}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Mensaje"
              variant="outlined"
              margin="dense"
              required
              size="small"
              value={mensaje}
              onChange={(e) => setData({ ...data, mensaje: e.target.value })}
              multiline
              rows={4}
            />

            <Button
              sx={{ mt: 1 }}
              fullWidth
              variant="contained"
              disableElevation
              type="submit"
              disabled={loading}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Email />
                )
              }
            >
              Enviar
            </Button>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}
