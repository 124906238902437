import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { Today } from "@mui/icons-material";
import { Box } from "@mui/material";
import DateTimeInput from "../../../../../Components/DateTimeInput";

export default function FilterDate() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [classN, setClassN] = React.useState("button-filter");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setClassN("");
  };
  const handleClose = () => {
    setAnchorEl(null);
    setClassN("button-filter");
  };

  return (
    <>
      <IconButton
        id="filter-date"
        aria-controls={open ? "filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        className={classN}
        sx={{
          display: open ? "inline-flex!important" : "none",
          color: (theme) => theme.palette.action.disabled,
          padding: 0,
        }}
      >
        <Today sx={{ fontSize: 20 }} />
      </IconButton>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "filter-date",
        }}
      >
        <Box
          sx={{
            p: 1,
            width: 250,
          }}
        >
          <DateTimeInput
            name="fechaInicio"
            //onChange={handleChangeDate}
            //value={torneo.fechaInicio
          />
        </Box>
      </Menu>
    </>
  );
}
