import { gql } from "@apollo/client";

export const GetBlacklist = gql`
  query getBlacklist($filter: String, $limit: Int, $page: Int) {
    getBlacklist(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        usuario
        nombre
        email
        motivo
        fecha
      }
      totalDocs
      page
      totalPages
    }
  }
`;
export const RemoveUserBlacklist = gql`
  mutation removeUserBlacklist($userBlacklist: ID!) {
    removeUserBlacklist(userBlacklist: $userBlacklist) {
      message
    }
  }
`;