import * as React from "react";
import { MainContext } from "../../../../../Context/MainCtx";
import { useQuery } from "@apollo/client";
import { ROLES } from "../../../../../Config/constantes";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getManagers } from "../graphql/queries";

export default function InputManager({ dataUser, setDataUser }) {
  const { tokenSesion } = React.useContext(MainContext);

  const { loading, data, error } = useQuery(getManagers, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  if (error || loading) {
    return (
      <FormControl fullWidth size="small" margin="dense">
        <InputLabel id="encargado-select">
          <span style={{ color: "red" }}>*</span>Asignar a
        </InputLabel>
        <Select labelId="encargado-select" value="error" label="Encargado">
          <MenuItem value="error">...</MenuItem>
        </Select>
      </FormControl>
    );
  }

  const managers = data?.getManagers || [];

  return (
    <FormControl
      fullWidth
      size="small"
      margin="dense"
      required={dataUser?.rol === ROLES.SELLER}
    >
      <InputLabel id="encargado-select">Asignar a</InputLabel>
      <Select
        labelId="encargado-select"
        value={dataUser?.encargado}
        label="Encargado"
        onChange={(_, child) => {
          const { data } = child.props;
          setDataUser({
            ...dataUser,
            encargado: data.nombre,
            idEncargado: data._id,
          });
        }}
      >
        {managers.map((res, index) => (
          <MenuItem key={index} value={res.nombre} data={res}>
            {res.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
