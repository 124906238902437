import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Faq from "./Faq";
import PoliticasComponent from "./Politicas";
import TerminosComponent from "./Terminos";
import AboutComponent from "./About";
import { Container } from "@mui/material";
import TutorialComponent from "./Tutorial";
import Banners from "./Banners";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      sx={{ flexGrow: 1 }}
    >
      {value === index && <Container maxWidth="lg">{children}</Container>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function SettingsFoot() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", display: "flex" }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider", minWidth: 150 }}
      >
        <Tab
          sx={{ textTransform: "none" }}
          label="Términos"
          {...a11yProps(0)}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label="Políticas"
          {...a11yProps(1)}
        />
        <Tab sx={{ textTransform: "none" }} label="FAQ" {...a11yProps(2)} />
        <Tab
          sx={{ textTransform: "none" }}
          label="Acerca de"
          {...a11yProps(3)}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label="Como jugar"
          {...a11yProps(4)}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label="Banners"
          {...a11yProps(5)}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <TerminosComponent />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PoliticasComponent />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Faq />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AboutComponent />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TutorialComponent />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Banners />
      </TabPanel>
    </Box>
  );
}
