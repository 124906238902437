import { gql } from "@apollo/client";

export const CancelBets = gql`
  mutation cancelarApuestas($apuestas: [cancelApuestaInput]) {
    cancelarApuestas(apuestas: $apuestas) {
      message
    }
  }
`;
export const DeleteGames = gql`
  mutation deleteTestGames($users: [String]) {
    deleteTestGames(users: $users) {
      message
    }
  }
`;