import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Close } from "@mui/icons-material";
import { MainContext } from "../../../../../../Context/MainCtx";
import { GetMovimientosCompetenciaReport } from "./queries";
import TableMovimientosApuesta from "./Table";
import CustomLoader from "../../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import FilterChips from "../FilterChips";
import CompetenciaExcelExport from "./CompetenciaExcelExport";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialFilter = {
  fechaInicio: null,
  fechaFin: null,
  status: "",
  movimiento: "",
};

export default function CompetenciaDetail({ idCompetencia, competenciaData, data }) {
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState(initialFilter);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });

  if(!competenciaData) return null
  const { charreadaData, suerteData, equipoData, charroData } = competenciaData;

  const isEquipo = suerteData.suerte?.toLowerCase() === "equipo" && true;
  let name = "";
  if (isEquipo) {
    name = `${charreadaData?.nombre}, ${equipoData?.nombre} (${suerteData?.suerte})`;
  } else {
    name = `${charreadaData?.nombre}, ${charroData?.nombre} (${suerteData?.suerte})`;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        size="small"
        sx={{ fontSize: 12, textTransform: "none", py: 0 }}
        onClick={handleClickOpen}
        color="info"
      >
        {data.full_description || name}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          sx: {
            height: "90vh",
          },
        }}
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{ flexGrow: 1 }}
              variant="h6"
            >{`Todos los movimientos de ${data.full_description || name}`}</Typography>
            <CompetenciaExcelExport
              filter={filter}
              competencia={idCompetencia}
              name={data.full_description || name}
              dataComp={data}
            />
            <Button
              size="small"
              variant="contained"
              disableElevation
              onClick={handleClose}
              sx={{ textTransform: "none" }}
              startIcon={<Close />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
            <Box>
              <Typography>
                Charreada: <b>{` ${charreadaData?.nombre || data.charreada_name}`}</b>
              </Typography>
              <Typography>
                Suerte: <b>{` ${suerteData?.suerte || data.suerte_name}`}</b>{" "}
              </Typography>
            </Box>
            <Box>
              {isEquipo ? (
                <Typography>
                  Equipo <b>{equipoData?.nombre || data.equipo_name}</b>
                </Typography> 
              ) : (
                <Typography>
                  Charro <b>{charroData?.nombre || data.charro_name}</b>
                </Typography>
              )}
            </Box>
          </Box>
          <FilterChips filter={filter} setFilter={setFilter} />
          <RenderTable
            competencia={idCompetencia}
            setPageConfig={setPageConfig}
            pageConfig={pageConfig}
            filter={filter}
            setFilter={setFilter}
            dataComp={data}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const RenderTable = ({
  competencia,
  setPageConfig,
  pageConfig,
  filter,
  setFilter,
  dataComp,
}) => {
  const { tokenSesion } = React.useContext(MainContext);
  const { page, limit } = pageConfig;

  const query = useQuery(GetMovimientosCompetenciaReport, {
    variables: {
      filter,
      competencia,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const { loading, data, error } = query;
  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const datos = data?.getMovimientosCompetenciaReport;

  return (
    <TableMovimientosApuesta
      query={datos}
      setPageConfig={setPageConfig}
      pageConfig={pageConfig}
      filter={filter}
      setFilter={setFilter}
      dataComp={dataComp}
    />
  );
};
