import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useReactToPrint } from "react-to-print";
import { Box, IconButton, Typography } from "@mui/material";
import {
  formatoFechaHora,
  formatoMexico,
} from "../../../../Config/reuserFunctions";
import { Print } from "@mui/icons-material";
import SCharraIcon from "../../../../Icons/ScharraIcon";
import { MainContext } from "../../../../Context/MainCtx";

export default function ReprintTicket({ data }) {
  const componentRef = React.useRef();
  const [open, setOpen] = React.useState();
  const { tokenDecoded } = React.useContext(MainContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => handleClose(),
  });

  const isTeam = data.suerteData?.suerte.toLowerCase() === "equipo";

  const ticket = {
    torneo: data?.torneoData?.nombre,
    charreada: data?.charreadaData?.nombre,
    equipo: data?.equipoData?.nombre,
    suerte: data?.suerteData?.suerte,
    charro: data?.charroData?.nombre,
    apuesta: data?.apuesta,
    totalGanar: data?.totalGanar,
    momio: data?.momio,
    fecha: data?.fecha,
    ticket: data?.ticket,
    formaPago: data?.formaPago,
  };

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClickOpen}>
        <Print />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: "center" }}>Ticket</DialogTitle>
        <DialogContent>
          <Box
            ref={componentRef}
            style={{ width: "58mm", margin: "auto", pb: 2 }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <SCharraIcon color="black" />
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                folio
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {ticket?.ticket}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                Torneo
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {ticket?.torneo}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {isTeam ? "Equipo" : "Charro"}
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {isTeam ? ticket?.equipo : ticket?.charro}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                Charreada
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {ticket?.charreada}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                Suerte
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {ticket?.suerte}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                Forma de pago
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {ticket?.formaPago}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                Juego
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {formatoMexico(ticket?.apuesta)}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                Momio
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {ticket?.momio}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                Atendido por
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {tokenDecoded.nombre}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                Fecha
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                {formatoFechaHora(ticket?.fecha)}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 14, lineHeight: 1 }}>
                ¡SUERTE!
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", mb: 1 }}>
              <Typography sx={{ fontSize: 12, lineHeight: 1 }}>
                Conserve este ticket para poder reclamar su premio
              </Typography>
            </Box>
            <Box sx={{ textAlign: "justify", my: 2 }}>
              <Typography sx={{lineHeight: 1, fontSize: 10}}>
                1. Los momios son dinámicos, y se pagara la recompensa con el
                momio reflejado en tu ticket, hasta cerrar las competencias.
              </Typography>
              <Typography sx={{lineHeight: 1, fontSize: 10}}>
                2. Los juegos de manera física: Podrá reclamar su recompensa
                únicamente el/los días que dure el torneo o evento
              </Typography>
              <Typography sx={{lineHeight: 1, fontSize: 10}}>
                3. Una vez expedido el ticket no habrá cambios ni devoluciones
              </Typography>
              <Typography sx={{lineHeight: 1, fontSize: 10}}>
                4. Los resultados de las competencias, así como el orden y
                nombre de los competidores son responsabilidad del Comité
                Organizador del evento.
              </Typography>
              <Typography sx={{lineHeight: 1, fontSize: 10}}>
                5. Los criterios de desempate se muestran en suertecharra.com
              </Typography>
            </Box>
            <Typography sx={{lineHeight: 1, fontSize: 10}}>
              Consulta términos y condiciones en suertecharra.com
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
          <Button onClick={handlePrint} autoFocus variant="contained">
            Imprimir Ticket
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
