import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Close, FilterAltOutlined, History } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { GetMovimientosUserReport } from "./queries";
import TableMovimientosUser from "./Table";
import CustomLoader from "../../../../../Components/CustomLoader";
import ErrorMessage from "../../../../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import FilterChips from "../FilterChips";
import UserExcelExport from "./UserExport";
import { IconButton } from "@mui/material";
import { formatoMexico } from "../../../../../Config/reuserFunctions";
import { TIPO_MOVIMIENTO } from "../../../../../Config/constantes";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialFilter = {
  fechaInicio: null,
  fechaFin: null,
  status: "",
  movimiento: "",
};

export default function MovsDetail({ idUser, usuarioData }) {
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState(initialFilter);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton size="small" onClick={handleClickOpen}>
        <History />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          sx: {
            height: "90vh",
          },
        }}
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography sx={{ flexGrow: 1 }} variant="h6">
              {`Movimientos de `}{" "}
              <b>{`${usuarioData.nombre} ${usuarioData.apellidoPaterno} ${usuarioData.apellidoMaterno}`}</b>
            </Typography>
            <UserExcelExport filter={filter} usuario={idUser} />
            <Button
              size="small"
              variant="contained"
              disableElevation
              onClick={handleClose}
              sx={{ textTransform: "none" }}
              startIcon={<Close />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
            <Button
              size="small"
              variant="outlined"
              color={
                filter.movimiento === TIPO_MOVIMIENTO.compra
                  ? "primary"
                  : "inherit"
              }
              disableElevation
              onClick={() =>
                !filter.movimiento
                  ? setFilter((st) => ({
                      ...st,
                      movimiento: TIPO_MOVIMIENTO.compra,
                    }))
                  : setFilter(initialFilter)
              }
              sx={{ textTransform: "none" }}
              endIcon={<FilterAltOutlined />}
            >
              <Typography>
                {`Toros Comprados: `}
                <b>{formatoMexico(usuarioData?.totalTorosAdquiridos)}</b>
              </Typography>
            </Button>
            <Button
              size="small"
              variant="outlined"
              color={
                filter.movimiento === TIPO_MOVIMIENTO.retiro
                  ? "primary"
                  : "inherit"
              }
              disableElevation
              onClick={() =>
                !filter.movimiento
                  ? setFilter((st) => ({
                      ...st,
                      movimiento: TIPO_MOVIMIENTO.retiro,
                    }))
                  : setFilter(initialFilter)
              }
              sx={{ textTransform: "none" }}
              endIcon={<FilterAltOutlined />}
            >
              <Typography>
                {`Retiros: `}
                <b>{formatoMexico(usuarioData?.totalRetiros)}</b>
              </Typography>
            </Button>
            <Typography>
              Balance: <b>{formatoMexico(usuarioData?.saldo)}</b>
            </Typography>
            <Typography>
              SC: <b>{formatoMexico(usuarioData?.totalTorosAdquiridos - usuarioData?.saldo - usuarioData?.totalRetiros)}</b>
            </Typography>
          </Box>
          <FilterChips filter={filter} setFilter={setFilter} />
          <RenderTable
            usuario={idUser}
            setPageConfig={setPageConfig}
            pageConfig={pageConfig}
            filter={filter}
            setFilter={setFilter}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const RenderTable = ({
  usuario,
  setPageConfig,
  pageConfig,
  filter,
  setFilter,
}) => {
  const { tokenSesion } = React.useContext(MainContext);
  const { page, limit } = pageConfig;

  const query = useQuery(GetMovimientosUserReport, {
    variables: {
      filter,
      usuario,
      page,
      limit,
    },
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const { loading, data, error } = query;

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  const datos = data?.getMovimientosUserReport;

  return (
    <TableMovimientosUser
      query={datos}
      setPageConfig={setPageConfig}
      pageConfig={pageConfig}
      filter={filter}
      setFilter={setFilter}
    />
  );
};
