import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import { Add, ArrowBack, Edit } from "@mui/icons-material";
import { Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { FilialesContext } from "../Context/FilialesContext";
import { CreateFilial, UpdateUserAdmin } from "../graphql/mutations";
import { useMutation } from "@apollo/client";

export default function UpdateFilial({ data, edit = false }) {
  const { queryRefetch } = React.useContext(FilialesContext);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [updateUserDashboard] = useMutation(UpdateUserAdmin);
  const [createFilial] = useMutation(CreateFilial);
  const [open, setOpen] = React.useState(false);
  const [dataUser, setDataUser] = React.useState(null);

  const handleOpen = () => {
    if (edit && data) setDataUser(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDataUser(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataUser((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      await createFilial({
        variables: {
          input: {
            ...dataUser,
            saldo: 0,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      queryRefetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };
  const handleUpdate = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      const { createdAt, _id, imagenesID, ...input } = dataUser;

      await updateUserDashboard({
        variables: {
          idUser: _id,
          input: {
            ...input,
            saldo: parseFloat(input.saldo),
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      queryRefetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      {!edit ? (
        <Button
          startIcon={<Add fontSize="small" />}
          onClick={handleOpen}
          size="small"
          disableElevation
          sx={{ textTransform: "none" }}
          variant="contained"
        >
          Nuevo
        </Button>
      ) : (
        <IconButton onClick={handleOpen} size="small">
          <Edit fontSize="small" />
        </IconButton>
      )}
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          componentsProps: {
            backdrop: {
              sx: { background: "rgb(0,0,0, 0.1)" },
            },
          },
        }}
        sx={{
          zIndex: 1200,
        }}
        PaperProps={{
          elevation: 1,
          sx: { width: { xs: "100vw", md: "35vw" }, overflowY: "auto" },
        }}
      >
        <Toolbar variant="dense" />
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" color="gray">
                {edit ? "Editar Filial" : "Nuevo Filial"}
              </Typography>
            </Stack>
            <Button
              size="small"
              variant="contained"
              disableElevation
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              type="submit"
              form="form-user"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <form id="form-user" onSubmit={edit ? handleUpdate : handleSave}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Nombre
                </Typography>
                <TextField
                  fullWidth
                  required
                  name="nombre"
                  size="small"
                  value={dataUser?.nombre}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Dirección
                </Typography>
                <TextField
                  fullWidth
                  name="direccion"
                  size="small"
                  value={dataUser?.direccion}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  Telefono
                </Typography>
                <TextField
                  tabIndex={1}
                  fullWidth
                  name="telefono"
                  size="small"
                  value={dataUser?.telefono}
                  onChange={handleChange}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="body2" color="gray">
                  <span style={{ color: "red" }}>*</span>Correo
                </Typography>
                <TextField
                  fullWidth
                  required
                  name="email"
                  size="small"
                  value={dataUser?.email}
                  onChange={handleChange}
                />
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Drawer>
    </div>
  );
}
