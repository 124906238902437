import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ManageAccounts from "@mui/icons-material/ManageAccounts";
import Logout from "@mui/icons-material/Logout";
import { MainContext } from "../../Context/MainCtx";
import DarkMode from "../DarkMode";
import { History, CurrencyExchange, Settings, Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import { ROLES } from "../../Config/constantes";

export default function AvatarMenuComponent() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { tokenDecoded, handleCloseSesion } = React.useContext(MainContext);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = (url) => {
    navigate(url);
    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        startIcon={<Person />}
        sx={{textTransform: "none"}}
      >
        {tokenDecoded?.nombre}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: 240,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {tokenDecoded.rol === ROLES.ADMIN ? (
          <div>
            <MenuItem onClick={() => handleRedirect("/admin")}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Panel Admin
            </MenuItem>
            <Divider />
          </div>
        ) : null}
        {tokenDecoded.rol === ROLES.FILIAL ? (
          <div>
            <MenuItem onClick={() => handleRedirect("/filial")}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Panel Solicitudes
            </MenuItem>
            <Divider />
          </div>
        ) : null}
        {tokenDecoded.rol === ROLES.MANAGER || tokenDecoded.rol === ROLES.SELLER ? (
          <div>
            <MenuItem onClick={() => handleRedirect("/seller")}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Panel Vendedores
            </MenuItem>
            <Divider />
          </div>
        ) : null}
        <MenuItem onClick={() => handleRedirect("/perfil")}>
          <ListItemIcon>
            <ManageAccounts fontSize="small" />
          </ListItemIcon>
          Mi cuenta
        </MenuItem>
        <MenuItem onClick={() => handleRedirect("/historial")}>
          <ListItemIcon>
            <History fontSize="small" />
          </ListItemIcon>
          Historial
        </MenuItem>
        <MenuItem onClick={() => handleRedirect("/movimientos")}>
          <ListItemIcon>
            <CurrencyExchange fontSize="small" />
          </ListItemIcon>
          Movimientos
        </MenuItem>
        <DarkMode listMode />
        <MenuItem onClick={handleCloseSesion}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
