import React from "react";
import { Box, Stack } from "@mui/material";
import UserCtxProvider, { UsuariosContext } from "./Context/UsuariosContext";
import SearchComponent from "./Actions/Search";
import UsuariosTable from "./Table";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import SendEmail from "./Actions/SendEmail";

export default function Usuarios() {
  return (
    <Box>
      <UserCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 1, position: { md: "absolute" }, top: 60, right: 24 }}
          bgcolor="background.paper"
        >
          <SearchComponent />
          <SendEmail />
          <Export />
        </Stack>
        <RenderTable />
      </UserCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { queryLoading, queryError } = React.useContext(UsuariosContext);

  if (queryLoading) return <CustomLoader />;
  if (queryError) return <ErrorMessage message={queryError.message} />;

  return <UsuariosTable />;
};
