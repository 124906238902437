import { gql } from "@apollo/client";

export const GetFAQs = gql`
  query getFaqs {
    getFaqs {
      _id
      orden
      pregunta
      respuesta
      image
      keyImage
    }
  }
`;
