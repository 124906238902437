import React from "react";
import { Box, Stack } from "@mui/material";
import SellerCtxProvider, { SellerContext } from "./Context/SellerContext";
import SearchComponent from "./Actions/Search";
import SelleresTable from "./Table";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import Export from "./Actions/Export";

export default function VendedoresAsignados() {

  /* return <div>coming soon</div>
  // eslint-disable-next-line */
  return (
    <Box>
      <SellerCtxProvider>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 1 }}
          bgcolor="background.paper"
        >
          <SearchComponent />
          <Export />
        </Stack>
        <RenderTable />
      </SellerCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { queryLoading, queryError } = React.useContext(SellerContext);

  if (queryLoading) return <CustomLoader />;
  if (queryError) return <ErrorMessage message={queryError.message} />;

  return <SelleresTable />;
};
