import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../../Components/ExcelExportButton";
import { CompetenciaContext } from "../Context/CompetenciaContext";
import { GetCompetencias } from "../graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    const {
      torneoData,
      charreadaData,
      equipoData,
      charroData,
      suerteData,
      createdAt,
      _id,
      ...newData
    } = res;
    dataFormat.push({
      ...newData,
      torneo: torneoData?.nombre || "",
      charreada: charreadaData?.nombre || "",
      equipo: equipoData?.nombre || "",
      charro: charroData?.nombre || "",
      suerte: suerteData?.suerte || "",
    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { compQuery, filter } = React.useContext(CompetenciaContext);
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const excelData = formatTable(compQuery.data?.getCompetencias?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getCompetencias = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetCompetencias,
        variables: {
          filter,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getCompetencias.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name="Suerte charra - Competencias"
        getQueryData={getCompetencias}
        loading={loading}
        importModel="competencias"
        refetchModel={compQuery.refetch}
        onlyUpload
      />
    </div>
  );
}
