import { gql } from "@apollo/client";

export const CreateCharreada = gql`
  mutation createCharreada($nombre: String!) {
    createCharreada(nombre: $nombre) {
      message
    }
  }
`;

export const UpdateCharreada = gql`
  mutation updateCharreada($idCharreada: ID!, $nombre: String!) {
    updateCharreada(idCharreada: $idCharreada, nombre: $nombre) {
      message
    }
  }
`;

export const DeleteCharreada = gql`
  mutation deleteCharreada($idCharreada: ID!) {
    deleteCharreada(idCharreada: $idCharreada) {
      message
    }
  }
`;
