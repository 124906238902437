import { gql } from "@apollo/client";

export const CreateTorneo = gql`
  mutation createTorneo($input: torneoInput) {
    createTorneo(input: $input) {
      message
    }
  }
`;

export const UpdateTorneo = gql`
  mutation updateTorneo($idTorneo: ID!, $input: torneoInput) {
    updateTorneo(idTorneo: $idTorneo, input: $input) {
      message
    }
  }
`;
export const UpdateStatusTorneo = gql`
  mutation updateStatusTorneo($idTorneo: ID!, $status: String) {
    updateStatusTorneo(idTorneo: $idTorneo, status: $status) {
      message
    }
  }
`;
export const UpdateTipoTorneo = gql`
  mutation updateTipoTorneo($idTorneo: ID!, $tipo: String) {
    updateTipoTorneo(idTorneo: $idTorneo, tipo: $tipo) {
      message
    }
  }
`;

export const DeleteTorneo = gql`
  mutation deleteTorneo($idTorneo: ID!) {
    deleteTorneo(idTorneo: $idTorneo) {
      message
    }
  }
`;
export const SendNewTorneoNotification = gql`
  mutation sendNewTorneoNotification($idTorneo: ID!) {
    sendNewTorneoNotification(idTorneo: $idTorneo) {
      message
    }
  }
`;