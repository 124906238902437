import { Box, Typography } from "@mui/material";
import React from "react";
import { STATUS_APUESTA } from "../../../../Config/constantes";
import { getColorStatusApuesta } from "../../../../Config/reuserFunctions";

export default function ColorLeyend({sx}) {
  return (
    <Box sx={{ ...sx, flexGrow: 1, gap: 1 }}>
      {Object.keys(STATUS_APUESTA).map((res, indx) => (
        <Box key={indx} sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Box
            sx={{
              height: 10,
              width: 10,
              borderRadius: "3px",
              bgcolor: getColorStatusApuesta(STATUS_APUESTA[res]),
            }}
          />
          <Typography variant="caption" sx={{ textTransform: "lowercase" }}>
            {STATUS_APUESTA[res]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
