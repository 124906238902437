import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Place,
  Stadium,
  SupervisedUserCircle,
  Casino,
} from "@mui/icons-material";
import Equipos from "./Equipos";
import Suertes from "./Suertes";
import EstadosPaises from "./EstadosPaises";
import { FaHatCowboySide, FaHorseHead } from "react-icons/fa";
import Charros from "./Charros";
import Charreadas from "./Charreadas";
import Torneos from "./Torneos";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-tratos-${index}`}
      aria-labelledby={`tab-tratos-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-tratos-${index}`,
    "aria-controls": `tabpanel-tratos-${index}`,
  };
}

export default function Catalogos() {
  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        //border: "1px solid red",
        height: { xs: `calc(100vh - 120px)`, md: `calc(100vh - 74px)` },
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        sx={{
          width: { xs: "initial", md: "calc(100vw - 400px)" },
          minHeight: "auto",
          "& .MuiButtonBase-root": {
            minHeight: "auto",
            py: { xs: 1.3, md: 0 },
            pb: { md: 1.3 },
            px: 1,
            textTransform: "none",
          },
        }}
      >
        <Tab
          icon={<Stadium sx={{ fontSize: 16 }} />}
          iconPosition="start"
          label="Torneos"
          {...a11yProps(0)}
        />
        <Tab
          icon={<SupervisedUserCircle sx={{ fontSize: 16 }} />}
          iconPosition="start"
          label="Equipos"
          {...a11yProps(1)}
        />
        <Tab
          icon={<FaHatCowboySide />}
          iconPosition="start"
          label="Charros"
          {...a11yProps(2)}
        />
        <Tab
          icon={<FaHorseHead />}
          iconPosition="start"
          label="Charreadas"
          {...a11yProps(3)}
        />
        <Tab
          icon={<Casino sx={{ fontSize: 16 }} />}
          iconPosition="start"
          label="Suertes"
          {...a11yProps(4)}
        />
        <Tab
          icon={<Place sx={{ fontSize: 16 }} />}
          iconPosition="start"
          label="Estados"
          {...a11yProps(5)}
        />
      </Tabs>
      <Box>
        <CustomTabPanel value={value} index={0}>
          <Torneos />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Equipos />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Charros />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Charreadas />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Suertes />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <EstadosPaises />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
