import { gql } from "@apollo/client";

export const GetMessages = gql`
  query GetMessages($filter: filterMessage, $limit: Int, $page: Int) {
    getMessages(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        email
        asunto
        mensaje
        fecha
        read
      }
      totalDocs
      page
      totalPages
    }
  }
`;

export const GetUnreadMessages = gql`
  query Query {
    getMessagesUnread
  }
`;
