import React from "react";
import {
  Editor,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { Box, Button, ButtonGroup } from "@mui/material";

function RichTinyTextEditor({ editorState, setEditorState, height = "50vh" }) {

  const onChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const onToggleInlineStyle = (style) => {
    onChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  const onToggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const handleKeyBinding = (e) => {
    if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.key === "M") {
      return "toggle-monospace";
    }
    return getDefaultKeyBinding(e);
  };

  const handleReturn = (e) => {
    if (e.shiftKey) {
      onChange(RichUtils.insertSoftNewline(editorState));
      return "handled";
    }
    return "not-handled";
  };

  return (
    <Box>
      <Box
        sx={{
          //position: "sticky",
          //top: 48,
          display: "flex",
          gap: 1,
          bgcolor: "background.paper",
          py: 1,
          zIndex: 9,
        }}
      >
        <ButtonGroup size="small" variant="outlined" color="inherit">
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-one")}
          >
            H1
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-two")}
          >
            H2
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-three")}
          >
            H3
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-four")}
          >
            H4
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-five")}
          >
            H5
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("header-six")}
          >
            H6
          </Button>
        </ButtonGroup>
        <ButtonGroup size="small" variant="outlined" color="inherit">
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleInlineStyle("BOLD")}
          >
            <b>Bold</b>
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleInlineStyle("ITALIC")}
          >
            <i>Italic</i>
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleInlineStyle("UNDERLINE")}
          >
            <u>Underline</u>
          </Button>
        </ButtonGroup>
        <ButtonGroup size="small" variant="outlined" color="inherit">
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("unordered-list-item")}
          >
            Ul
          </Button>
          <Button
            sx={{ borderColor: "divider", textTransform: "none" }}
            onClick={() => onToggleBlockType("ordered-list-item")}
          >
            Ol
          </Button>
        </ButtonGroup>
      </Box>
      <Box sx={{ minHeight: height }}>
        <Editor
          editorState={editorState}
          onChange={onChange}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={handleKeyBinding}
          handleReturn={handleReturn}
          keyCommandMap={{
            "toggle-monospace": "toggle-monospace",
          }}
        />
      </Box>
    </Box>
  );
}

export default RichTinyTextEditor;
