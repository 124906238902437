import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { formatoFechaHora, formatoMexico } from "../../../../Config/reuserFunctions";
import CerrarCorte from "../Actions/CerrarCorte";
import { CorteContext } from "../Context/CorteContext";

export default function CorteTableBody({ data }) {
  const { corteQuery } = React.useContext(CorteContext);

  return (
    <>
      <TableBody>
        {data.map((res) => {
          return (
            <TableRow
              role="checkbox"
              tabIndex={-1}
              key={res._id}
              sx={{
                height: "30px",
                "& .cell": {
                  fontSize: 12,
                  py: 0,
                },
              }}
            >
              <TableCell className="cell"><CerrarCorte data={res} refetch={corteQuery.refetch} /></TableCell>
              <TableCell className="cell">{res.status}</TableCell>
              <TableCell className="cell">
                {formatoFechaHora(res?.fechaInicio)}
              </TableCell>
              <TableCell className="cell">
                {formatoFechaHora(res?.fechaFin)}
              </TableCell>
              <TableCell className="cell">${formatoMexico(res?.totalMontoInicial)}</TableCell>
              <TableCell className="cell">${formatoMexico(res?.totalMontoFinal)}</TableCell>
              <TableCell className="cell">${formatoMexico(res?.totalMontoApuestas)}</TableCell>
              <TableCell className="cell">${formatoMexico(res?.saldoDespuesDeVentas)}</TableCell>
              <TableCell className="cell">${formatoMexico(res?.totalMontoApuestasPerdidas)}</TableCell>
              <TableCell className="cell">${formatoMexico(res?.totalMontoApuestasPagadas)}</TableCell>
              <TableCell className="cell">${formatoMexico(res?.utilidad)}</TableCell>
              <TableCell className="cell">{res?.cantidadApuestasTotal}</TableCell>
              <TableCell className="cell">{res?.cantiadadApuestasRealizadas}</TableCell>
              <TableCell className="cell">{res?.cantiadadapuestasCanceladas}</TableCell>
              <TableCell className="cell">{res?.cantidadApuestasGanadas}</TableCell>
              <TableCell className="cell">{res?.cantidadApuestasPerdidas}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
}
