import * as React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import esLocale from "date-fns/locale/es";
import moment from "moment-timezone";

export default function DateInputEditable({
  name = "",
  value = "",
  onChange = null,
  required = false,
  margin = "none",
  label = "",
  disabled = false
}) {
  const [focus, setFocus] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleMouseDown = (event) => {
    // Evita que pierda el foco al hacer clic en el botón
    event.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={esLocale}>
      <DemoContainer components={["DatePicker"]} sx={{padding: 0}}>
        <DemoItem>
          <DatePicker
            inputRef={inputRef}
            label={label}
            value={moment(value)}
            onChange={(val) =>
              onChange(name, moment(val).tz("America/Mexico_City").format())
            }
            slotProps={{
              textField: {
                size: "small",
                required,
                disabled,
                onFocus: () => setFocus(true),
                onBlur: () => setFocus(false),
                sx: {
                  "& .MuiOutlinedInput-root": {
                    cursor: "pointer",
                    "& fieldset": {
                      border: "none",
                    },
                    "& .MuiInputBase-input": {
                      cursor: "pointer",
                      padding: "4px",
                      fontSize: 18,
                    },
                    "& .MuiInputBase-input:focus": {
                      cursor: "text",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #B2BAC2",
                    },
                  },
                },
              },
              inputAdornment: {
                sx: { display: focus ? "flex" : "none", m: 0 },
              },
              openPickerButton: {
                onMouseDown: (e) => handleMouseDown(e),
                sx: { p: 0.5 },
                disabled
              },
            }}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
