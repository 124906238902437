import * as React from "react";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { FilterAlt, Search } from "@mui/icons-material";

export default function FilterStringInput({
  value = "",
  setFilter = null,
  filterName = "",
}) {
  const [open, setOpen] = React.useState(null);
  const [localValue, setLocalValue] = React.useState("");
  const [classN, setClassN] = React.useState("button-filter");

  const handleClick = (event) => {
    event.stopPropagation();
    setOpen(!open);
    setClassN(!open ? "" : "button-filter");
    setLocalValue(value[filterName] || "");
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        className={classN}
        sx={{
          display: open || value[filterName] ? "inline-flex!important" : "none",
          color: (theme) =>
            value[filterName]
              ? theme.palette.primary.main
              : theme.palette.action.disabled,
          padding: 0,
          //mx: 1,
        }}
      >
        <FilterAlt sx={{ fontSize: 18 }} />
      </IconButton>
      {(open || value[filterName]) && (
        <Box sx={{ width: 350 }}>
          <TextField
            size="small"
            fullWidth
            onChange={(e) => {
              e.stopPropagation();
              setLocalValue(e.target.value);
            }}
            value={localValue || value[filterName]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    startIcon={<Search />}
                    size="small"
                    sx={{ textTransform: "none" }}
                    disabled={!localValue}
                    onClick={(e) => {
                      e.stopPropagation();
                      setFilter((state) => ({
                        ...state,
                        [filterName]: localValue,
                      }));
                    }}
                  >
                    Buscar
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </>
  );
}
