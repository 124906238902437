import React from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";

export default function FilterChips({ context = null, sx = null }) {
  const { filter, setFilter } = React.useContext(context);
  const data = Object.keys(filter) || [];

  const handleDelete = (key) => {
    setFilter({
      ...filter,
      [key]: "",
    });
  };

  return (
    <Grid container spacing={1} sx={{ ...sx, alignItems: "center", mb: 1 }}>
      {data.map((key, indx) =>
        filter[key] ? (
          <Grid item>
            <Chip
              key={indx}
              label={filter[key]}
              size="small"
              onDelete={() => handleDelete(key)}
            />
          </Grid>
        ) : null
      )}
    </Grid>
  );
}
