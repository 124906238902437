import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../../Components/ExcelExportButton";
import { ResultadoContext } from "../Context/ResultadoContext";
import { GetResultados } from "../graphql/queries";
import { MainContext } from "../../../../../Context/MainCtx";
import { formatoFechaCorta } from "../../../../../Config/reuserFunctions";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    const {
      torneoData,
      equipoData,
      charroData,
      suerteData,
      fecha,
      _id,
      ...newData
    } = res;
    dataFormat.push({
      ...newData,
      torneo: torneoData?.nombre || "",
      equipo: equipoData?.nombre || "",
      charro: charroData?.nombre || "",
      suerte: suerteData?.suerte || "",
      fecha: formatoFechaCorta(fecha),
    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { resultQuery, filter } = React.useContext(ResultadoContext);
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const excelData = formatTable(resultQuery.data?.getResultados?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getResultados = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetResultados,
        variables: {
          filter,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getResultados.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <ExcelExportButton
      data={excelData}
      name="Suerte charra - Resultados"
      getQueryData={getResultados}
      loading={loading}
      importModel="resultados"
      refetchModel={resultQuery.refetch}
    />
  );
}
