import React from "react";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useMatches, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../Context/MainCtx";
import { TextField } from "@mui/material";
import { Done, Password, Visibility, VisibilityOff } from "@mui/icons-material";
import { ResetPassword } from "./gql/mutations";
import background from "../../../assets/images/background.png";

export default function PasswordReset() {
  const { snackMessage } = React.useContext(MainContext);
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [updatePassword] = useMutation(ResetPassword);
  const [data, setData] = React.useState({
    password: "",
    repeatPassword: "",
  });

  const navigate = useNavigate();
  const matches = useMatches();
  const token = matches && matches.length > 0 ? matches[0].params.token : "";

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (data.password !== data.repeatPassword) {
        snackMessage({
          message: "Las contraseñas no coiciden",
          variant: "error",
        });
        return;
      }
      setLoading(true);
      const response = await updatePassword({
        variables: {
          password: data.password,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });

      setLoading(false);
      snackMessage({
        message: response.data.updatePassword.message,
        variant: "success",
      });
      navigate("/signin");
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        position: "absolute",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Paper sx={{ p: 2, mx: 1, maxWidth: 400 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" align="center">
            <b>Cambio de contraseña</b>
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            placeholder="Escribe una nueva contraseña"
            variant="outlined"
            margin="dense"
            required
            type={show ? "text" : "password"}
            value={data.password}
            onChange={(e) => setData({ ...data, password: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Password />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!show)}>
                    {show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            placeholder="Confirma tu nueva contraseña"
            variant="outlined"
            margin="dense"
            required
            type={show ? "text" : "password"}
            value={data.repeatPassword}
            onChange={(e) =>
              setData({ ...data, repeatPassword: e.target.value })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Password />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShow(!show)}>
                    {show ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            sx={{ mt: 1, textTransform: "none" }}
            fullWidth
            variant="contained"
            disableElevation
            type="submit"
            disabled={loading}
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Done />
              )
            }
          >
            Guardar cambios
          </Button>
        </form>
      </Paper>
    </Box>
  );
}
