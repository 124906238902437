import React from "react";
import { Stack } from "@mui/material";
import MovimeintoCtxProvider, {
  MovimientoContext,
} from "./Context/MovimientoContext";
import TableMovimientos from "./Table";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import FilterChips from "../../../Components/FilterChips";
import CleanFilters from "./Actions/cleanFilter";
import SearchComponent from "./Actions/Search";
import EditarProfile from "../../Filial/EditProfile";
import { MainContext } from "../../../Context/MainCtx";
import { ROLES } from "../../../Config/constantes";
import EliminarMovimientos from "./Actions/EliminarMovimientos";

export default function SolicitudesRetiro() {
  const { tokenDecoded } = React.useContext(MainContext);

  return (
    <MovimeintoCtxProvider>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-end"
        sx={{ mb: 1 }}
        bgcolor="background.paper"
      >
        <SearchComponent />
        <CleanFilters />
        {tokenDecoded.rol === ROLES.ADMIN ? <EliminarMovimientos /> : null}
        {tokenDecoded.rol === ROLES.FILIAL ? <EditarProfile /> : null}
        <Export />
      </Stack>
      <FilterChips context={MovimientoContext} sx={{ display: "flex" }} />
      <RenderTable />
    </MovimeintoCtxProvider>
  );
}

const RenderTable = () => {
  const { movQuery } = React.useContext(MovimientoContext);
  const { loading, error } = movQuery;

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error.message} />;

  return <TableMovimientos />;
};
