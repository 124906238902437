import React from "react";
import Switch from "@mui/material/Switch";
import { useMutation } from "@apollo/client";
import { UpdateStatusUser } from "../graphql/mutations";
import { MainContext } from "../../../../../Context/MainCtx";

export default function ChipStatusUser({ usuario, refetch }) {
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [checked, setChecked] = React.useState(usuario.activo);
  const [loading, setLoading] = React.useState(null);
  const [updateStatusUser] = useMutation(UpdateStatusUser);

  const updateStastus = async (activo) => {
    try {
      setLoading(true);
      const response = await updateStatusUser({
        variables: {
          idUser: usuario._id,
          activo,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setChecked(activo);
      snackMessage({
        message: response.data.updateStatusUser.message,
        variant: "success",
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <Switch
      size="small"
      disabled={loading}
      onChange={(e) => updateStastus(e.target.checked)}
      checked={checked}
      color="info"
    />
  );
}
