import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MainContext } from "../../../Context/MainCtx";
import { DeleteUser } from "./gql/mutations";
import { CircularProgress } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { GetUserBalance } from "../../Dashboard/Auditorias/Usuarios/graphql/queries";
import { formatoMexico } from "../../../Config/reuserFunctions";

export default function DeleteAccount({ idUser }) {
  const [accept, setAccept] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion, handleCloseSesion } = React.useContext(
    MainContext
  );
  const [deleteUser] = useMutation(DeleteUser);
  const [open, setOpen] = React.useState(false);

  const { data } = useQuery(GetUserBalance, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  const balance = data?.getUserBalance?.saldo || 0;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (e) => {
    try {
      setLoading(true);
      const response = await deleteUser({
        variables: {
          idUser,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.deleteUser.message,
        variant: "success",
      });
      setLoading(false);
      handleCloseSesion();
      handleClose();
    } catch (error) {
      //console.log(error);
      setLoading(false);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Alert severity="error" sx={{ mt: 2 }}>
      <AlertTitle>Eliminar cuenta</AlertTitle>
      Al eliminar tu cuenta perderas todos tus datos, esta acción es
      irreversible
      <Box mt={1}>
        <FormControlLabel
          control={
            <Checkbox
              color="warning"
              value={accept}
              onChange={(e) => setAccept(e.target.checked)}
            />
          }
          label="Quiero eliminar mi cuenta"
        />
        <Button
          color="warning"
          size="small"
          variant="contained"
          disableElevation
          onClick={handleClickOpen}
          disabled={!accept || loading}
          startIcon={
            loading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          Eliminar cuenta
        </Button>
        <Dialog open={open} keepMounted onClose={handleClose}>
          <DialogTitle>{"Eliminar mi cuenta"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Esta acción es irreversible, haz click en "Eliminar cuenta" solo
              si eso es lo que deseas. Toda tu información será borrada de
              SuerteCharra.
            </DialogContentText>
            <br />
            {balance ? (
              <DialogContentText>
                <b>
                  No es posible eliminar tu cuenta, ya que tienes{" "}
                  {formatoMexico(balance)} toros en tu cuenta, te recomendamos
                  realizar una solicitud de retiro primero antes de continuar
                  con esta acción.
                </b>
              </DialogContentText>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              onClick={handleDelete}
              color="warning"
              size="small"
              variant="contained"
              disableElevation
              disabled={!accept || loading || balance}
            >
              Eliminar cuenta
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Alert>
  );
}
