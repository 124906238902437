import { gql } from "@apollo/client";

export const CreateApuesta = gql`
  mutation createApuesta($input: apuestaInput) {
    createApuesta(input: $input) {
      message
    }
  }
`;
export const CreateApuestaSeller = gql`
  mutation createApuestaSeller($input: apuestaInput, $metodoPago: String) {
    createApuestaSeller(input: $input, metodoPago: $metodoPago) {
      apuesta
      totalGanar
      momio
      fecha
      ticket
    }
  }
`;
