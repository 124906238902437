import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function LogsTableBody({ data }) {
  return (
    <TableBody>
      {data.map((log) => {
        return (
          <TableRow
            role="checkbox"
            tabIndex={-1}
            key={log._id}
            sx={{ height: "48px" }}
          >
            <TableCell>{log.nombre}</TableCell>
            <TableCell>{log.email}</TableCell>
            <TableCell>{log.rol}</TableCell>
            <TableCell>{log.urlRef}</TableCell>
            <TableCell>{log.ipAddress}</TableCell>
            <TableCell>{log.place}</TableCell>
            <TableCell>{log.device}</TableCell>
            <TableCell>{log.browser}</TableCell>
            <TableCell>{log.detail}</TableCell>
            <TableCell>{log.fecha}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
