import React from "react";
import { MovimientoContext } from "../Context/MovimientoContext";
import { formatoFechaHora } from "../../../../../Config/reuserFunctions";
import Button from "@mui/material/Button";
import { RiFileExcel2Line } from "react-icons/ri";
import { exportToCSV } from "react-easy-export";
import { green } from "@mui/material/colors";
import { TIPO_MOVIMIENTO } from "../../../../../Config/constantes";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    dataFormat.push({
      movimiento: res.movimiento,
      usuario: res.usuarioData?.nombre || "",
      concepto: res.concepto,
      monto: res.monto,
      metodoPago: res.metodoPago,
      status: res.status,
      fecha: formatoFechaHora(res.fecha),
      balanceEnMovimiento: res.balanceEnMovimiento,
      balanceAnterior: res.balanceAnterior,
      nuevoBalance: res.nuevoBalance,
      comentarios: res.comentarios,
      orderID: res.orderID,
      payerID: res.payerID,
      paymentID: res.paymentID,
      paymentSource: res.paymentSource
    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { query } = React.useContext(MovimientoContext);
  const excelData = formatTable(query.data?.docs);

  const exportAllData = async () => {
    if (excelData)
      exportToCSV(excelData, `movimientos ${TIPO_MOVIMIENTO.compra}.csv`);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="success"
        sx={{ bgcolor: green[600] }}
        disableElevation
        onClick={exportAllData}
      >
        <RiFileExcel2Line style={{ fontSize: 19, margin: "2px 0" }} />
      </Button>
    </div>
  );
}
