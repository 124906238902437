import React from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { CreateBannerCarousel } from "./gql";
import { useMutation } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { AddPhotoAlternate, Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";

export default function CreateImage({ setNewImg, refetch }) {
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [createBannerCarousel] = useMutation(CreateBannerCarousel);

  const cargarImagen = (e) => {
    const { files } = e.target;
    setFile(files[0]);
    setNewImg(files[0] ? URL.createObjectURL(files[0]) : "");
  };

  const handleSave = async (e) => {
    try {
      if (!file) return;
      setLoading(true);
      const response = await createBannerCarousel({
        variables: {
          image: file,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.createBannerCarousel.message,
        variant: "success",
      });
      setFile(null);
      setNewImg(null);
      setLoading(false);
      refetch();
    } catch (err) {
      console.log(err.networkError);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Box>
      <>
        <input
          accept="image/jpeg, image/jpg, image/png"
          style={{ display: "none" }}
          id={`banner-carousel`}
          type="file"
          onChange={cargarImagen}
        />
        <label htmlFor={`banner-carousel`}>
          <IconButton component="span">
            <AddPhotoAlternate />
          </IconButton>
        </label>
      </>
      <IconButton disabled={!file || loading} onClick={handleSave}>
        {loading ? <CircularProgress size={20} color="inherit" /> : <Done />}
      </IconButton>
    </Box>
  );
}
