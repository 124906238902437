import { ROLES } from "../../../Config/constantes";

const listItems = (sesion) => {
  let items = [
    {
      title: "Torneos",
      url: "/seller/torneos",
      icon: "Stadium",
    },
    {
      title: "Juegos",
      url: "/seller/juegos",
      icon: "Casino",
    },
    {
      title: "Movimientos",
      url: "/seller/movimientos",
      icon: "Timeline",
    },
    {
      title: "Actividades",
      url: "/seller/actividades",
      icon: "CurrencyExchange",
    },
  ];

  if (sesion && sesion.rol === ROLES.MANAGER)
    items.push({
      title: "Vendedores",
      url: "/seller/vendedores",
      icon: "Groups",
    });

  return items;
};

export { listItems };
