import React from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { gql, useQuery } from "@apollo/client";
import { io } from "socket.io-client";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import { formatoFechaHora } from "../../../Config/reuserFunctions";
import { Link, useMatches } from "react-router-dom";
import { STATUS_TORNEO } from "../../../Config/constantes";
import { MainContext } from "../../../Context/MainCtx";
import { GetTorneosHome } from "../../Home/Torneos/graphql/queries";
import { blueGrey, orange, yellow } from "@mui/material/colors";
import ObtenerCorteCurso from "./IniciarCorte";

const GetCorteEnCurso = gql`
  query getCorteEnCurso($idVendedor: ID) {
    getCorteEnCurso(idVendedor: $idVendedor) {
      _id
      vendedor
    }
  }
`;

export default function TorneosManager() {
  const { tokenSesion, tokenDecoded } = React.useContext(MainContext);

  const { loading, data, error, refetch } = useQuery(GetCorteEnCurso, {
    fetchPolicy: "network-only",
    variables: {
      idVendedor: tokenDecoded._id,
    },
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  if(loading) return <CustomLoader />
  if (error) return <ErrorMessage />;
  if (data.getCorteEnCurso) return <TorneosManagerComponent />;
  return <ObtenerCorteCurso refetch={refetch} />;
}

const TorneosManagerComponent = () => {
  const { setErrors } = React.useContext(MainContext);
  const { loading, data, error, refetch } = useQuery(GetTorneosHome, {
    variables: { filter: "" },
    fetchPolicy: "network-only",
  });
  const matches = useMatches();
  const url = matches[0]?.pathname || "seller";

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("updatedTorneo", () => refetch());
    return () => socket.disconnect();
  }, [refetch]);

  React.useEffect(() => {
    if (error) setErrors({ ...error, funcion: "getTorneosHome" });
  }, [error, setErrors]);

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error?.message} />;

  const torneos = data?.getTorneosHome || [];

  return (
    <Box sx={{ minHeight: "calc(95vh - 48px)" }}>
      <Container>
        <Box mt={2}>
          {torneos.map((res) => (
            <RenderTiposTorneo key={res.tipo} data={res} url={url} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

const RenderTiposTorneo = ({ data, url }) => {
  const { tipo, torneos } = data;

  const GetTextTypeTournament = () => {
    if (tipo === "ORO") {
      return (
        <Typography
          variant="h6"
          sx={{ bgcolor: yellow[100], px: 1, borderRadius: 1 }}
        >
          <b>Torneo {tipo}</b>
        </Typography>
      );
    } else if (tipo === "PLATA") {
      return (
        <Typography
          variant="h6"
          sx={{ bgcolor: blueGrey[50], px: 1, borderRadius: 1 }}
        >
          <b>Torneo {tipo}</b>
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="h6"
          sx={{ bgcolor: orange[100], px: 1, borderRadius: 1 }}
        >
          <b>Torneo {tipo}</b>
        </Typography>
      );
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 1 }}>
      <GetTextTypeTournament />
      <List>
        {torneos.map((res, index) => {
          const pausado = res.status === STATUS_TORNEO.pausado;
          return (
            <Paper key={index} variant="outlined" sx={{ mb: 1 }}>
              <ListItem disablePadding>
                <ListItemButton
                  component={Link}
                  to={`${url}/torneo/${res._id}`}
                >
                  <ListItemText
                    primary={
                      <Typography
                        component="div"
                        color={pausado ? "text.secondary" : "InfoText"}
                      >
                        {pausado ? (
                          <span style={{ color: "red", marginRight: 8 }}>
                            {STATUS_TORNEO.pausado}
                          </span>
                        ) : null}
                        {res.nombre}
                      </Typography>
                    }
                    secondary={`${res.lienzoCharro}, ${
                      res.lugar
                    } - ${formatoFechaHora(res.fechaInicio)}`}
                  />
                </ListItemButton>
              </ListItem>
            </Paper>
          );
        })}
      </List>
    </Container>
  );
};
