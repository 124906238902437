import { gql } from "@apollo/client";

export const GetInfoPage = gql`
  query getInfoPage {
    getInfoPage {
      _id
      terminos
      politicas
      about
      tutorial
    }
  }
`;
