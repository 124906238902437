import React from "react";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import { useMutation } from "@apollo/client";
import { CircularProgress, List, ListItemText } from "@mui/material";
import { UpdateStatusCompetencia } from "../graphql/mutations";
import { MainContext } from "../../../../../Context/MainCtx";
import { getColorStatusProm } from "../../../../../Config/reuserFunctions";
import { STATUS_COMP } from "../../../../../Config/constantes";

export default function ChipStatus({ competencia, refetch }) {
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const open = Boolean(anchorEl);
  const [updateStatusCompetencia] = useMutation(UpdateStatusCompetencia);
  const items = {...STATUS_COMP};
  delete items.ganador;

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateStastus = async (event, status) => {
    try {
      event.stopPropagation();
      handleClose();
      setLoading(true);
      await updateStatusCompetencia({
        variables: {
          idCompetencia: competencia._id,
          status,
        },
        context: {
            headers: {
              Authorization: `Bearer ${tokenSesion}`,
            },
          },
      });
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
      console.log(err.networkError?.result);
    }
  };

  return (
    <>
      <Chip
        id="custom-chip-status"
        aria-controls={open ? "status-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        component={"span"}
        label={competencia.status}
        size="small"
        icon={
          loading ? (
            <CircularProgress sx={{ color: "white!important" }} size={16} />
          ) : null
        }
        sx={{
          mr: 0.5,
          height: 24,
          fontWeight: "bold",
          fontSize: 12,
          width: "100%",
          bgcolor: getColorStatusProm(competencia.status),
          color: theme => theme.palette.getContrastText(getColorStatusProm(competencia.status)),
          "&:hover": {
            bgcolor: getColorStatusProm(competencia.status),
          }
        }}
        onClick={handleClick}
      />
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "custom-chip-status",
        }}
      >
        <List disablePadding dense>
          {Object.keys(items).map((res, index) => (
            <ListItemButton
              key={index}
              onClick={(e) => updateStastus(e, items[res])}
              selected={items[res] === competencia.status}
            >
              <ListItemText>{items[res]}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      </Menu>
    </>
  );
}
