import React from "react";
import { useApolloClient } from "@apollo/client";
import ExcelExportButton from "../../../../Components/ExcelExportButton";
import { ApuestasContext } from "../Context/ApuestasContext";
import { GetApuestasManager } from "../graphql/queries";
import { MainContext } from "../../../../Context/MainCtx";
import { formatoFechaCorta } from "../../../../Config/reuserFunctions";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((res) => {
    const {
      torneoData,
      equipoData,
      charroData,
      charreadaData,
      suerteData,
      usuarioData,
      fecha,
      _id,
      ...newData
    } = res;
    dataFormat.push({
      ...newData,
      torneo: torneoData?.nombre || "",
      charreada: charreadaData?.nombre || "",
      equipo: equipoData?.nombre || "",
      charro: charroData?.nombre || "",
      suerte: suerteData?.suerte || "",
      fecha: formatoFechaCorta(fecha)
    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { apuestasQuery } = React.useContext(ApuestasContext);
  const { tokenSesion, snackMessage } = React.useContext(MainContext);
  const excelData = formatTable(apuestasQuery.data?.getApuestasManager?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getApuestas = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetApuestasManager,
        variables: {
          filter: "",
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getApuestasManager.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name="Suerte charra - Juegos"
        getQueryData={getApuestas}
        loading={loading}
      />
    </div>
  );
}
