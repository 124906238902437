import { gql } from "@apollo/client";

export const GetCharreadas = gql`
  query getCharreadas($filter: String, $limit: Int, $page: Int) {
    getCharreadas(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        nombre
      }
      totalDocs
      page
      totalPages
    }
  }
`;
