import React from "react";
import { Box, Stack } from "@mui/material";
import CuotaCtxProvider, { CuotaContext } from "./Context/CuotaContext";
import SearchComponent from "./Actions/Search";
import TableCuotas from "./Table";
import CustomLoader from "../../../../Components/CustomLoader";
import ErrorMessage from "../../../../Components/ErrorMessage";
import Export from "./Actions/Export";
import CreateCuota from "./Actions/Create";

export default function Cuotas({type = 3}) {
  return (
    <Box>
      <CuotaCtxProvider typeCuota={type}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          sx={{ mb: 1, position: { md: "absolute" }, top: 60, right: 24 }}
          bgcolor="background.paper"
        >
          <SearchComponent />
          <CreateCuota />
          <Export />
        </Stack>
        <RenderTable />
      </CuotaCtxProvider>
    </Box>
  );
}

const RenderTable = () => {
  const { cuotasQuery } = React.useContext(CuotaContext);

  if (cuotasQuery.loading) return <CustomLoader />;
  if (cuotasQuery.error) return <ErrorMessage message={cuotasQuery.error.message} />;

  return <TableCuotas />;
};