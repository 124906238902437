import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { MainContext } from "../Context/MainCtx";

const CreateLogUser = gql`
  mutation createLogUser($input: inputLog) {
    createLogUser(input: $input) {
      message
    }
  }
`;

export default function CreateLogComponent() {
  const { tokenSesion } = React.useContext(MainContext);
  const [path, setPath] = React.useState("");
  const [createLogUser] = useMutation(CreateLogUser);
  const location = useLocation();
  const productionLogs = process.env.REACT_APP_LOGS;

  const getPositionPlace = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`
          );
          const data = await response.json();
          const address = data.address;
          return `${address.town}, ${address.state}, ${address.country}`;
        } catch (error) {
          return "No disponible";
        }
      });
    } else {
      return "No disponible";
    }
  };
  const getIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return "No disponible";
    }
  };
  const getDevice = () => {
    const userAgent = window?.navigator?.userAgent;
    if (!userAgent) return "Other";

    if (/Android/i.test(userAgent)) {
      return "Android";
    } else if (/iPad/i.test(userAgent)) {
      return "iPad iOS";
    } else if (/iPhone/i.test(userAgent)) {
      return "iPhone iOS";
    } else if (/Macintosh/i.test(userAgent)) {
      return "Mac/MacBook iOS";
    } else {
      return "Other";
    }
  };
  const getBrowser = () => {
    const userAgent = window?.navigator?.userAgent;
    if (!userAgent) return "Unknown";

    if (/Chrome/i.test(userAgent)) {
      return "Google Chrome";
    } else if (/Firefox/i.test(userAgent)) {
      return "Mozilla Firefox";
    } else if (/Safari/i.test(userAgent)) {
      return "Apple Safari";
    } else if (/Opera|OPR/i.test(userAgent)) {
      return "Opera";
    } else if (/Edge/i.test(userAgent)) {
      return "Microsoft Edge";
    } else if (/MSIE/i.test(userAgent)) {
      return "Internet Explorer";
    } else {
      return "Unknown";
    }
  };

  React.useEffect(() => {
    setPath(location.pathname);
    if (location.pathname !== path) {
      const handleCreateLog = async () => {
        try {
          if (productionLogs === "false") return;
          if (!tokenSesion) return;
          const place = await getPositionPlace();
          const ipAddress = await getIpAddress();
          const device = getDevice();
          const browser = getBrowser();

          await createLogUser({
            variables: {
              input: {
                urlRef: `${process.env.REACT_APP_URL_DOMAIN}${path}`,
                place,
                ipAddress,
                device,
                browser,
              },
            },
            context: {
              headers: {
                Authorization: `Bearer ${tokenSesion}`,
              },
            },
          });
        } catch (err) {
          console.log(err);
        }
      };
      handleCreateLog();
    }
  }, [path, location, createLogUser, productionLogs, tokenSesion]);

  return null;
}
