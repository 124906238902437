import React from "react";
import Box from "@mui/material/Box";
import LoginForm from "./LoginForm";
import { Navigate } from "react-router-dom";
import { MainContext } from "../../../Context/MainCtx";

export default function LoginUser() {
  const { tokenDecoded } = React.useContext(MainContext);

  if (tokenDecoded) return <Navigate to="/" />;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        minHeight: "calc(100vh - 210px)",
        mt: 3,
        width: "100%",
      }}
    >
      <LoginForm />
    </Box>
  );
}
