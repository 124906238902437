import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { AddPhotoAlternate, Done } from "@mui/icons-material";
import { MainContext } from "../../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { UpdateBannerButton } from "./gql";
import { CircularProgress } from "@mui/material";

export default function UploadButton({data, file, setFile, setPreview, refetch}) {
    const [loading, setLoading] = React.useState(false)
    const { snackMessage, tokenSesion } = React.useContext(MainContext);
    const [updateBannerButton] = useMutation(UpdateBannerButton)

  const cargarImagen = (e) => {
    const { files } = e.target;
    setFile(files[0]);
    setPreview(files[0] ? URL.createObjectURL(files[0]) : "")
  };

  const handleSave = async (e) => {
    try {
      if (!file) return;
      setLoading(true);
      const response = await updateBannerButton({
        variables: {
          idBanner: data._id,
          image: file
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.updateBannerButton.message,
        variant: "success",
      });
      setFile("")
      setLoading(false);
      refetch();
    } catch (err) {
        console.log(err.networkError)
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Box>
      <>
        <input
          accept="image/jpeg, image/jpg, image/png"
          style={{ display: "none" }}
          id={`banner-btn-${data._id}`}
          type="file"
          onChange={cargarImagen}
        />
        <label htmlFor={`banner-btn-${data._id}`}>
          <IconButton
            component="span"
          >
            <AddPhotoAlternate />
          </IconButton>
        </label>
      </>
      <IconButton disabled={!file || loading} onClick={handleSave} >
        {loading ? <CircularProgress size={20} color="inherit" /> : <Done />}
      </IconButton>
    </ Box>
  );
}
