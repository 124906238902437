import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import { Close } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { MainContext } from "../../../../Context/MainCtx";
import { MovimientoContext } from "../Context/MovimientoContext";
import { DeleteSolicitudes } from "../graphql/mutations";

export default function EliminarMovimientos() {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const {
    movQuery,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(MovimientoContext);
  const [deleteSolicitudes] = useMutation(DeleteSolicitudes);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteMany = async () => {
    try {
      if (!itemsSelected.length) return;
      const toDelete = itemsSelected.map((res) => res._id);
      setLoading(true);
      await deleteSolicitudes({
        variables: {
          solicitudes: toDelete,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      setLoading(false);
      movQuery.refetch();
      setItemsSelected([]);
      setIndeterminated(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <>
      <Button
        color="warning"
        size="small"
        variant="contained"
        startIcon={<Close />}
        disableElevation
        disabled={!itemsSelected.length}
        onClick={handleClick}
        sx={{ textTransform: "none" }}
      >
        Eliminar
      </Button>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle>
          Se van a eliminar {itemsSelected.length} solicitudes
        </DialogTitle>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="inherit"
            size="small"
            onClick={handleClose}
            variant="contained"
            fullWidth
            disableElevation
          >
            No, cancelar
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="error"
            size="small"
            onClick={handleDeleteMany}
            variant="contained"
            fullWidth
            disableElevation
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Si, Continuar y Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
