import * as React from "react";
import { DraftsOutlined, Email } from "@mui/icons-material";
import { MainContext } from "../../../../Context/MainCtx";
import { useMutation } from "@apollo/client";
import { CircularProgress, IconButton } from "@mui/material";
import { EmailsContext } from "../Context/EmailsContext";
import { MarkAsReadUnread } from "../graphql/mutations";

export default function AsReadMark({ data }) {
  const [loading, setLoading] = React.useState(false);
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const { emailQuery } = React.useContext(EmailsContext);
  const [markAsReadUnread] = useMutation(MarkAsReadUnread);
  const unread = !data.read;

  const handleReply = async () => {
    try {
      setLoading(true);
      const response = await markAsReadUnread({
        variables: {
          idMessage: data._id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: response.data.markAsReadUnread.message,
        variant: "success",
      });
      emailQuery.refetch();
      setLoading(false);
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <IconButton size="small" onClick={() => handleReply()}>
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : unread ? (
          <Email fontSize="small" />
        ) : (
          <DraftsOutlined fontSize="small" />
        )}
      </IconButton>
    </div>
  );
}
