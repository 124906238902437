import { gql } from "@apollo/client";

export const GetTorneos = gql`
  query getTorneos($filter: String, $limit: Int, $page: Int) {
    getTorneos(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        nombre
        lugar
        lienzoCharro
        fechaInicio
        fechaFin
        status
        abrev
        tipo
        createdAt
      }
      totalDocs
      page
      totalPages
    }
  }
`;
