import React, { useContext } from "react";
import Container from "@mui/material/Container";
import UserForm from "./UserForm";
import { MainContext } from "../../../Context/MainCtx";
import CustomLoader from "../../../Components/CustomLoader";
import { useQuery } from "@apollo/client";
import ErrorMessage from "../../../Components/ErrorMessage";
import { Navigate } from "react-router-dom";
import { GetUserData } from "../../Home/Perfil/gql/queries";

function PerfilManager() {
  const { tokenDecoded, tokenSesion, setErrors } = useContext(MainContext);

  const { data, loading, error, refetch } = useQuery(GetUserData, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: `Bearer ${tokenSesion}`,
      },
    },
  });

  React.useEffect(() => {
    if (error) setErrors({ ...error, funcion: "getUser" });
  }, [error, setErrors]);

  if (!tokenDecoded) return <Navigate to="/" />;
  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage height="90vh" message={error.message} />;

  return (
    <Container maxWidth="md">
      <UserForm data={data} refetch={refetch} />
    </Container>
  );
}

export default PerfilManager;
