import { gql } from "@apollo/client";

export const GetTeams = gql`
  query getTeams($filter: String, $limit: Int, $page: Int) {
    getTeams(filter: $filter, limit: $limit, page: $page) {
      docs {
        _id
        nombre
        estado {
          _id
          estado
          pais
        }
        createdAt
      }
      totalDocs
      page
      totalPages
    }
  }
`;
