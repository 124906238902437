import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import faq from "../../../assets/images/faq.png";
import { GetFAQs } from "../../Dashboard/Settings/Faq/graphql/queries";
import CustomLoader from "../../../Components/CustomLoader";
import ErrorMessage from "../../../Components/ErrorMessage";
import { useQuery } from "@apollo/client";
import PreviewContent from "../../../Components/CustomEditor/PreviewContent";
import LazyImage from "../../../Components/LazyImage";

function PreguntasFrec() {
  const { loading, data, error } = useQuery(GetFAQs, {
    fetchPolicy: "network-only",
  });

  if (loading) return <CustomLoader />;
  if (error) return <ErrorMessage message={error?.message} />;

  const FAQs = data?.getFaqs || [];

  return (
    <Container maxWidth="md" sx={{ pt: 1, minHeight: "calc(100vh - 200px)" }}>
      <Box
        sx={{
          height: 50,
          width: { xs: "100%", md: 400 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LazyImage src={faq} alt="preguntas frecuentes" />
      </Box>
      <Box sx={{ py: 2 }}>
        {FAQs.map((res, index) => (
          <Accordion
            key={index}
            variant="outlined"
            sx={{
              border: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <b>{`${index + 1} - ${res.pregunta}`}</b>
            </AccordionSummary>
            <AccordionDetails>
              <PreviewContent content={res.respuesta} />
              {res.image ? (
                <Box
                  sx={{
                    height: 400,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LazyImage alt={`faq-${index + 1}`} src={res.image} />
                </Box>
              ) : null}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}

export default PreguntasFrec;
